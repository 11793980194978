import React, { useRef, useState } from "react";
import cn from "classnames";
import { projects } from "./ExternalLinks.helper";
import ExternalPopup from "./ExternalPopup/ExternalPopup";
import css from "./ExternalLinks.module.scss";
import EventsTrackWrapperClick from "../../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";

const ExternalLinks = () => {
  const linksRef = useRef(null);
  const [currentProject, setCurrentProject] = useState(null);

  return (
    <div ref={linksRef} className={css.wrapper}>
      {projects.map((project, i) => (
        <EventsTrackWrapperClick
          needParent
          key={i}
          id={182}
          replace={[project.title]}
        >
          <div
            role="button"
            tabIndex={0}
            className={css.link}
            style={{ color: project.color }}
            onClick={() => setCurrentProject(project)}
          >
            <img
              className={cn(css.icon, css[project.name])}
              src={project.logoSrc}
              alt={project.name}
            />
            <div
              className={css.text}
              dangerouslySetInnerHTML={{ __html: project.title }}
            />
          </div>
        </EventsTrackWrapperClick>
      ))}

      {currentProject && (
        <ExternalPopup
          ref={linksRef}
          project={currentProject}
          onClose={() => setCurrentProject(null)}
        />
      )}
    </div>
  );
};

export default ExternalLinks;
