import { useContext } from "react";
import {
  AuthActionParam,
  ComponentType,
  AuthActionType,
  AuthAction,
} from "../../../services/sudirService";
import { AppContext } from "../../Root";
import EventsTrackWrapperClick from "../EventsTrack/wrappers/EventsTrackWrapperClick";

export function HeaderLoggedOut() {
  const { sudirService } = useContext(AppContext);

  const openModal = () => {
    const thisContainerData: AuthActionParam = {
      component: ComponentType.HEADER,
      type: AuthActionType.JOIN_PROJECTS_SILENTLY,
      args: {},
    };

    const action1 = new AuthAction(thisContainerData);
    return sudirService.authWithActions([action1]);
  };

  return (
    <div className="cr-header__inner-profile__loggedOut">
      <EventsTrackWrapperClick id={11}>
        <div
          role="button"
          tabIndex={0}
          className="ic promo-icon-87"
          onClick={() => openModal()}
        />
      </EventsTrackWrapperClick>
    </div>
  );
}
