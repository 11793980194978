import {
  FC,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import "./Experts.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import SliderArrows from "../../../../presentational/Sliders/SliderArrows/SliderArrows";
import { User } from "../../../../../types/User.interface";
import UserService from "../../../../../services/userService";
import { RootState } from "../../../../../types/State.interface";
import UserAvatar from "../../../../presentational/UserAvatar/UserAvatar";
import {
  phoneWidth,
  wideTabletWidth,
} from "../../../../../utils/constants/widthConstants";
import Sign from "../Sign/Sign";
import { debounce } from "../../../../../utils";
import { AppModal } from "../../../../containers/Modal/AppModal";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import Button from "../../../../presentational/Controls/Button/Button";
import { useOuterClick } from "../../../../../hooks/useOnOutsideClick";
import { AppContext } from "../../../../Root";
import {
  AuthAction,
  AuthActionParam,
  AuthActionType,
  ComponentType,
} from "../../../../../services/sudirService";
import useDiscussionLandingContext from "../../hooks/useDiscussionLandingContext";
import { MediaPlatformSections } from "../../routing/routes";

export interface ExpertsProps {
  onLoadEnd?: (data) => void;
}

const Experts: FC<ExpertsProps> = ({ onLoadEnd }) => {
  const discussionLandingContext = useDiscussionLandingContext();
  const appContext = useContext(AppContext);
  const currentUser = useSelector((state: RootState) => state.user.userDetails);
  const ref = useOuterClick<HTMLDivElement>(() => {
    onHideTip();
  }, true);

  const currentProjects = useSelector(
    (state: RootState) => state.projects.current
  );
  const env = useSelector((state: RootState) => state.environment);

  const hoverAvatarRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const isWideTablet = useMediaQuery({
    query: `(max-width: ${wideTabletWidth}px)`,
  });
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>(null);
  const [experts, setExperts] = useState<User[]>(null);
  const [selectedExpert, setSelectedExpert] = useState<User>();

  const slidesPerView = isPhone ? 2 : 7;
  const spaceBetween = isPhone ? 20 : isWideTablet ? 12 : 32;

  // ACTIONS AFTER AUTH
  useEffect(() => {
    if (!currentUser?.loggedIn || !env?.platformUrl) return;

    const actions = appContext.sudirService.getActions(
      ComponentType.DISCUSSION_LANDING_EXPERTS
    );
    if (actions.length) {
      const redirectAction = actions.find(
        (act) => act.type === AuthActionType.REDIRECT
      );
      if (redirectAction) {
        handleChatWithExpert(redirectAction.args.expertId);
      }
    }
  }, [currentUser?.loggedIn, env]);

  const onShowTip = (e, expert: User) => {
    hoverAvatarRef.current = e.target as HTMLDivElement;
    setSelectedExpert(expert);
  };

  const onHideTip = () => {
    hoverAvatarRef.current = null;
    setSelectedExpert(undefined);
  };

  useEffect(() => {
    if (currentProjects) {
      let onLoadEndData = [];
      const project = discussionLandingContext.projectCode
        ? currentProjects.find(
            ({ id }) => id === discussionLandingContext.projectCode
          )
        : undefined;
      if (project) {
        UserService.getExperts(project.id)
          .then(({ status, data }) => {
            if (status === ApiStatusCode.OK) {
              setExperts(data);
              onLoadEndData = data;
            }
          })
          .finally(() => {
            onLoadEnd(onLoadEndData);
          });
      } else {
        onLoadEnd(onLoadEndData);
      }
    }
  }, [currentProjects, discussionLandingContext.projectCode]);

  useLayoutEffect(() => {
    const setTipPosition = () => {
      if (
        !isPhone &&
        swiperInstance &&
        selectedExpert &&
        hoverAvatarRef.current &&
        tooltipRef.current
      ) {
        const { left, width } = hoverAvatarRef.current.getBoundingClientRect();
        const { width: tooltipWidth } =
          tooltipRef.current.getBoundingClientRect();
        tooltipRef.current.style.left = `${Math.min(
          swiperInstance.$el.outerWidth() - tooltipWidth,
          Math.max(
            0,
            left -
              swiperInstance.$el.offset().left -
              tooltipWidth * 0.5 +
              width * 0.5
          )
        )}px`;
      }
    };
    const setTipPositionDelay = debounce(setTipPosition, 10);

    setTipPosition();

    window.addEventListener("resize", setTipPositionDelay);

    return () => {
      setTipPositionDelay.cancel();
      window.removeEventListener("resize", setTipPositionDelay);
    };
  }, [isPhone, selectedExpert, swiperInstance]);

  const handleChatWithExpert = (expertId) => {
    if (!currentUser.loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.DISCUSSION_LANDING_EXPERTS,
        type: AuthActionType.REDIRECT,
        args: {
          expertId,
        },
      };
      const action1 = new AuthAction(thisContainerData);
      return appContext.sudirService.authWithActions([action1]);
    }

    window.location.href = `${env.platformUrl}/profile/mail?userId=${expertId}`;
  };

  const renderTip = (expert: User) => (
    <div className="discussion-landing-experts__info" ref={ref}>
      {isPhone && <UserAvatar user={expert} />}
      {!!expert.description && (
        <div
          className="discussion-landing-experts__info__description"
          dangerouslySetInnerHTML={{ __html: expert.description }}
        />
      )}

      <Sign
        name={`${expert.lastName} ${expert.firstName}`}
        position={expert.position}
      />
      <Button
        // hrefTarget="_blank"
        // linkUrl={env.platformUrl + `/profile/mail?userId=${expert.id}`}
        text="Задать вопрос"
        type="outlined"
        onClick={() => handleChatWithExpert(expert.id)}
      />
    </div>
  );

  if (!experts?.length) return <></>;

  return (
    <section
      className="discussion-landing-experts"
      id={MediaPlatformSections.EXPERT_TEAM}
    >
      <div className="discussion-landing-subtitle">Команда экспертов</div>
      <div className="discussion-landing-experts__slider__wrapper">
        <div className="discussion-landing-experts__slider">
          <div className="discussion-landing-experts__slider__inner">
            <Swiper
              onSwiper={setSwiperInstance}
              slidesPerView={slidesPerView}
              loop={experts.length > slidesPerView}
              spaceBetween={spaceBetween}
              observer
              observeParents
            >
              {experts.map((expert) => (
                <SwiperSlide key={expert.id}>
                  <div
                    onClick={(e) => {
                      if (expert === selectedExpert) {
                        onHideTip();
                      } else {
                        onShowTip(e, expert);
                      }
                    }}
                  >
                    <UserAvatar user={expert} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>

            {selectedExpert &&
              (isPhone ? (
                <AppModal
                  open
                  onClose={() => onHideTip()}
                  blockScroll={false}
                  classNames={{
                    modal: "discussion-landing-experts__modal",
                  }}
                  overlayId="modal-overlay"
                  closeIconId="close-modal-icon"
                >
                  {renderTip(selectedExpert)}
                </AppModal>
              ) : (
                <div
                  ref={tooltipRef}
                  className="discussion-landing-experts__tip"
                >
                  {renderTip(selectedExpert)}
                </div>
              ))}
          </div>
        </div>
        {experts.length > slidesPerView && (
          <SliderArrows swiper={swiperInstance} />
        )}
      </div>
    </section>
  );
};

export default Experts;
