import { useEffect, useRef, useState } from "react";
import cn from "classnames";
import OpenLayersMap from "../../../presentational/OpenLayersMap/OpenLayersMap";
import ResultPopup from "./ResultPopup/ResultPopup";
import EventsTrackWrapperScroll from "../../../containers/EventsTrack/wrappers/EventsTrackWrapperScroll";
import EventsTrackWrapperClick from "../../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";
import MappedIdeaService, {
  GetByPolygonResponse,
  MappedIdea,
} from "../../../../services/mappedIdea.service";
import { ApiStatusCode } from "../../../../types/Common.interface";
import ReportButton from "./ReportButton/ReportButton";
import css from "./ResultSection.module.scss";
import { shuffle } from "../../../../utils";
import Text from "../../../presentational/Typography/Text";
import { usePointerLeave } from "../../../../hooks/usePointerLeave";

const ResultSection = () => {
  const [points, setPoints] = useState(null);
  const [allMappedIdeas, setAllMappedIdeas] = useState(null);
  const [currentMappedIdeas, setCurrentMappedIdeas] = useState(null);
  const [hidden, setHidden] = useState(true);
  const [mapActive, setMapActive] = useState(false);
  const mapRef = useRef(null);
  const timerRef = useRef(null);

  useEffect(() => {
    MappedIdeaService.getByPolygon().then((response: GetByPolygonResponse) => {
      if (response.status === ApiStatusCode.OK) {
        if (Array.isArray(response.data)) {
          setAllMappedIdeas(response.data);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (allMappedIdeas?.length) {
      setPoints(
        allMappedIdeas.map((mappedIdea: MappedIdea) => ({
          id: mappedIdea.id,
          coordinates: [mappedIdea.latitude, mappedIdea.longitude],
        }))
      );
    }
  }, [allMappedIdeas]);

  const handleMapActiveOn = () => {
    clearTimeout(timerRef.current);
    setMapActive(true);
  };

  const handleMapActiveOff = () => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setMapActive(false);
    }, 2000);
  };

  usePointerLeave({
    ref: mapRef,
    cb: handleMapActiveOff,
    active: mapActive,
  });

  return (
    <EventsTrackWrapperScroll id={9}>
      <section className={cn(css.wrapper, hidden && css.hidden)}>
        <Text size={2} className={css.text}>
          Ознакомиться с результатами работы платформы можно на карте. Здесь
          отмечены идеи, предложенные жителями и реализованные городом.
        </Text>

        <div
          className={cn(css.map, mapActive && css.mapActive)}
          onPointerEnter={handleMapActiveOn}
          ref={mapRef}
        >
          {points && (
            <EventsTrackWrapperClick id={252}>
              <div>
                <OpenLayersMap
                  points={points}
                  clasterization={{}}
                  location={{}}
                  controlsCentered
                  onMapReady={() => setHidden(false)}
                  onClick={({ feature }) => {
                    const mappedIdeas = feature
                      .get("features")
                      .map((f) =>
                        allMappedIdeas.find((idea) => idea.id === f.get("id"))
                      );

                    setCurrentMappedIdeas(
                      shuffle(mappedIdeas).sort(
                        (a, b) =>
                          b.attachmentIds.length - a.attachmentIds.length
                      )
                    );
                  }}
                  onClickCluster={() => setCurrentMappedIdeas(null)}
                  onOutsideClick={() => setCurrentMappedIdeas(null)}
                />
              </div>
            </EventsTrackWrapperClick>
          )}
          {currentMappedIdeas && (
            <ResultPopup
              ideas={currentMappedIdeas}
              onClose={() => setCurrentMappedIdeas(null)}
            />
          )}
        </div>

        <div className={css.footer}>
          <ReportButton />
        </div>
      </section>
    </EventsTrackWrapperScroll>
  );
};

export default ResultSection;
