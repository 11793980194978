import "./PageDescription.scss";

interface TProps {
  text: string;
}

const PageDescription = ({ text }: TProps) => (
  <div
    className="page-description"
    dangerouslySetInnerHTML={{ __html: text }}
  />
);

export default PageDescription;
