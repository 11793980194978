import Text from "../../../../presentational/Typography/Text";
import { Link } from "./Link";
import styles from "./MayorLink.module.scss";

export const MayorLink = () => (
  <Link href="https://newtech.mos.ru/?utm_source=crowd&utm_medium=block&utm_campaign=promo">
    <div className={styles.wrapper}>
      <div className={styles.icon} />
      <div className={styles["text-container"]}>
        <Text size={4} className={styles.text} uppercase>
          Проекты Мэра Москвы
        </Text>
        <br />
        <Text size={4} className={styles.text} uppercase>
          Собянина С. С.
        </Text>
      </div>
    </div>
  </Link>
);
