import classNames from "classnames";
import { FC } from "react";
import Base from "../Base";
import { TextProps } from "./Text.types";
import styles from "./Text.module.scss";

const Text: FC<TextProps> = ({
  size = 1,
  paragraph = false,
  inherit = false,
  className,
  ...otherProps
}) => {
  const tag = paragraph ? "p" : "span";
  return (
    <Base
      component={tag}
      className={classNames(
        styles.text,
        {
          [styles[`size-${size}`]]: !inherit,
          [styles.inherit]: inherit,
        },
        className
      )}
      {...otherProps}
    />
  );
};

export default Text;
