import { Children, FC, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import classNames from "classnames";
import SwiperCore from "swiper";
import SliderArrows from "../SliderArrows/SliderArrows";
import { SliderProps } from "./Slider.interface";
import styles from "./Slider.module.scss";

const Slider: FC<SliderProps> = ({
  sliderOptions,
  arrowsOrtions,
  children,
  showArrows = true,
  containerClassName,
}) => {
  const [swiper, setSwiper] = useState<SwiperCore>();

  return (
    <div className={containerClassName}>
      <Swiper
        {...sliderOptions}
        onSwiper={(sw) => {
          setSwiper(sw);
          sliderOptions.onSwiper?.(sw);
        }}
      >
        {Children.map(children, (element, index) => (
          <SwiperSlide className={sliderOptions.slideClass} key={index}>
            {element}
          </SwiperSlide>
        ))}
      </Swiper>
      {showArrows && (
        <SliderArrows
          {...arrowsOrtions}
          swiper={swiper}
          className={classNames(
            arrowsOrtions?.className,
            styles["slider-arrows"]
          )}
          arrowOptions={{
            ...arrowsOrtions?.arrowOptions,
            left: {
              ...arrowsOrtions?.arrowOptions?.left,
              className: classNames(
                arrowsOrtions?.arrowOptions?.left?.className,
                styles["slider-arrow"]
              ),
            },
            right: {
              ...arrowsOrtions?.arrowOptions?.right,
              className: classNames(
                arrowsOrtions?.arrowOptions?.right?.className,
                styles["slider-arrow"]
              ),
            },
          }}
        />
      )}
    </div>
  );
};

export default Slider;
