import { FC, Fragment, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import EventsTrackWrapperClick from "../../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";
import GorodService, {
  GorodIdeaSphere,
} from "../../../../services/gorodService";
import {
  AuthAction,
  AuthActionParam,
  AuthActionType,
  ComponentType,
} from "../../../../services/sudirService";
import {
  selectIsUserReady,
  selectUserDetails,
} from "../../../../store/selectors/profile";
import { AppContext } from "../../../Root";
import Tooltip from "../../../presentational/Controls/Tooltip/Tooltip";
import { useIsMobile, useIsTablet } from "../../../../hooks/useMedia";
import styles from "./ProposeIdeaSection.module.scss";
import { selectSpheres } from "../../../../store/selectors/gorod";
import { shuffle } from "../../../../utils";
import ProposeIdeaForm from "../../../containers/Forms/ProposeIdeaForm/ProposeIdeaForm";
import ProposeIdeaSubmittedForm from "../../../containers/Forms/ProposeIdeaForm/ProposeIdeaSubmittedForm";
import Text from "../../../presentational/Typography/Text";
import Slider from "../../../presentational/Sliders/Slider/Slider";

const ProposeIdeaSection: FC = () => {
  const isPhone = useIsMobile();
  const isTablet = useIsTablet();

  const history = useHistory();
  const appContext = useContext(AppContext);
  const user = useSelector(selectUserDetails);
  const isUserReady = useSelector(selectIsUserReady);
  const spheres = useSelector(selectSpheres);

  const [selectedSphere, setSelectedSphere] = useState<GorodIdeaSphere>(null);
  const [isProposeModalOpen, setIsProposeModalOpen] = useState<boolean>(false);
  const [isSubmittedProposeModalOpen, setIsSubmittedProposeModalOpen] =
    useState<boolean>(false);

  const shuffledSpheres = useMemo<GorodIdeaSphere[]>(() => {
    if (!spheres) {
      return [];
    }

    const pinnedIndexes: number[] = [];
    const unpinnedSpheres: GorodIdeaSphere[] = [];
    spheres.forEach((item, i) => {
      if (item.pinnedOnPromo) {
        pinnedIndexes.push(i);
      } else {
        unpinnedSpheres.push(item);
      }
    });

    const shuffled = shuffle(unpinnedSpheres.slice());
    for (const i of pinnedIndexes) {
      shuffled.splice(i, 0, spheres[i]);
    }

    return shuffled.slice(0, 11).map((sphere) => ({
      ...sphere,
      name:
        sphere.name === "Предпринимательство" && isPhone
          ? "Предпринима-<br>тельство"
          : sphere.name,
    }));
  }, [spheres]);

  const openProposeForm = (sphere: GorodIdeaSphere) => {
    if (!sphere) {
      history.push("/propose");
      return;
    }

    if (!user.loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.MAIN_PROPOSE_IDEA_SECTION,
        type: AuthActionType.OPEN_PROPOSE_MODAL,
        args: {
          code: sphere?.code || null,
        },
      };

      const action1 = new AuthAction(thisContainerData);
      return appContext.sudirService.authWithActions([action1]);
    }

    setSelectedSphere(sphere);
    setIsProposeModalOpen(true);
  };

  // ACTIONS AFTER AUTH
  useEffect(() => {
    if (!spheres?.length || !isUserReady) return;

    const actions = appContext.sudirService.getActions(
      ComponentType.MAIN_PROPOSE_IDEA_SECTION
    );
    if (actions.length) {
      const openProposeFormAction = actions.find(
        (act) => act.type === AuthActionType.OPEN_PROPOSE_MODAL
      );
      if (openProposeFormAction) {
        const { code } = openProposeFormAction.args;
        const sphere = GorodService.getSphereByCode(code, spheres);
        openProposeForm(sphere);
      }
    }
  }, [spheres, isUserReady]);

  const renderItem = (sphere?: GorodIdeaSphere) => {
    const text = sphere ? sphere.name : "Предложить идею по другой сфере";
    const core = (
      <EventsTrackWrapperClick needParent id={55} replace={[text]}>
        <div
          className={classNames(styles.card, {
            [styles["card-empty"]]: !sphere,
            [styles["card-dpir"]]: sphere?.code === "dpir",
          })}
          onClick={() => openProposeForm(sphere)}
        >
          {sphere?.fillColor && (
            <div className={styles["fill-wrapper"]}>
              <div
                className={styles.fill}
                style={{ backgroundColor: sphere.fillColor }}
              />
            </div>
          )}
          <Text
            size={isPhone ? 4 : isTablet ? 5 : 3}
            wordBreak
            ellipsis={3}
            className={classNames(styles.title)}
          >
            <span lang="ru" dangerouslySetInnerHTML={{ __html: text }} />
          </Text>
          {sphere?.logoAttachmentId && (
            <div
              className={styles.bg}
              style={{
                backgroundImage: `url(/uploads/get/${sphere.logoAttachmentId})`,
              }}
            />
          )}
          {!sphere && <div className={styles.bg} />}
        </div>
      </EventsTrackWrapperClick>
    );
    const item =
      !!sphere && text.replaceAll("&nbsp;", " ").length > 25 ? (
        <Tooltip
          text={text}
          idx={`propose-idea-section-${sphere.id}`}
          place="top"
          isOuter
        >
          {core}
        </Tooltip>
      ) : (
        core
      );

    return item;
  };

  const renderList = () => [
    ...shuffledSpheres.map((sphere) => (
      <Fragment key={sphere.id}>{renderItem(sphere)}</Fragment>
    )),
    <Fragment key="all-spheres">{renderItem()}</Fragment>,
  ];

  return (
    <>
      <div className={styles.wrapper}>
        {!isPhone && renderList()}
        {isPhone && (
          <Slider
            sliderOptions={{
              className: styles.slider,
              slidesPerView: "auto",
              spaceBetween: 12,
              slideClass: styles.slide,
            }}
          >
            {renderList()}
          </Slider>
        )}
      </div>
      {isProposeModalOpen && (
        <ProposeIdeaForm
          sphere={selectedSphere}
          isOpen
          onClose={() => setIsProposeModalOpen(false)}
          setIsSubmittedProposeModalOpen={setIsSubmittedProposeModalOpen}
        />
      )}
      {isSubmittedProposeModalOpen && (
        <ProposeIdeaSubmittedForm
          isOpen
          onClose={() => setIsSubmittedProposeModalOpen(false)}
          handleOpenPropose={openProposeForm}
        />
      )}
    </>
  );
};

export default ProposeIdeaSection;
