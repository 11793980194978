import { useEffect, useState } from "react";
import classNames from "classnames";
import { Link } from "./Links/Link";
import { MayorLink } from "./Links/MayorLink";
import styles from "./TopSection.module.scss";
import { baseLinks, LinkData } from "./Links/linksData";
import { shuffleArray } from "./Links/helpers";
import Text from "../../../presentational/Typography/Text";

export const TopSection = () => {
  const [links, setLinks] = useState<LinkData[]>([]);

  useEffect(() => {
    setLinks(shuffleArray(baseLinks));
  }, []);

  const [isTopSectionVisible, setIsTopSectionVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 44) {
        setIsTopSectionVisible(false);
      } else {
        setIsTopSectionVisible(true);
      }
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //   if (!isTopSectionVisible) {
  //     return null;
  //   }

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles["wrapper-hidden"]]: !isTopSectionVisible,
      })}
    >
      <div className={styles.container}>
        <div className={styles.mayor}>
          <MayorLink />
        </div>
        <div className={styles["links-container"]}>
          <div className={styles.links}>
            {links.map((link) => (
              <Link href={link.href} key={link.id}>
                <Text size={3} className={styles["link-text"]}>
                  {link.title}
                </Text>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
