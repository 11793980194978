export type MediaPlatformItem = MediaPlatformDto & {
  url: string;
};

export interface MediaPlatformState {
  list?: MediaPlatformItem[];
}

export enum MediaPlatformStatus {
  NEW = "NEW",
  ACTIVE = "ACTIVE",
  FINISHED = "FINISHED",
}

export interface MediaPlatformDto {
  id: string;
  code: string;
  published: boolean;
  withProject: boolean;
  projectCode: string;
  withBroadcast: boolean;
  broadcastPriority: number;
  withPhotoReport: boolean;
  photoReportPriority: number;
  withAboutUs: boolean;
  aboutUsPriority: number;
  withEvent: boolean;
  eventPriority: number;
  withNews: boolean;
  newsPriority: number;
  withThematicProject: boolean;
  thematicProjectPriority: number;
  withCategory: boolean;
  categoryPriority: number;
  withOnlineStream: boolean;
  onlineStreamPriority: number;
  withExpertTeam: boolean;
  expertTeamPriority: number;
  withSuggestIdea: boolean;
  suggestIdeaPriority: number;
  withImplementedIdea: boolean;
  implementedIdeaPriority: number;
  withDiscussion: boolean;
  discussionPriority: number;
  status: MediaPlatformStatus;
}

export interface MediaPlatformThematicProject {
  id: string;
  categoryGroup: string;
  description: string;
  imageId: string;
  published: boolean;
  finishDate: string;
  startDate: string;
}

export interface MediaPlatformReview {
  id: string;
  link: string;
  news: string;
  resource: string;
  published: boolean;
}

export interface MediaPlatformProposedIdea {
  id: string;
  number: number;
  author: string;
  ideaText: string;
  categoryId: string;
  className?: string;
}

export interface MediaPlatformIdeaCategory {
  id: string;
  published: boolean;
  name: string;
  link: string;
  imageId: string;
  created: string;
  className?: string;
}

export interface MediaPlatformPhotoReport {
  id: string;
  coverId: string;
  imageIds: string[];
  name: string;
  published: boolean;
}

export interface MediaPlatformNews {
  id: string;
  header: string;
  mainPhotoId: string;
  newsText: string;
  publishDate: string;
  delayed: boolean;
}

export type MediaPlatformNewsList = Array<MediaPlatformNews>;

export interface TextSettingsDto {
  id: string;
  thematicProjectsText: string;
  ideaRatingText: string;
  ideaPhotoText: string;
  mediaPlatformText: string;
  mediaPlatformId: string;
}

export interface LandingIdeaTopicDto {
  id: string;
  mediaPlatformId: string;
  published: boolean;
  gorodIdeaGroupId: string;
  gorodIdeaCategoryId: string;
  color: string;
}
