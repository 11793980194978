import { useState, useEffect } from "react";
import { useIsTablet, useIsMobile } from "../../../hooks/useMedia";
import {
  LocalStorageKeys,
  LocalStorageService,
} from "../../../services/LocalStorage.service";
import Button from "../Controls/Button/Button";
import Text from "../Typography/Text";
import styles from "./AcceptCookie.module.scss";

const AcceptCookie: React.FC = () => {
  const [isAccepted, setIsAccepted] = useState(true);
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  useEffect(() => {
    const cookieAccepted = Boolean(
      LocalStorageService.getData(LocalStorageKeys.COOKIE_ACCEPTED)
    );
    setIsAccepted(cookieAccepted);
  }, []);

  const handleAccept = () => {
    LocalStorageService.saveData(LocalStorageKeys.COOKIE_ACCEPTED, "true");
    setIsAccepted(true);
  };

  if (isAccepted) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <Text size={2} className={styles.text}>
        Продолжая пользоваться платформой «Город идей»,
        {isTablet && !isMobile && <br />} вы соглашаетесь на обработку файлов
        cookie с использованием метрических программ.
        {isMobile && <br />} Это поможет сделать платформу еще более удобной и
        полезной{isMobile && <br />} для миллионов пользователей.{" "}
        <a
          href="https://www.mos.ru/legal/rules/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          Подробнее
        </a>
      </Text>
      <Button
        onClick={handleAccept}
        text="Принять"
        type="filled"
        classList={styles.button}
      />
    </div>
  );
};

export default AcceptCookie;
