import React, { ReactNode } from "react";
import Hammer from "react-hammerjs";

import Button from "../Controls/Button/Button";
import SimpleGrid from "./classes/SimpleGrid";
import RestGrid from "./classes/RestGrid";
import "./GridView.scss";
import EventsTrackWrapperClick from "../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";
import FourColumnLoader from "../Loaders/FourColumnLoader/FourColumnLoader";
import Pagination, { PaginationProps } from "../Controls/Pagination/Pagination";

interface Props {
  onInit?: (grid) => void;
  endpoint?: { url: string; params?: any };
  scrollToTopOnSlide?: boolean;
  onSlide?: () => void;
  arrowsPosition?: string;
  showLink?: boolean;
  linkText?: string;
  linkUrl?: string;
  linkType?: "link" | any;
  swippable?: boolean;
  columns?: string;
  autoRows?: string;
  gap?: string;
  extraItem?: any;
  restrictBy?: number;
  bindTo?: string;
  withUpdateLocation?: boolean;
  withPagination?: boolean;
  renderStub?: () => ReactNode;
  renderPagination?: (instance: GridView) => ReactNode;
  onLoadEnd?: (items) => void;
  onGridUpdated?: () => void;
  rebuildOnLastPage?: boolean;
}

class GridView extends React.Component<Props, any> {
  grid: any = null;

  constructor(props) {
    super(props);

    this.initGrid();
    this.grid.update();
  }

  initGrid() {
    switch (this.gridType) {
      case "rest":
        this.grid = new RestGrid(this);
        break;
      default:
        this.grid = new SimpleGrid(this);
    }

    if (this.props.onInit) {
      this.props.onInit(this);
    }
  }

  componentDidUpdate(nextProps, nextState) {
    if (this.propsDidChange(this.props, nextProps)) {
      this.grid.update();
    }
  }

  propsDidChange(oldProps, newProps) {
    if (oldProps.endpoint || newProps.endpoint) {
      return (
        oldProps.endpoint.url !== newProps.endpoint.url ||
        oldProps.endpoint.params?.tags !== newProps.endpoint.params?.tags
      );
    }
    return false;
  }

  get gridType() {
    if (this.props.endpoint) return "rest";
  }

  getItems() {
    return this.grid.getItems();
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  canSlidePrev() {
    return this.grid.canSlidePrev;
  }

  canSlideNext() {
    return this.grid.canSlideNext;
  }

  slidePrev = () => {
    if (!this.grid.canSlidePrev) return;

    if (this.props.scrollToTopOnSlide) this.scrollToTop();

    if (this.props.onSlide && typeof this.props.onSlide === "function") {
      this.props.onSlide();
    }

    return this.grid.slidePrev();
  };

  slideNext = () => {
    if (!this.grid.canSlideNext) return;

    if (this.props.scrollToTopOnSlide) this.scrollToTop();

    if (this.props.onSlide && typeof this.props.onSlide === "function") {
      this.props.onSlide();
    }

    return this.grid.slideNext();
  };

  renderArrowLeft = () => {
    let classes = "grid-view__arrows-btn ic promo-icon-105";
    if (!this.grid.canSlidePrev) classes += " arrow-disabled";
    if (this.props.arrowsPosition === "center") classes += " arrow-left-center";

    return (
      <EventsTrackWrapperClick needParent id={184} replace={["Слайдер влево"]}>
        <div className={classes} onClick={(e) => this.slidePrev()} />
      </EventsTrackWrapperClick>
    );
  };

  renderArrowRight = () => {
    let classes = "grid-view__arrows-btn ic promo-icon-88";
    if (!this.grid.canSlideNext) classes += " arrow-disabled";
    if (this.props.arrowsPosition === "center") classes += " arrow-right-center";

    return (
      <EventsTrackWrapperClick needParent id={184} replace={["Слайдер вправо"]}>
        <div className={classes} onClick={(e) => this.slideNext()} />
      </EventsTrackWrapperClick>
    );
  };

  renderPageNum: PaginationProps["renderPageNum"] = (base, page) => (
    <EventsTrackWrapperClick
      needParent
      id={184}
      replace={[`Номер страницы [${page + 1}]`]}
    >
      {base}
    </EventsTrackWrapperClick>
  );

  renderArrows = () => {
    let classes = "grid-view__arrows";
    if (this.props.arrowsPosition === "bottom") {
      classes += " grid-view__arrows-bottom";
    }

    return (
      <div className={classes}>
        {this.renderArrowLeft()}
        {this.renderArrowRight()}
      </div>
    );
  };

  renderLink() {
    return (
      this.props.showLink &&
      this.props.linkText &&
      this.props.linkUrl && (
        <div className="grid-view__link">
          {this.props.linkType && this.props.linkType === "link" ? (
            <EventsTrackWrapperClick
              needParent
              id={183}
              replace={[this.props.linkText]}
            >
              <a href={this.props.linkUrl}>{this.props.linkText}</a>
            </EventsTrackWrapperClick>
          ) : (
            <EventsTrackWrapperClick
              needParent
              id={184}
              replace={[this.props.linkText]}
            >
              <Button
                text={this.props.linkText}
                linkUrl={this.props.linkUrl}
                type="outlined"
              />
            </EventsTrackWrapperClick>
          )}
        </div>
      )
    );
  }

  renderItems() {
    return this.grid.renderItems();
  }

  handleSwipe = (e) => {
    if (e.direction === 4 && this.props.swippable) {
      this.slidePrev();
    }

    if (e.direction === 2 && this.props.swippable) {
      this.slideNext();
    }
  };

  renderPagination() {
    if (!this.props.withPagination) return;
    if (!this.grid?.pagination) return;

    const { pageNumber, totalPages } = this.grid.pagination;
    return (
      <Pagination
        currentPage={pageNumber}
        totalPages={totalPages}
        onPageChange={(page) => {
          if (this.props.scrollToTopOnSlide) this.scrollToTop();
          this.grid.page = page;
          this.grid.update();
        }}
        renderLeftArrow={this.renderArrowLeft}
        renderRightArrow={this.renderArrowRight}
        renderPageNum={this.renderPageNum}
      />
    );
  }

  render() {
    const items = this.renderItems();
    const hasItems = !!items?.length;

    if (this.grid.isFetching) return <FourColumnLoader isTitled={false} />;

    return this.props.renderStub && !hasItems && !this.grid.isDataFetching() ? (
      this.props.renderStub()
    ) : (
      <div className="grid-view">
        {this.props.arrowsPosition === "center" &&
          hasItems &&
          this.renderArrowLeft()}

        <Hammer onSwipe={this.handleSwipe}>
          <div
            className={`grid-view__list ${items.length < 6 && this.props.rebuildOnLastPage ? `last-page items-${items.length}` : ""}`} // костыль переписать
            style={{
              gridTemplateColumns: this.props.columns,
              gridAutoRows: this.props.autoRows,
              gridGap: this.props.gap,
              width: this.props.arrowsPosition === "center" ? "90%" : "100%",
            }}
          >
            {items}
            {this.props.extraItem}
          </div>
        </Hammer>

        {this.props.arrowsPosition === "center" &&
          hasItems &&
          this.renderArrowRight()}

        <div className="grid-view__footer">
          {this.props.renderPagination
            ? this.props.renderPagination(this)
            : this.renderPagination()}

          {this.props.arrowsPosition === "bottom" &&
            hasItems &&
            this.renderArrows()}

          {hasItems && this.renderLink()}
        </div>
      </div>
    );
  }
}

export default GridView;
