import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../../types/State.interface";
import { limitNumberToMax } from "../../../utils";
import ProfilePic from "../../presentational/ProfilePic/ProfilePic";
import EventsTrackWrapperClick from "../EventsTrack/wrappers/EventsTrackWrapperClick";

interface TProps {
  withNotifications: boolean;
}

export function HeaderUserLink({ withNotifications }: TProps) {
  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);
  const user = useSelector((state: RootState) => state.user.userDetails);
  const unreadNotifications = useSelector(
    (state: RootState) => state.notifications.unreadNotifications
  );

  if (!loggedIn) return null;

  return (
    <EventsTrackWrapperClick id={15}>
      <Link className="cr-header__userlink cr-header-link" to="/profile">
        <ProfilePic imageId={user.pictureId} isRounded />

        {withNotifications && unreadNotifications > 0 && (
          <div className="cr-header__userlink-notifications">
            {limitNumberToMax(unreadNotifications, 99)}
          </div>
        )}
      </Link>
    </EventsTrackWrapperClick>
  );
}
