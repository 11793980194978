import { FC, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import "./Reviews.scss";

import CommonSlider from "../../../../presentational/Sliders/CommonSlider/CommonSlider";
import {
  phoneWidth,
  tabletWidth,
} from "../../../../../utils/constants/widthConstants";
import ReviewCard from "./ReviewCard/ReviewCard";
import MediaPlatformService from "../../../../../services/mediaPlatform.service";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import { displayError } from "../../../../../utils";
import useDiscussionLandingContext from "../../hooks/useDiscussionLandingContext";
import { MediaPlatformReview } from "../../../../../types/MediaPlatform.interface";
import { MediaPlatformSections } from "../../routing/routes";

export interface ReviewsProps {
  onLoadEnd?: (data) => void;
}

const Reviews: FC<ReviewsProps> = ({ onLoadEnd }) => {
  const discussionLandingContext = useDiscussionLandingContext();
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const isTablet = useMediaQuery({
    query: `(max-width: ${tabletWidth}px) and (min-width: ${phoneWidth}px)`,
  });
  const [reviews, setReviews] = useState<MediaPlatformReview[]>([]);

  useEffect(() => {
    fetchData();
  }, [discussionLandingContext.id]);

  const fetchData = async () => {
    try {
      const response = await MediaPlatformService.getAboutUs({
        mediaPlatformId: discussionLandingContext.id,
      });

      if (response.status === ApiStatusCode.OK) {
        setReviews(response.data.filter((review) => review.published));
      } else {
        displayError("Ошибка при получении отзывов", response.message);
      }
    } catch (error) {
      displayError("Reviews", "Ошибка при получении отзывов");
    } finally {
      onLoadEnd([]);
    }
  };

  const handleOnInit = () => {};

  return reviews.length ? (
    <section
      id={MediaPlatformSections.ABOUT_US}
      className="discussion-landing-reviews"
    >
      <div className="discussion-landing-subtitle">О нас пишут</div>
      <CommonSlider
        onInit={handleOnInit}
        className="notifications__swiper"
        options={{
          loop: true,
          spaceBetween: 24,
          slidesPerView: isTablet ? 2 : isPhone ? 1 : 3,
          slidesPerColumn: 1,
          slidesPerGroup: 1,
        }}
      >
        {reviews.map((review) => (
          <ReviewCard key={review.id} {...review} />
        ))}
      </CommonSlider>
    </section>
  ) : null;
};
export default Reviews;
