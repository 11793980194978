import classNames from "classnames";
import { FC } from "react";
import { Link, LinkProps } from "react-router-dom";
import styles from "./AppLink.module.scss";
import { TextProps } from "../../Typography/Text";

export type AppLinkProps = LinkProps & {
  size?: TextProps["size"];
  inherit?: TextProps["inherit"];
  underline?: boolean;
};

const AppLink: FC<AppLinkProps> = ({
  children,
  className,
  size = 1,
  inherit = false,
  underline = false,
  ...otherProps
}) => (
  <Link
    className={classNames(styles.link, className, {
      [styles[`size-${size}`]]: !inherit,
      [styles.inherit]: inherit,
      [styles.link_underlined]: underline,
    })}
    {...otherProps}
  >
    {children}
  </Link>
);

export default AppLink;
