import littleBirthdayImage from "../../../../assets/special-projects/little-birthday.png";
import littleCityImage from "../../../../assets/special-projects/little-city.png";
import littleNewYear2024Image from "../../../../assets/special-projects/newYear2024-little-desktop.png";
import largeBirthdayImage from "../../../../assets/special-projects/large-birthday.jpg";
import largeCityImage from "../../../../assets/special-projects/large-city.jpg";
import largeNewYear2024Image from "../../../../assets/special-projects/large-newyear2024.jpg";
import newYear2024LargeTablet from "../../../../assets/special-projects/newYear2024-large-tablet.png";
import littleBirthday10Image from "../../../../assets/special-projects/little-birthday10.png";
import largeBirthday10Image from "../../../../assets/special-projects/large-birthday10.png";
import cultureLargeDesktopImage from "../../../../assets/special-projects/culture-large-desktop.png";
import cultureLittleDesktopImage from "../../../../assets/special-projects/culture-little-desktop.png";
import cultureLargeTabletImage from "../../../../assets/special-projects/culture-large-tablet.png";
import cultureLittleTabletImage from "../../../../assets/special-projects/culture-little-tablet.png";
import newyear2025Large from "../../../../assets/special-projects/newyear2025-large.jpg";
import newyear2025Little from "../../../../assets/special-projects/newyear2025-little.png";
import newyear2025TabletLarge from "../../../../assets/special-projects/newyear2025-tablet-large.jpg";
import { birthday10LandingUrl } from "../../CrowdBirthday10/CrowdBirthday10.constants";
import { TSpecialProject } from "../types/types";
import { newYear2025Url } from "../../NewYear2025/constants";

export const specialProjects: TSpecialProject[] = [
  {
    id: "6",
    images: {
      desktop: {
        large: newyear2025Large,
        little: newyear2025Little,
      },
      tablet: {
        large: newyear2025TabletLarge,
        little: newyear2025Little,
      },
      mobile: {
        large: newyear2025Large,
        little: newyear2025Little,
      },
    },
    title: "Новый год 2025",
    url: newYear2025Url,
  },
  {
    id: "5",
    images: {
      desktop: {
        large: largeBirthday10Image,
        little: littleBirthday10Image,
      },
      tablet: {
        large: largeBirthday10Image,
        little: littleBirthday10Image,
      },
      mobile: {
        large: largeBirthday10Image,
        little: littleBirthday10Image,
      },
    },
    title: "10 лет «Городу идей»",
    url: birthday10LandingUrl,
  },
  {
    id: "1",
    images: {
      desktop: {
        large: cultureLargeDesktopImage,
        little: cultureLittleDesktopImage,
      },
      tablet: {
        large: cultureLargeTabletImage,
        little: cultureLittleTabletImage,
      },
      mobile: {
        large: cultureLittleDesktopImage,
        little: cultureLittleDesktopImage,
      },
    },
    title: "Город обсуждает стандарты культурного досуга",
    url: "/culture",
  },
  {
    id: "2",
    images: {
      desktop: {
        large: largeNewYear2024Image,
        little: littleNewYear2024Image,
      },
      tablet: {
        large: newYear2024LargeTablet,
        little: littleNewYear2024Image,
      },
      mobile: {
        large: largeNewYear2024Image,
        little: littleNewYear2024Image,
      },
    },
    title: "Новый год 2024",
    url: "/newyear2024",
  },
  {
    id: "3",
    images: {
      desktop: {
        large: largeCityImage,
        little: littleCityImage,
      },
      tablet: {
        large: largeCityImage,
        little: littleCityImage,
      },
      mobile: {
        large: largeCityImage,
        little: littleCityImage,
      },
    },
    title: "Досуг в городе",
    url: "/city",
  },
  {
    id: "4",
    images: {
      desktop: {
        large: largeBirthdayImage,
        little: littleBirthdayImage,
      },
      tablet: {
        large: largeBirthdayImage,
        little: littleBirthdayImage,
      },
      mobile: {
        large: largeBirthdayImage,
        little: littleBirthdayImage,
      },
    },
    title: "9 лет «Городу идей»",
    url: "/birthday",
  },
];
