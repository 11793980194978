export interface LinkData {
  href: string;
  title: string;
  id: string;
}

export const baseLinks: LinkData[] = [
  {
    title: "Выполняй задания",
    href: "https://gz.mos.ru/?utm_source=crowd&utm_medium=block&utm_campaign=promo",
    id: "1",
  },
  {
    title: "Управляй домом",
    href: "https://ed.mos.ru/?utm_source=crowd&utm_medium=block&utm_campaign=promo",
    id: "2",
  },
  {
    title: "Голосуй",
    href: "https://ag.mos.ru/home/?utm_source=crowd&utm_medium=block&utm_campaign=promo",
    id: "3",
  },
  {
    title: "Для детей",
    href: "https://deti.ag.mos.ru/home/?utm_source=crowd&utm_medium=block&utm_campaign=promo",
    id: "4",
  },
  {
    title: "Используй баллы",
    href: "https://ag-vmeste.ru/main?utm_source=crowd&utm_medium=block&utm_campaign=promo",
    id: "5",
  },
];
