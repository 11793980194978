import { FooterSocialNetworks } from "../SocialNetworks/FooterSocialNetworks";
import Text from "../../../presentational/Typography/Text";
import css from "./FooterSocial.module.scss";
import { useIsTablet } from "../../../../hooks/useMedia";

export function FooterSocial() {
  const isTablet = useIsTablet();

  return (
    <div className={css.wrapper}>
      <Text size={4} className={css.label}>
        Контакты пресс-службы (для обращений СМИ):
      </Text>
      <a href="mailto:press@newtech.mos.ru" className={css.email}>
        press@newtech.mos.ru
      </a>

      <div className={css.social}>
        <Text size={4} className={css.subscribe}>
          Подписывайтесь на нас в
        </Text>
        <FooterSocialNetworks />
      </div>
    </div>
  );
}
