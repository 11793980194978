import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../types/State.interface";
import Validator from "../../../../../services/validator";
import SubmitForm from "../../../../containers/Forms/SubmitForm/SubmitForm";
import Input from "../../../../presentational/Controls/Input/Input";
import "./DiscussionEventRegistrationModal.scss";
import { displayError } from "../../../../../utils";
import {
  ApiStatusCode,
  BaseResponse,
} from "../../../../../types/Common.interface";
import { RegisterOnEventQuery } from "../../../../../services/mediaPlatform.service";

export interface DiscussionEventRegistrationModalProps {
  title: string;
  id: string;
  formKey: "broadcastId" | "eventId";
  formMethod: (
    query: RegisterOnEventQuery<"broadcastId" | "eventId">
  ) => Promise<BaseResponse>;
  onClose: () => void;
  onSuccess: () => void;
}

const DiscussionEventRegistrationModal = ({
  title,
  id,
  formKey,
  formMethod,
  onClose,
  onSuccess,
}: DiscussionEventRegistrationModalProps) => {
  const user = useSelector((state: RootState) => state.user.userDetails);

  const [firstName, setFirstName] = useState(user.firstName);
  const [firstNameValidator, setFirstNameValidator] = useState<Validator>(null);
  const [lastName, setLastName] = useState(user.lastName);
  const [lastNameValidator, setLastNameValidator] = useState<Validator>(null);
  const [email, setEmail] = useState(user.email);
  const [emailValidator, setEmailValidator] = useState<Validator>(null);

  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const submitForm = async (e) => {
    if (e) e.preventDefault();

    setSubmitted(true);
    if (formValid()) {
      setSubmitting(true);
      const query = {
        [formKey]: id,
        firstName,
        lastName,
        email,
      } as unknown as RegisterOnEventQuery<typeof formKey>;

      try {
        const response = await formMethod(query);
        if (response.status === ApiStatusCode.OK) {
          onClose();
          onSuccess();
        } else {
          displayError("Ошибка при регистраций", response.message);
        }
      } catch (error) {
        displayError(
          "DiscussionEventRegistrationModal",
          "Ошибка при регистрации"
        );
      }
    }
  };

  const formValid = () =>
    firstNameValidator.isValid() &&
    lastNameValidator.isValid() &&
    emailValidator.isValid();

  const renderContent = () => (
    <div className="support-form">
      <Input
        placeholder="Имя"
        isRequired
        value={firstName}
        onChange={(value) => setFirstName(value)}
        validateRules={{
          notEmpty: true,
        }}
        onInitValidator={(validator) => setFirstNameValidator(validator)}
        showError={submitted}
      />
      <Input
        placeholder="Фамилия"
        isRequired
        value={lastName}
        onChange={(value) => setLastName(value)}
        validateRules={{
          notEmpty: true,
        }}
        onInitValidator={(validator) => setLastNameValidator(validator)}
        showError={submitted}
      />
      <Input
        placeholder="Email"
        isRequired
        value={email}
        onChange={(value) => setEmail(value)}
        validateRules={{
          email: true,
        }}
        onInitValidator={(validator) => setEmailValidator(validator)}
        showError={submitted}
      />
    </div>
  );

  return (
    <SubmitForm
      user={user}
      isSubmitting={submitting}
      isOpen
      title={title}
      onClose={onClose}
      onSubmit={submitForm}
      sendBtnText="Зарегистрироваться"
      cancelBtnText="Отменить"
      classList="discussion-modal-modal discussion-landing-modal"
    >
      {renderContent()}
    </SubmitForm>
  );
};

export default DiscussionEventRegistrationModal;
