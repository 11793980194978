import { createContext } from "react";
import { MediaPlatformItem } from "../../../types/MediaPlatform.interface";
import {
  defaultMediaPlatforms,
  prepareMediaPlatform,
} from "../../../utils/mediaPlatform";

const DiscussionLandingContext = createContext<MediaPlatformItem>(
  prepareMediaPlatform(defaultMediaPlatforms[0])
);

export default DiscussionLandingContext;
