import { FC } from "react";
import classNames from "classnames";
import { BaseProps } from "./Base.types";
import styles from "./Base.module.scss";

const Base: FC<BaseProps> = ({
  component: Component,
  italic = false,
  bold = false,
  noWrap = false,
  wordBreak = false,
  align = "inherit",
  underline = false,
  ellipsis = false,
  className,
  children,
  uppercase = false,
}) => {
  const rowsEllipsis =
    typeof ellipsis === "number" ? ellipsis : (ellipsis && 1) || 0;

  return (
    <Component
      className={classNames(
        styles.base,
        {
          [styles.italic]: italic,
          [styles.bold]: bold,
          [styles.noWrap]: noWrap,
          [styles.wordBreak]: wordBreak,
          [styles.underline]: underline,
          [styles.ellipsis]: !!rowsEllipsis,
          [styles.uppercase]: uppercase,
        },
        styles[`align-${align}`],
        className
      )}
      style={{ WebkitLineClamp: rowsEllipsis || undefined }}
    >
      {children}
    </Component>
  );
};

export default Base;
