import React, { useState, useEffect, useCallback, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";

import { useMediaQuery } from "react-responsive";
import { Idea } from "../../../types/Idea.interface";
import { RootState } from "../../../types/State.interface";
import IdeaCard from "../../presentational/Cards/IdeaCard/IdeaCard";
import InfoCard from "../../presentational/Cards/InfoCard/InfoCard";
import UserDetailsModal from "../Modals/UserDetailsModal/UserDetailsModal";
import IdeasPhotoViewer from "../Modals/IdeasPhotoViewer/IdeasPhotoViewer";
import SendIdeaForm from "../Forms/SendIdeaForm/SendIdeaForm";
import { IdeasListProps } from "./Ideas.interface";
import {
  AuthAction,
  AuthActionParam,
  AuthActionType,
  ComponentType,
} from "../../../services/sudirService";
import { AppContext } from "../../Root";
import SliderArrows from "../../presentational/Sliders/SliderArrows/SliderArrows";
import "./IdeasList.scss";
import IdeaService from "../../../services/ideaService";
import { useForceUpdate } from "../../../hooks/useForceUpdate";
import Button from "../../presentational/Controls/Button/Button";
import useEventsTrackParentId from "../../../hooks/useEventsTrackParentId";
import EventsTrackWrapperClick from "../EventsTrack/wrappers/EventsTrackWrapperClick";
import ReportButton from "../../pages/Main/ResultSection/ReportButton/ReportButton";
import { ApiStatusCode } from "../../../types/Common.interface";
import { displayError } from "../../../utils";
import { useIsWideTablet } from "../../../hooks/useMedia";
import { selectIsNeedAfterRegistrationForm } from "../../../store/selectors/profile";
import MediaPlatformService from "../../../services/mediaPlatform.service";

const fetchAmout = 12;

const IdeasList = ({
  isShowReports,
  isShowInfoCard = true,
  spaceBetween,
  isAllPublished = false,
  isRandom = false,
  isShowAllInNewTab = false,
  loop = false,
  tags,
  ideaItemTitles,
  maxPages,
  viewerClassName,
  isCulture = false,
}: IdeasListProps) => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const forceUpdate = useForceUpdate();

  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);
  const isNeedAfterRegistrationForm = useSelector(
    selectIsNeedAfterRegistrationForm
  );
  const [ideasList, setIdeasList] = useState([]);
  const [ideasPaging, setIdeasPaging] = useState(null);
  const [isIdeasLoading, setIsIdeasLoading] = useState(false);
  const [nextPageLoaded, setNextPageLoaded] = useState<number>();
  const [prevPageLoaded, setPrevPageLoaded] = useState<number>();
  const showNewIdeas = useSelector(
    (state: RootState) => state.sittings.NEW_IDEAS
  );

  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>(null);
  const user = useSelector((state: RootState) => state.user.userDetails);

  const [ideaUploadModalOpen, setIdeaUploadModalOpen] = useState(false);
  const [idea, setIdea] = useState<Idea | null>(null);
  const [photoViewerOpen, setPhotoViewerOpen] = useState(false);
  const [authorModalOpen, setAuthorModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const isTablet = useIsWideTablet();
  const isPhone = useMediaQuery({ query: "(max-width: 500px)" });

  const parentEventsTrackId = useEventsTrackParentId();

  const getTotalPages = (paging) =>
    (maxPages
      ? Math.min(maxPages, paging?.totalPages || 0)
      : paging?.totalPages || 0);

  // ACTIONS AFTER AUTH
  useEffect(() => {
    if (!ideasList.length || !loggedIn || isNeedAfterRegistrationForm) return;

    const actions = appContext.sudirService.getActions(ComponentType.IDEA_LIST);
    if (actions.length) {
      const openIdeaModal = actions.find(
        (act) => act.type === AuthActionType.OPEN_IDEAS_MODAL
      );
      if (openIdeaModal) {
        if (openIdeaModal.args?.navigate) {
          scrollToList();
        }
        setIdeaUploadModalOpen(true);
      }

      const likeIdea = actions.find((act) => act.type === AuthActionType.LIKE);
      if (likeIdea) {
        IdeaService.ideaById(likeIdea.args.ideaId)
          .then((response) => {
            if (response.status === ApiStatusCode.OK) {
              const idea = response.data;
              onLike(idea);
              if (likeIdea.args?.navigate) {
                scrollToList();
              }
            }
          })
          .catch(console.log);
      }
    }
  }, [ideasList, loggedIn, isNeedAfterRegistrationForm]);

  useEffect(() => {
    dispatchNext(0).then((data) => {
      if (loop && data) {
        const totalPages = getTotalPages(data.paging);
        if (totalPages > 1) {
          dispatchNext(totalPages - 1, true);
        }
      }
    });
  }, []);

  useEffect(() => {
    swiperInstance?.update();
  }, [swiperInstance, ideasList?.length]);

  useEffect(() => {
    if (swiperInstance) {
      if (canFetchNext(currentIndex)) {
        dispatchNext(nextPageLoaded + 1);
      } else if (canFetchPrev(currentIndex)) {
        dispatchNext(prevPageLoaded - 1, true);
      }
    }
  }, [currentIndex]);

  useEffect(() => {
    if (+prevPageLoaded < getTotalPages(ideasPaging) - 1) {
      swiperInstance?.slideToLoop(swiperInstance.realIndex + fetchAmout, 0);
    }
  }, [prevPageLoaded]);

  const scrollToList = () => {
    document.querySelector(".ideas-list").scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const dispatchNext = (page, isPrev = false) => {
    const statuses = ["PUBLISHED_MAIN"];
    if (isAllPublished) {
      statuses.push("PUBLISHED");
    }

    const query = {
      page,
      size: fetchAmout,
      sort: "main_pos,desc",
    } as any;

    const params = {
      statuses,
      isRandom,
      hidden: false,
    } as any;

    if (tags) {
      params.tags = tags;
    }
    if (ideaItemTitles) {
      params.ideaItemTitles = ideaItemTitles;
    }

    setIsIdeasLoading(true);

    const response = !isCulture
      ? MediaPlatformService.getByFilter({
          page,
          size: fetchAmout,
        })
      : IdeaService.getByFilter(query, params);

    return response
      .then(({ data, paging }) => {
        setIdeasList((prev) => {
          const changedList = Array.isArray(prev) ? [...prev] : [];
          if (data) {
            if (isPrev) {
              const lastPageRemainder = changedList.length % fetchAmout;
              changedList.splice(
                changedList.length -
                  (getTotalPages(paging) - 1 - page) * fetchAmout +
                  (lastPageRemainder ? fetchAmout - lastPageRemainder : 0),
                0,
                ...data
              );
            } else {
              changedList.splice(page * fetchAmout, 0, ...data);
            }
          }

          return changedList;
        });
        setIdeasPaging(paging);
        setIsIdeasLoading(false);
        if (isPrev) {
          setPrevPageLoaded(page);
        } else {
          setNextPageLoaded(page);
        }
        forceUpdate();
        return { data, paging };
      })
      .catch((err) => {
        displayError("IdeasList", "Ошибка получения идей");
        setIsIdeasLoading(false);
      });
  };

  const setIdeaUploadModalOpenCb = useCallback(
    () => setIdeaUploadModalOpen(false),
    [ideaUploadModalOpen]
  );

  const openIdeaUploadModal = function () {
    if (!loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.IDEA_LIST,
        type: AuthActionType.OPEN_IDEAS_MODAL,
        args: { navigate: true },
      };

      const action1 = new AuthAction(thisContainerData);

      return appContext.sudirService.authWithActions([action1]);
    }

    setIdeaUploadModalOpen(true);
  };

  const pushPage = (item, page) => {
    history.push({
      pathname: `/${page}`,
      state: { item },
    });
  };

  // RENDER

  const renderInfoCard = () => {
    const btnText = "Загрузить фото";
    return (
      <InfoCard
        icon="promo-icon-1105"
        title={showNewIdeas && "Новый список идей"}
        onTitleClick={() => {
          history.push("/photos#ideas");
        }}
        text="Подтверждайте реализацию идей вместе с нами! <br> Вы можете отправить фото реализованных идей для публикации."
        subText={`<a ${
          parentEventsTrackId
            ? `data-click-id='${JSON.stringify([parentEventsTrackId, 197])}'`
            : ""
        } href="/photos" target="_blank">Основные условия</a>`}
        renderButton={() => (
          <EventsTrackWrapperClick needParent id={182} replace={[btnText]}>
            <Button
              type="filled"
              text={btnText}
              onClick={() => openIdeaUploadModal()}
            />
          </EventsTrackWrapperClick>
        )}
      >
        {isShowReports && <ReportButton />}
      </InfoCard>
    );
  };

  const setNextIdea = (idea) => {
    const idx = ideasList.indexOf(idea);
    const next = ideasList[idx + 1];
    if (next) {
      setIdea(next);
    } else {
      setIdea(ideasList[0]);
    }
  };

  const setPrevIdea = (idea) => {
    const idx = ideasList.indexOf(idea);
    const prev = ideasList[idx - 1];
    if (prev) {
      setIdea(prev);
    } else {
      setIdea(ideasList[ideasList.length - 1]);
    }
  };

  const canSlidePrev = () =>
    !isIdeasLoading && ideasList.length > 1 && (loop || currentIndex > 0);

  const canSlideNext = () =>
    !isIdeasLoading &&
    ideasList.length > 1 &&
    (loop || currentIndex < ideasList.length);

  const canFetchNext = (index) => {
    const loadedFromStart = (nextPageLoaded + 1) * fetchAmout;
    return (
      index >= loadedFromStart - 4 &&
      index < loadedFromStart &&
      nextPageLoaded < getTotalPages(ideasPaging) - 1 &&
      (!loop || nextPageLoaded < prevPageLoaded - 1)
    );
  };

  const canFetchPrev = (index) => {
    const lastPageRemainder = ideasList.length % fetchAmout;
    const loadedFromStart =
      ideasList.length -
      (getTotalPages(ideasPaging) - prevPageLoaded) * fetchAmout +
      (lastPageRemainder ? fetchAmout - lastPageRemainder : 0);
    return (
      loop &&
      index >= loadedFromStart &&
      index < loadedFromStart + 4 &&
      prevPageLoaded > 0 &&
      prevPageLoaded > nextPageLoaded + 1
    );
  };

  const onSlideChange = (sw: SwiperCore) => setCurrentIndex(sw.realIndex);

  const onLike = (idea: Idea) => {
    if (!loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.IDEA_LIST,
        type: AuthActionType.LIKE,
        args: {
          ideaId: idea.id,
          navigate: true,
        },
      };

      const action1 = new AuthAction(thisContainerData);
      return appContext.sudirService.authWithActions([action1]);
    }

    !idea.liked &&
      IdeaService.like(idea.id)
        .then((data) => {
          const newIdea = { ...idea, liked: true, rating: idea.rating + 1 };
          setIdeasList((prev) => {
            const i = prev.findIndex(({ id }) => id === idea.id);
            if (~i) {
              const changedList = [...prev];
              changedList.splice(i, 1, newIdea);
              return changedList;
            }
            return prev;
          });
          setIdea((prev) => (prev === idea ? newIdea : prev));
        })
        .catch(console.log);
  };

  const getClassListForSwiper = () => {
    let classList = "";
    if (isIdeasLoading) classList += "ideas-list__loading";
    return classList;
  };

  return (
    <div
      className={`ideas-list ${!isShowInfoCard ? "ideas-list_without-info-card" : ""}`}
    >
      {" "}
      {Boolean(ideasList?.length) && (
        <div className="ideas-list__wrapper">
          <Swiper
            className={getClassListForSwiper()}
            autoplay={{
              delay: 200000,
              disableOnInteraction: false,
            }}
            onAfterInit={(sw) => {
              sw.update();
            }}
            onSwiper={setSwiperInstance}
            onSlideChange={onSlideChange}
            loop={loop}
            slidesPerView={
              isShowInfoCard
                ? isPhone
                  ? 1
                  : isTablet
                    ? 2
                    : 3
                : isPhone
                  ? 1
                  : isTablet
                    ? 3
                    : 4
            }
            slidesPerGroup={
              photoViewerOpen
                ? 1
                : isShowInfoCard
                  ? isPhone
                    ? 1
                    : isTablet
                      ? 2
                      : 3
                  : isPhone
                    ? 1
                    : isTablet
                      ? 3
                      : 4
            }
            spaceBetween={spaceBetween || 32}
            autoHeight
          >
            {ideasList.map((idea: any, idx) => (
              <SwiperSlide key={idea.id}>
                <IdeaCard
                  user={user}
                  idea={idea}
                  onCardClick={(idea) => {
                    setIdea(idea);
                    setPhotoViewerOpen(true);
                  }}
                  onLike={onLike}
                  onTagClick={(idea) => pushPage(idea, "ideas")}
                  onAuthorClick={(idea) => {
                    setIdea(idea);
                    setAuthorModalOpen(true);
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          {isShowInfoCard && renderInfoCard()}
          <EventsTrackWrapperClick
            needParent
            id={183}
            replace={["Посмотреть все"]}
          >
            <Link
              className="ideas-list__all"
              to={`/ideas${tags ? `?tags=${tags.join(",")}` : ""}`}
              target={isShowAllInNewTab ? "_blank" : "_self"}
            >
              Посмотреть все
            </Link>
          </EventsTrackWrapperClick>{" "}
        </div>
      )}
      <SliderArrows swiper={swiperInstance} disabled={isIdeasLoading} />
      {/* MODALS */}
      {idea && authorModalOpen && (
        <UserDetailsModal
          idea={idea}
          isOpen={authorModalOpen}
          onClose={() => setAuthorModalOpen(false)}
        />
      )}
      {idea && (
        <IdeasPhotoViewer
          className={viewerClassName}
          idea={idea}
          isOpened={photoViewerOpen}
          onClose={(e) => setPhotoViewerOpen(false)}
          canSlideNext={canSlideNext()}
          canSlidePrev={canSlidePrev()}
          onSlideNext={() => {
            setNextIdea(idea);
            swiperInstance.slideNext();
          }}
          onSlidePrev={() => {
            setPrevIdea(idea);
            swiperInstance.slidePrev();
          }}
          onAuthorClick={(idea) => {
            setIdea(idea);
            setAuthorModalOpen(true);
          }}
          onLike={onLike}
        />
      )}
      {ideaUploadModalOpen && (
        <SendIdeaForm
          isOpened={ideaUploadModalOpen}
          user={user}
          onClose={() => setIdeaUploadModalOpenCb()}
        />
      )}
    </div>
  );
};

export default IdeasList;
