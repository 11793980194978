import dayjs from "dayjs";
import { Scrollbar } from "react-scrollbars-custom";
import {
  RegistrationStatus,
  RegistrationStatusToTextMap,
} from "../../../DiscussionLanding.constants";
import "./ActivityCard.scss";
import Button from "../../Button/Button";
import classNames from "classnames";
import { Activity } from "../Activity.interface";

dayjs.locale("ru");

export interface ActivityCardProps {
  data: Activity;
  onRegister: (data: Activity) => void;
}

const ActivityCard: React.FC<ActivityCardProps> = ({ data, onRegister }) => {
  const {
    description,
    finished,
    id,
    imageId,
    name,
    place,
    published,
    registrationFinished,
    startDate,
    status,
  } = data;

  return (
    <div
      className={classNames(
        "activity-card",
        `activity-card__${status.toLowerCase()}`
      )}
    >
      <img
        src={`/uploads/get/${imageId}`}
        alt={imageId}
        className="activity-card__image"
      />
      <div className="activity-card__content">
        <div className="activity-card__title">{name}</div>
        <div className="activity-card__splitter" />
        <div className="activity-card__place">{place}</div>
        <div className="activity-card__date">
          {dayjs(startDate).format("D MMMM YYYY, HH:mm")}
        </div>
        <Scrollbar
          className="activity-card__scroll discussion-landing-scroll"
          noDefaultStyles
        >
          <div
            className="activity-card__description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </Scrollbar>
        <Button
          type={
            status === RegistrationStatus.FINISHED ||
            status === RegistrationStatus.REGISTRATION_FINISHED ||
            status === RegistrationStatus.REGISTERED
              ? "outlined-grey"
              : "outlined"
          }
          onClick={(e) => onRegister(data)}
          text={RegistrationStatusToTextMap[status]}
          isDisabled={
            status === RegistrationStatus.FINISHED ||
            status === RegistrationStatus.REGISTRATION_FINISHED ||
            status === RegistrationStatus.REGISTERED
          }
        />
      </div>
    </div>
  );
};

export default ActivityCard;
