import { FC, useContext, useEffect, useState } from "react";
import "./StreamsAndOnlineMeetings.scss";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import SwiperCore from "swiper";
import { useSelector } from "react-redux";
import CommonSlider from "../../../../presentational/Sliders/CommonSlider/CommonSlider";
import {
  phoneWidth,
  wideTabletWidth,
} from "../../../../../utils/constants/widthConstants";
import StreamCard from "./StreamCard/StreamCard";
import MediaPlatformService from "../../../../../services/mediaPlatform.service";
import { displayError } from "../../../../../utils";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import { Stream } from "./StreamsAndOnlineMeetings.interface";
import DiscussionEventRegistrationModal from "../DiscussionEventRegistrationModal/DiscussionEventRegistrationModal";
import { AppContext } from "../../../../Root";
import {
  AuthAction,
  AuthActionParam,
  AuthActionType,
  ComponentType,
} from "../../../../../services/sudirService";
import { RegistrationStatus } from "../../DiscussionLanding.constants";
import DiscussionEventRegistrationSuccessModal from "../DiscussionEventRegistrationModal/DiscussionEventRegistrationSuccessModal/DiscussionEventRegistrationSuccessModal";
import useDiscussionLandingContext from "../../hooks/useDiscussionLandingContext";
import { MediaPlatformSections } from "../../routing/routes";
import { selectUserDetails } from "../../../../../store/selectors/profile";

export interface StreamsAndOnlineMeetingsProps {
  onLoadEnd?: (data) => void;
}

const StreamsAndOnlineMeetings: FC<StreamsAndOnlineMeetingsProps> = ({
  onLoadEnd,
}) => {
  const discussionLandingContext = useDiscussionLandingContext();
  const appContext = useContext(AppContext);
  const currentUser = useSelector(selectUserDetails);
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const isTablet = useMediaQuery({
    query: `(max-width: ${wideTabletWidth}px) and (min-width: ${phoneWidth}px)`,
  });
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>(null);
  const [streams, setStreams] = useState<Stream[]>(null);
  const [streamsWithRegistartion, setStreamsWithRegistartion] =
    useState<Stream[]>(null);
  const [selectedStream, setSelectedStream] = useState<Stream>(null);
  const [registrationStatusMap, setRegistrationStatusMap] = useState<{
    [key: string]: boolean;
  }>({});
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const history = useHistory();

  const streamsList = currentUser?.loggedIn ? streamsWithRegistartion : streams;

  // ACTIONS AFTER AUTH
  useEffect(() => {
    if (!currentUser?.loggedIn || !streamsWithRegistartion?.length) return;

    const actions = appContext.sudirService.getActions(
      ComponentType.StreamsAndOnlineMeetings
    );
    if (actions.length) {
      const registerOnBroadcastAction = actions.find(
        (act) => act.type === AuthActionType.REGISTER_ON_BROADCAST
      );
      if (registerOnBroadcastAction) {
        handleOnRegister(
          streamsWithRegistartion.find(
            (stream) => stream.id === registerOnBroadcastAction.args.streamId
          )
        );
        history.push(
          `${window.location.pathname}?section=StreamsAndOnlineMeetings`
        );
      }
    }
  }, [currentUser?.loggedIn, streamsWithRegistartion]);

  useEffect(() => {
    if (currentUser) {
      fetchData();
    }
  }, [currentUser, discussionLandingContext.id]);

  useEffect(() => {
    swiperInstance?.update();
    swiperInstance?.slideToLoop(0, 0);
  }, [swiperInstance, streamsList]);

  const fetchData = async () => {
    try {
      const response = await MediaPlatformService.getBroadcasts({
        mediaPlatformId: discussionLandingContext.id,
      });
      if (response.status === ApiStatusCode.OK) {
        setStreams(response.data);
        if (response.data.length && currentUser.loggedIn) {
          await fetchBrodcastRegistrationList(response.data);
        }
      } else {
        displayError("Ошибка при получении эфиров", response.message);
      }
    } catch (error) {
      displayError("StreamsAndOnlineMeetings", "Ошибка при получении эфиров");
    } finally {
      onLoadEnd([]);
    }
  };

  const fetchBrodcastRegistrationList = async (streams: Stream[]) => {
    try {
      const response = await MediaPlatformService.getBrodcastRegistrationList({
        broadcastIds: streams.map((s) => s.id).join(","),
      });
      if (response.status === ApiStatusCode.OK) {
        const registartionStatusMap = response.data.reduce((acc, item) => {
          acc[item.broadcastId] = item.registered;
          return acc;
        }, {});
        setRegistrationStatusMap(registartionStatusMap);
        setStreamsWithRegistartion(
          streams.map((stream: Stream) => ({
            ...stream,
            status:
              registartionStatusMap[stream.id] &&
              stream.status !== RegistrationStatus.FINISHED
                ? RegistrationStatus.REGISTERED
                : stream.status,
          }))
        );
      } else {
        displayError(
          "Ошибка при получении списка зарег. юзеров",
          response.message
        );
      }
    } catch (error) {
      displayError(
        "StreamsAndOnlineMeetings",
        "Ошибка при получении списка зарег. юзеров"
      );
    }
  };

  const handleOnInit = () => {};

  const handleOnSlideChange = () => {};

  const isUserRegisteredOnBroadcast = (stream) =>
    registrationStatusMap[stream.id];

  const handleOnRegister = (stream: Stream) => {
    if (!currentUser?.loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.StreamsAndOnlineMeetings,
        type: AuthActionType.REGISTER_ON_BROADCAST,
        args: {
          streamId: stream.id,
        },
      };

      const action = new AuthAction(thisContainerData);
      return appContext.sudirService.authWithActions([action]);
    }

    if (!isUserRegisteredOnBroadcast(stream)) {
      setSelectedStream(stream);
    }
  };

  const handleSuccessRegistration = () => {
    setShowSuccessModal(true);
    fetchData();
  };

  if (!streamsWithRegistartion?.length && !streams?.length) return <></>;

  return (
    <section className="dls" id={MediaPlatformSections.BROADCAST}>
      <div className="discussion-landing-subtitle">Эфиры и онлайн-встречи</div>
      <div className="dls-cards">
        <CommonSlider
          onInit={handleOnInit}
          onSwiper={setSwiperInstance}
          onSlideChange={handleOnSlideChange}
          className="notifications__swiper"
          containterClassName="box-shadow-padding"
          options={{
            loop: true,
            spaceBetween: 24,
            slidesPerView: isTablet ? 2 : isPhone ? 1 : 3,
            slidesPerColumn: 1,
            slidesPerGroup: 1,
            touchStartPreventDefault: false,
          }}
        >
          {streamsList?.map((item) => (
            <StreamCard
              key={item.id}
              data={item}
              onRegister={handleOnRegister}
            />
          ))}
        </CommonSlider>
      </div>

      {selectedStream && (
        <DiscussionEventRegistrationModal
          title={`Регистрация на эфир «${selectedStream.name}»`}
          id={selectedStream.id}
          formKey="broadcastId"
          formMethod={MediaPlatformService.registerOnBroadcast}
          onClose={() => setSelectedStream(null)}
          onSuccess={handleSuccessRegistration}
        />
      )}

      {showSuccessModal && (
        <DiscussionEventRegistrationSuccessModal
          text="Накануне эфира приглашение будет направлено на электронную почту, указанную при регистрации."
          onClose={() => setShowSuccessModal(false)}
        />
      )}
    </section>
  );
};
export default StreamsAndOnlineMeetings;
