import { AppColorsEnum } from "../../components/App.interface";
import { TRoute } from "../../types/navigation.interface";
import { RootState } from "../../types/State.interface";
import {
  SHOW_INFOMODAL,
  HIDE_INFOMODAL,
  SHOW_MAINTENANCE_MODAL,
  HIDE_MAINTENANCE_MODAL,
  SHOW_MOSRU_AUTH_FORM,
  TOGGLE_DOCUMENT_BLOCKED,
  SET_HEADER_OPTIONS,
  WIDEN_PAGE,
  LESSEN_PAGE,
  SET_APP_COLOR,
  EVENTS_TRACK_SCROLL_UPDATE,
  DISABLE_BODY_SCROLL,
  ENABLE_BODY_SCROLL,
  SET_WEB_VIEW_MODE,
  FULLSCREEN_PAGE,
  SHOW_QRCODE_MODAL,
  HIDE_QRCODE_MODAL,
  SET_AFTER_REGISTRATION_FORM_OPEN,
  SET_RESEND_EMAIL_MODAL_OPEN,
  LESSEN_MAIN_PAGE,
  ADAPTIVE_PAGE,
  LESSEN_MAIN_PAGE_WITH_BLOCK,
} from "../actions/LayoutActions";

export enum PageExpandEnum {
  LESSEN = "LESSEN",
  LESSEN_MAIN = "LESSEN_MAIN",
  LESSEN_MAIN_PAGE_WITH_BLOCK = "LESSEN_MAIN_PAGE_WITH_BLOCK",
  WIDEN = "WIDEN",
  FULLSCREEN = "FULLSCREEN",
  ADAPTIVE = "ADAPTIVE",
}

export interface LayoutState {
  showQrCodeModal: boolean;
  showInfoModal: boolean;
  infoModalMessage: string;
  infoModalJSX: string;
  infoModalOnDismiss: () => void | null;
  showMaintenanceModal: boolean;
  isShowMosRuAuthForm: boolean;
  returnUrl: string;
  documentIsBlocked: boolean;
  routes: {
    type: string;
    baseRoutes: TRoute[];
    mobileRoutes: TRoute[];
  };
  logo: { link: string };
  appColor: AppColorsEnum;
  eventsTrackScrollCounterTrigger: number;
  isBodyScrollDisabled: boolean;
  pageExpand: PageExpandEnum;
  inWebViewMode: boolean;
  afterRegistrationFormOpen: boolean;
  resendEmailModalOpen: boolean;
}

const initialState = {
  showQrCodeModal: false,
  showInfoModal: false,
  infoModalMessage: "",
  infoModalJSX: "",
  infoModalOnDismiss: null,
  showMaintenanceModal: false,
  isShowMosRuAuthForm: false,
  returnUrl: "",
  documentIsBlocked: false,
  routes: null,
  logo: null,
  eventsTrackScrollCounterTrigger: 0, // триггер для переинициализации IntersectionObserver в EventsTrackContainer
  appColor: AppColorsEnum.WHITE,
  isBodyScrollDisabled: false,
  pageExpand: PageExpandEnum.LESSEN,
  inWebViewMode: false,
  afterRegistrationFormOpen: false,
  resendEmailModalOpen: false,
};

export function layoutReducer(state: LayoutState = initialState, action) {
  switch (action.type) {
    case SHOW_QRCODE_MODAL:
      return {
        ...state,
        showQrCodeModal: true,
      };
    case HIDE_QRCODE_MODAL:
      return {
        ...state,
        showQrCodeModal: false,
      };
    case SHOW_MOSRU_AUTH_FORM:
      return {
        ...state,
        isShowMosRuAuthForm: true,
        returnUrl: action.payload,
      };

    case "HIDE_MOSRU_AUTH_FORM":
      return {
        ...state,
        isShowMosRuAuthForm: false,
        returnUrl: "",
      };

    case SHOW_INFOMODAL:
      return {
        ...state,
        showInfoModal: true,
        infoModalMessage: action.payload.message,
        infoModalJSX: action.payload.jsx || state.infoModalJSX,
        infoModalOnDismiss: action.payload.onDismiss,
      };

    case HIDE_INFOMODAL:
      return {
        ...state,
        showInfoModal: false,
        infoModalMessage: "",
        infoModalOnDismiss: null,
      };

    case SHOW_MAINTENANCE_MODAL:
      return {
        ...state,
        showMaintenanceModal: true,
      };

    case HIDE_MAINTENANCE_MODAL:
      return {
        ...state,
        showMaintenanceModal: false,
      };

    case TOGGLE_DOCUMENT_BLOCKED:
      return {
        ...state,
        documentIsBlocked: action.payload,
      };

    case SET_HEADER_OPTIONS:
      return {
        ...state,
        routes: {
          type: action.payload?.type,
          baseRoutes: action.payload?.routes?.baseRoutes,
          mobileRoutes: action.payload?.routes?.mobileRoutes,
        },
        logo: action.payload?.logo,
      };

    case WIDEN_PAGE:
      return {
        ...state,
        pageExpand: PageExpandEnum.WIDEN,
      };

    case LESSEN_PAGE:
      return {
        ...state,
        pageExpand: PageExpandEnum.LESSEN,
      };

    case ADAPTIVE_PAGE:
      return {
        ...state,
        pageExpand: PageExpandEnum.ADAPTIVE,
      };

    case LESSEN_MAIN_PAGE:
      return {
        ...state,
        pageExpand: PageExpandEnum.LESSEN_MAIN,
      };

    case LESSEN_MAIN_PAGE_WITH_BLOCK:
      return {
        ...state,
        pageExpand: PageExpandEnum.LESSEN_MAIN_PAGE_WITH_BLOCK,
      };

    case FULLSCREEN_PAGE:
      return {
        ...state,
        pageExpand: PageExpandEnum.FULLSCREEN,
      };

    case SET_WEB_VIEW_MODE:
      return {
        ...state,
        inWebViewMode: action.payload,
      };

    case SET_APP_COLOR:
      return {
        ...state,
        appColor: action.payload.appColor,
      };

    case DISABLE_BODY_SCROLL:
      return {
        ...state,
        isBodyScrollDisabled: true,
      };

    case ENABLE_BODY_SCROLL:
      return {
        ...state,
        isBodyScrollDisabled: false,
      };

    case EVENTS_TRACK_SCROLL_UPDATE:
      return {
        ...state,
        eventsTrackScrollCounterTrigger:
          state.eventsTrackScrollCounterTrigger + 1,
      };

    case SET_AFTER_REGISTRATION_FORM_OPEN:
      return {
        ...state,
        afterRegistrationFormOpen: action.payload,
      };

    case SET_RESEND_EMAIL_MODAL_OPEN:
      return {
        ...state,
        resendEmailModalOpen: action.payload,
      };

    default:
      return state;
  }
}

export const selectAfterRegistrationFormOpen = (state: RootState) =>
  state.layout.afterRegistrationFormOpen;
export const selectResendEmailModalOpen = (state: RootState) =>
  state.layout.resendEmailModalOpen;
