import "./LikeCounter.scss";
import classNames from "classnames";

interface LikeCounterProps {
  count: number;
  isLiked: boolean;
  onVote: () => void;
  onCounterClick: () => void;
  isLikeDisabled?: boolean;
}

const LikeCounter = ({
  count,
  onVote,
  isLiked,
  onCounterClick,
  isLikeDisabled,
}: LikeCounterProps) => {
  const handleCounterClick = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    onCounterClick();
  };

  const handleVote = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    onVote();
  };

  return (
    <div className="like-counter">
      <button
        className={classNames(
          "ic simple-btn like-counter__like",
          { [`promo-icon-59 like-counter__liked`]: isLiked },
          { [`promo-icon-like-empty`]: !isLiked },
          { [`like-counter__no-vote`]: !count },
          { "pointer-none": isLikeDisabled }
        )}
        onClick={handleVote}
      />
      {Boolean(count) && (
        <div className="like-counter__count" onClick={handleCounterClick}>
          {count}
        </div>
      )}
    </div>
  );
};
export default LikeCounter;
