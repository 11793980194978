import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "../../../../types/State.interface";
import "./LayoutControl.scss";
import { findUrlParam } from "../../../../utils";
import { setWebViewMode } from "../../../../store/actions/LayoutActions";
import { PageExpandEnum } from "../../../../store/reducers/layout";
import Footer from "../../Footer/Footer";
import { matchHiddenUrls } from "../../../../utils/url";
import AcceptCookie from "../../../presentational/AcceptCookie/AcceptCookie";

const LayoutControl: FC = ({ children }) => {
  const dispatch = useDispatch();
  const appColor = useSelector((state: RootState) => state.layout.appColor);
  const pageExpandState = useSelector(
    (state: RootState) => state.layout.pageExpand
  );
  const isBodyScrollDisabled = useSelector(
    (state: RootState) => state.layout.isBodyScrollDisabled
  );
  const inWebViewMode = findUrlParam("contentOnly", window.location.search);
  const location = useLocation();

  const getClassListForApp = () => {
    let classList = "app";
    if (appColor) classList += ` app-color-${appColor}`;
    return classList;
  };

  const canShowFooter = () =>
    !inWebViewMode && !matchHiddenUrls(location.pathname);

  const getClassListForWrapper = () => {
    let classList = "wrapper";
    if (pageExpandState === PageExpandEnum.LESSEN) {
      classList += " wrapper__lessen";
    } else if (pageExpandState === PageExpandEnum.LESSEN_MAIN) {
      classList += " wrapper__lessen-main";
    } else if (pageExpandState === PageExpandEnum.LESSEN_MAIN_PAGE_WITH_BLOCK) {
      classList += " wrapper__lessen-main-with-block";
    } else if (pageExpandState === PageExpandEnum.WIDEN) {
      classList += " wrapper__widen";
    } else if (pageExpandState === PageExpandEnum.FULLSCREEN) {
      classList += " wrapper__fullscreen";
    } else if (pageExpandState === PageExpandEnum.ADAPTIVE) {
      classList += " wrapper__lessen wrapper__adaptive";
    }
    return classList;
  };

  useEffect(() => {
    const { body } = document;
    if (isBodyScrollDisabled) {
      body.classList.add("layout__disable-scroll");
    } else {
      body.classList.remove("layout__disable-scroll");
    }
  }, [isBodyScrollDisabled]);

  useEffect(() => {
    if (inWebViewMode) {
      dispatch(setWebViewMode(true));
    }
  }, [inWebViewMode]);

  return (
    <div className={getClassListForApp()}>
      <div className={getClassListForWrapper()}>{children}</div>
      {canShowFooter() && <Footer />}
      <AcceptCookie />
    </div>
  );
};

export default LayoutControl;
