import React from "react";

interface Props {
  children: any;
  data: any;
}

export default class Slide extends React.Component<Props, any> {
  render() {
    return <>{this.props.children}</>;
  }
}
