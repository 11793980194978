import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import { useRef } from "react";
import EventsTrackWrapperClick from "../EventsTrack/wrappers/EventsTrackWrapperClick";
import { isActiveLink } from "./header.helpers";
import { TRoute } from "../../../types/navigation.interface";
import { usePointerLeave } from "../../../hooks/usePointerLeave";

interface TProps {
  item: TRoute;
  openDropdown: (id: string) => void;
  closeDropdown: (id: string) => void;
  dropdown: Record<string, boolean>;
}

export function HeaderRoute({
  item,
  openDropdown,
  closeDropdown,
  dropdown,
}: TProps) {
  const { pathname, search } = useLocation();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const isActiveListItem = (children) =>
    pathname !== "/" &&
    children.findIndex((child) => child.route.startsWith(pathname)) !== -1;

  usePointerLeave({
    ref: dropdownRef,
    cb: () => closeDropdown(item.id),
    active: item.children?.length > 0 && dropdown[item.id],
  });

  if (!item.children) {
    return (
      <li className="cr-header__inner-links-link cr-header-link" key={item.id}>
        <EventsTrackWrapperClick id={item.trackClickId}>
          <Link
            to={item.route}
            className={
              isActiveLink(pathname, search, item.route) ? "active-link" : ""
            }
          >
            {item.text}
          </Link>
        </EventsTrackWrapperClick>
        {item.badge && (
          <span className="cr-header__inner-links-link-badge">
            {item.badge}
          </span>
        )}
      </li>
    );
  }

  return (
    <EventsTrackWrapperClick key={item.text} id={item.trackClickId}>
      <div
        ref={dropdownRef}
        role="button"
        tabIndex={0}
        className={`cr-header__inner-links-dropdown cr-header-link ${
          isActiveListItem(item.children) ? "active-link" : ""
        }`}
        onPointerEnter={() => openDropdown(item.id)}
        onPointerLeave={() => closeDropdown(item.id)}
        onClick={() => openDropdown(item.id)}
      >
        {item.text}
        <div
          className={cn("cr-header__inner-links-dropdown-menu", {
            "cr-header__inner-links-dropdown-menu--active": dropdown[item.id],
          })}
        >
          {item.children.map((child) => (
            <EventsTrackWrapperClick key={child.text} id={child.trackClickId}>
              <NavLink
                key={child.id}
                onClick={() => closeDropdown(item.id)}
                to={child.route}
                activeClassName="active-link"
              >
                {child.text}
              </NavLink>
            </EventsTrackWrapperClick>
          ))}
        </div>
      </div>
    </EventsTrackWrapperClick>
  );
}
