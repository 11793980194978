import { FC, Fragment, ReactElement } from "react";
import "./Pagination.scss";

export interface PaginationProps {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  edgeCountPages?: number;
  pagesRange?: number;
  loop?: boolean;
  renderLeftArrow?: (base: ReactElement) => ReactElement;
  renderRightArrow?: (base: ReactElement) => ReactElement;
  renderPageNum?: (
    base: ReactElement,
    page: number,
    isEmpty: boolean
  ) => ReactElement;
}

const Pagination: FC<PaginationProps> = ({
  totalPages,
  currentPage,
  edgeCountPages = 1,
  pagesRange = 2,
  onPageChange,
  loop = false,
  renderLeftArrow,
  renderRightArrow,
  renderPageNum,
}) => {
  const pageNum = (page: number, isEmpty = false) => {
    const res = (
      <span
        className={`cr-pagination__num ${page === currentPage ? "cr-pagination__num_active" : ""}`}
        onClick={() => {
          onPageChange(page);
        }}
      >
        {isEmpty ? "..." : page + 1}
      </span>
    );

    return renderPageNum ? renderPageNum(res, page, isEmpty) : res;
  };

  const isSpaceBefore =
    currentPage > edgeCountPages + Math.floor(pagesRange / 2) &&
    totalPages > edgeCountPages + pagesRange + 1;

  const isSpaceAfter =
    currentPage < totalPages - 1 - edgeCountPages - Math.ceil(pagesRange / 2) &&
    totalPages > edgeCountPages + pagesRange + 1;

  let minPageInside = currentPage - Math.floor(pagesRange / 2);
  const maxPossibleMinPageInside = totalPages - pagesRange - 1;
  if (minPageInside < 0) {
    minPageInside = 0;
  } else if (minPageInside > maxPossibleMinPageInside) {
    minPageInside = maxPossibleMinPageInside;
  }

  const leftArrow = () => {
    const res = (
      <span
        className={`cr-pagination__arrow cr-pagination__arrow-left ${
          !loop && currentPage === 0 ? "cr-pagination__arrow_disabled" : ""
        } ic promo-icon-105`}
        onClick={() =>
          onPageChange(currentPage > 0 ? currentPage - 1 : totalPages - 1)}
      />
    );
    return renderLeftArrow ? renderLeftArrow(res) : res;
  };

  const rightArrow = () => {
    const res = (
      <span
        className={`cr-pagination__arrow cr-pagination__arrow-right ${
          !loop && currentPage === totalPages - 1
            ? "cr-pagination__arrow_disabled"
            : ""
        } ic promo-icon-88`}
        onClick={() =>
          onPageChange(currentPage < totalPages - 1 ? currentPage + 1 : 0)}
      />
    );

    return renderRightArrow ? renderRightArrow(res) : res;
  };

  return (
    totalPages > 1 && (
      <div className="cr-pagination">
        {leftArrow()}
        {Array.from(Array(edgeCountPages), (_, num) => (
          <Fragment key={num}>{num < totalPages && pageNum(num)}</Fragment>
        ))}
        {isSpaceBefore && pageNum(edgeCountPages, true)}
        {Array.from(Array(pagesRange + 1), (_, n) => {
          const p = minPageInside + n;
          return (
            <Fragment key={p}>
              {p >= edgeCountPages &&
                p < totalPages - edgeCountPages &&
                pageNum(p)}
            </Fragment>
          );
        })}
        {isSpaceAfter && pageNum(totalPages - edgeCountPages - 1, true)}
        {Array.from(Array(edgeCountPages), (_, num) => {
          const p = totalPages - edgeCountPages + num;
          return (
            <Fragment key={p}>{p >= edgeCountPages && pageNum(p)}</Fragment>
          );
        })}
        {rightArrow()}
      </div>
    )
  );
};

export default Pagination;
