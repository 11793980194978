import "./StreamCard.scss";
import { Scrollbar } from "react-scrollbars-custom";
import dayjs from "dayjs";
import classNames from "classnames";
import Button from "../../Button/Button";
import UserAvatar from "../../../../../presentational/UserAvatar/UserAvatar";
import {
  RegistrationStatus,
  RegistrationStatusToTextMap,
} from "../../../DiscussionLanding.constants";
import { Stream } from "../StreamsAndOnlineMeetings.interface";
import { DataAdapter } from "../../../../../../services/adapter/data-adapter";

dayjs.locale("ru");

interface StreamProps {
  data: Stream;
  onRegister: (data: Stream) => void;
}

const StatusToColorMap = {
  [RegistrationStatus.WAIT]: "#F4649C",
  [RegistrationStatus.NOW]: "#F4649C",
  [RegistrationStatus.REGISTERED]: "#04CC88",
  [RegistrationStatus.FINISHED]: "#DEDEDE",
  [RegistrationStatus.REGISTRATION_FINISHED]: "#DEDEDE",
};

const StreamCard: React.FC<StreamProps> = ({ data, onRegister }) => {
  const {
    id,
    description,
    name,
    published,
    registrationFinished,
    speaker,
    speakerPhotoId,
    speakerTitle,
    startDate,
    status,
  } = data;

  return (
    <div
      className={classNames("dls-card", `dls-card__${status.toLowerCase()}`)}
    >
      <div className="dls-card__content">
        <div className="dls-card__title">{name}</div>
        <div className="dls-card__date">
          {dayjs(startDate).format("D MMMM YYYY, HH:mm")}
        </div>
        {speaker && (
          <div className="dls-card__expert">
            <UserAvatar
              user={DataAdapter.toUser({
                firstName: speaker,
                pictureId: speakerPhotoId,
                expert: true,
              })}
            />
            <div className="dls-card__expert-info">
              <div className="dls-card__expert-name">{speaker}</div>
              <Scrollbar
                className="dls-card__scroll-expert-position discussion-landing-scroll"
                noDefaultStyles
              >
                <div className="dls-card__expert-position">{speakerTitle}</div>
              </Scrollbar>
            </div>
          </div>
        )}
        <Scrollbar
          className="dls-card__scroll-text discussion-landing-scroll"
          noDefaultStyles
        >
          <div
            className="dls-card__text"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </Scrollbar>
      </div>
      <div className="dls-card__button">
        <Button
          type="filled"
          onClick={(e) => onRegister(data)}
          text={RegistrationStatusToTextMap[data.status]}
          color={StatusToColorMap[data.status]}
          isDisabled={
            data.status === RegistrationStatus.FINISHED ||
            data.status === RegistrationStatus.REGISTRATION_FINISHED ||
            data.status === RegistrationStatus.REGISTERED
          }
        />
      </div>
    </div>
  );
};
export default StreamCard;
