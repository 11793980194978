import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";

import { useSelector } from "react-redux";
import Loader from "../../../presentational/Loaders/Loader/Loader";
import { declOfNum, getAvatarSrc, getUserSystems } from "../../../../utils";
import UserService from "../../../../services/userService";
import IdeasService from "../../../../services/ideaService";
import { AppModal } from "../../Modal/AppModal";

import "swiper/swiper.scss";
import "./UserDetailsModal.scss";
import {
  canSeeHiddenNames,
  nameIfHidden,
  renderHatIfUserNameIsHidden,
} from "../../../../utils/user.utils";
import { RootState } from "../../../../types/State.interface";

export interface UserDetailsModalProps {
  idea: any;
  isOpen?: boolean;
  onClose?: Function;
}

const SLIDER_PAGE_SIZE = 100;
const SLIDE_BEFORE_LOAD_NEXT_PAGE = 10;

const UserDetailsModal = (props: UserDetailsModalProps) => {
  const currentUser = useSelector((state: RootState) => state.user.userDetails);

  const [loading, setLoading] = useState(false);
  const [author, setAuthor] = useState<any>();
  const [ideas, setIdeas] = useState<any>([]);
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>(null);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [realIndex, setRealIndex] = useState<number>(0); // It needs for render after change slide

  const loadIdeas = (): Promise<any> =>
    IdeasService.getByFilter(
      {
        size: SLIDER_PAGE_SIZE,
        page: currentPage,
      },
      {
        isRandom: false,
        userId: props.idea.author.id,
        statuses: ["PUBLISHED", "PUBLISHED_MAIN"],
        hidden: false,
      }
    );

  const loadIdeasNextPage = (): void => {
    if (currentPage > 0 && currentPage < totalPages) {
      loadIdeas().then((data) => {
        setIdeas(ideas.concat(data.data));
      });
    }
  };

  const onNextSlide = (): void => {
    const lastSlideIndex = ideas.length - 1;
    const loadNextPageSlideIndex = lastSlideIndex - SLIDE_BEFORE_LOAD_NEXT_PAGE;
    if (swiperInstance.realIndex === loadNextPageSlideIndex) {
      setCurrentPage(currentPage + 1);
    }
    swiperInstance.slideNext();
  };

  useEffect(loadIdeasNextPage, [currentPage]);

  useEffect(() => {
    setLoading(true);

    const getPerson = UserService.getPerson(props.idea.author.id);
    Promise.all([getPerson, loadIdeas()])
      .then(([getPersonResult, getIdeasResult]) => {
        setTotalPages(getIdeasResult.paging.totalPages);
        const author = getPersonResult.data;
        const ideas = getIdeasResult?.data || [];
        setAuthor(author);
        setIdeas(ideas);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  const getName = () => {
    if (!props.idea.author) return "";

    const { deletedSudir, showName, firstName, lastName } = props.idea.author;
    const fullName = `${firstName} ${lastName}`;

    if (deletedSudir || (!showName && !canSeeHiddenNames(currentUser))) return nameIfHidden;

    return fullName;
  };

  const projectsDecl = (num) =>
    declOfNum(num, ["проекта", "проектов", "проектов"]);

  // RENDER

  const renderUserInfo = () => (
    <div className="user-details__info">
      <div className="user-details__info-avatar">
        {getAvatarSrc(author) && (
          <img src={getAvatarSrc(author)} alt="Аватар пользователя" />
        )}
      </div>
      <div className="user-details__info-name">
        {getName()}
        {renderHatIfUserNameIsHidden(author, currentUser)}
      </div>
      <div className="user-details__info-location">
        <div className="user-details__info-location-region">
          {author.moscowRegion}
        </div>
        <div className="user-details__info-location-district">
          {author.moscowDistrict}
        </div>
      </div>
    </div>
  );

  const renderStats = () => {
    const projectsCount = author.projectsCount || 0;
    const ideasCount = author.proposalCount || 0;
    const photoCount = author.ideaPhotoCount || 0;

    return (
      <div className="user-details__stats">
        <div className="user-details__stats-counters">
          {renderStatItem(
            "Проекты",
            "в которых принято участие",
            projectsCount,
            "promo-icon-96"
          )}
          {renderStatItem(
            "Идеи",
            "предложенные на платформе",
            ideasCount,
            "promo-icon-1102"
          )}
          {renderStatItem(
            "Фото",
            "реализованных идей",
            photoCount,
            "promo-icon-1103"
          )}
        </div>
      </div>
    );
  };

  const renderStatItem = (title, subtitle, count, icon) => (
    <div className="user-details__statitem">
      <div className={`ic ${icon}`} />
      <div className="user-details__statitem-title">
        {title}
        {subtitle && <span>{subtitle}</span>}
      </div>
      <div className="user-details__statitem-count">{count}</div>
    </div>
  );

  const getSwipeControlClass = (direction: "prev" | "next"): string => {
    let cls = "user-details__ideas-swiper-control";

    if (
      direction === "next" &&
      swiperInstance?.realIndex === ideas.length - 1
    ) {
      cls += " control-disabled";
    }

    if (direction === "prev") {
      cls += " control-left";

      if (swiperInstance?.realIndex === 0) {
        cls += " control-disabled";
      }
    }

    return cls;
  };

  const renderIdeas = () => (
    <div className="user-details__ideas">
      <div className="user-details__ideas-swiper">
        {ideas?.length > 1 && (
          <div
            className={getSwipeControlClass("prev")}
            onClick={(e) => swiperInstance.slidePrev()}
          >
            <div className="ic promo-icon-88" />
          </div>
        )}

        <Swiper
          className="ideas-swiper"
          spaceBetween={10}
          slidesPerView={1}
          loop={false}
          onSwiper={(sw) => setSwiperInstance(sw)}
          onSlideChange={(sw) => setRealIndex(sw.realIndex)}
        >
          {ideas.map((idea, idx) => (
            <SwiperSlide key={idx}>
              {idea.watermarkAttachmentId && (
                <img
                  alt={idea.watermarkAttachmentId}
                  src={`/uploads/thumbnails/get/${idea.watermarkAttachmentId}`}
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>

        {ideas?.length > 1 && (
          <div className={getSwipeControlClass("next")} onClick={onNextSlide}>
            <div className="ic promo-icon-88" />
          </div>
        )}
      </div>
    </div>
  );

  const renderOtherProjects = () => {
    const userSystems = getUserSystems(author.userSystems);
    const projectsParticipant = userSystems.filter((s) => s.isParticipant);
    const projectsStr = projectsParticipant.reduce((acc, currVal, i) => {
      const currentValue = `«<a href="${currVal.url}" target="_blank">${currVal.name}</a>»`;
      return i === 0 ? currentValue : `${acc} и ${currentValue}`;
    }, "");
    const html = `Участник ${projectsDecl(projectsParticipant.length)} ${projectsStr}`;

    return (
      <div
        className="user-details__projects"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
  };

  return (
    <AppModal
      center
      classNames={{ modal: "user-details-modal" }}
      overlayId="modal-overlay"
      closeIconId="close-modal-icon"
      blockScroll
      closeIconSize={25}
      open={!!props.isOpen}
      closeOnEsc
      closeOnOverlayClick={false}
      onClose={props.onClose}
    >
      {loading ? (
        <Loader />
      ) : (
        author &&
        ideas && (
          <div className="user-details">
            {renderUserInfo()}
            {renderStats()}
            {renderIdeas()}
            {author.showParticipation && renderOtherProjects()}
          </div>
        )
      )}
    </AppModal>
  );
};

export default UserDetailsModal;
