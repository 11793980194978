export interface Stage {
  altName?: string;
  commentCount?: number;
  description?: string;
  created?: string;
  finish?: Date;
  id?: string;
  priority?: number;
  showOnPromo?: boolean;
  specification?: any;
  start?: Date;
  title?: string;
  type?: StageType | ProjectStageTypes;
  userCount?: number;
}

export enum ProjectStageTypes {
  GENERATION = "generation",
  SIMPLE_DISCUSSION = "simple_discussion",
  VOTING = "voting",
}

export enum StageTypes {
  GENERATION = "generation",
  VOTING = "voting",
  EXPERT_SELECTION = "expert_selection",
  IMPROVEMENT = "improvement",
  SIMPLE_DISCUSSION = "simple_discussion",
  PROJECT_DISCUSSION = "project_discussion",
}

export enum StageLinks {
  GENERATION = "generation",
  VOTING = "voting",
  EXPERT_SELECTION = "selection",
  IMPROVEMENT = "improvement",
  SIMPLE_DISCUSSION = "discussion",
  PROJECT_DISCUSSION = "projdisc",
}

export type StageType =
  | StageTypes.EXPERT_SELECTION
  | StageTypes.GENERATION
  | StageTypes.IMPROVEMENT
  | StageTypes.VOTING
  | StageTypes.PROJECT_DISCUSSION
  | StageTypes.SIMPLE_DISCUSSION;
