export interface SittingsData {
  data: any;
}

export type SittingKey = keyof typeof SittingsKeys;

export enum SittingsKeys {
  SHOW_PROJECT_CATEGORY = "SHOW_PROJECT_CATEGORY",
  NEW_IDEAS = "NEW_IDEAS",
  PROMO_BLOCKS = "PROMO_BLOCKS",
}

export type SittingsState = {
  [SittingsKeys.SHOW_PROJECT_CATEGORY]?: boolean;
  [SittingsKeys.NEW_IDEAS]?: boolean;
  [SittingsKeys.PROMO_BLOCKS]: string[];
};
