import { useSelector } from "react-redux";
import { RootState } from "../../../../types/State.interface";
import { FooterRoute } from "../FooterRoute/FooterRoute";
import css from "./FooterRoutes.module.scss";

export function FooterRoutes() {
  const routes = useSelector((state: RootState) => state.layout.routes);

  return (
    <nav className={css.routes}>
      {routes?.baseRoutes?.map((route) => (
        <FooterRoute key={route.id} route={route} />
      ))}
    </nav>
  );
}
