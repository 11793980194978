import { NewsLineState } from "../../types/NewsLine.interface";
import {
  GET_NEWS_LINES_FAIL,
  GET_NEWS_LINES_REQUEST,
  GET_NEWS_LINES_SUCCESS,
} from "../actions/NewsLineActions";

const initialState: NewsLineState = {
  lines: null,
  isFetchingLines: false,
  errorFetchingLines: "",
};

export const newsLineReducer = (
  state: NewsLineState = initialState,
  action
) => {
  switch (action.type) {
    case GET_NEWS_LINES_REQUEST:
      return {
        ...state,
        isFetchingLines: true,
        errorFetchingLines: "",
      };

    case GET_NEWS_LINES_SUCCESS:
      return {
        ...state,
        isFetchingLines: false,
        errorFetchingLines: "",
        lines: action.payload,
      };

    case GET_NEWS_LINES_FAIL:
      return {
        ...state,
        isFetchingLines: false,
        errorFetchingLines: action.payload.message,
      };

    default:
      return state;
  }
};
