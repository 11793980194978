import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";

import useQuery from "../../../hooks/useQuery";
import IdeaService from "../../../services/ideaService";
import ProjectService from "../../../services/projectService";
import {
  lessenPage,
  setAppColor,
  widenPage,
} from "../../../store/actions/LayoutActions";
import { ProjectInfo } from "../../../types/Banner.interface";
import { RootState } from "../../../types/State.interface";
import {
  phoneWidth,
  tabletWidth,
} from "../../../utils/constants/widthConstants";
import { AppColorsEnum } from "../../App.interface";
import CommonViewer from "../../containers/Modals/CommonViewer/CommonViewer";
import WorkResultCard from "../../presentational/Cards/WorkResultCard/WorkResultCard";
import ArrowBack from "../../presentational/Controls/ArrowBack/ArrowBack";
import Button from "../../presentational/Controls/Button/Button";
import Loader from "../../presentational/Loaders/Loader/Loader";
import { createList } from "../WorkResult/WorkResult.helper";
import { RealizedType } from "../WorkResult/WorkResultPage";
import "./WorkResultDetailedPage.scss";
import { GroupDirection } from "../../../types/GroupDirection.interface";
import MediaPlatformService from "../../../services/mediaPlatform.service";

const mapType = {
  realized: {
    title: "Реализованные идеи",
  },
  accepted: {
    title: "Принятые идеи",
  },
};

const limit = 200;

const WorkResultDetailedPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const scroll = useSelector((state: RootState) => state.globalEvents.scroll);

  const [collapseMap, setCollapseMap] = useState({
    realizedIdeas: true,
    acceptedIdeas: true,
    realizedSections: true,
    acceptedSections: true,
  });

  const isDesktop = useMediaQuery({ query: `(min-width: ${tabletWidth}px)` });
  const isTablet = useMediaQuery({
    query: `(max-width: ${tabletWidth}px) and (min-width: ${phoneWidth}px)`,
  });
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });

  const [project, setProject] = useState<ProjectInfo>(null);
  const [groupDirection, setGroupDirection] = useState<GroupDirection>(null);
  const [realizedIdeas, setRealizedIdeas] = useState(null);
  const [acceptedIdeas, setAcceptedIdeas] = useState(null);
  const [realizedSections, setRealizedSections] = useState(null);
  const [acceptedSections, setAcceptedSections] = useState(null);
  const [realizedAll, setRealizedAll] = useState(null);
  const [acceptedAll, setAcceptedAll] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [selectedIdea, setSelectedIdea] = useState(null);
  const [selectedList, setSelectedList] = useState(null);
  const [selectedListType, setSelectedListType] = useState("");

  useEffect(() => {
    dispatch(widenPage());
    dispatch(setAppColor(AppColorsEnum.WHITE));

    query.id
      ? fetchData(query.id, query.groupDirectionId)
      : history.push("/archive");

    return function () {
      dispatch(lessenPage());
    };
  }, []);

  useEffect(() => {
    if (!isLoading) {
      query.type && scrollToSection(query.type.toLowerCase());
    }
  }, [isLoading]);

  // Api

  async function fetchData(projectId, groupDirectionId?) {
    setLoading(true);

    try {
      const { data: projectResponse } =
        await ProjectService.getProjectInfo(projectId);
      const { data: realizedResponse } =
        await IdeaService.getRealizedIdeas(projectId);

      const defaultGroupDirection = { ideas: [], sections: [] };
      let realized;
      let waiting;
      if (groupDirectionId) {
        const { data: groupDirectionResponse } =
          await MediaPlatformService.projectGroupDirectionGetById(
            groupDirectionId
          );
        setGroupDirection(groupDirectionResponse);

        realized =
          realizedResponse.realized.groupDirectionals.find(
            ({ projectGroupDirectionId }) =>
              projectGroupDirectionId === groupDirectionId
          ) || defaultGroupDirection;
        waiting =
          realizedResponse.waiting.groupDirectionals.find(
            ({ projectGroupDirectionId }) =>
              projectGroupDirectionId === groupDirectionId
          ) || defaultGroupDirection;
      } else {
        realized = realizedResponse.realized;
        waiting = realizedResponse.waiting;
      }

      numerate([...realized.ideas, ...waiting.ideas]);

      numerate(
        [
          ...realized.sections.map((s) => s.ideas),
          ...waiting.sections.map((s) => s.ideas),
        ].flat()
      );

      setRealizedIdeas(realized.ideas);
      setAcceptedIdeas(waiting.ideas);
      setRealizedSections(realized.sections);
      setAcceptedSections(waiting.sections);

      const realizedAll = createList(realized, RealizedType.REALIZED);
      const acceptedAll = createList(waiting, RealizedType.ACCEPTED);

      setRealizedAll(realizedAll);
      setAcceptedAll(acceptedAll);

      setProject(projectResponse[0]);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  // Render

  const renderCollapsedButton = (isExeeds, isCollapsed, type) => {
    if (!isExeeds) return;

    return (
      <Button
        type="outlined"
        text={isCollapsed ? "Показать еще" : "Свернуть"}
        onClick={() =>
          setCollapseMap({ ...collapseMap, ...{ [type]: !isCollapsed } })}
      />
    );
  };

  const renderSection = (sections, type) => {
    const isCollapsed = collapseMap[`${type}Sections`];
    const isExeeds = sections.length > limit;
    const slicedSections = sections.slice(0, limit);

    return (
      <div className={`wrd-page__${type}`}>
        <div className="wrd-page__title" id={type}>
          {mapType[type].title}
        </div>

        <div className="wrd-page__content">
          {(isExeeds && isCollapsed ? slicedSections : sections).map(
            (section) =>
              Boolean(section.ideas?.length) && (
                <div className="wrd__card" key={section.id}>
                  <div
                    dangerouslySetInnerHTML={{ __html: section.title }}
                    className="wrd__card-title"
                  />
                  <ol className="wrd-list wrd-list--nomarker">
                    {section.ideas.map((idea) => (
                      <div className="wrd-list__item" key={idea.id}>
                        <span>{idea.iteration + 1}.</span>
                        <li
                          onClick={() => {
                            setSelectedListType(type);
                            setSelectedIdea(idea);
                            setSelectedList(
                              type === "realized" ? realizedAll : acceptedAll
                            );
                          }}
                          dangerouslySetInnerHTML={{ __html: idea.text }}
                          className="wrd-list__point"
                        />
                      </div>
                    ))}
                  </ol>
                </div>
              )
          )}
          {renderCollapsedButton(isExeeds, isCollapsed, `${type}Sections`)}
        </div>
      </div>
    );
  };

  const renderRealizedSections = useMemo(
    () =>
      Boolean(realizedSections?.length) &&
      sectionsHasIdeas(realizedSections) &&
      renderSection(realizedSections, "realized"),
    [realizedSections, realizedAll, collapseMap]
  );

  const renderAcceptedSections = useMemo(
    () =>
      Boolean(acceptedSections?.length) &&
      sectionsHasIdeas(acceptedSections) &&
      renderSection(acceptedSections, "accepted"),
    [acceptedSections, acceptedAll, collapseMap]
  );

  const renderIdeas = (ideas, type) => {
    const isCollapsed = collapseMap[`${type}Ideas`];
    const isExeeds = ideas.length > limit;
    const slicedIdeas = ideas.slice(0, limit);

    return (
      <div className="wrd-page__accepted">
        <div className="wrd-page__title" id={type}>
          {mapType[type].title}
        </div>
        <div className="wrd-page__content">
          <ol className="wrd-list wrd-list--nomarker">
            {(isExeeds && isCollapsed ? slicedIdeas : ideas)?.map((idea) => (
              <div className="wrd-list__item" key={idea.id}>
                <span>{idea.iteration + 1}.</span>
                <li
                  onClick={() => {
                    setSelectedListType(type);
                    setSelectedIdea(idea);
                    setSelectedList(
                      type === "realized" ? realizedAll : acceptedAll
                    );
                  }}
                  dangerouslySetInnerHTML={{ __html: idea.text }}
                  className="wrd-list__point"
                />
              </div>
            ))}
          </ol>
          {renderCollapsedButton(isExeeds, isCollapsed, `${type}Ideas`)}
        </div>
      </div>
    );
  };

  const renderRealizedIdeas = useMemo(
    () =>
      Boolean(realizedIdeas?.length) && renderIdeas(realizedIdeas, "realized"),
    [realizedIdeas, realizedAll, collapseMap]
  );

  const renderAcceptedIdeas = useMemo(
    () =>
      Boolean(acceptedIdeas?.length) && renderIdeas(acceptedIdeas, "accepted"),
    [acceptedIdeas, acceptedAll, collapseMap]
  );

  const renderNavigation = useMemo(() => {
    if (!realizedIdeas || !acceptedSections || !realizedIdeas || !acceptedIdeas) return;
    return (
      <div className={getNavClasslist()}>
        <div className="wrd-navigation__inner">
          <div className="wrd-navigation__content">
            {Boolean(
              realizedIdeas?.length || sectionsHasIdeas(realizedSections)
            ) && (
              <Button
                type="outlined"
                text="Реализованные идеи"
                onClick={(e) => scrollToSection("realized")}
              />
            )}
            {Boolean(
              acceptedIdeas?.length || sectionsHasIdeas(acceptedSections)
            ) && (
              <Button
                type="outlined"
                text="Принятые идеи"
                onClick={(e) => scrollToSection("accepted")}
              />
            )}
          </div>
        </div>
      </div>
    );
  }, [
    scroll,
    realizedIdeas,
    realizedSections,
    acceptedIdeas,
    acceptedSections,
  ]);

  // Helpers

  function sectionsHasIdeas(sections) {
    return Boolean(sections.map((s) => s.ideas).flat().length);
  }

  function getNavClasslist() {
    let classList = "wrd-navigation ";
    if (scroll > 79) {
      classList += "wrd-navigation--fixed";
    }

    return classList;
  }

  function numerate(ideas) {
    for (let i = 0; i < ideas.length; i++) {
      ideas[i].iteration = i;
    }
  }

  const scrollToSection = (id) => {
    document.querySelector(`#${id}`)?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  return (
    <section className="wrd-page">
      <div className="wrd-page__back">
        <ArrowBack
          text="Назад на страницу проекта"
          linkUrl={`/workresult/?id=${query.id}${
            query.groupDirectionId
              ? `&groupDirectionId=${query.groupDirectionId}`
              : ""
          }`}
        />
      </div>

      {renderNavigation}

      <div className="wrd-page__inner">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {renderRealizedIdeas}
            {renderAcceptedIdeas}
            {renderRealizedSections}
            {renderAcceptedSections}
          </>
        )}

        {Boolean(selectedList?.length) && (
          <CommonViewer
            openAt={selectedList.indexOf(selectedIdea)}
            className="wr-page__viewer"
            onChange={console.log}
            onClose={() => {
              setSelectedList(null);
              setSelectedListType("");
            }}
            arrowOptions={
              isPhone && {
                left: {
                  icon: "ic promo-icon-1100",
                  title: "Предыдущая идея",
                },
                right: {
                  icon: "ic promo-icon-1101",
                  title: "Следующая идея",
                },
              }
            }
          >
            {selectedList.map((idea) => (
              <WorkResultCard
                idea={idea}
                project={project}
                groupDirection={groupDirection}
                type={selectedListType}
              />
            ))}
          </CommonViewer>
        )}
      </div>
    </section>
  );
};

export default WorkResultDetailedPage;
