import React from "react";
import axios from "axios";

import GridBase from "./GridBase";
import {
  fetchRequest,
  httpParamsSerialize,
} from "../../../../utils/axiosConfig";
import { findUrlParam } from "../../../../utils/index";
import GridView from "../GridView";

class RestGrid extends GridBase {
  gridView: null | GridView = null;

  items: any[];

  page: number;

  pagination: any;

  unfilteredPage: any;

  search: boolean;

  cancelToken: any;

  source: any;

  isFetching: boolean;

  constructor(gridView: GridView) {
    super();

    this.gridView = gridView;
    this.items = [];
    this.page = this.getPageFromLocation();
    this.pagination = null;
    this.unfilteredPage = null;
    this.search = this.isSearch();
    this.cancelToken = axios.CancelToken;
    this.source = this.cancelToken.source();
    this.isFetching = false;
  }

  update() {
    return this.fetchData();
  }

  getItems() {
    return this.items;
  }

  isDataFetching() {
    return this.isFetching;
  }

  isSearch() {
    return !!findUrlParam("tags", window.location.search);
  }

  getPageFromLocation() {
    const page = findUrlParam("page", window.location.search);

    return page ? Number(page) - 1 : 0;
  }

  get canSlidePrev() {
    return this.page > 0;
  }

  get canSlideNext() {
    if (!this.pagination) return false;

    return this.page + 1 < this.pagination.totalPages;
  }

  async slideNext() {
    this.page += 1;
    return this.fetchData();
  }

  async slidePrev() {
    this.page -= 1;
    return this.fetchData();
  }

  sanitizeRequest(querystring) {
    if (typeof querystring !== "string") return querystring;
    const result = querystring.replace(/([^&?]+)=(&|$)/g, "");
    return result.endsWith("&") ? result.substr(0, result.length - 1) : result;
  }

  updateLocation() {
    let [location, search] = decodeURI(this.gridView.props.endpoint.url).split(
      "?"
    );
    const hasTags = search.match(/(text|tags)=([^&#]*)/g);
    search = hasTags ? `&${hasTags.join("&")}` : "";
    search +=
      window.location.search &&
      window.location.search
        .split("&")
        .filter((i) => !i.startsWith("?page") && !i.startsWith("tags"))
        .map((i) => `&${i}`)
        .join("");
    const url = `?page=${this.pagination.pageNumber + 1}${search}`;

    window.history.replaceState(null, "", url);
  }

  async fetchData() {
    this.isFetching = true;

    this.source.cancel();

    this.cancelToken = axios.CancelToken;
    this.source = this.cancelToken.source();
    const { endpoint } = this.gridView.props;
    const isSearch = !!endpoint.url.match(/tags=.+&/);

    if (isSearch && !this.search) {
      this.unfilteredPage = this.page;
      this.page = 0;
      this.search = isSearch;
    } else if (!isSearch && this.search && !!this.unfilteredPage) {
      this.page = this.unfilteredPage;
      this.search = isSearch;
    }

    const params = {
      page: this.page,
      size: this.gridView.props.restrictBy,
    };
    const url = this.sanitizeRequest(
      endpoint.url + httpParamsSerialize(params)
    );

    try {
      let response: any = await fetchRequest(
        url,
        endpoint.params,
        endpoint.params ? "POST" : "GET",
        null,
        this.source.ktoen
      );
      response = response.data;

      if (response.status === 0) {
        this.pagination = response.paging;

        if (this.gridView.props.withUpdateLocation) {
          if (response.data?.length) {
            this.updateLocation();
          }
        }

        this.items = response.data;
        this.gridView.props.onGridUpdated?.();
        return this.items;
      }
      console.log("Fail");
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log("Request canceled", err.message);
      } else {
        console.log("Error", err);
      }
    } finally {
      this.isFetching = false;
      this.gridView.props?.onLoadEnd?.({ page: this.page, items: this.items });
      this.gridView.forceUpdate();
    }
  }

  renderItems() {
    if (this.isFetching) return;

    return this.items.map((item, id) =>
      React.createElement(this.gridView.props.children.type, {
        [this.gridView.props.bindTo || "item"]: item,
        id: item.id || id,
        key: item.id || id,
        ...this.gridView.props.children.props,
      })
    );
  }
}

export default RestGrid;
