import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import Logo from "./Logo/Logo";
import { scrollTop } from "../../../utils/helperFunctions";
import "./Header.scss";
import { AuthActionType, ComponentType } from "../../../services/sudirService";
import { isParticipant } from "../../../utils";
import UserService from "../../../services/userService";
import { getUser } from "../../../store/actions/UserActions";
import { API } from "../../../services/api";
import EventsTrackWrapperClick from "../EventsTrack/wrappers/EventsTrackWrapperClick";
import { RootState } from "../../../types/State.interface";
import { AppContext } from "../../Root";
import { selectIsNeedAfterRegistrationForm } from "../../../store/selectors/profile";
import { HeaderLinks } from "./HeaderLinks";
import { HeaderProfile } from "./HeaderProfile";
import { HeaderMobileLinks } from "./HeaderMobileLinks";
import { TopSection } from "./TopSection/TopSection";
import { useIsMobile } from "../../../hooks/useMedia";

function Header() {
  const location = useLocation();

  const { sudirService } = useContext(AppContext);
  const [dropdown, setDropdown] = useState<Record<string, boolean>>({});

  const dispatch = useDispatch();
  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);
  const routes = useSelector((state: RootState) => state.layout.routes);
  const logo = useSelector((state: RootState) => state.layout.logo);
  const user = useSelector((state: RootState) => state.user.userDetails);
  const isNeedAfterRegistrationForm = useSelector((state: RootState) =>
    selectIsNeedAfterRegistrationForm(state)
  );
  const isMobile = useIsMobile();

  const canShowHeaderTopSection =
    location.pathname === "/" && (!isMobile || !dropdown.mobile);

  const joinAllProjects = () => {
    const projectTypes = ["CURRENT", "ANNOUNCEMENT"];
    if (user && !isNeedAfterRegistrationForm) {
      const reqs = projectTypes.map((type) =>
        API.get(`/api/project/get?type=${type}`)
      );
      Promise.all(reqs)
        .then(([currentResponse, announceResponse]) => {
          const currentProjects = currentResponse.data.data;
          const announceProjects = announceResponse.data.data;
          const projectsToJoin = [
            ...currentProjects,
            ...announceProjects,
          ].filter((project) => !isParticipant(user, project.id));
          UserService.addToProjects({
            ids: projectsToJoin.map((project) => project.id),
          })
            .catch((err) => console.error(err))
            .finally(() => dispatch(getUser()));
        })
        .catch(console.error);
    }
  };

  const checkActionsAfterAuth = () => {
    const actions = sudirService.getActions(ComponentType.HEADER);
    if (actions.length) {
      const joinAllProjectsAction = actions.find(
        (act) => act.type === AuthActionType.JOIN_PROJECTS_SILENTLY
      );
      if (joinAllProjectsAction) {
        joinAllProjects();
      }
    }
  };
  const checkActionsAfterAuthRef = useRef(checkActionsAfterAuth);
  checkActionsAfterAuthRef.current = checkActionsAfterAuth;

  useEffect(() => {
    if (loggedIn) {
      checkActionsAfterAuthRef.current();
    }
  }, [loggedIn]);

  const openDropdown = (id) => {
    setTimeout(() => {
      setDropdown({ ...dropdown, [id]: true });
    });
  };

  const closeDropdown = (id) => {
    setTimeout(() => {
      setDropdown({ ...dropdown, [id]: false });
    });
  };

  return (
    <div
      className={classNames(
        "cr-header",
        `cr-header__${window.location.pathname.slice(1) || "base"}`
      )}
    >
      {canShowHeaderTopSection && <TopSection />}
      <div className="cr-header__inner">
        <div className="cr-header__inner-left-column">
          <EventsTrackWrapperClick id={1}>
            <Logo onLogoClick={scrollTop} options={logo} />
          </EventsTrackWrapperClick>
          <HeaderLinks
            routes={routes?.baseRoutes}
            openDropdown={openDropdown}
            closeDropdown={closeDropdown}
            dropdown={dropdown}
          />
        </div>
        <div className="cr-header__inner-right-column">
          {/* <CrossPromo /> */}
          <HeaderProfile />
          <HeaderMobileLinks
            routes={routes?.mobileRoutes}
            openDropdown={openDropdown}
            closeDropdown={closeDropdown}
            dropdown={dropdown}
          />
        </div>
      </div>
    </div>
  );
}

export default Header;
