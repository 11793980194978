import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import classNames from "classnames";

import styles from "./NewsSection.module.scss";
import EventsTrackParentIdContext from "../../../containers/EventsTrack/EventsTrackParentIdContext";
import EventsTrackWrapperScroll from "../../../containers/EventsTrack/wrappers/EventsTrackWrapperScroll";
import NewsService from "../../../../services/newsService";
import { ApiStatusCode } from "../../../../types/Common.interface";
import NewsCard from "./NewsCard/NewsCard";
import Slider from "../../../presentational/Sliders/Slider/Slider";
import AppLink from "../../../presentational/Controls/AppLink/AppLink";
import { News } from "../../../../types/News.interface";
import { useIsMobile, useIsWideTablet } from "../../../../hooks/useMedia";

const NewsSection = () => {
  const history = useHistory();

  const isWideTablet = useIsWideTablet();
  const isPhone = useIsMobile();

  const [news, setNews] = useState<News[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const newsCount = isWideTablet ? 4 : 5;
  const slicedNews = news.slice(0, newsCount);

  useEffect(() => {
    if (loading || !hasMore) return;

    const fetchNews = async () => {
      setLoading(true);
      try {
        const query = {
          page: 0,
          size: 5,
          sort: "published,desc",
        };
        const response = await NewsService.getNews(query);
        if (response.status === ApiStatusCode.OK) {
          setNews((prev) => [...prev, ...response.data]);
          if (response.paging.pageNumber + 1 >= response.paging.totalPages) {
            setHasMore(false);
          }
        }
      } catch (error) {
        console.error("Ошибка при получении новостей:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  const newsSlider = useMemo(() => {
    if (!slicedNews) {
      return null;
    }

    return (
      <Slider
        sliderOptions={{
          className: classNames(styles.slider, {
            [styles["slider--loading"]]: loading,
          }),
          loop: false,
          autoHeight: true,
          spaceBetween: 24,
          slidesPerView: 1,
          slidesPerColumn: 1,
          slidesPerGroup: 1,
          centerInsufficientSlides: true,
          onAfterInit: (sw) => {
            sw.update();
          },
        }}
        arrowsOrtions={{
          arrowOptions: {
            left: {
              className: styles["news-slider-arrow"],
            },
            right: {
              className: styles["news-slider-arrow"],
            },
          },
          pagination: "dots",
        }}
        containerClassName={styles["slider-container"]}
      >
        {slicedNews.map((newsItem) => (
          <NewsCard
            key={newsItem.id}
            id={`${newsItem.id}`}
            element={newsItem}
            showedVideo
            href={(n) => `/news/${n.number}`}
            linkRel="canonical"
            onTagClick={(n) =>
              history.push({
                pathname: "/news",
                search: `page=1&tags=${n.tag}`,
                state: { n },
              })}
          />
        ))}
      </Slider>
    );
  }, [loading, slicedNews, history]);

  const renderContent = useCallback(() => {
    if (!news) {
      return null;
    }

    if (isPhone) {
      return newsSlider;
    }

    return slicedNews.map((newsItem, idx) => (
      <NewsCard
        isMain={idx === 0}
        showedVideo={idx === 0}
        key={newsItem.id}
        id={`${newsItem.id}`}
        element={newsItem}
        href={(n) => `/news/${n.number}`}
        linkRel="canonical"
        onTagClick={(n) =>
          history.push({
            pathname: "/news",
            search: `page=1&tags=${n.tag}`,
            state: { n },
          })}
      />
    ));
  }, [history, isPhone, news, newsSlider, slicedNews]);

  return (
    <EventsTrackParentIdContext.Provider value="BLOCK_NEWS">
      <EventsTrackWrapperScroll id={6}>
        <div className={styles["news-section-container"]}>
          <div className={styles["news-section"]}>{renderContent()}</div>
          <div className={styles["show-all-section"]}>
            {news.length > 0 && (
              <AppLink
                to="/news"
                className={styles["common-slider__link"]}
                underline
                size={isWideTablet ? 3 : 2}
              >
                Посмотреть все
              </AppLink>
            )}
          </div>
        </div>
      </EventsTrackWrapperScroll>
    </EventsTrackParentIdContext.Provider>
  );
};

export default NewsSection;
