import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import cn from "classnames";
import EventsTrackWrapperClick from "../EventsTrack/wrappers/EventsTrackWrapperClick";
import { HeaderUserLink } from "./HeaderUserLink";
import { logout as logoutAction } from "../../../store/actions/UserActions";
import { RootState } from "../../../types/State.interface";

export function HeaderLoggedIn() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    const handleHideDropdown = () => setIsDropdownOpen(false);

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("scroll", handleHideDropdown);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("scroll", handleHideDropdown);
    };
  }, []);

  const logout = () =>
    dispatch(logoutAction({ returnUrl: window.location.href }));
  const name = useSelector(
    (state: RootState) =>
      `${state.user.userDetails.firstName} ${state.user.userDetails.lastName[0]}.`
  );

  const isTouchDevice =
    navigator.maxTouchPoints > 0 || navigator.maxTouchPoints > 0;

  const handleClick = () => {
    if (isTouchDevice) {
      setIsDropdownOpen((prev) => !prev);
    }
  };

  const handleMouseEnter = () => {
    if (!isTouchDevice) {
      setIsDropdownOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isTouchDevice) {
      setIsDropdownOpen(false);
    }
  };

  return (
    <div
      className="cr-header__inner-profile__loggedIn"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      aria-hidden="true"
    >
      <HeaderUserLink withNotifications />

      <div
        className="cr-header__loggedIn-container"
        ref={dropdownRef}
        onClick={handleClick}
        aria-hidden="true"
      >
        <EventsTrackWrapperClick id={16}>
          <button
            type="button"
            className={cn("cr-header__loggedIn-name", {
              "cr-header__loggedIn-name--active": isDropdownOpen,
            })}
          >
            {name}
            <div className="ic promo-icon-88" />
          </button>
        </EventsTrackWrapperClick>

        {isDropdownOpen && (
          <div
            className={cn(
              "cr-header__inner-links-dropdown-menu cr-header__inner-links-dropdown-menu--loggedIn",
              {
                "cr-header__inner-links-dropdown-menu--active": isDropdownOpen,
              }
            )}
          >
            <Link
              to="/profile"
              onClick={() => {
                if (!isTouchDevice) {
                  setIsDropdownOpen(false);
                }
              }}
            >
              Личный кабинет
            </Link>

            <div
              role="button"
              tabIndex={0}
              onClick={() => {
                logout();
                if (!isTouchDevice) {
                  setIsDropdownOpen(false);
                }
              }}
              className="cr-header__inner-logInOutBtn"
            >
              Выйти
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
