import { API, httpParamsSerialize } from "./api";

const IdeaService = {
  getByFilter: (query, params) => {
    const url = `/api/idea/getByFilter?${httpParamsSerialize(query)}`;
    return API.post(url, params).then((data) => data?.data);
  },

  getTags: () => API.get("/api/idea/tags").then(({ data }) => data),

  like: (id) => API.post("/api/idea/rate", { id }).then(({ data }) => data),

  propose: (idea) =>
    API.post("/api/idea/rate", { id: idea.id }).then(({ data }) => data),

  getProjects: () => API.get("/api/idea/list").then(({ data }) => data),

  sendIdea: (query) =>
    API.post("/api/idea/add", query).then(({ data }) => data),

  showAuthorInfo: () => API.get("/api/idea/list").then(({ data }) => data),
  ideaById: (id) => API.get(`/api/idea/get?id=${id}`).then(({ data }) => data),
  gorodIdeaById: (id) =>
    API.get(`/api/gorod/idea/get?id=${id}`).then(({ data }) => data),

  getRealizedIdeas: (projectId) =>
    API.get(`/api/idea/realized/getAll?projectId=${projectId}`).then(
      ({ data }) => data
    ),
};

export default IdeaService;
