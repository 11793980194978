import React, { useState } from "react";
import EventsTrackWrapperClick from "../../../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";
import JobReportsModal from "../../../../containers/Modals/JobReportsModal/JobReportsModal";
import Button from "../../../../presentational/Controls/Button/Button";
import ReportsService from "../../../../../services/reportsService";
import { Report } from "../../../../../types/Report.interface";

interface TProps {
  className?: string;
  wrapperClassName?: string;
}

const ReportButton = ({ className, wrapperClassName }: TProps) => {
  const [jobReportsModalOpen, setJobReportsModalOpen] = useState(false);
  const [reportsList, setReportsList] = useState<Report[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const fetchReports = async () => {
    try {
      setIsFetching(true);
      const { data } = await ReportsService.getAllReports();
      const publishedReports = data.filter(
        (report) => report.published && report.reportCount > 0
      );
      setReportsList(publishedReports);
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  const handleClose = () => setJobReportsModalOpen(false);

  return (
    <div className={wrapperClassName}>
      <EventsTrackWrapperClick
        needParent
        id={182}
        replace={["Отчеты о работе платформы"]}
      >
        <Button
          text="Отчеты о работе платформы"
          type="filled"
          size="mid"
          onClick={() => {
            setJobReportsModalOpen(true);
            fetchReports();
          }}
          classList={className}
        />
      </EventsTrackWrapperClick>

      <JobReportsModal
        reports={reportsList}
        isOpened={jobReportsModalOpen}
        onClose={handleClose}
        isFetching={isFetching}
      />
    </div>
  );
};

export default ReportButton;
