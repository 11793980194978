import { SittingsKeys, SittingsState } from "../../types/Sittings.interface";
import { GET_SETTING_KEY_SUCCESS } from "../actions/SittingsActions";

const initialState: SittingsState = {
  [SittingsKeys.SHOW_PROJECT_CATEGORY]: false,
  [SittingsKeys.NEW_IDEAS]: false,
  [SittingsKeys.PROMO_BLOCKS]: [],
};

export function sittingsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SETTING_KEY_SUCCESS:
      return {
        ...state,
        [action.payload.key]:
          action.payload.key === SittingsKeys.PROMO_BLOCKS
            ? action.payload.value
              ? JSON.parse(decodeURIComponent(action.payload.value))
              : []
            : action.payload.value === "true",
      };

    default:
      return state;
  }
}
