import { TRoute } from "../../../types/navigation.interface";
import { HeaderRoute } from "./HeaderRoute";

interface TProps {
  routes: TRoute[] | null;
  openDropdown: (id: string) => void;
  closeDropdown: (id: string) => void;
  dropdown: Record<string, boolean>;
}

export function HeaderLinks({
  routes,
  openDropdown,
  closeDropdown,
  dropdown,
}: TProps) {
  return (
    <div className="cr-header__inner-links">
      {routes?.map((item) => (
        <HeaderRoute
          key={item.id}
          item={item}
          openDropdown={openDropdown}
          closeDropdown={closeDropdown}
          dropdown={dropdown}
        />
      ))}
    </div>
  );
}
