import { ButtonHTMLAttributes, FC } from "react";
import "./CloseButton.scss";

const CloseButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  className = "",
  ...otherProps
}) => (
  <button className={`modal__close-btn ${className}`} {...otherProps}>
    <span className="ic promo-icon-14" />
  </button>
);

export default CloseButton;
