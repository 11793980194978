import { RootState } from "../../types/State.interface";

export const selectUserDetails = (state: RootState) => state.user.userDetails;
export const selectIsUserChild = (state: RootState) =>
  state.user.userDetails?.child;
export const selectIsNeedAfterRegistrationForm = (state: RootState) =>
  state.user.userDetails?.loggedIn &&
  (state.user.userDetails.partiallyFilled ||
    (!state.user.userDetails.email && !selectIsUserChild(state)));
export const selectIsUserReady = (state: RootState) =>
  !!state.user.userDetails?.loggedIn &&
  !selectIsNeedAfterRegistrationForm(state) &&
  (selectIsUserChild(state) || state.user.userDetails.emailVerified);
export const selectProjectsUserState = (state: RootState) =>
  state.user.projectsState;
