import { ProjectStageTypes, Stage } from "../../../../types/Stage.interface";
import { Project } from "../../../../types/Projects.interface";

export interface BannerDirectionsProps {
  project?: Project;
}

export enum BannerStageNull {
  GENERATION = "Сбор идей не запланирован",
  VOTING = "Голосование за идеи не запланировано",
  SIMPLE_DISCUSSION = "Обсуждения с участниками не запланированы",
}

export type BannerStageType =
  | ProjectStageTypes.GENERATION
  | ProjectStageTypes.SIMPLE_DISCUSSION
  | ProjectStageTypes.VOTING;

export type BannerStages = {
  [index: string]: BannerCurrentStage;
};

export interface BannerStage {
  type: BannerStageType;
  filtered: Stage[];
  all: Stage[];
  currentIndex: number;
  mobileCurrentIndex: number;
}

export type BannerCurrentStage = {
  [key in BannerStageType]?: BannerStage | string;
};

export type UserAssigned = { [index: string]: string[] } | null;
