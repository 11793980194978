import React from "react";
import Swipe from "react-easy-swipe";

import FullPage from "./classes/FullPage";
import "./BookView.scss";
import FlipBook from "./classes/FlipBook";
import NotAvaiable from "./classes/NotAvailable";
import { ProjectBookTypes } from "../../pages/BookPresentation/BookPresentation.interface";
import { throttle } from "../../../utils";

class BookView extends React.Component<any, any> {
  viewRef: any;

  bookView: any = null;

  cleanups: any[] = [];

  inProcess = false;

  constructor(props) {
    super(props);

    this.viewRef = React.createRef();
    this.initBookView();
    window.history.scrollRestoration = "manual";
  }

  componentDidMount() {
    this.fixLayout();
    !this.isPhone() && this.bindEvents();
  }

  componentWillUnmount() {
    window.history.scrollRestoration = "auto";
    this.cleanups.forEach((cb) => cb());
  }

  initBookView() {
    switch (this.props.type) {
      case ProjectBookTypes.FLIPBOOK:
        this.bookView = new FlipBook(this);
        break;
      case ProjectBookTypes.FULLPAGE:
        this.bookView = new FullPage(this);
        break;
      default:
        this.bookView = new NotAvaiable(this);
    }

    if (this.props.onInit) {
      this.props.onInit(this);
    }
  }

  bindEvents() {
    this.bindMouseEvents();
    this.bindKeyboardEvents();
  }

  bindMouseEvents() {
    const onMouseWheel = (e) => {
      if (!this.inProcess) {
        this.inProcess = true;
        setTimeout(() => {
          this.inProcess = false;
        }, 1000);
        return e.deltaY > 0 ? this.handleSwipeDown(e) : this.handleSwipeUp(e);
      }
    };

    this.viewRef.current.addEventListener(
      "wheel",
      throttle(onMouseWheel, 400, { leading: false })
    );

    this.cleanups.push(() =>
      this.viewRef.current.removeEventListener("wheel", onMouseWheel)
    );
  }

  bindKeyboardEvents() {
    const self = this;
    function handler(event) {
      const { key } = event;
      switch (key) {
        case "ArrowUp": {
          self.handleSwipeUp();
          break;
        }
        case "ArrowDown": {
          self.handleSwipeDown();
          break;
        }

        default:
      }
    }

    window.addEventListener("keydown", handler);
    this.cleanups.push(() => window.removeEventListener("keydown", handler));
  }

  fixLayout() {
    if (!this.isPhone()) {
      const body = document.querySelector("body");
      if (body && !this.isPhone()) {
        body.classList.add("book-view__prevent-scroll");
        this.cleanups.push(() =>
          body.classList.remove("book-view__prevent-scroll")
        );
      }
    }

    if (this.isTablet()) {
      const body = document.querySelector("body");
      body.classList.add("tablet-bounce-prevent");
      this.cleanups.push(() => body.classList.remove("tablet-bounce-prevent"));
    }

    const wrapper = document.querySelector(".wrapper");
    if (wrapper) {
      wrapper.classList.add("book-view__zero-padding");
      this.cleanups.push(() =>
        wrapper.classList.remove("book-view__zero-padding")
      );
    }
  }

  isPhone() {
    return window.innerWidth <= 500;
  }

  isTablet() {
    return window.innerWidth > 500 && window.innerWidth <= 820;
  }

  handleSwipeRight = (e?) => {
    this.bookView.swipeRight();
  };

  handleSwipeLeft = (e?) => {
    this.bookView.swipeLeft();
  };

  handleSwipeUp = (e?) => {
    this.bookView.swipeUp();
  };

  handleSwipeDown = (e?) => {
    this.bookView.swipeDown();
  };

  getNavigationClassList(position) {
    let classList = `navigation__button navigation__button-${position}`;
    if (position === "top" && !this.bookView.canSlidePrev) classList += " navigation__button--disabled";
    if (position === "bottom" && !this.bookView.canSlideNext) classList += " navigation__button--disabled";

    return classList;
  }

  renderNavigation() {
    if (this.isPhone()) return;

    return (
      <div className="navigation">
        <button
          className={this.getNavigationClassList("top")}
          onClick={this.handleSwipeUp}
        >
          <span className="ic promo-icon-105" />
        </button>

        <button
          className={this.getNavigationClassList("bottom")}
          onClick={this.handleSwipeDown}
        >
          <span className="ic promo-icon-88" />
        </button>
      </div>
    );
  }

  render() {
    return (
      <div className="book-view" ref={this.viewRef}>
        {this.isPhone() ? (
          this.bookView.render()
        ) : (
          <Swipe
            onSwipeRight={this.handleSwipeRight}
            onSwipeLeft={this.handleSwipeLeft}
            onSwipeUp={this.handleSwipeDown}
            onSwipeDown={this.handleSwipeUp}
          >
            {this.bookView.render()}
          </Swipe>
        )}

        {ProjectBookTypes[this.props.type] && this.renderNavigation()}
      </div>
    );
  }
}

export default BookView;
