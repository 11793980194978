import { lazy, Suspense, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import "./App.scss";
import "../common.scss";
import HeaderContainer from "./containers/Header/HeaderContainer";
import {
  getProjectBanners,
  getCurrentProjects,
  getAnnouncementProjects,
  getArchiveProjects,
  getCompletedProjects,
} from "../store/actions/ProjectActions";
import { getUser, logout } from "../store/actions/UserActions";
import { getNewNotificationsCount } from "../store/actions/NotificationsActions";
import { getTotalStatistics } from "../store/actions/StatisticsActions";
import {
  HeaderRoutesType,
  hideInfoModal,
  setAfterRegistrationFormOpen,
  setHeaderOptions,
  setResendEmailModalOpen,
} from "../store/actions/LayoutActions";
import { loadEnv } from "../store/actions/EnvironmentActions";
import indexedRoutes from "../routing/IndexedRoutes";
import ModerationPage from "./containers/Moderation/ModerationPage";
import ideaModerationPage from "./containers/ideaModeration/ideaModerationPage";
import LicensePage from "./pages/License/LicensePage";
import BlockedPage from "./pages/Blocked/BlockedPage";
import baseRoutes from "../routing/BaseRoutes";
import GuardService from "../routing/GuardService";
import BlockedUserGuard from "../routing/guards/BlockedUserGuard";
import MainPage from "./pages/Main/MainPage";
import PointsRulesPage from "./pages/PointsRules/PointsRulesPage";
import { routes as BaseRoutes } from "./App.helper";
import BookPresentationPage from "./pages/BookPresentation/BookPresentation";
import { getSpheres } from "../store/actions/GorodActions";
import { getDistricts, getRegions } from "../store/actions/AddressActions";
import { ping } from "../store/actions/OperabilityActions";
import { getSittingByKey } from "../store/actions/SittingsActions";
import { SittingsKeys } from "../types/Sittings.interface";
import { RootState } from "../types/State.interface";
import { ResourceLoader } from "./containers/Wrappers";
import NewYear2023Root from "./pages/NewYear2023/NewYear2023Root";
import LeisureLandingRoot from "./pages/LeisureLanding/LeisureLandingRoot";
import AfterRegistrationForm from "./containers/Forms/AfterRegistrationForm/AfterRegistrationForm";
import { AppContext } from "./Root";
import { AuthActionType, ComponentType } from "../services/sudirService";
import ResendEmailModal from "./containers/Modals/ResendEmailModal/ResendEmailModal";
import {
  selectAfterRegistrationFormOpen,
  selectResendEmailModalOpen,
} from "../store/reducers/layout";
import useMpRoutes from "./pages/DiscussionLanding/hooks/useMpRoutes";
import { newYear2025Url } from "./pages/NewYear2025/constants";
import AuthChildForbidden from "./containers/Modals/AuthChildForbidden/AuthChildForbidden";
import {
  selectIsNeedAfterRegistrationForm,
  selectIsUserChild,
} from "../store/selectors/profile";
import { selectMediaPlatformList } from "../store/selectors/mediaPlatform";
import { getAllMediaPlatforms } from "../store/actions/MediaPlatformActions";
import { matchHiddenUrls } from "../utils/url";

const NewYear2025 = lazy(() => import("./pages/NewYear2025/NewYear2025"));

const App = () => {
  const appContext = useContext(AppContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const mpRoutes = useMpRoutes();
  const user = useSelector((state: RootState) => state.user.userDetails);
  const inWebViewMode = useSelector(
    (state: RootState) => state.layout.inWebViewMode
  );
  const afterRegistrationFormOpen = useSelector(
    selectAfterRegistrationFormOpen
  );
  const resendEmailModalOpen = useSelector(selectResendEmailModalOpen);
  const regFormRedirectRef = useRef(() => {});
  const [afterRegFormJoinProjectIds, setAfterRegFormJoinProjectIds] =
    useState<string[]>();
  const isNeedAfterRegistrationForm = useSelector(
    selectIsNeedAfterRegistrationForm
  );
  const isUserChild = useSelector(selectIsUserChild);
  const mediaPlatformList = useSelector(selectMediaPlatformList);

  // ACTIONS AFTER AUTH
  useEffect(() => {
    const actions = appContext.sudirService.getActions(ComponentType.MAIN);
    if (actions.length) {
      const redirectAction = actions.find(
        (act) => act.type === AuthActionType.REDIRECT_AFTER_REG_FORM
      );
      if (redirectAction) {
        const redirectAsLocal = () => {
          const [path, search] = redirectAction.args.redirectLink.split("?");
          history.push({
            pathname: path,
            search: `?${search}`,
            state: { alreadyJoined: true },
          });
        };

        const redirectAsAbsolute = () =>
          (window.location.href = redirectAction.args.redirectLink);

        const redirect = () =>
          (redirectAction.args.absolutePath
            ? redirectAsAbsolute()
            : redirectAsLocal());

        regFormRedirectRef.current = redirect;

        if (redirectAction.args.projectId) {
          setAfterRegFormJoinProjectIds([redirectAction.args.projectId]);
        }
      }
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (user?.loggedIn) {
      dispatch(getNewNotificationsCount());
    }
  }, [user]);

  const fetchData = async () => {
    try {
      dispatch(getUser());
      await Promise.all([
        dispatch(ping()),
        dispatch(loadEnv()),
        dispatch(
          setHeaderOptions({
            routes: BaseRoutes,
            type: HeaderRoutesType.MAIN,
          })
        ),
        dispatch(getProjectBanners()),
        dispatch(getTotalStatistics()),
        dispatch(getSpheres()),
        dispatch(getRegions()),
        dispatch(getDistricts()),
        dispatch(getCurrentProjects()),
        dispatch(getAnnouncementProjects()),
        dispatch(getCompletedProjects()),
        dispatch(getArchiveProjects()),
        dispatch(getSittingByKey(SittingsKeys.NEW_IDEAS)),
        dispatch(getAllMediaPlatforms()),
        dispatch(getSittingByKey(SittingsKeys.SHOW_PROJECT_CATEGORY)),
        dispatch(getSittingByKey(SittingsKeys.PROMO_BLOCKS)),
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const emailAction = (history?.location?.state as any)?.emailAction;

    if (user?.loggedIn && isNeedAfterRegistrationForm) {
      dispatch(setAfterRegistrationFormOpen(true));
    }

    if (
      !emailAction &&
      user &&
      user?.loggedIn &&
      !user.emailVerified &&
      !isNeedAfterRegistrationForm &&
      !isUserChild
    ) {
      dispatch(setResendEmailModalOpen(true));
    }

    if (
      !emailAction &&
      user &&
      user?.loggedIn &&
      user.emailVerified &&
      user.accountType === "NATIVE"
    ) {
      const params = new URLSearchParams(history?.location?.search);
      if (
        typeof user.passwordLastChanged === "undefined" ||
        user.passwordLastChanged === null
      ) {
        params.set("firstTime", "true");
        history.replace(`/changePassword?${params.toString()}`);
      } else if (user.passwordExpired) {
        params.set("firstTime", "false");
        history.replace(`/changePassword?${params.toString()}`);
      }
    }
  }, [
    user?.loggedIn,
    history?.location?.state,
    isNeedAfterRegistrationForm,
    user?.emailVerified,
    isUserChild,
  ]);

  const getBlockedGuardedRoutes = () => {
    const routes = (
      <>{[<Route exact path="/" component={MainPage} key="Main" />]}</>
    );
    const guardService = new GuardService([BlockedUserGuard]);
    return guardService.getGuarded(routes);
  };

  const getGuardedRoutes = () => {
    const routes = (
      <>
        {[...indexedRoutes]}
        {[...mpRoutes]}
        {[...baseRoutes]}
      </>
    );
    const guardService = new GuardService([BlockedUserGuard]);
    return guardService.getGuarded(routes);
  };

  const canShowHeader = () =>
    !inWebViewMode && !matchHiddenUrls(location.pathname);

  const handleClose = () => {
    dispatch(setAfterRegistrationFormOpen(false));
    dispatch(hideInfoModal());
    regFormRedirectRef.current();
  };

  return (
    <Suspense fallback="">
      <Switch>
        <Route path={newYear2025Url} component={NewYear2025} />
        <Route path="/newyear2024" component={NewYear2023Root} />
        <Route path="/city" component={LeisureLandingRoot} />
        <Route>
          <>
            <AuthChildForbidden />
            <ResourceLoader />
            {canShowHeader() && <HeaderContainer />}
            <main>
              {!!mediaPlatformList && (
                <Suspense fallback={<div />}>
                  <Switch>
                    <Route
                      path="/blocked"
                      component={BlockedPage}
                      key="Blocked"
                    />
                    <Route
                      path="/moderation"
                      component={ModerationPage}
                      key="Moderation"
                    />
                    <Route
                      path="/ideaModeration"
                      component={ideaModerationPage}
                      key="IdeaModeration"
                    />
                    <Route
                      path="/pointsRules"
                      component={PointsRulesPage}
                      key="PointsRules"
                    />
                    <Route
                      path="/license"
                      component={LicensePage}
                      key="License"
                    />
                    <Route
                      path="/projects/:project/book"
                      component={BookPresentationPage}
                      key="ProjectBook"
                    />
                    {user && getBlockedGuardedRoutes()}
                    {user && getGuardedRoutes()}
                  </Switch>
                </Suspense>
              )}
            </main>

            {afterRegistrationFormOpen && (
              <AfterRegistrationForm
                user={user}
                joinProjectIds={afterRegFormJoinProjectIds}
                isOpen={afterRegistrationFormOpen}
                close={handleClose}
                closeWithLogout={() => {
                  dispatch(setAfterRegistrationFormOpen(false));
                  dispatch(logout());
                }}
                onSubmit={() =>
                  dispatch(
                    getUser(() => {
                      if (!user.emailVerified && !isUserChild) {
                        dispatch(setResendEmailModalOpen(true));
                      }
                      const actions = appContext.sudirService.getActions(
                        ComponentType.AFTER_REGISTRATION_FORM
                      );
                      const redirectToLastVisitedPage = actions.find(
                        (act) => act.type === AuthActionType.VISIT_LAST_PAGE
                      );
                      if (redirectToLastVisitedPage) {
                        history.push(
                          redirectToLastVisitedPage.args.lastVisitedPage,
                          {
                            lastVisitedPage:
                              redirectToLastVisitedPage.args.lastVisitedPage,
                          }
                        );
                      }
                    })
                  )}
              />
            )}

            {resendEmailModalOpen && (
              <ResendEmailModal
                isOpened={resendEmailModalOpen}
                onClose={() => dispatch(setResendEmailModalOpen(false))}
              />
            )}
          </>
        </Route>
      </Switch>
    </Suspense>
  );
};

export default App;
