import { FC, useEffect, useState } from "react";
import "./MediaPlatform.scss";
import NewsAndPublications from "./NewsAndPublications/NewsAndPublications";
import MediaPlatformService from "../../../../../services/mediaPlatform.service";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import { displayError } from "../../../../../utils";
import useDiscussionLandingContext from "../../hooks/useDiscussionLandingContext";
import { MediaPlatformNewsList } from "../../../../../types/MediaPlatform.interface";
import { MediaPlatformSections } from "../../routing/routes";

export interface MediaPlatformProps {
  onLoadEnd?: (data) => void;
  description: string;
}

const MediaPlatform: FC<MediaPlatformProps> = ({ onLoadEnd, description }) => {
  const discussionLandingContext = useDiscussionLandingContext();
  const [newsList, setNewsList] = useState<MediaPlatformNewsList>([]);

  const fetchData = async () => {
    let onLoadEndData = [];

    try {
      const response = await MediaPlatformService.getNews({
        mediaPlatformId: discussionLandingContext.id,
        page: 0,
        size: 50,
      });
      if (response.status === ApiStatusCode.OK) {
        setNewsList(response.data);
        onLoadEndData = response.data;
      } else {
        displayError("Ошибка при получении новостей", response.message);
      }
    } catch (error) {
      displayError("MediaPlatform", "Ошибка при получении новостей");
    } finally {
      onLoadEnd(onLoadEndData);
    }
  };

  useEffect(() => {
    fetchData();
  }, [discussionLandingContext.id]);

  if (!newsList?.length) return <></>;

  return (
    <section
      className="discussion-landing-media"
      id={MediaPlatformSections.NEWS}
    >
      <div className="discussion-landing-title">МЕДИАПЛАТФОРМА</div>

      {!!description && (
        <div
          className="discussion-landing-text"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}

      {newsList.length ? <NewsAndPublications newsList={newsList} /> : null}
    </section>
  );
};
export default MediaPlatform;
