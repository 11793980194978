import EventsTrackWrapperClick from "../../EventsTrack/wrappers/EventsTrackWrapperClick";
import css from "./SocialNetworks.module.scss";
import vk from "../../../../assets/social/vk.png";

export function FooterSocialNetworks() {
  return (
    <div className={css.wrapper}>
      <EventsTrackWrapperClick needParent id={183} replace={["Vkontakte"]}>
        <a
          href="https://vk.com/crowdmosru"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className={css.img} src={vk} alt="vk" />
        </a>
      </EventsTrackWrapperClick>
    </div>
  );
}
