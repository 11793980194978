import React, { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import MetaDecorator from "../../presentational/MetaDecorator/MetaDecorator";
import IdeaDetailsCardsList from "./IdeaDetailsCardsList/IdeaDetailsCardsList";
import Button from "../../presentational/Controls/Button/Button";
import Search from "../../presentational/Controls/Search/Search";
import ArrowBack from "../../presentational/Controls/ArrowBack/ArrowBack";
import PageTitle from "../../presentational/PageTitle/PageTitle";
import PageDescription from "../../presentational/PageDescription/PageDescription";
import IdeaDetailsCard from "../../presentational/Cards/IdeaDetailsCard/IdeaDetailsCard";
import Checkbox from "../../presentational/Controls/Checkbox/Checkbox";
import ProposeIdeaForm from "../../containers/Forms/ProposeIdeaForm/ProposeIdeaForm";
import Platform from "../../../services/platform";
import {
  debounce,
  declOfNum,
  displayError,
  findUrlParam,
  removeUrlParam,
} from "../../../utils";
import "./ProposeIdeaDetailsPage.scss";

import { IdeaDetails } from "../../../types/Idea.interface";
import { User } from "../../../types/User.interface";
import GorodService, { GorodIdeaSphere } from "../../../services/gorodService";
import { RootState } from "../../../types/State.interface";
import {
  AuthAction,
  AuthActionParam,
  AuthActionType,
  ComponentType,
} from "../../../services/sudirService";
import { AppContext } from "../../Root";
import ProposeIdeaSubmittedForm from "../../containers/Forms/ProposeIdeaForm/ProposeIdeaSubmittedForm";
import useQuery from "../../../hooks/useQuery";
import { useForceUpdate } from "../../../hooks/useForceUpdate";
import ProposeService, {
  SphereStatistic,
} from "../../../services/proposeService";
import DistrictSelector from "./DistrictSelector/DistrictSelector";
import { District } from "../../../types/Address.interface";
import { selectIsUserReady } from "../../../store/selectors/profile";

const ProposeIdeaDetailsPage = (props) => {
  const appContext = useContext(AppContext);

  const history = useHistory();
  const query = useQuery();
  const location = useLocation<{ categoryName?: string }>();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const forceUpdate = useForceUpdate();

  const spheres: GorodIdeaSphere[] = useSelector(
    (state: RootState) => state.gorod.spheres
  );
  const regions = useSelector((state: RootState) => state.address.regions);
  const districts = useSelector((state: RootState) => state.address.districts);
  const user: User = useSelector((state: RootState) => state.user.userDetails);
  const isUserReady = useSelector(selectIsUserReady);

  const [sphere, setSphere] = useState<GorodIdeaSphere>(null);
  const [sphereStatistic, setSphereStatistic] = useState<SphereStatistic>(null);
  const [isSubmittedProposeModalOpen, setIsSubmittedProposeModalOpen] =
    useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [district, setDistrict] = useState(null);

  const showDistricts = useMemo(
    () =>
      !!sphere?.id &&
      !!spheres
        ?.find(({ id }) => id === sphere.id)
        ?.categories?.some(
          ({ places }) => places.length > 1 || !places.includes("CITY")
        ),
    [sphere?.id, spheres]
  );

  useEffect(() => {
    if (sphere) {
      fetchStatistic(sphere.id);
    }
  }, [sphere]);

  useEffect(() => {
    if (spheres?.length) {
      try {
        const sphere = spheres.find(
          (s) => s.code === location.pathname.split("/")[2]
        );
        sphere && sphere.active ? setSphere(sphere) : history.push("/propose");
      } catch (err) {
        history.push("/propose");
      }
    }
  }, [spheres]);

  useEffect(() => {
    if (
      sphere?.id &&
      (location.state?.categoryName || params.has("categoryName"))
    ) {
      addTag(location.state?.categoryName || params.get("categoryName"));
    }
  }, [location.state?.categoryName, sphere?.id, params]);

  const fetchStatistic = async (id) => {
    try {
      const response = await ProposeService.getStatistic({ id });
      setSphereStatistic(response.data);
    } catch (error) {
      displayError("ProposeIdeaDetailsPage", "Ошибка при получении статистики");
    }
  };

  const getTextFromUri = () => {
    const decoded = decodeURI(props.history.location.search);
    if (decoded) {
      return findUrlParam("text", decoded) || "";
    }
    return "";
  };

  const [gridViewInstance, setGridViewInstance] = useState<any | null>(null);
  const [searchValue, setSearchValue] = useState(getTextFromUri());
  const [idea, setIdea] = useState<IdeaDetails | null>(null);
  const [onlyMyIdeas, setOnlyMyIdeas] = useState(
    findUrlParam("onlyMyIdeas") === "true"
  );
  const [realized, setRealized] = useState(
    () => findUrlParam("realized", location.search) === "true"
  );
  const [cardsColumns, setCardColumns] = useState("");
  const [cardsAutoRows, setCardsAutoRows] = useState("");
  const [proposeModalOpen, setProposeModalOpen] = useState(false);
  const [mapModalOpen, setMapModalOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    addResizeListeners([adjustCardsColumns, adjustCardsAutoRows]);

    return function cleanup() {
      removeResizeListeners([adjustCardsColumns, adjustCardsAutoRows]);
    };
  }, []);
  useEffect(() => {
    const idea = props?.idea;
    if (idea) {
      setIdea(idea);
      setMapModalOpen(true);
    }
  }, [props?.idea]);

  // ACTIONS AFTER AUTH
  useEffect(() => {
    if (!spheres?.length || !isUserReady) return;

    const actions = appContext.sudirService.getActions(
      ComponentType.PROPOSE_IDEA_PAGE_DETAILS
    );
    if (actions.length) {
      const _openProposeModal = actions.find(
        (act) => act.type === AuthActionType.OPEN_PROPOSE_MODAL
      );
      if (_openProposeModal) {
        const { code } = _openProposeModal.args;
        const sphere = GorodService.getSphereByCode(code, spheres);
        openProposeModal(sphere);
      }
    }
  }, [spheres, isUserReady]);

  useEffect(() => {
    if (gridViewInstance) {
      const items = gridViewInstance.getItems();
      if (items?.length) {
        const id = findUrlParam("id", history.location.search);
        if (id) {
          setTimeout(() => {
            scrollToElement(id);
          }, 1000);
          removeUrlParam("id", history);
        }
      }
    }
  }, [gridViewInstance?.items, history.location]);

  const adjustCardsColumns = () => {
    setCardColumns(
      Platform.maxWidth(620)
        ? "repeat(auto-fill, minmax(196px, 1fr))"
        : "repeat(3, 1fr)"
    );
  };

  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const findIdeaById = (id: string) =>
    gridViewInstance?.getItems().find((i) => i.id === id);

  const adjustCardsAutoRows = () => {
    setCardsAutoRows(Platform.maxWidth(900) ? "160px" : "190px");
  };

  const addResizeListeners = (callbacks) => {
    callbacks.map((callback) => {
      callback();
      window.addEventListener("resize", debounce(callback, 500));
    });
  };

  const removeResizeListeners = (callbacks) => {
    callbacks.map((callback) => window.removeEventListener("resize", callback));
  };

  const openProposeModal = (sphere?: GorodIdeaSphere) => {
    const _sphere = spheres.find(
      (s) => s.code === location.pathname.split("/")[2]
    );
    if (!user.loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.PROPOSE_IDEA_PAGE_DETAILS,
        type: AuthActionType.OPEN_PROPOSE_MODAL,
        args: {
          code: _sphere?.code || null,
        },
      };

      const openProposeModalAction = new AuthAction(thisContainerData);
      return appContext.sudirService.authWithActions([openProposeModalAction]);
    }

    setSphere(_sphere);
    setProposeModalOpen(true);
  };

  const getEndpoint = (filterByTags) => {
    const ssoid = onlyMyIdeas ? user?.ssoid : "";
    const newFilter = filterByTags.length ? filterByTags : sphere.categories;
    const categotyList = newFilter
      .map((categori) => `category=${categori.code}&`)
      .join("");
    let endpoint = `/api/gorod/idea/findByFilter?${categotyList}ssoid=${ssoid}&realized=${realized}&`;
    if (district) {
      endpoint += `&districtId=${district.id}&`;
    }

    if (searchValue) {
      return `${endpoint}text=${searchValue}&`;
    }

    return endpoint;
  };

  const _setIdea = (idea: IdeaDetails) => {
    history.replace(
      `/propose/${sphere.code}?page=${gridViewInstance.grid.page}&id=${idea.id}`
    );
  };

  const setPrevIdea = async () => {
    if (!gridViewInstance) return;

    const ideas = gridViewInstance.getItems();
    const index = ideas.indexOf(idea);

    if (index === 0) {
      await gridViewInstance.slidePrev();
      const ideas = gridViewInstance.getItems();
      _setIdea(ideas[ideas.length - 1]);
      return;
    }

    _setIdea(ideas[index - 1]);
  };

  const setNextIdea = async () => {
    if (!gridViewInstance) return;

    const ideas = gridViewInstance.getItems();
    const index = ideas.indexOf(idea);

    if (index === ideas.length - 1) {
      await gridViewInstance.slideNext();
      const ideas = gridViewInstance.getItems();
      _setIdea(ideas[0]);
      return;
    }

    _setIdea(ideas[index + 1]);
  };

  const canSlidePrev = () => {
    if (!gridViewInstance || query.fromShare) return false;

    const ideas = gridViewInstance.getItems();
    const index = ideas.indexOf(idea);

    return index > 0 || gridViewInstance.grid.page !== 0;
  };

  const canSlideNext = () => {
    if (!gridViewInstance || query.fromShare) return false;

    const ideas = gridViewInstance.getItems();
    const index = ideas.indexOf(idea);

    return index < ideas.length - 1 || gridViewInstance.canSlideNext();
  };

  // RENDER

  const renderStub = () => {
    if (searchValue || realized || district || onlyMyIdeas) return <></>;
    return (
      <div className="propose-details__stub">
        Информация о принятых в реализацию и реализованных идеях будет размещена
        в ближайшее время.
      </div>
    );
  };

  const renderCards = () => (
    <div className="propose-idea__cards-list">
      <IdeaDetailsCardsList
        endpoint={getEndpoint(selectedTags)}
        columns={3}
        gap="24px"
        restrictBy={12}
        withPagination
        scrollToTopOnSlide
        onLoadEnd={({ page }) => setPage(page)}
        bindTo="idea"
        isSequencial
        withUpdateLocation
        className="cards-list"
        renderStub={renderStub}
        onInit={(instance) => setGridViewInstance(instance)}
      >
        <IdeaDetailsCard
          sphere={sphere}
          regions={regions}
          districts={districts}
          page={page}
        />
      </IdeaDetailsCardsList>
    </div>
  );
  const onTagSelect = (tag) => {
    if (!selectedTags.includes((s) => s.title === tag)) {
      addTag(tag);
    } else {
      deleteTag(tag);
    }
  };
  const addTag = (tag) => {
    const currentCategori = sphere.categories.find((s) => s.title === tag);
    if (currentCategori) {
      const newCategories = sphere.categories.filter((s) => s.title !== tag);
      setSelectedTags([...selectedTags, currentCategori]);
      setSphere({ ...sphere, categories: newCategories });
    }
  };
  const deleteTag = (tag) => {
    const newSphere = { ...sphere };
    const currentTag = selectedTags.find((s) => s.title === tag);
    const newTagsList = selectedTags.filter((s) => s.title !== tag);
    newSphere.categories.push(currentTag);
    setSelectedTags([...newTagsList]);
    setSphere({ ...newSphere });
  };
  const renderTags = () => {
    if (!selectedTags.length) return;

    return (
      <div className="card-grid-page__tags">
        <div className="card-grid-page__tags-list">
          {selectedTags.map((tag, idx) => (
            <div className="card-grid-page__tags-list-tag" key={idx}>
              <span>{tag.title}</span>
              <div
                className="ic promo-icon-14"
                onClick={(e) => deleteTag(tag.title)}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };
  const onClosePropose = () => {
    setProposeModalOpen(false);
    const newCategories = sphere.categories.filter(
      (s) => selectedTags.findIndex((tag) => s.title === tag.title) === -1
    );
    setSphere({ ...sphere, categories: newCategories });
  };
  const onCloseSubmittedPropose = () => {
    setIsSubmittedProposeModalOpen(false);
  };
  const handleOpenPropose = (sphere) => {
    setSphere(sphere);
    setProposeModalOpen(true);
  };

  const StatisticItem = ({ count, label }) => (
    <div className="propose-details__statistic-item">
      <span className="propose-details__statistic-count">
        {count.toLocaleString("ru-RU")}
      </span>
      <span className="propose-details__statistic-text">{label}</span>
    </div>
  );

  const handleSelectDistrict = (district: District) => {
    setDistrict(district);
    resetGridPage();
  };

  const handleClearDistrict = () => {
    setDistrict(null);
    resetGridPage();
  };

  const handleRealizedChange = (state: boolean) => {
    setRealized(state);
    resetGridPage();
  };

  const handleMyIdeasChange = (state: boolean) => {
    setOnlyMyIdeas(state);
    resetGridPage();
  };

  const resetGridPage = () => {
    // gridViewInstance.page = 0;
    forceUpdate();
  };

  const title =
    sphere?.titleSeo || "Платформа Правительства Москвы «Город идей»";
  const description =
    sphere?.descriptionSeo ||
    "Вы можете улучшить город, отправив идею по его развитию в любое удобное для вас время.";

  return (
    <section className="propose-details">
      {sphere && (
        <>
          <MetaDecorator
            title={`${title}. Страница ${page + 1}`}
            description={description}
            opengraph={{ title, description }}
          />
          <ArrowBack text="Все сферы" linkUrl="/propose" />
          <div className="propose-details__head">
            <PageTitle
              text={<span dangerouslySetInnerHTML={{ __html: sphere.name }} />}
            />
            <div className="propose-details__statistic">
              <StatisticItem
                count={sphereStatistic?.activeCategoryCount || 0}
                label={`${declOfNum(sphereStatistic?.activeCategoryCount || 0, [
                  "тема",
                  "темы",
                  "тем",
                ])} для подачи идей`}
              />
              <StatisticItem
                count={sphereStatistic?.ideaInProcessCount || 0}
                label={`${declOfNum(sphereStatistic?.ideaInProcessCount || 0, ["идея", "идеи", "идей"])} в реализации`}
              />
              <StatisticItem
                count={sphereStatistic?.completedIdeaCount || 0}
                label={`${declOfNum(sphereStatistic?.completedIdeaCount || 0, ["идея", "идеи", "идей"])} ${declOfNum(
                  sphereStatistic?.completedIdeaCount || 0,
                  ["реализована", "реализованы", "реализовано"]
                )}`}
              />
            </div>
          </div>
          <PageDescription text={sphere.description} />
          <div className="propose-details__controls-wrap">
            <Button
              text="Предложить идею"
              classList="propose-details__propose-btn"
              type="filled"
              onClick={() => openProposeModal()}
            />

            <div className="propose-details__checkboxes">
              <Checkbox
                html="Реализованные идеи"
                onChange={handleRealizedChange}
                defaultValue={realized}
              />
              {user?.loggedIn && (
                <Checkbox
                  html="Мои идеи"
                  onChange={handleMyIdeasChange}
                  defaultValue={onlyMyIdeas}
                />
              )}
            </div>

            <div className="propose-details__inputs">
              {showDistricts && (
                <DistrictSelector
                  selectedDistrict={district}
                  onSelect={handleSelectDistrict}
                  onClear={handleClearDistrict}
                />
              )}
              <Search
                value={searchValue}
                placeholder="Поиск"
                items={sphere.categories.map((categori) => categori.title)}
                onChange={(value) => setSearchValue(value)}
                isRounded
                isClearPlaceholderOnSelect
                onItemSelect={(tag) => onTagSelect(tag)}
                selectedItems={selectedTags}
                autocomplete={false}
              />
            </div>
          </div>

          {renderTags()}
          {renderCards()}

          {proposeModalOpen && (
            <ProposeIdeaForm
              sphere={sphere}
              isOpen={proposeModalOpen}
              onClose={onClosePropose}
              setIsSubmittedProposeModalOpen={setIsSubmittedProposeModalOpen}
            />
          )}
          {isSubmittedProposeModalOpen && (
            <ProposeIdeaSubmittedForm
              isOpen={isSubmittedProposeModalOpen}
              onClose={onCloseSubmittedPropose}
              handleOpenPropose={handleOpenPropose}
            />
          )}
          {/* {idea && mapModalOpen && (
            <IdeaMapModal
              isOpened={mapModalOpen}
              idea={idea}
              sphere={sphere}
              canSlidePrev={canSlidePrev()}
              canSlideNext={canSlideNext()}
              onSlidePrev={setPrevIdea}
              onSlideNext={setNextIdea}
              onClose={() => {
                removeUrlParam("id", history);
                removeUrlParam("fromShare", history);
                setMapModalOpen(false);
              }}
            />
          )} */}
        </>
      )}
    </section>
  );
};

export default ProposeIdeaDetailsPage;
