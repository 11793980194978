import { useSelector } from "react-redux";
import { RootState } from "../../../types/State.interface";
import { HeaderLoggedIn } from "./HeaderLoggedIn";
import { HeaderLoggedOut } from "./HeaderLoggedOut";

export function HeaderProfile() {
  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);
  const isLoading = useSelector((state: RootState) => state.user.isFetching);

  return (
    <div className="cr-header__inner-profile cr-header-link">
      {isLoading ? "" : loggedIn ? <HeaderLoggedIn /> : <HeaderLoggedOut />}
    </div>
  );
}
