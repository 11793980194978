import dayjs from "dayjs";
import {
  ProjectStageTypes,
  Stage,
  StageLinks,
} from "../../../../types/Stage.interface";
import {
  BannerStage,
  BannerStageNull,
  BannerStageType,
} from "./BannerDirections.interface";

export const isStageFinished = (stage: Stage): boolean =>
  dayjs(stage.finish).diff(dayjs(), "second") < 0;

export const isStageActive = (stage: Stage): boolean =>
  dayjs(stage.start).diff(dayjs(), "second") < 0 && !isStageFinished(stage);

export const isSomeStageActive = (stages: Stage[]): boolean =>
  stages?.some((stage) => isStageActive(stage));

export const isStageAnnouncement = (stage: Stage): boolean =>
  dayjs(stage.start).diff(dayjs(), "second") > 0;

export const isSomeStageAnnouncement = (stages: Stage[]): boolean =>
  !!stages?.length && stages.some((stage) => isStageAnnouncement(stage));

export const isAllStagesFinished = (stages: Stage[]): boolean =>
  (stages?.length ? stages.every((stage) => isStageFinished(stage)) : false);

export const isAllStagesAnnouncement = (stages: Stage[]): boolean =>
  (stages?.length ? stages.every((stage) => isStageAnnouncement(stage)) : false);

export const checkAnnouncementStages = (stages: BannerStage): boolean =>
  !!stages?.all?.length &&
  isAllStagesAnnouncement(stages.all) &&
  !stages?.filtered?.length;

export const getLinkType = (stage: Stage): string => {
  switch (stage.type) {
    case ProjectStageTypes.GENERATION:
      return StageLinks.GENERATION;
    case ProjectStageTypes.SIMPLE_DISCUSSION:
      return StageLinks.SIMPLE_DISCUSSION;
    case ProjectStageTypes.VOTING:
      return StageLinks.VOTING;
    default:
      return "";
  }
};

export const sortStagesByValue = (
  stages: Stage[],
  value,
  sortType = "ASC"
): Stage[] => {
  const stagesCopy = [...stages];

  return stagesCopy.sort((a, b) => {
    const aValue = a[value];
    const bValue = b[value];
    if (aValue === bValue) return 0;
    if (aValue === null) return 1;
    if (bValue === null) return -1;
    if (aValue > bValue) return sortType === "ASC" ? 1 : -1;
    return sortType === "ASC" ? -1 : 1;
  });
};

export const getDateLastStage = (
  stages: Stage[],
  value: string,
  sortType?: string
): string => {
  if (stages?.length) {
    const stagesSorted: Stage[] = sortStagesByValue(
      [...stages],
      value,
      sortType
    );
    return dayjs(stagesSorted[0][value]).locale("ru").format("D MMMM");
  }
  return "";
};

export const filterStagesByType = (
  stages: Stage[],
  type: BannerStageType
): Stage[] => stages?.filter((stage) => stage.type === type);

export const getNullStageTitle = (stages: BannerStage): string => {
  switch (stages.type) {
    case ProjectStageTypes.GENERATION:
      return BannerStageNull.GENERATION;
    case ProjectStageTypes.SIMPLE_DISCUSSION:
      return BannerStageNull.SIMPLE_DISCUSSION;
    case ProjectStageTypes.VOTING:
      return BannerStageNull.VOTING;
    default:
      return "";
  }
};

export const getStageHeading = (stage: BannerStage, type?: BannerStageType) => {
  switch (stage?.type || type) {
    case ProjectStageTypes.GENERATION:
      return "Предлагать";
    case ProjectStageTypes.SIMPLE_DISCUSSION:
      return "Обсуждать";
    case ProjectStageTypes.VOTING:
      return "Оценивать";
    default:
      return "";
  }
};

export const getStageTitle = (stage: Stage): string =>
  stage.altName || stage.title || "";

export const stageIsString = (stage: any | string): boolean =>
  typeof stage === "string";

export const getCurrentIndex = (
  stagesArr: Stage[] | Stage[][] | any,
  currentIndex: number
): number =>
  (stagesArr?.length && currentIndex < stagesArr.length - 1 ? ++currentIndex : 0);

export const getCurrentStage = (stages: BannerStage): Stage | string => {
  const stage: Stage = { ...stages.filtered[stages.currentIndex] };
  stages.currentIndex = getCurrentIndex(stages.filtered, stages.currentIndex);
  return Object.keys(stage).length ? stage : getNullStageTitle(stages);
};

export const createNestedArray = (array: Stage[], number): Stage[][] => {
  const newArray: Stage[][] = [];
  array.forEach((el, i) => {
    if (i % 2 === 0) {
      newArray.push(array.slice(i, i + number));
    }
  });
  return newArray;
};
