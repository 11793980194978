import { Link } from "react-router-dom";
import React, { useRef } from "react";
import cn from "classnames";
import { declOfNum } from "../../../../../utils";
import EventsTrackWrapperClick from "../../../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";
import { TAboutStatistic } from "../helpers";
import css from "./AboutStatistic.module.scss";
import Text from "../../../../presentational/Typography/Text";
import { useNumberAnimation } from "../../../../../hooks/useNumberAnimation";

export function AboutStatistic({
  icon,
  count,
  declension,
  suffix,
  link,
  trackId,
}: TAboutStatistic) {
  const elementRef = useRef<HTMLSpanElement>(null);
  useNumberAnimation({ count, elementRef });

  const Wrapper = link
    ? trackId
      ? ({ children }) => (
          <EventsTrackWrapperClick id={trackId}>
            <Link to={link} className={cn(css.wrapper, css.link)}>
              {children}
            </Link>
          </EventsTrackWrapperClick>
        )
      : ({ children }) => (
          <Link to={link} className={cn(css.wrapper, css.link)}>
            {children}
          </Link>
        )
    : ({ children }) => <div className={css.wrapper}>{children}</div>;

  return (
    <div className={css.statistic}>
      <Wrapper>
        <i className={cn(icon, css.icon)} />
        <Text paragraph className={css.count}>
          <span ref={elementRef} />
        </Text>
        <Text size={3} paragraph className={css.name}>
          {declension.map((d, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={i}>
              {declOfNum(count || 0, d)} {suffix}
            </React.Fragment>
          ))}
        </Text>
      </Wrapper>
    </div>
  );
}
