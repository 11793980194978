import { useMemo, useRef, useState } from "react";
import {
  BigPlayButton,
  ControlBar,
  Player,
  PlayerReference,
} from "video-react";
import classNames from "classnames";
import { News } from "../../../../types/News.interface";
import TagsCard from "../TagsCard/TagsCard";
import { TagsCardParentProps } from "../TagsCard/TagsCard.interface";
import styles from "./NewsCard.module.scss";

const NewsCard = ({
  element,
  id,
  onClick,
  onTagClick,
  href,
  linkRel,
}: TagsCardParentProps<News>) => {
  const playerRef = useRef<PlayerReference | null>(null);
  const [isTouched, setIsTouched] = useState(false);

  const handleVideoContainerHover = () => {
    if (playerRef.current) {
      playerRef.current.play();
    }
  };

  const handleVideoContainerLeave = () => {
    if (playerRef.current) {
      playerRef.current.pause();
      playerRef.current.seek(0);
    }
  };

  const handleTouchStart = () => {
    if (playerRef.current) {
      playerRef.current.play();
    }
    setIsTouched(true);
  };

  const handleTouchEnd = () => {
    if (playerRef.current) {
      playerRef.current.pause();
      playerRef.current.seek(0);
    }
    setIsTouched(false);
  };

  const playerNode = useMemo(
    () => (
      <div
        className={styles["video-container"]}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <Player
          ref={playerRef}
          key={element?.videoPreviewId}
          muted
          playsInline
          preload="auto"
        >
          <source src={`/uploads/get/${element?.videoPreviewId}`} />
          <ControlBar
            disableDefaultControls
            className={styles["video-control"]}
          />
          <BigPlayButton className={styles["play-button"]} />
        </Player>

        <div
          className={classNames(styles["video-poster"], {
            [styles["video-poster--touched"]]: isTouched,
          })}
        >
          {element?.imageId && (
            <img
              src={`/uploads/get/${element?.imageId}`}
              alt={element?.title}
            />
          )}
          <div className={styles["play-icon"]} />
        </div>
      </div>
    ),
    [element?.imageId, element?.title, element?.videoPreviewId, isTouched]
  );

  return (
    <TagsCard<News>
      tagCardContainerClassName={styles["news-card"]}
      id={id}
      section="news"
      date={element?.published}
      element={element}
      imageId={element?.imageId}
      onClick={onClick}
      onTagClick={onTagClick}
      tag={element?.tag}
      title={element?.title}
      href={href}
      linkRel={linkRel}
      onVideoContainerHover={handleVideoContainerHover}
      onVideoContainerLeave={handleVideoContainerLeave}
      videoId={element?.videoPreviewId}
      videoContainerContent={playerNode}
    />
  );
};

export default NewsCard;
