import { useState } from "react";
import { Link } from "react-router-dom";
import EventsTrackWrapperClick from "../../EventsTrack/wrappers/EventsTrackWrapperClick";
import SupportForm from "../../Forms/SupportForm/SupportForm";
import Text from "../../../presentational/Typography/Text";
import css from "./FooterInfo.module.scss";

export function FooterInfo() {
  const [modalOpened, setModalOpened] = useState(false);

  return (
    <div className={css.wrapper}>
      <Text size={4} paragraph className={css.copyright}>
        © Краудсорсинг-проекты Правительства Москвы, {new Date().getFullYear()}
      </Text>
      <div className={css.links}>
        <EventsTrackWrapperClick id={246}>
          <button type="button" onClick={() => setModalOpened(true)}>
            Помощь
          </button>
        </EventsTrackWrapperClick>
        <EventsTrackWrapperClick id={247}>
          <Link to="/license" target="_blank">
            Пользовательское соглашение
          </Link>
        </EventsTrackWrapperClick>
        <EventsTrackWrapperClick id={248}>
          <a
            href="https://www.mos.ru/legal/rules/"
            target="_blank"
            rel="noreferrer"
          >
            Соглашение о пользовании информационными системами и ресурсами
            города Москвы
          </a>
        </EventsTrackWrapperClick>

        <a
          href="https://www.mos.ru/dit/documents/informatcionnaia-bezopasnost/view/274898220/"
          target="_blank"
          rel="noreferrer"
        >
          Политика в отношении обработки персональных данных
        </a>
      </div>

      {modalOpened && (
        <SupportForm
          isOpened={modalOpened}
          close={() => setModalOpened(false)}
        />
      )}
    </div>
  );
}
