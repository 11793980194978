import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Hammer from "react-hammerjs";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { findRole } from "../../../../utils";

import styles from "./BannerDirections.module.scss";
import {
  BannerCurrentStage,
  BannerDirectionsProps,
  BannerStage,
  BannerStages,
  BannerStageType,
} from "./BannerDirections.interface";
import "dayjs/locale/ru";
import { ProjectStageTypes, Stage } from "../../../../types/Stage.interface";
import {
  Project,
  ProjectStructure,
} from "../../../../types/Projects.interface";
import { AppContext } from "../../../Root";
import {
  AuthAction,
  AuthActionParam,
  AuthActionType,
  ComponentType,
} from "../../../../services/sudirService";
import { GorodIdeaSphere } from "../../../../services/gorodService";
import EventsTrackWrapperScroll from "../../../containers/EventsTrack/wrappers/EventsTrackWrapperScroll";

import {
  selectIsUserReady,
  selectProjectsUserState,
  selectUserDetails,
} from "../../../../store/selectors/profile";
import { useIsMobile, useIsTablet } from "../../../../hooks/useMedia";
import { selectSpheres } from "../../../../store/selectors/gorod";
import { selectGlobalEvents } from "../../../../store/selectors/globalEvents";
import { selectCurrentProjects } from "../../../../store/selectors/project";
import {
  checkAnnouncementStages,
  createNestedArray,
  filterStagesByType,
  getCurrentStage,
  getDateLastStage,
  getLinkType,
  getNullStageTitle,
  getStageHeading,
  getStageTitle,
  isAllStagesAnnouncement,
  isAllStagesFinished,
  isSomeStageActive,
  isSomeStageAnnouncement,
  isStageActive,
  isStageAnnouncement,
  stageIsString,
} from "./BannerDirections.utils";
import { selectSettings } from "../../../../store/selectors/sittings";
import Slider from "../../../presentational/Sliders/Slider/Slider";
import Text from "../../../presentational/Typography/Text";

const BannerDirections: FC<BannerDirectionsProps> = ({ project }) => {
  const appContext = useContext(AppContext);

  const isPhone = useIsMobile();
  const isTablet = useIsTablet();

  const [stages, setStages] = useState<BannerStages>({});
  const [currentStage, setCurrentStage] = useState<BannerCurrentStage>({});

  const { SHOW_PROJECT_CATEGORY: showProjectCategory } =
    useSelector(selectSettings);
  const currentProjects = useSelector(selectCurrentProjects);
  const spheres: GorodIdeaSphere[] = useSelector(selectSpheres);
  const projectsUserState = useSelector(selectProjectsUserState);
  const user = useSelector(selectUserDetails);
  const isUserReady = useSelector(selectIsUserReady);
  const { width: screenWidth } = useSelector(selectGlobalEvents);

  const [currentMobileStage, setCurrentMobileStage] =
    useState<BannerCurrentStage>({});
  const userDetails = useSelector(selectUserDetails);

  const getCurrentProjectById = useCallback(
    (id: string): Project | undefined =>
      currentProjects?.find((p) => p.id === id),
    [currentProjects]
  );

  const isCurrentUserAssigned = (stageId, projectId): boolean =>
    (projectsUserState && projectsUserState[projectId]
      ? projectsUserState[projectId].stageIds?.includes(stageId) ||
        projectsUserState[projectId].stageIds?.includes("*")
      : false);

  const getStageFinishedText = (stage: BannerStage, projectId): string => {
    const stagesFiltered = userDetails?.loggedIn
      ? stage.filtered.filter((s) => isCurrentUserAssigned(s.id, projectId))
      : stage.filtered;
    const lastFinishDate = getDateLastStage(stagesFiltered, "finish", "DESC");

    if (lastFinishDate) {
      switch (stage.type) {
        case ProjectStageTypes.GENERATION:
          return `Предложение идей завершено ${lastFinishDate}`;
        case ProjectStageTypes.SIMPLE_DISCUSSION:
          return `Обсуждения на проекте завершены ${lastFinishDate}`;
        case ProjectStageTypes.VOTING:
          return `Оценка идей завершена ${lastFinishDate}`;
        default:
          return "";
      }
    }

    return getNullStageTitle(stage);
  };

  const getCurrentStages = (
    projectId
  ): [
    currentStage: BannerCurrentStage,
    currentMobileStage: BannerCurrentStage,
  ] => {
    const currStage: BannerCurrentStage = { ...currentStage };
    Object.keys(stages[projectId]).forEach((key) => {
      currStage[key] = null;
      if (isAllStagesFinished(stages[projectId][key].filtered)) {
        currStage[key] = getStageFinishedText(
          stages[projectId][key],
          projectId
        );
      }
      if (
        isAllStagesAnnouncement(stages[projectId][key].filtered) ||
        checkAnnouncementStages(stages[projectId][key])
      ) {
        currStage[key] = getStageAnnouncementText(
          stages[projectId][key],
          projectId
        );
      }
      if (!currStage[key]) {
        currStage[key] = getCurrentStage(stages[projectId][key]);
      }
    });

    return [currStage, getCurrentMobileStage(stages[projectId], projectId)];
  };

  const stageValid = (stage: Stage, projectId: string): boolean =>
    (userDetails?.loggedIn && findRole(userDetails, projectId)
      ? isCurrentUserAssigned(stage.id, projectId) &&
        !!stage.showOnPromo &&
        !userDetails.blockedOnProjects.includes(projectId)
      : !!stage.showOnPromo);

  const filterStagesByPriority = (
    stagesToFilter: Stage[],
    projectId: string
  ): Stage[] => {
    let resultArr: Stage[] = [];
    if (isSomeStageActive(stagesToFilter)) {
      resultArr = stagesToFilter?.length
        ? stagesToFilter.filter(
            (stage) => isStageActive(stage) && stageValid(stage, projectId)
          )
        : [];
    }
    if (!resultArr.length && isSomeStageAnnouncement(stagesToFilter)) {
      resultArr = stagesToFilter?.length
        ? stagesToFilter.filter(
            (stage) =>
              isStageAnnouncement(stage) && stageValid(stage, projectId)
          )
        : [];
    }

    if (!resultArr.length) {
      resultArr = stagesToFilter?.length
        ? stagesToFilter.filter(
            (stage) =>
              !isStageActive(stage) &&
              !isStageAnnouncement(stage) &&
              stageValid(stage, projectId)
          )
        : [];
    }
    return resultArr;
  };

  const createStage = (
    allStages: Stage[],
    type: BannerStageType,
    projectId: string
  ): BannerStage => {
    const instance: BannerStage = {
      type,
      filtered: [],
      all: [],
      currentIndex: 0,
      mobileCurrentIndex: 0,
    };

    instance.all = filterStagesByType(allStages, type);
    instance.filtered = filterStagesByPriority(instance.all, projectId);
    instance.filtered.sort((a, b) => {
      if (a.finish !== b.finish) {
        return +new Date(a.finish) < +new Date(b.finish) ? -1 : 1;
      }
      if (a.priority !== b.priority) {
        return a.priority !== null && a.priority < b.priority ? -1 : 1;
      }
      return +new Date(a.created) < +new Date(b.created) ? -1 : 1;
    });

    return instance;
  };

  const getAllStages = (structure: ProjectStructure): Stage[] => {
    let allStages: Stage[] = [];

    if (structure?.stages.length) {
      allStages = allStages.concat(structure.stages);
    }

    if (structure.nodes.length) {
      structure.nodes.forEach((subStructure) => {
        allStages = allStages.concat(getAllStages(subStructure));
      });
    }

    return allStages;
  };

  const getStages = (): BannerStages => {
    const bannerStages: BannerStages = {};
    currentProjects?.forEach(({ id, structure }) => {
      bannerStages[id] = {};
      Object.values(ProjectStageTypes).forEach((value) => {
        bannerStages[id][value] = createStage(
          getAllStages(structure),
          value,
          id
        );
      });
    });
    return bannerStages;
  };

  const currentProject = useMemo(() => {
    if (project) {
      return getCurrentProjectById(project.id);
    }
    return currentProjects?.[0];
  }, [currentProjects, getCurrentProjectById, project]);

  const getLinkToStage = (stage: Stage): string => {
    const platformUrl = currentProject.url;
    return (
      stage && platformUrl && `${platformUrl}/${getLinkType(stage)}/${stage.id}`
    );
  };

  const isShowBlock = (): boolean =>
    Object.keys(currentStage).some(
      (key) => Object.entries(currentStage[key]).length
    );

  const hasStages = () => Boolean(Object.keys(stages).length);

  const getStageAnnouncementText = (stage: BannerStage, projectId): string => {
    const stagesFiltered = userDetails?.loggedIn
      ? stage.filtered.filter((item) =>
          isCurrentUserAssigned(item.id, projectId)
        )
      : stage.filtered;
    const lastStartDate = getDateLastStage(stagesFiltered, "start");

    if (lastStartDate) {
      switch (stage.type) {
        case ProjectStageTypes.GENERATION:
          return `Предлагайте свои идеи с ${lastStartDate}`;
        case ProjectStageTypes.SIMPLE_DISCUSSION:
          return `Принимайте участие в обсуждении с ${lastStartDate}`;
        case ProjectStageTypes.VOTING:
          return `Выбирайте лучшие идеи с ${lastStartDate}`;
        default:
          return "";
      }
    }

    return getNullStageTitle(stage);
  };

  const createMobileStage = (
    bannerStage: BannerStage,
    projectId
  ): Stage[][] | string => {
    if (isAllStagesFinished(bannerStage.filtered)) {
      return getStageFinishedText(bannerStage, projectId);
    }
    if (
      isAllStagesAnnouncement(bannerStage.filtered) ||
      checkAnnouncementStages(bannerStage)
    ) {
      return getStageAnnouncementText(bannerStage, projectId);
    }

    const nestedArray: Stage[][] = createNestedArray(
      [...bannerStage.filtered],
      2
    );
    return nestedArray.length ? nestedArray : [[]];
  };

  const getMobileStage = (
    stage: BannerStage,
    projectId
  ): Stage[][] | string => {
    const mobileStage = createMobileStage(stage, projectId);
    return stageIsString(mobileStage) ? (mobileStage as string) : mobileStage;
  };

  const getCurrentMobileStage = (
    bannerCurrentStage: BannerCurrentStage,
    projectId
  ): BannerCurrentStage => {
    let currentMobileStages: BannerCurrentStage = {};
    Object.keys(bannerCurrentStage).forEach((key) => {
      const mobileStage = getMobileStage(bannerCurrentStage[key], projectId);
      if (stageIsString(mobileStage)) {
        currentMobileStages[key] = mobileStage as string;
      } else {
        (mobileStage as Stage[][]).forEach((stageArr) => {
          if (stageArr.length) {
            currentMobileStages = stageArr.reduce<BannerCurrentStage>(
              (prev, curr) => ({ ...prev, [curr.type]: curr }),
              currentMobileStages
            );
          } else {
            currentMobileStages[key] = getNullStageTitle(
              bannerCurrentStage[key]
            );
          }
        });
      }
    });

    const sortedKeys = Object.keys(currentMobileStages).sort((a, b) =>
      (stageIsString(currentMobileStages[b]) ? -1 : 1)
    );
    return sortedKeys.reduce(
      (prev, curr) => ({ ...prev, [curr]: currentMobileStages[curr] }),
      {}
    );
  };

  const goToPlatform = (ev, stage?) => {
    ev && ev.preventDefault();

    const link = stage ? getLinkToStage(stage) : currentProject.url;
    if (!user.loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.MAIN_BANNER_DIRECTIONS,
        type: AuthActionType.REDIRECT,
        args: {
          redirectLink: link,
          absolutePath: true,
        },
      };

      const mainContainerData: AuthActionParam = {
        component: ComponentType.MAIN,
        type: AuthActionType.REDIRECT_AFTER_REG_FORM,
        args: {
          projectId: currentProject.id,
          absolutePath: true,
          redirectLink: link,
        },
      };

      const action1 = new AuthAction(thisContainerData);
      const action2 = new AuthAction(mainContainerData);

      return appContext.sudirService.authWithActions([action1, action2]);
    }

    window.location.href = link;
    return null;
  };

  useEffect(() => setStages(getStages()), [currentProjects, projectsUserState]);

  useEffect(() => {
    if (currentProject) {
      if (!Object.keys(stages).length) return;

      const [cs, cms] = getCurrentStages(currentProject.id);
      setCurrentStage(cs);
      setCurrentMobileStage(cms);
    }
  }, [currentProject, stages]);

  // ACTIONS AFTER AUTH
  useEffect(() => {
    if (!spheres?.length || !isUserReady) return;

    const actions = appContext.sudirService.getActions(
      ComponentType.MAIN_BANNER_DIRECTIONS
    );
    if (actions.length) {
      const redirectAction = actions.find(
        (act) => act.type === AuthActionType.REDIRECT
      );
      if (redirectAction) {
        window.location.href = redirectAction.args.redirectLink;
      }
    }
  }, [spheres, isUserReady]);

  const renderCurrentProjectBannerDirection = () => {
    if (!hasStages()) return;

    const currentStages = screenWidth > 500 ? currentStage : currentMobileStage;
    const stagesKeys = currentStages ? Object.keys(currentStages) : [];

    const getClassNames = (stage, type?: BannerStageType): string =>
      classNames(styles.item, styles[`item_${stage.type || type}`], {
        [styles.item_disabled]:
          stageIsString(stage) || (!stage.altName && !stage.title),
      });

    const slidesPerView = isPhone ? 1 : 3;

    return (
      stagesKeys.length > 0 && (
        <Slider
          sliderOptions={{
            slidesPerView,
            slidesPerGroup: 1,
            spaceBetween: 24,
          }}
          showArrows={slidesPerView < stagesKeys.length}
        >
          {stagesKeys.map((key) => {
            const heading = getStageHeading(
              currentStages[key],
              key as BannerStageType
            );
            return (
              <a
                key={key}
                className={getClassNames(
                  currentStages[key],
                  key as BannerStageType
                )}
                onClick={(e) => goToPlatform(e, currentStages[key])}
                href={
                  stageIsString(currentStages[key])
                    ? "#"
                    : getLinkToStage(currentStages[key])
                }
              >
                {heading && <div className={styles.title}>{heading}</div>}
                <Text
                  size={isTablet ? 3 : 2}
                  wordBreak
                  ellipsis={4}
                  className={styles.text}
                >
                  {stageIsString(currentStages[key])
                    ? currentStages[key]
                    : getStageTitle(currentStages[key])}
                </Text>
              </a>
            );
          })}
        </Slider>
      )
    );
  };

  return showProjectCategory && !!currentProject && isShowBlock() ? (
    <Hammer>
      <EventsTrackWrapperScroll id={3} needParent>
        <div className={styles["banner-directions"]}>
          {renderCurrentProjectBannerDirection()}
        </div>
      </EventsTrackWrapperScroll>
    </Hammer>
  ) : null;
};

export default BannerDirections;
