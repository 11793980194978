import React from "react";
import { useHistory } from "react-router-dom";

import IconButton from "../../Controls/IconButton/IconButton";
import Image from "../../Image/Image";
import { declOfNum, getProgressValue, getStringDate } from "../../../../utils";
import { Project } from "../../../../types/Projects.interface";
import "./ProjectFlipcard.scss";
import { GroupDirection } from "../../../../types/GroupDirection.interface";

interface Props {
  project: Project;
  groupDirection?: GroupDirection;
  type: "archive" | "announcement" | "current" | "subproject" | "main";
  isFlippable: Boolean;
  defaultLogo: string;
}

const EL_USLUGI_ID = "el_uslugi";

const ProjectFlipcard = (props: Props) => {
  const history = useHistory();

  const getDate = (project) =>
    getStringDate(
      project,
      !project.hasSubProjects && !project.parentProjectId
        ? "monthToMonthOfYear"
        : "finishYear"
    );

  // RENDER

  const renderCardHeader = () => {
    // TODO: remove assets hardcode by getting additional logo uploads
    let logoSrc = props.defaultLogo;

    if (props.type === "archive" && props.project.id === EL_USLUGI_ID) {
      logoSrc =
        require("../../../../assets/logo/el_uslugi_main_vertical.png").default;
    } else if (props.groupDirection) {
      if (props.groupDirection.logo?.verticalAttachmentId) {
        logoSrc = `/uploads/get/${props.groupDirection.logo.verticalAttachmentId}`;
      }
    } else if (props.project.logo.vertical) {
      logoSrc = `/uploads/get/${props.project.logo.vertical}`;
    }

    return (
      <div className="project-flipcard-inner__front-logo">
        <Image
          src={logoSrc}
          alt="Логотип проекта"
          defaultSrc={props.defaultLogo}
        />
      </div>
    );
  };

  const renderStats = () => (
    <div className="project-flipcard-inner__front-footer-stats">
      <div className="project-flipcard-inner__front-footer-stats-users">
        <div className="ic promo-icon-89" />
        {props.project.participantCount || 0}
      </div>

      <div className="project-flipcard-inner__front-footer-stats-bulb">
        <div className="ic promo-icon-90" />
        {props.project.hasSubProjects
          ? props.project.totalProposalCount
          : props.project.proposalCount || 0}
      </div>
    </div>
  );

  const getProgressTitle = (project, type) => {
    const { waitingRealizedIdeaCount, realizedIdeaCount } = project;
    const totalCount = waitingRealizedIdeaCount + realizedIdeaCount;

    const isOnlyAccepted = waitingRealizedIdeaCount && !realizedIdeaCount;
    const isSomeRealized = waitingRealizedIdeaCount && realizedIdeaCount;
    const isAllRealized = realizedIdeaCount && realizedIdeaCount === totalCount;

    if (isOnlyAccepted) {
      return `<span>${waitingRealizedIdeaCount}</span> ${declOfNum(
        waitingRealizedIdeaCount,
        ["идея", "идеи", "идей"]
      )} ${declOfNum(waitingRealizedIdeaCount, ["принята", "принято", "принято"])}${
        type === "back" ? "</br>" : ""
      } в реализацию`;
    }
    if (type === "front" && isSomeRealized) {
      return `<span>${realizedIdeaCount}</span> ${declOfNum(realizedIdeaCount, [
        "идея",
        "идеи",
        "идей",
      ])} из <span>${totalCount}</span> ${declOfNum(realizedIdeaCount, ["реализована", "реализованы", "реализовано"])}`;
    }
    if (type === "back" && isSomeRealized) {
      return `<span>${totalCount}</span> ${declOfNum(totalCount, ["идея", "идеи", "идей"])} ${declOfNum(
        totalCount,
        ["принята", "принято", "принято"]
      )} </br> в реализацию`;
    }
    if (isAllRealized) {
      return `Все <span>${realizedIdeaCount}</span> ${declOfNum(
        realizedIdeaCount,
        ["идея", "идеи", "идей"]
      )} реализованы`;
    }

    return "Информация появится позже";
  };

  const getName = (project) =>
    (project.structure && project.structure.title) || project.title;

  const renderProgress = (project) => (
    <div className="project-progress">
      <div className="project-progress__bar">
        <div
          className="project-progress__text"
          dangerouslySetInnerHTML={{
            __html: getProgressTitle(project, "front"),
          }}
        />
        <div
          className="project-progress__value"
          style={{ width: `${getProgressValue(project)}%` }}
        >
          {" "}
        </div>
      </div>
    </div>
  );

  const renderBackButtons = () => {
    if (!!props.project.hasSubProjects && props.project.id !== EL_USLUGI_ID) {
      return <IconButton text="Промежуточные итоги" icon="promo-icon-17" />;
    }

    return <IconButton text="Подробнее о проекте" icon="promo-icon-104" />;
  };

  const renderFrontContent = () => {
    if (props.type === "archive") {
      return (
        <>
          <div className="project-flipcard-inner__front-footer-date">
            {getDate(props.project)}
          </div>
          <div className="project-flipcard-inner__front-footer-name">
            {getName(props.project)}
          </div>
          {renderProgress(props.project)}
        </>
      );
    }
    if (props.type === "announcement") {
      return (
        <div className="project-flipcard-inner__front-footer-announcement">
          <div className="project-flipcard-inner__front-footer-name">
            {getName(props.project)}
          </div>

          <IconButton
            text="Перейти на проект"
            linkUrl={`announcement?id=${props.project.id}`}
          />
        </div>
      );
    }

    if (props.type === "current") {
      return (
        <div className="project-flipcard-inner__front-footer-current">
          <div className="project-flipcard-inner__front-footer-name">
            {getName(props.project)}
          </div>

          <IconButton
            text="Перейти на проект"
            linkUrl={`current?id=${props.project.id}`}
          />
        </div>
      );
    }

    if (props.type === "subproject") {
      return (
        <div className="project-flipcard-inner__back-footer">
          <IconButton
            text="Подробнее о проекте"
            icon="promo-icon-104"
            linkUrl={`workresult?id=${props.project.id}${
              props.groupDirection
                ? `&groupDirectionId=${props.groupDirection.id}`
                : ""
            }`}
          />
        </div>
      );
    }

    if (props.type === "main") {
      return "Main";
    }
  };

  const renderFront = () => (
    <div className="project-flipcard-inner__front">
      {renderCardHeader()}

      <div className="project-flipcard-inner__front-footer">
        {renderFrontContent()}
      </div>
    </div>
  );

  const renderBack = () => (
    <div
      className="project-flipcard-inner__back"
      onClick={() => {
        history.push(
          !!props.project.hasSubProjects || props.project.hasLanding
            ? `archive?id=${props.project.id}`
            : `workresult?id=${props.project.id}`
        );
      }}
    >
      <div className="project-flipcard-inner__back-name">
        {props.project.structure && props.project.structure.title}
      </div>

      <div className="project-flipcard-inner__back-progress">
        <div className="ic promo-icon-13" />
        <div
          className="project-flipcard-inner__back-progress-title"
          dangerouslySetInnerHTML={{
            __html: getProgressTitle(props.project, "back"),
          }}
        />
      </div>

      <div
        className="project-flipcard-inner__back-description"
        style={{ height: props.project.hasSubProjects ? "60%" : "40%" }}
        dangerouslySetInnerHTML={{ __html: props.project.shortDescription }}
      />

      <div className="project-flipcard-inner__back-footer">
        {renderBackButtons()}
      </div>
    </div>
  );

  return (
    <div className="project-flipcard">
      <div
        className={`project-flipcard-inner ${
          props.isFlippable ? "project-flipcard-flippable" : ""
        }`}
      >
        {renderFront()}
        {props.isFlippable && renderBack()}
      </div>
    </div>
  );
};

export default ProjectFlipcard;
