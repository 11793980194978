import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, useHistory } from "react-router-dom";
import Swiper from "swiper";

import useQuery from "../../../hooks/useQuery";
import IdeaService from "../../../services/ideaService";
import ProjectService from "../../../services/projectService";
import {
  adaptivePage,
  lessenPage,
  setAppColor,
} from "../../../store/actions/LayoutActions";
import { ProjectInfo } from "../../../types/Banner.interface";
import { declOfNum, getProgressValue } from "../../../utils";
import {
  phoneWidth,
  tabletWidth,
} from "../../../utils/constants/widthConstants";
import { AppColorsEnum } from "../../App.interface";
import CommonViewer from "../../containers/Modals/CommonViewer/CommonViewer";
import ContentCard from "../../presentational/Cards/ContentCard/ContentCard";
import InProgressCard from "../../presentational/Cards/InProgressCard/InProgressCard";
import WorkResultCard from "../../presentational/Cards/WorkResultCard/WorkResultCard";
import ArrowBack from "../../presentational/Controls/ArrowBack/ArrowBack";
import Button from "../../presentational/Controls/Button/Button";
import Loader from "../../presentational/Loaders/Loader/Loader";
import MetaDecorator from "../../presentational/MetaDecorator/MetaDecorator";
import ProgressBar from "../../presentational/ProgressBar/ProgressBar";
import ProgressCounter from "../../presentational/ProgressCounter/ProgressCounter";
import CommonSlider from "../../presentational/Sliders/CommonSlider/CommonSlider";
import { createList, ideasDecl } from "./WorkResult.helper";

import "./WorkResultPage.scss";
import {
  GroupDirection,
  RealizedIdeaProjectGroupDirection,
} from "../../../types/GroupDirection.interface";
import MediaPlatformService from "../../../services/mediaPlatform.service";

export interface ResultIdeaAuthor {
  blocked: boolean;
  firstName: string;
  id: string;
  lastName: string;
  pictureId: string;
  deletedSudir: boolean;
}

export enum RealizedType {
  REALIZED,
  ACCEPTED,
}

export interface ResultIdea {
  type: RealizedType;
  id: string;
  author: ResultIdeaAuthor;
  completed: string;
  deadline: string;
  mainUserPhotoId: string;
  organizationId: string;
  organizationName: string;
  photoIds: string[];
  publishedPhotoIds: string[];
  publishedUserPhotoIds: string[];
  selectedByExperts: boolean;
  text: string;
}

const WorkResultPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();

  const isDesktop = useMediaQuery({ query: `(min-width: ${tabletWidth}px)` });
  const isTablet = useMediaQuery({
    query: `(max-width: ${tabletWidth}px) and (min-width: ${phoneWidth}px)`,
  });
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });

  const [project, setProject] = useState<ProjectInfo>(null);
  const [groupDirection, setGroupDirection] = useState<GroupDirection>(null);
  const [projectGroupDirectionWaiting, setProjectGroupDirectionWaiting] =
    useState<RealizedIdeaProjectGroupDirection>(null);
  const [projectGroupDirectionRealized, setProjectGroupDirectionRealized] =
    useState<RealizedIdeaProjectGroupDirection>(null);
  const [selectedIdea, setSelectedIdea] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [realized, setRealized] = useState(null);
  const [realizedAll, setRealizedAll] = useState(null);
  const [acceptedAll, setAcceptedAll] = useState(null);
  const [showStab, setShowStab] = useState(false);
  const [selectedList, setSelectedList] = useState(null);
  const [selectedListType, setSelectedListType] = useState("");
  const [realizedSwiper, setRealizedSwiper] = useState<Swiper>();
  const [acceptedSwiper, setAcceptedSwiper] = useState<Swiper>();
  const [currentSlideIdeaIndex, setCurrentSlideIdeaIndex] = useState<number>();

  useEffect(() => {
    dispatch(adaptivePage());
    dispatch(setAppColor(AppColorsEnum.WHITE));
    query.id
      ? fetchData(query.id, query.groupDirectionId)
      : history.push("/archive");

    return function () {
      dispatch(lessenPage());
    };
  }, []);

  async function fetchData(projectId, groupDirectionId?) {
    setLoading(true);

    try {
      const { data: realizedResponse } =
        await IdeaService.getRealizedIdeas(projectId);
      const { data: projectResponse } =
        await ProjectService.getProjectInfo(projectId);

      let realizedAll = [];
      let acceptedAll = [];
      if (groupDirectionId) {
        const { data: groupDirectionResponse } =
          await MediaPlatformService.projectGroupDirectionGetById(
            groupDirectionId
          );
        setGroupDirection(groupDirectionResponse);

        const projectGroupDirectionRealized =
          realizedResponse.realized.groupDirectionals.find(
            ({ projectGroupDirectionId }) =>
              projectGroupDirectionId === groupDirectionId
          );
        const projectGroupDirectionWaiting =
          realizedResponse.waiting.groupDirectionals.find(
            ({ projectGroupDirectionId }) =>
              projectGroupDirectionId === groupDirectionId
          );
        if (projectGroupDirectionRealized) {
          realizedAll = createList(
            projectGroupDirectionRealized,
            RealizedType.REALIZED
          );
        }
        if (projectGroupDirectionWaiting) {
          acceptedAll = createList(
            projectGroupDirectionWaiting,
            RealizedType.ACCEPTED
          );
        }
        setProjectGroupDirectionWaiting(projectGroupDirectionWaiting);
        setProjectGroupDirectionRealized(projectGroupDirectionRealized);
      } else {
        realizedAll = createList(
          realizedResponse.realized,
          RealizedType.REALIZED
        );
        acceptedAll = createList(
          realizedResponse.waiting,
          RealizedType.ACCEPTED
        );
      }

      setRealized(realizedResponse.realized);
      setRealizedAll(realizedAll);
      setAcceptedAll(acceptedAll);
      setShowStab(realizedAll.length === 0 && acceptedAll.length === 0);
      setProject(projectResponse[0]);
      if (query.ideaId) {
        const selectedAtStartAccepted = acceptedAll.find(
          ({ id }) => id === query.ideaId
        );
        const selectedAtStartRealized = realizedAll.find(
          ({ id }) => id === query.ideaId
        );
        if (selectedAtStartAccepted || selectedAtStartRealized) {
          setSelectedIdea(selectedAtStartAccepted || selectedAtStartRealized);
          setSelectedList(selectedAtStartAccepted ? acceptedAll : realizedAll);
          setSelectedListType(
            selectedAtStartAccepted ? "accepted" : "realized"
          );
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  const renderRealizedSlider = useMemo(
    () => (
      <CommonSlider
        onSwiper={setRealizedSwiper}
        className="realized-slider"
        linkOptions={{
          text: "Посмотреть все реализованные идеи",
          linkUrl: `/workresult/detailed?id=${query.id}${
            query.groupDirectionId
              ? `&groupDirectionId=${query.groupDirectionId}`
              : ""
          }&type=REALIZED`,
        }}
        options={{
          loop: true,
          spaceBetween: isPhone ? 2 : 24,
          width: isPhone ? 280 : null,
          slidesPerView: isPhone ? 1.122 : null,
          slidesPerGroup: selectedListType === "realized" ? 1 : null,
          centeredSlides: isPhone,
        }}
      >
        {realizedAll?.map((idea) => (
          <ContentCard
            key={idea.id}
            project={project}
            groupDirection={groupDirection}
            id={idea.id}
            card={idea}
            onClick={(idea) => {
              setSelectedIdea(idea);
              setSelectedList(realizedAll);
              setSelectedListType("realized");
            }}
          />
        ))}
      </CommonSlider>
    ),
    [isPhone, project, groupDirection, query.id, realizedAll, selectedListType]
  );

  const renderNotRealizedSlider = useMemo(() => {
    const ideaProgressCards = acceptedAll
      ? acceptedAll.map((idea) => (
          <InProgressCard
            key={idea.id}
            card={idea}
            onClick={(idea) => {
              setSelectedIdea(idea);
              setSelectedList(acceptedAll);
              setSelectedListType("accepted");
            }}
          />
        ))
      : [];

    const slidesPerView = isDesktop ? 2 : isTablet ? 1 : 1;
    const slidesPerColumn =
      ideaProgressCards.length <= slidesPerView
        ? 1
        : isDesktop
          ? 2
          : isTablet
            ? 4
            : 1;
    let colsCount = Math.ceil(ideaProgressCards.length / slidesPerColumn);
    if (colsCount < slidesPerView) {
      colsCount = slidesPerView;
    }
    const slides = [];
    const usedSlides = [];
    for (let col = 0; col < colsCount; col++) {
      const slidesColGroup = [];
      const group = Math.floor(col / slidesPerView);
      const colInGroup = col - group * slidesPerView;
      for (let row = 0; row < slidesPerColumn; row++) {
        const from =
          row * slidesPerView +
          group * slidesPerView * slidesPerColumn +
          colInGroup;

        let card = ideaProgressCards.slice(from, from + 1)[0];
        if (!card) {
          const newFrom = from - (slidesPerView - 1) * row;
          card = ideaProgressCards.slice(newFrom, newFrom + 1)[0];
        }
        if (!usedSlides.includes(card)) {
          slidesColGroup.push(card);
          usedSlides.push(card);
        }
      }
      slides.push(
        <div key={col} className="accepted-slider-group">
          {slidesColGroup}
        </div>
      );
    }

    return (
      <CommonSlider
        onSwiper={setAcceptedSwiper}
        className="accepted-slider"
        options={{
          loop: true,
          slidesPerGroup: slidesPerView,
          slidesPerView,
          slidesPerColumn: 1,
        }}
        linkOptions={{
          text: "Посмотреть все принятые идеи",
          linkUrl: `/workresult/detailed?id=${query.id}${
            query.groupDirectionId
              ? `&groupDirectionId=${query.groupDirectionId}`
              : ""
          }&type=ACCEPTED`,
        }}
      >
        {slides}
      </CommonSlider>
    );
  }, [acceptedAll, isDesktop, isTablet, query.id]);

  const renderProjectLogo = () => {
    const logoId = groupDirection
      ? isTablet
        ? groupDirection.logo?.quadrateAttachmentId
        : groupDirection.logo?.horizontalAttachmentId
      : isTablet
        ? project?.logo?.square
        : project?.logo?.horizontal;
    return (
      !!logoId && (
        <img className="wr-page__heading-img" src={`/uploads/get/${logoId}`} />
      )
    );
  };

  const renderStab = () => (
    <div className="wr-page__stab">
      Информация о проекте будет размещена в ближайшее время.
    </div>
  );

  const renderIdeas = () => {
    const projectGroupDirections = groupDirection
      ? {
          waiting: projectGroupDirectionWaiting,
          realized: projectGroupDirectionRealized,
        }
      : undefined;
    return (
      <>
        {Boolean(realizedAll?.length) && (
          <div className="wr-page__ideas">
            <div className="wr-page__wrapper">
              <div className="wr-page__title">Реализованные идеи жителей</div>
              {project && (
                <>
                  <ProgressCounter
                    project={project}
                    projectGroupDirections={projectGroupDirections}
                  />
                  <ProgressBar
                    value={getProgressValue(project, projectGroupDirections)}
                  />
                </>
              )}

              {renderRealizedSlider}
            </div>
          </div>
        )}

        {Boolean(acceptedAll?.length) && (
          <div className="wr-page__inprogress">
            <div className="wr-page__wrapper">
              <div className="wr-page__title">Идеи в процессе реализации</div>
              <div className="wr-page__remained">
                По итогам проекта{" "}
                {declOfNum(acceptedAll.length, [
                  "будет реализована",
                  "будут реализованы",
                  "будет реализовано",
                ])}{" "}
                {realizedAll.length ? "ещё" : ""} {acceptedAll.length}{" "}
                {ideasDecl(acceptedAll.length)}.
              </div>

              {renderNotRealizedSlider}
            </div>
          </div>
        )}

        {!!project?.slider && !project.parentProjectId && (
          <div className="wr-page__more">
            <div className="wr-page__wrapper">
              <div className="wr-page__title">
                Хотите узнать о проекте больше?
              </div>
              <Button
                type="outlined"
                text="Презентация о проекте"
                onClick={() => history.push(`/projects/${query.id}/book`)}
              />
            </div>
          </div>
        )}

        <div className="wr-page__idea-lists">
          <div className="wr-page__wrapper">
            <Link to={`/plan?id=${query.id}`}>Список идей к реализации</Link>
            <Link to={`/ideas?id=${query.id}`}>Список реализованных идей</Link>
          </div>
        </div>
      </>
    );
  };

  return (
    <section className="wr-page">
      <MetaDecorator
        title="Платформа Правительства Москвы «Город идей»"
        description="Информация о выполненном проекте, реализованном на платформе «Город идей»."
      />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="wr-page__page">
          <div className="wr-page__back">
            <ArrowBack
              text="Назад к списку проектов"
              linkUrl={
                query.groupDirectionId ? `/archive?id=${query.id}` : "/archive"
              }
            />
          </div>

          <div className="wr-page__wrapper">
            <div className="wr-page__heading">
              <div className="wr-page__prblock">
                <div className="wr-page__heading-name">
                  {groupDirection?.title || project?.title}
                </div>
                {isPhone && renderProjectLogo()}
                <div
                  className="wr-page__heading-desc"
                  dangerouslySetInnerHTML={{
                    __html: groupDirection?.description || realized?.title,
                  }}
                />
              </div>

              {!isPhone && renderProjectLogo()}
            </div>
          </div>

          {showStab ? renderStab() : renderIdeas()}
        </div>
      )}

      {Boolean(selectedList?.length) && (
        <CommonViewer
          openAt={selectedList.indexOf(selectedIdea)}
          className="wr-page__viewer"
          onChange={setCurrentSlideIdeaIndex}
          onSlidePrev={() => {
            if (selectedListType === "realized") {
              realizedSwiper?.slidePrev();
            } else if (selectedListType === "accepted") {
              // acceptedSwiper?.slidePrev();
            }
          }}
          onSlideNext={() => {
            if (selectedListType === "realized") {
              realizedSwiper?.slideNext();
            } else if (selectedListType === "accepted") {
              // acceptedSwiper?.slideNext();
            }
          }}
          canSlidePrev
          canSlideNext
          arrowOptions={
            isPhone && {
              left: {
                icon: "ic promo-icon-1100",
                title: "Предыдущая идея",
              },
              right: {
                icon: "ic promo-icon-1101",
                title: "Следующая идея",
              },
            }
          }
          onClose={() => {
            setSelectedList(null);
            setSelectedListType("");
          }}
        >
          {selectedList.map((idea) => (
            <WorkResultCard
              key={idea.id}
              idea={idea}
              project={project}
              groupDirection={groupDirection}
              type={selectedListType}
            />
          ))}
        </CommonViewer>
      )}
    </section>
  );
};

export default WorkResultPage;
