import { RoutesConfig } from "../../../App.helper";

export enum MediaPlatformSections {
  BROADCAST = "broadcast",
  PHOTO_REPORT = "photoReport",
  ABOUT_US = "aboutUs",
  EVENT = "event",
  NEWS = "news",
  THEMATIC_PROJECT = "thematicProject",
  CATEGORY = "category",
  ONLINE_STREAM = "onlineStream",
  EXPERT_TEAM = "expertTeam",
  SUGGEST_IDEA = "suggestIdea",
  IMPLEMENTED_IDEA = "implementedIdea",
  DISCUSSION = "discussion",
}

const _routes = [
  {
    id: "d1",
    route: `?section=${MediaPlatformSections.THEMATIC_PROJECT}`,
    text: "Тематические проекты",
  },
  {
    id: "d2",
    route: `?section=${MediaPlatformSections.NEWS}`,
    text: "Медиаплатформа",
  },
  {
    id: "d3",
    route: `?section=${MediaPlatformSections.DISCUSSION}`,
    text: "Обсуждения",
  },
  {
    id: "d4",
    route: `?section=${MediaPlatformSections.SUGGEST_IDEA}`,
    text: "Предложить идею",
  },
  {
    id: "d5",
    route: `?section=${MediaPlatformSections.CATEGORY}`,
    text: "Рейтинг идей",
  },
];

const routes: RoutesConfig = {
  baseRoutes: _routes,
  mobileRoutes: _routes,
};

export { routes };
