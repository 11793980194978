import React from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import styles from "./TagsCard.module.scss";
import { TagsCardProps } from "./TagsCard.interface";
import EventsTrackWrapperClick from "../../../../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";
import Share from "../../../../../presentational/Share/Share";
import Text from "../../../../../presentational/Typography/Text";
import {
  phoneWidth,
  wideTabletWidth,
} from "../../../../../../utils/constants/widthConstants";
import { VideoPreview } from "./VideoPreview/VideoPreview";

const TagsCard = <T,>({
  onClick,
  onTagClick,
  element,
  imageId,
  title,
  tag,
  date,
  videoId,
  href,
  linkRel,
  share,
  className,
  isMain,
  showedVideo = false,
}: React.PropsWithChildren<TagsCardProps<T>>) => {
  const isWideTablet = useMediaQuery({
    query: `(max-width: ${wideTabletWidth}px)`,
  });

  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });

  const handleClick = (e) => {
    if (onClick) {
      e.stopPropagation();
      e.preventDefault();
      element && onClick(element);
    }
  };

  const handleTagClick = (e) => {
    if (onTagClick) {
      e.stopPropagation();
      e.preventDefault();
      element && onTagClick(element);
    }
  };

  const getTitleElipsis = () => {
    if (isMain) {
      return isWideTablet ? 1 : 2;
    }
    return isPhone ? 4 : 3;
  };

  const renderImage = (showPlayIcon: boolean = false) => (
    <div className={styles.image} onClick={handleClick} role="presentation">
      {imageId && <img src={`/uploads/get/${imageId}`} alt={title} />}
      {showPlayIcon && videoId && <div className={styles["play-icon"]} />}
    </div>
  );

  const renderVideo = () => (
    <VideoPreview videoPreviewId={videoId} containerClassName={styles.video} />
  );

  const renderCard = () => (
    <>
      {showedVideo && videoId ? renderVideo() : renderImage(!isMain)}
      <div className={styles["text-wrapper"]}>
        <div className={styles.details}>
          {!!tag && (
            <EventsTrackWrapperClick needParent id={184} replace={[tag]}>
              <div
                className={styles["tag-wrapper"]}
                onClick={handleTagClick}
                role="presentation"
              >
                <Text
                  className={`${styles.tag} ${styles.activity__tag}`}
                  size={4}
                  ellipsis
                  uppercase
                  wordBreak
                >
                  {tag}
                </Text>
              </div>
            </EventsTrackWrapperClick>
          )}
          <Text className={styles.date} size={isWideTablet ? 4 : 3}>
            {dayjs(date).format("DD.MM.YYYY")}
          </Text>
        </div>

        <div onClick={handleClick} role="presentation">
          <Text
            className={styles.title}
            size={isWideTablet ? 3 : 2}
            ellipsis={getTitleElipsis()}
          >
            {title}
          </Text>
        </div>
        {share && (
          <Share {...share} backgroundColorIcon="#999999" colorIcon="white" />
        )}
      </div>
    </>
  );

  return (
    <EventsTrackWrapperClick needParent id={211} replace={[title]}>
      <div
        className={classNames(styles["tags-card"], className, {
          [styles["main-card"]]: isMain,
        })}
      >
        {href ? (
          <Link
            rel={linkRel}
            className={styles["link-wrapper"]}
            to={typeof href === "function" ? href(element) : href}
          >
            {renderCard()}
          </Link>
        ) : (
          renderCard()
        )}
      </div>
    </EventsTrackWrapperClick>
  );
};

export default TagsCard;
