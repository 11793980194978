import { Stats } from "../../../../types/Stats.interface";

export interface TAboutStatistic {
  icon: string;
  count: number;
  declension: string[][];
  suffix?: string;
  link?: string;
  trackId?: number;
}

export const aboutStatistics = (totalStatistics: Stats): TAboutStatistic[] => [
  {
    count: totalStatistics.registrationCount,
    declension: [["Участник", "Участника", "Участников"]],
    icon: "promo-icon-user-multi",
  },
  {
    count: totalStatistics.categoryCount || 0,
    declension: [["Тема", "Темы", "Тем"]],
    suffix: "для подачи идей",
    link: "/propose",
    icon: "promo-icon-category-multi",
  },
  {
    count: totalStatistics.archivedProjectCount,
    declension: [
      ["Выполненный", "Выполненных", "Выполненных"],
      ["проект", "проекта", "проектов"],
    ],
    link: "/archive",
    trackId: 171,
    icon: "promo-icon-complete-multi",
  },
  {
    count:
      totalStatistics.realizedIdeaCount ||
      totalStatistics.realizedIdeaCountSetting,
    declension: [
      ["Идея", "Идеи", "Идей"],
      ["реализована", "реализованы", "реализовано"],
    ],
    link: "/ideas",
    icon: "promo-icon-idea-multi",
  },
];
