import React, { Children, cloneElement, useEffect, useState } from "react";

import MetaDecorator from "../MetaDecorator/MetaDecorator";
import PageTitle from "../PageTitle/PageTitle";
import Search from "../Controls/Search/Search";
import GridView from "../GridView/GridView";

import Platform from "../../../services/platform";
import { findUrlParam } from "../../../utils";

import "./CardGridPageGenerate.scss";
import { getEndpoint } from "../../../utils/helperFunctions";
import { withRouter } from "react-router";
import { CardGridPageGenerateProps } from "./CardGridPageGenerate.interface";
import PageDescription from "../PageDescription/PageDescription";

const CardGridPageGenerate = ({
  location,
  history,
  section,
  tags,
  screenWidth,
  endpoint,
  title,
  description,
  opengraph,
  pageTitle,
  pageDescription,
  children,
  onInit,
}: CardGridPageGenerateProps) => {
  const getTagsFromUri = () => {
    const decoded = decodeURI(history.location.search);
    if (decoded) {
      const params = findUrlParam("tags", decoded);
      return (params && params.split(",").filter(Boolean)) || [];
    }
    return [];
  };

  const [filteredTags, setFilteredTags] = useState(getTagsFromUri());
  const [selectedTags, setSelectedTags] = useState(getTagsFromUri());
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (location.state && location.state.item) {
      addTag(location.state.item.tag);
    }
  }, [location.state]);

  const addTag = (tag) => {
    if (selectedTags.includes(tag)) return;

    setSelectedTags([...selectedTags, tag]);
  };

  const deleteTag = (tag) => {
    if (location.state && location.state.item) {
      history.replace({ ...location.state, item: null });
    }
    setSelectedTags([...selectedTags.filter((t) => t !== tag)]);
  };

  const getSearchItems = () =>
    (searchValue && filteredTags.sort((a, b) => a.localeCompare(b))) ||
    tags.sort((a, b) => a.localeCompare(b));

  const onSearch = (value: string) => {
    setSearchValue(value);
    value &&
      setFilteredTags(
        tags.filter((tag) => tag.toLowerCase().includes(value.toLowerCase()))
      );
  };

  const onTagSelect = (tag) =>
    !selectedTags.includes(tag) ? addTag(tag) : deleteTag(tag);

  const onCardTagClick = (element) => {
    if (!selectedTags.includes(element.tag)) addTag(element.tag);
  };

  // RENDER

  const renderHeader = () => (
    <div className="card-grid-page__header">
      <PageTitle text={pageTitle} />

      <Search
        value={searchValue}
        restrictListBy={7}
        placeholder="Поиск"
        items={getSearchItems()}
        onChange={onSearch}
        selectedItems={selectedTags}
        isWithList
        isRounded
        isMultiplePerRow
        isClearPlaceholderOnSelect
        isCloseOnSelect
        onItemSelect={(tag) => onTagSelect(tag)}
      />
    </div>
  );

  const renderTags = () => {
    if (!selectedTags.length) return;

    return (
      <div className="card-grid-page__tags">
        <div className="card-grid-page__tags-list">
          {selectedTags.map((tag, idx) => (
            <div className="card-grid-page__tags-list-tag" key={idx}>
              <span>{tag}</span>
              <div
                className="ic promo-icon-14"
                onClick={(e) => deleteTag(tag)}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  const cloneChildren = (child) =>
    cloneElement(child, {
      onTagClick: onCardTagClick,
    });

  const getChildrenClone = () =>
    Array.isArray(children)
      ? Children.map(children, (child) => cloneChildren(child))
      : cloneChildren(children);

  const renderList = () => {
    const isTabletLayout =
      Platform.isTablet() || (screenWidth > 500 && screenWidth <= 1024);
    const isPhoneLayout = Platform.isPhone() && !isTabletLayout;

    const width = isTabletLayout ? 224 : isPhoneLayout ? 280 : 320;
    const gridColumns = `repeat(auto-fill, minmax(${width}px, 1fr))`;

    return (
      <div className="card-grid-page__list">
        <GridView
          columns={gridColumns}
          gap="24px"
          restrictBy={18}
          endpoint={endpoint ? getEndpoint(endpoint, selectedTags) : undefined}
          withUpdateLocation
          arrowsPosition="bottom"
          scrollToTopOnSlide
          bindTo="element"
          onInit={(instance) => onInit && onInit(instance)}
          onLoadEnd={({ page }) => setPage(page)}
        >
          {getChildrenClone()}
        </GridView>
      </div>
    );
  };

  return (
    <section className={`card-grid-page card-grid-page_${section}`}>
      <MetaDecorator
        title={`${title}. Страница ${page + 1}`}
        description={description}
        opengraph={opengraph}
      />
      {renderHeader()}
      <PageDescription text={pageDescription} />
      {renderTags()}
      {renderList()}
    </section>
  );
};

export default withRouter(CardGridPageGenerate);
