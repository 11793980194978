import { News } from "../../../../../types/News.interface";
import styles from "./NewsCard.module.scss";
import TagsCard from "./TagsCard/TagsCard";
import { TagsCardParentProps } from "./TagsCard/TagsCard.interface";

const NewsCard = ({
  element,
  id,
  onClick,
  onTagClick,
  href,
  linkRel,
  isMain,
  showedVideo,
}: TagsCardParentProps<News>) => (
  <TagsCard<News>
    id={id}
    section="news"
    date={element?.published}
    element={element}
    imageId={element?.imageId}
    videoId={element?.videoPreviewId}
    showedVideo={showedVideo}
    onClick={onClick}
    onTagClick={onTagClick}
    tag={element?.tag}
    title={element?.title}
    href={href}
    linkRel={linkRel}
    className={styles["news-tags-card"]}
    isMain={isMain}
  />
);

export default NewsCard;
