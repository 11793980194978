import { PaginationResponse } from "../types/Common.interface";
import { Report } from "../types/Report.interface";
import { queryBuilder } from "../utils/url";
import { API } from "./api";

interface GetReportsQuery {
  sort: string;
}

const ReportsService = {
  getAllReports: (query?: GetReportsQuery) =>
    API.get<PaginationResponse<Report[]>>(
      `/api/platform/report/getAll${query ? queryBuilder(query) : ""}`
    ).then(({ data }) => data),
};

export default ReportsService;
