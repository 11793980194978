import React, { useEffect, useState } from "react";
import SwiperCore from "swiper";
import classNames from "classnames";
import styles from "./SliderArrows.module.scss";
import EventsTrackWrapperClick from "../../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";
import Pagination, {
  PaginationProps,
} from "../../Controls/Pagination/Pagination";

export interface ArrowOptionsItem {
  icon?: string;
  title?: string;
  className?: string;
}

export interface ArrowOptions {
  left: ArrowOptionsItem;
  right: ArrowOptionsItem;
}

export interface SliderArrowsProps {
  swiper: SwiperCore;
  disabled?: boolean;
  arrowOptions?: ArrowOptions;
  pagination?: boolean | "dots";
  className?: string;
}

const SliderArrows = ({
  swiper,
  disabled,
  arrowOptions,
  pagination,
  className,
}: SliderArrowsProps) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [slidesLength, setSlidesLength] = useState(0);
  const [slidesOnPageCount, setSlidesOnPageCount] = useState(0);

  const getClassList = (direction) => {
    if (!swiper) return;

    return classNames({
      [styles["slider-arrows--disabled"]]:
        (!swiper.params.loop && direction === "left" && swiper.isBeginning) ||
        disabled ||
        (!swiper.params.loop && direction === "right" && swiper.isEnd),
    });
  };

  useEffect(() => {
    const handler = () => {
      setCurrentPage(Math.ceil(swiper.realIndex / slidesOnPageCount));
    };
    swiper?.on("slideChange", handler);
    return () => swiper?.off("slideChange", handler);
  }, [slidesOnPageCount, swiper]);

  useEffect(() => {
    if (swiper) {
      const handler = () => {
        setSlidesLength(swiper.slides.length - (swiper.loopedSlides || 0) * 2);
        setSlidesOnPageCount(
          (typeof swiper.params.slidesPerView === "number"
            ? swiper.params.slidesPerView
            : 1) * (swiper.params.slidesPerColumn || 1)
        );
      };
      handler();
      swiper.on("update", handler);
      return () => swiper.off("update", handler);
    }
  }, [swiper]);

  const onPageChange = (page: number) =>
    swiper[swiper.params.loop ? "slideToLoop" : "slideTo"](
      page * slidesOnPageCount
    );

  const renderLeftArrow = () => (
    <EventsTrackWrapperClick needParent id={184} replace={["Слайдер влево"]}>
      <div
        className={getClassList("left")}
        onClick={() => {
          if (swiper.params.loop && swiper.params.slidesPerGroup > 1) {
            const newIndex = swiper.realIndex - swiper.params.slidesPerGroup;
            const newRealIndex =
              newIndex < 0
                ? Math.abs(newIndex) < swiper.params.slidesPerGroup
                  ? 0
                  : slidesLength + newIndex
                : newIndex;
            const r = newRealIndex % swiper.params.slidesPerGroup;
            if (r) {
              if (swiper.realIndex === 0) {
                const { slidesGrid } = swiper as any;
                const t = slidesGrid[slidesGrid.length - swiper.loopedSlides];
                typeof t !== "undefined" && swiper.setTranslate(-t);
              }
              setTimeout(
                () =>
                  swiper.slideToLoop(
                    newRealIndex + (swiper.params.slidesPerGroup - r)
                  ),
                0
              );
            } else {
              swiper.slidePrev();
            }
          } else {
            swiper.slidePrev();
          }
        }}
      >
        <span
          className={classNames(
            arrowOptions?.left.icon || `${styles.ic} promo-icon-105`,
            arrowOptions?.left.className
          )}
        />
      </div>
    </EventsTrackWrapperClick>
  );

  const renderRightArrow = () => (
    <EventsTrackWrapperClick needParent id={184} replace={["Слайдер вправо"]}>
      <div
        className={getClassList("right")}
        onClick={(e) => {
          swiper.slideNext();
        }}
      >
        <span
          className={classNames(
            arrowOptions?.right.icon || `${styles.ic} promo-icon-88`,
            arrowOptions?.right.className
          )}
        />
      </div>
    </EventsTrackWrapperClick>
  );

  const renderPageNum: PaginationProps["renderPageNum"] = (base, page) => (
    <EventsTrackWrapperClick
      needParent
      id={184}
      replace={[`Номер страницы [${page + 1}]`]}
    >
      {pagination === "dots" ? (
        <div
          className={classNames(styles["page-dot"], {
            [styles["page-dot_active"]]: page === currentPage,
          })}
          onClick={() => onPageChange(page)}
        />
      ) : (
        base
      )}
    </EventsTrackWrapperClick>
  );

  const totalPages = Math.ceil(slidesLength / slidesOnPageCount);
  return (
    <>
      {swiper && (
        <div className={classNames(styles["slider-arrows"], className)}>
          {pagination ? (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={onPageChange}
              // если пагинация точками, то показ всех точек
              edgeCountPages={pagination === "dots" ? totalPages : undefined}
              renderLeftArrow={renderLeftArrow}
              renderRightArrow={renderRightArrow}
              renderPageNum={renderPageNum}
            />
          ) : (
            <>
              {renderLeftArrow()}
              {renderRightArrow()}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default SliderArrows;
