import { birthday10LandingUrl } from "../components/pages/CrowdBirthday10/CrowdBirthday10.constants";
import { newYear2025Url } from "../components/pages/NewYear2025/constants";

export const hiddenURLs = [
  "/teamLogin",
  "/teamLogin/",
  "/restore",
  "/restore/",
  "/moderation",
  "/moderation/",
  "/ideaModeration",
  "/ideaModeration/",
  "/license",
  "/license/",
  "/pointsRules",
  "/pointsRules/",
  "/changePassword",
  "/changePassword/",
  "/restorePassword",
  "/newYearRules",
  "/restorePassword/",
  "/newpassword",
  "/newpassword/",
  "/birthday",
  "/birthday/propose",
  birthday10LandingUrl,
  newYear2025Url,
  new RegExp(`${newYear2025Url}/.*`),
  "/city",
].map((url) => (typeof url === "string" ? url.toLowerCase() : url));

export const emailVerifiedGuardWhitelist = [
  "/",
  "/successEmailConfirmation",
  "/failedEmailConfirmation",
];

export const notExistingURLs = ["/newYear"];
