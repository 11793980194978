export const isActiveLink = (
  pathname: string,
  search: string,
  route: string
) => {
  const cleanSearch = search.replace(/[?&]/g, "");
  const cleanRoute = route.replace(/[?&]/g, "");
  return (
    pathname !== "/" &&
    (route?.includes(pathname) || (search && cleanSearch.includes(cleanRoute)))
  );
};
