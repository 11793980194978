import css from "./Footer.module.scss";
import EventsTrackParentIdContext from "../EventsTrack/EventsTrackParentIdContext";
import EventsTrackWrapperScroll from "../EventsTrack/wrappers/EventsTrackWrapperScroll";
import { FooterInfo } from "./FooterInfo/FooterInfo";
import footerLogo from "../../../assets/footer-logo.svg";
import { FooterRoutes } from "./FooterRoutes/FooterRoutes";
import { FooterSocial } from "./FooterSocial/FooterSocial";

const Footer = () => (
  <EventsTrackParentIdContext.Provider value="FOOTER">
    <EventsTrackWrapperScroll id={8}>
      <footer className={css.footer}>
        <div className={css.container}>
          <div className={css.top}>
            <img src={footerLogo} alt="footer-logo" className={css.logo} />
            <FooterRoutes />
          </div>

          <div className={css.bottom}>
            <FooterInfo />
            <FooterSocial />
          </div>
        </div>
      </footer>
    </EventsTrackWrapperScroll>
  </EventsTrackParentIdContext.Provider>
);

export default Footer;
