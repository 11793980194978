import {
  MediaPlatformDto,
  MediaPlatformItem,
  MediaPlatformStatus,
} from "../types/MediaPlatform.interface";

export const PROJECT_CULTURE_ID = "mmr";

export const MP_MOSCOWSPORT_CODE = "moscowsport";

export const cultureTags = [
  "Музеи",
  "Библиотеки",
  "Мероприятия",
  "Культурные центры",
  "Парки",
  "Культурные мероприятия",
  "Культура онлайн",
  "Городские мастерские",
];
export const moscowSportTags = ["Мой спортивный район", "Город спорта"];

export const gorodIdeaTitlesMoscowSport = [
  "Спортивные площадки в Гольяновском парке",
  "Спортивные тренажеры на Перекопской улице",
  "Горка для тюбинга в парке «Чермянка»",
  "Спортивная площадка в районе Ярославский",
  "Спортивные тренажеры в районе Гольяново",
  "Каток в Северном Бутово",
  "Спортивная площадка в Пресненском районе",
  "Спортивная площадка на Шипиловской улице",
  "Спортивная площадка в Мещанском районе",
  "Спортивная площадка в районе Крылатское",
  "Городской каток в районе Бирюлево Восточное",
  "Детская и спортивная площадки в районе Войковский",
  "Спортивная площадка в Лосиноостровском районе",
  "Спортивная площадка в районе Бибирево",
  "Спортивная площадка в Таганском районе",
  "Спортивная площадка в Бабушкинском районе",
  "Спортивная площадка в Некрасовке",
  "Стол для настольного тенниса в районе Зюзино",
  "Уличные тренажеры в районе Некрасовка",
  "Спортивные тренажеры в районе Аэропорт",
  "Футбольная площадка в районе Зябликово",
  "Детская и спортивная площадки в районе Кунцево",
  "Спортивная площадка в Бибирево",
  "Спортивная площадка в районе Беляево",
  "Теннисный корт в парке «Битцевский лес»",
  "Теннисный корт в парке «Измайлово»",
  "Теннисные столы в районе Коптево",
  "Развлекательная площадка в Даниловском районе",
  "Спортивная площадка «Бекет»",
  "Скейт-площадка в парке 70-летия Победы",
  "Лыжный центр в Салтыковском лесу",
  "Столы для настольного тенниса в парке",
  "Каток на Чистых прудах",
  "Лыжная трасса в пос. Сосенское",
  "Спортивные тренировки в парке Южное Бутово",
  "Велопарковки в Тверском районе",
  "Велопарковки около кинотеатра «Иллюзион»",
  "Игровая зона в Ломоносовском районе",
  "Волейбольная площадка в районе Чертаново Центральное",
  "Велополоса на Бульварном кольце",
  "Выделенная велополоса в районе Марьиной рощи",
  "Скандинавская ходьба в парке",
  "Занятие по беговым лыжам на территории парка",
  "Танцевальный мастер-класс в парке «Сокольники»",
  "Занятия по танцам в Воронцовском парке",
  "Фитнес-день в парке",
  "Танцы в Бабушкинском парке",
  "Современные танцы для старшего поколения",
  "Велосипедная экскурсия по парку «Кузьминки»",
];

export const defaultMediaPlatforms: Array<MediaPlatformDto> = [
  {
    id: "1",
    code: "culture",
    projectCode: PROJECT_CULTURE_ID,
    published: true,
    withProject: true,
    withBroadcast: false,
    broadcastPriority: 3,
    withPhotoReport: true,
    photoReportPriority: 7,
    withAboutUs: true,
    aboutUsPriority: 6,
    withEvent: true,
    eventPriority: 4,
    withNews: true,
    newsPriority: 2,
    withThematicProject: false,
    thematicProjectPriority: 0,
    withCategory: true,
    categoryPriority: 10,
    withOnlineStream: true,
    onlineStreamPriority: 5,
    withExpertTeam: false,
    expertTeamPriority: 1,
    withSuggestIdea: false,
    suggestIdeaPriority: 9,
    withImplementedIdea: true,
    implementedIdeaPriority: 11,
    withDiscussion: false,
    discussionPriority: 8,
    status: MediaPlatformStatus.FINISHED,
  },
];

export const prepareMediaPlatform = (
  item: MediaPlatformDto
): MediaPlatformItem => ({
  ...item,
  url: `/${item.code}`,
});
