import { useEffect, useRef, useState } from "react";
import {
  BigPlayButton,
  ControlBar,
  Player,
  PlayerReference,
} from "video-react";
import styles from "./NewsDetailsVideo.module.scss";

interface NewsDetailsVideoProps {
  videoId: string;
  posterId: string;
}

export const NewsDetailsVideo = ({
  videoId,
  posterId,
}: NewsDetailsVideoProps) => {
  const [isVideoStarted, setIsVideoStarted] = useState(false);

  const playerRef = useRef<PlayerReference | null>(null);

  useEffect(() => {
    if (playerRef.current && isVideoStarted) {
      playerRef.current.play();
    }
  }, [isVideoStarted]);

  return (
    <div className={styles["player-container"]}>
      <Player
        ref={playerRef}
        key={videoId}
        playsInline
        preload="metadata"
        poster={`/uploads/get/${posterId}`}
      >
        <source src={`/uploads/get/${videoId}`} />
        <ControlBar />
        <BigPlayButton className={styles["play-button"]} />
      </Player>
      {!isVideoStarted && (
        <div className={styles["play-container"]}>
          <button
            type="button"
            className={styles["play-icon"]}
            onClick={() => setIsVideoStarted(!isVideoStarted)}
          />
        </div>
      )}
    </div>
  );
};
