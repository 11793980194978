import { API, httpParamsSerialize } from "./api";
import { SittingKey, SittingsData } from "../types/Sittings.interface";
import { BaseResponse } from "../types/Common.interface";

const SittingsService = {
  getSittingByKey: (key: SittingKey) =>
    API.get<BaseResponse<SittingsData>>(
      `/api/settings/get?${httpParamsSerialize({ key })}`
    ).then(({ data }) => data),

  saveSittings(data: SittingsData) {
    const _data = data.updated ? data : { ...data, updated: new Date() };
    return API.post("/api/settings/save", _data).then(({ data }) => data);
  },
};

export default SittingsService;
