import { hiddenURLs } from "./constants";

export function queryBuilder(query) {
  let queryBuilder = "?";

  Object.keys(query).forEach((key) => {
    queryBuilder += `${key}=${typeof query[key] === "string" ? query[key] : JSON.stringify(query[key])}&`;
  });

  return queryBuilder;
}

export const linkify = (text: string, isBlank = false) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(
    urlRegex,
    `<a href="$1" ${isBlank ? "target='_blank'" : ""}>$1</a>`
  );
};

export const matchHiddenUrls = (url: string) =>
  hiddenURLs.some((hiddenUrl) => {
    if (typeof hiddenUrl === "string") {
      return url.toLowerCase() === hiddenUrl.toLowerCase();
    }
    return hiddenUrl.test(url);
  });
