import React, { ReactElement } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import Button from "../../Controls/Button/Button";
import "./InfoCard.scss";

type Props = RouteComponentProps & {
  title: string;
  text: string;
  subText: string;
  buttonText?: string;
  onClick?: () => void;
  onTitleClick?: () => void;
  renderButton?: () => ReactElement;
};

class InfoCard extends React.Component<Props, any> {
  onClick = () => {
    if (this.props.onClick && typeof this.props.onClick === "function") {
      this.props.onClick();
    } else {
      console.error("Please provide a callback for InfoCard component");
    }
  };

  handleLinkClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target.href) {
      this.props.history.push(e.target.href.split(window.location.host)[1]);
    }
  };

  renderButton() {
    return this.props.renderButton ? (
      this.props.renderButton()
    ) : (
      <Button
        type="filled"
        text={this.props.buttonText}
        onClick={this.onClick}
      />
    );
  }

  render() {
    return (
      <div className="infocard__wrapper">
        <div className="infocard">
          {this.props.title && (
            <div
              className={`infocard__title ${this.props.onTitleClick ? "infocard__title_clickable" : ""}`}
              onClick={this.props.onTitleClick}
            >
              <span className="ic promo-icon-1105" />
              {this.props.title}
            </div>
          )}
          <div
            onClick={this.handleLinkClick}
            className="infocard__text"
            dangerouslySetInnerHTML={{ __html: this.props.text }}
          />
          <div
            className="infocard__subtext"
            dangerouslySetInnerHTML={{ __html: this.props.subText }}
          />

          {this.renderButton()}
        </div>
        {this.props.children}
      </div>
    );
  }
}

export default withRouter(InfoCard);
