import { FC, useEffect, useState } from "react";
import "./Rating.scss";

// Import Swiper core, modules, and components
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

import RatingCard from "./RatingCard/RatingCard";
import MediaPlatformService from "../../../../../services/mediaPlatform.service";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import { displayError } from "../../../../../utils";
import DefaultPagination from "../../../../presentational/Controls/Pagination/Pagination";
import useDiscussionLandingContext from "../../hooks/useDiscussionLandingContext";
import { MediaPlatformSections } from "../../routing/routes";

export interface RatingProps {
  onLoadEnd?: (data) => void;
  description: string;
}

// переписать с исп lazyLoading, если много направлений
const Rating: FC<RatingProps> = ({ onLoadEnd, description }) => {
  const discussionLandingContext = useDiscussionLandingContext();
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  SwiperCore.use([Autoplay, Navigation, Pagination]);

  const categoryColors = [
    "blue",
    "purple",
    "orange",
    "green",
    "pink",
    "light-blue",
    "red",
  ];

  useEffect(() => {
    fetchData();
  }, [discussionLandingContext.id]);

  const fetchData = async () => {
    setLoading(true);

    let onLoadEndData = [];

    try {
      const response = await MediaPlatformService.getIdeasRating({
        mediaPlatformId: discussionLandingContext.id,
      });
      if (response.status === ApiStatusCode.OK) {
        setCategories(response?.data);
        onLoadEndData = response.data;
      } else {
        displayError("Ошибка при получении рейтинга идей", response.message);
      }
    } catch (error) {
      displayError("Rating", "Ошибка при получении рейтинга идей");
    } finally {
      onLoadEnd(onLoadEndData);
      setLoading(false);
    }
  };

  const handleSlideChange = (swiper) => {
    const { activeIndex } = swiper;
    setCurrentPage(activeIndex);
  };

  if (!categories?.length) return <></>;

  return (
    categories &&
    !isLoading &&
    categories.length > 0 && (
      <section
        className="discussion-landing-rating"
        id={MediaPlatformSections.CATEGORY}
      >
        <div className="discussion-landing-title">Рейтинг идей</div>
        {!!description && (
          <div
            className="discussion-landing-text"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}

        <Swiper
          onSwiper={setSwiperInstance}
          className="rating__slider"
          onInit={handleSlideChange}
          onSlideChangeTransitionEnd={handleSlideChange}
          slidesPerView={1}
          effect="fade"
          navigation={{
            nextEl: ".cr-pagination__arrow-right",
            prevEl: ".cr-pagination__arrow-left",
          }}
          spaceBetween={40}
          loop={false}
        >
          {categories?.map((category, index) => {
            const { id, name, link, imageId, created, published } = category;
            const colorIndex = index % categoryColors.length;
            const colorClass = categoryColors[colorIndex];
            return (
              <SwiperSlide key={id}>
                <RatingCard
                  className={colorClass}
                  id={id}
                  name={name}
                  link={link}
                  imageId={imageId}
                  created={created}
                  published={published}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <DefaultPagination
          loop={false}
          onPageChange={(page) => {
            swiperInstance.slideTo(page);
          }}
          pagesRange={2}
          totalPages={categories.length}
          currentPage={currentPage}
        />
      </section>
    )
  );
};

export default Rating;
