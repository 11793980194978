import React from "react";
import classNames from "classnames";
import styles from "./Link.module.scss";

interface LinkProps {
  href: string;
  children: React.ReactNode;
  className?: string;
}

export const Link = ({ href, children, className }: LinkProps) => (
  <a
    className={classNames(styles.link, className)}
    href={href}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </a>
);
