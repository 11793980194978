import { FC, forwardRef, useEffect, useMemo, useRef, useState } from "react";
import "./Welcome.scss";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";
import { declOfNum } from "../../../../../utils";
import { RootState } from "../../../../../types/State.interface";
import { LandingInfo } from "../../DiscussionLanding.interface";
import MediaPlatformService from "../../../../../services/mediaPlatform.service";
import {
  phoneWidth,
  wideTabletWidth,
} from "../../../../../utils/constants/widthConstants";
import Tooltip from "../../../../presentational/Controls/Tooltip/Tooltip";
import useDiscussionLandingContext from "../../hooks/useDiscussionLandingContext";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import { PROJECT_CULTURE_ID } from "../../../../../utils/mediaPlatform";
import { useIsMobile, useIsTablet } from "../../../../../hooks/useMedia";

interface ExpertData {
  avatarUrl: string;
  text: string;
  fullName: string;
  position1: string;
  position2: string;
}

const Expert = forwardRef<HTMLDivElement>((_, ref) => {
  const discussionLandingContext = useDiscussionLandingContext();
  const [data, setData] = useState<ExpertData>();
  const isPhone = useIsMobile();
  const isTablet = useIsTablet();

  useEffect(() => {
    if (discussionLandingContext.projectCode === PROJECT_CULTURE_ID) {
      setData({
        avatarUrl:
          require("../../../../../assets/discussion-landing/expert-avatar.png")
            .default,
        text: `Мы хотим развивать культуру Москвы, делая ее доступной, разнообразной и интересной для жителей и гостей
          столицы. Для этого создан новый формат взаимодействия с участниками «Города идей» — медиаплатформа «Город
          обсуждает стандарты культурного досуга». Она позволит горожанам активно участвовать в повышении качества
          оказываемых услуг и формировании культурной повестки столицы. Пользователи станут участниками масштабного
          проекта, первыми узнают об оценке экспертов отрасли и смогут внести свои предложения. Услуги, сервисы и
          мероприятия в области культуры получат новую жизнь именно благодаря мнениям москвичей.`,
        fullName: "А.А. Фурсин",
        position1: "Министр Правительства Москвы,",
        position2: "руководитель Департамента культуры города Москвы",
      });
    } else {
      MediaPlatformService.getExpertQuote({
        mediaPlatformId: discussionLandingContext.id,
      })
        .then(({ data, status, message }) => {
          if (status === ApiStatusCode.OK) {
            setData({
              avatarUrl: `/uploads/get/${isPhone ? data.mobileImageId : isTablet ? data.tabletImageId : data.desktopImageId}`,
              text: data.text,
              fullName: data.expertFullName,
              position1: data.expertPosition1,
              position2: data.expertPosition2,
            });
          } else {
            console.error(message);
          }
        })
        .catch(console.error);
    }
  }, [discussionLandingContext]);

  return (
    <div className="discussion-landing-welcome__expert" ref={ref}>
      {data && (
        <>
          <div className="discussion-landing-welcome__expert__avatar">
            <img src={data.avatarUrl} alt="" />
          </div>
          <div className="discussion-landing-welcome__expert__quote">
            <div
              className="discussion-landing-welcome__expert__quote__text"
              dangerouslySetInnerHTML={{ __html: data.text }}
            />
            <div className="discussion-landing-sign">
              <div className="discussion-landing-sign__name">
                {data.fullName}
              </div>
              <div className="discussion-landing-sign__position">
                {data.position1}
                <br />
                {data.position2}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
});

const Welcome: FC = () => {
  const discussionLandingContext = useDiscussionLandingContext();
  const expertRef = useRef<HTMLDivElement>();
  const currentProjects = useSelector(
    (state: RootState) => state.projects.current
  );
  const completedProjects = useSelector(
    (state: RootState) => state.projects.completed
  );
  const [count, setCount] = useState<number>();
  const [participantCount, setParticipantCount] = useState<number>();
  const [landingInfo, setLandingInfo] = useState<Partial<LandingInfo>>();
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const isTablet = useMediaQuery({
    query: `(max-width: ${wideTabletWidth}px)`,
  });
  const bgId = isPhone
    ? landingInfo?.mobileImageId
    : isTablet
      ? landingInfo?.tabletImageId
      : landingInfo?.desktopImageId;

  const shouldShowCounters = useMemo(
    () => !!(Number(count) && Number(participantCount)),
    [count, participantCount]
  );

  useEffect(() => {
    Promise.all([
      MediaPlatformService.getInfo({
        mediaPlatformId: discussionLandingContext.id,
      }),
      MediaPlatformService.getCounter(discussionLandingContext.id),
    ])
      .then(([{ data }, { data: pCount }]) => {
        setLandingInfo(data || {});
        setParticipantCount(pCount);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [discussionLandingContext]);

  useEffect(() => {
    if (!discussionLandingContext.id) return;

    if (window.location.pathname === "/culture") {
      setCount(3807);
      return;
    }

    MediaPlatformService.getMediaPlatformIdeasCount({
      mediaPlatformId: discussionLandingContext.id,
    })
      .then(({ data }) => {
        setCount(data || 0);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [discussionLandingContext.projectCode, discussionLandingContext.id]);

  const renderCounter = (count = 0, words, isVisible: boolean) => (
    <div
      className={classNames("discussion-landing-welcome__banner__counter", {
        "discussion-landing-welcome__banner__counter--visible": isVisible,
      })}
    >
      <div className="discussion-landing-welcome__banner__counter__num">
        {String(count)
          .split("")
          .map((num, i) => (
            <span key={i}>{num}</span>
          ))}
      </div>
      <div className="discussion-landing-welcome__banner__counter__txt">
        {declOfNum(count, words)}
      </div>
    </div>
  );

  const renderParticipantTooltip = (title: string) => (
    <Tooltip
      text="Уникальные посетители медиаплатформы «Город обсуждает стандарты культурного досуга»"
      idx="discussion-landing-welcome-participant-tip"
      place="bottom"
      className="discussion-landing-welcome__participant-tip"
      overridePosition={({ left, top }, _, __, refNode) => {
        let oLeft = left;
        if (!isTablet && expertRef.current) {
          oLeft =
            expertRef.current.offsetLeft +
            expertRef.current.offsetWidth -
            refNode.clientWidth;
        }
        return { left: Math.max(oLeft, 20), top };
      }}
    >
      {title}
    </Tooltip>
  );

  return (
    <section className="discussion-landing-welcome">
      <div
        className="discussion-landing-welcome__banner"
        style={{
          backgroundImage: bgId ? `url(/uploads/get/${bgId})` : undefined,
        }}
      >
        <div className="discussion-landing-welcome__banner__plate">
          {!!landingInfo && (
            <>
              <div className="discussion-landing-title">
                {landingInfo.name ||
                  "Город обсуждает стандарты культурного досуга"}
              </div>
              <div
                className="discussion-landing-welcome__banner__description"
                dangerouslySetInnerHTML={{
                  __html:
                    landingInfo.description ||
                    `Принимайте участие в создании нового стандарта культуры Москвы — предлагайте идеи, узнавайте мнение профессионалов отрасли, первыми читайте новости об акциях и проектах.`,
                }}
              />
              <div className="discussion-landing-welcome__banner__bottom">
                {renderCounter(
                  count,
                  ["идея подана", "идеи поданы", "идей подано"],
                  shouldShowCounters
                )}
                {renderCounter(
                  participantCount,
                  [
                    renderParticipantTooltip("посетитель*"),
                    renderParticipantTooltip("посетителя*"),
                    renderParticipantTooltip("посетителей*"),
                  ],
                  shouldShowCounters
                )}
              </div>
            </>
          )}
        </div>
        {!isPhone && <Expert ref={expertRef} />}
      </div>
      {isPhone && <Expert ref={expertRef} />}
    </section>
  );
};

export default Welcome;
