import React, { Children } from "react";
import ResizeObserver from "resize-observer-polyfill";

import classNames from "classnames";
import styles from "./TrioSlider.module.scss";

interface Props {
  onInit: (slider) => void;
  setWidthAsHeight: boolean;
  autoPlay: boolean;
  interval: number;
  onChange: Function;
  children: any[];
}

class TrioSlider extends React.Component<Props, any> {
  hostRef: any = null;

  itemsRef: any = [];

  timer: any = null;

  canPlay: boolean = true;

  resizeObserver: ResizeObserver | any;

  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
    };

    this.autoPlay();
  }

  componentDidMount() {
    if (this.props.onInit && typeof this.props.onInit === "function") {
      this.props.onInit(this);
    }

    this.onChange();

    if (this.props.setWidthAsHeight) {
      this.setResizeListener();
    }
  }

  componentWillUnmount() {
    this.destroy();
  }

  autoPlay = () => {
    if (!this.props.autoPlay || Children.count(this.props.children) <= 1) return;

    if (this.timer) clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.canPlay && this.moveRight();
    }, this.props.interval);
  };

  setHostRef = (element) => {
    this.hostRef = element;
  };

  setItemsRef = (node, index) => {
    if (!this.itemsRef) {
      this.itemsRef = [];
    }
    this.itemsRef[index] = node;
  };

  setResizeListener() {
    if (!this.hostRef) return;

    this.resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        entry.target.style.height = `${entry.contentRect.width}px`;
        this.autoPlay();
      });
    });
    this.resizeObserver.observe(this.hostRef);
  }

  getClassForItem = (index) =>
    classNames(styles.item, {
      "item-colorized": process.env.NODE_ENV === "development",
      [styles["item-center"]]: index === this.state.activeIndex,
      [styles["item-right"]]:
        index === this.getActualIndex(this.state.activeIndex + 1),
      [styles["item-left"]]:
        index === this.getActualIndex(this.state.activeIndex - 1),
    });

  getActualIndex(index) {
    const itemsLength = Children.count(this.props.children);

    return Math.sign(index) < 0
      ? itemsLength - Math.abs(index)
      : index % itemsLength;
  }

  moveRight = () => {
    this.setState(
      (state) => ({
        activeIndex: this.getActualIndex(state.activeIndex + 1),
      }),
      () => {
        this.onChange();
      }
    );

    this.autoPlay();
  };

  moveLeft = () => {
    this.setState(
      (state) => ({
        activeIndex: this.getActualIndex(state.activeIndex - 1),
      }),
      () => {
        this.onChange();
      }
    );

    this.autoPlay();
  };

  onChange = () => {
    if (this.props.onChange && typeof this.props.onChange === "function") {
      const child =
        this.props.children[this.getActualIndex(this.state.activeIndex)];
      if (child) this.props.onChange(child.props?.data);
    }
  };

  handleClickItem(index) {
    if (index === this.getActualIndex(this.state.activeIndex + 1)) {
      this.moveRight();
    }

    if (index === this.getActualIndex(this.state.activeIndex - 1)) {
      this.moveLeft();
    }
  }

  onMouseEnter = () => {
    if (this.props.autoPlay) {
      this.canPlay = false;
    }
  };

  onMouseLeave = () => {
    if (this.props.autoPlay) {
      this.canPlay = true;
      this.autoPlay();
    }
  };

  renderItems() {
    return Children.map(this.props.children, (element, index) => {
      const slideProps = {
        key: index,
        ref: (element) => this.setItemsRef(element, index),
        className: this.getClassForItem(index),
        onClick: this.handleClickItem.bind(this, index),
      };

      return <div {...slideProps}>{element}</div>;
    });
  }

  renderArrows = () => (
    <div className={styles.arrows}>
      <span
        className={classNames(styles["arrows-btn"], styles["arrows-btn--left"])}
        onClick={(e) => this.moveLeft()}
      >
        <i className="promo-icon-105" />
      </span>

      <span
        className={classNames(
          styles["arrows-btn"],
          styles["arrows-btn--right"]
        )}
        onClick={(e) => this.moveRight()}
      >
        <i className="promo-icon-88" />
      </span>
    </div>
  );

  destroy() {
    if (this.resizeObserver && this.hostRef) {
      this.resizeObserver.unobserve(this.hostRef);
    }
  }

  canRender() {
    return this.props.children?.length;
  }

  render() {
    return (
      <>
        {this.canRender() && (
          <div
            className={styles.trio}
            ref={(host) => this.setHostRef(host)}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
          >
            {this.renderItems()}
            {this.renderArrows()}
          </div>
        )}
      </>
    );
  }
}

export default TrioSlider;
