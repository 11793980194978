import { Reducer } from "redux";
import { MediaPlatformState } from "../../types/MediaPlatform.interface";
import { MEDIAPLATFORM_SET_LIST } from "../actions/MediaPlatformActions";
import {
  defaultMediaPlatforms,
  prepareMediaPlatform,
} from "../../utils/mediaPlatform";

const initialState: MediaPlatformState = {
  list: null,
};

export const mediaPlatformReducer: Reducer<MediaPlatformState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case MEDIAPLATFORM_SET_LIST:
      return {
        ...state,
        list: [...defaultMediaPlatforms, ...action.payload].map(
          prepareMediaPlatform
        ),
      };

    default:
      return state;
  }
};
