const styles = {
  large: {
    radius: 35,
    borderWidth: 6,
    color: "#65AAE0",
  },
  medium: {
    radius: 25,
    borderWidth: 6,
    color: "#3C8DE2",
  },
  small: {
    radius: 20,
    borderWidth: 5,
    color: "#1473E6",
  },
  tiny: {
    radius: 5,
    borderWidth: 3,
    color: "#0E4C99",
  },
};

const getPinStyle = (size) => {
  if (size >= 100) return styles.large;
  if (size >= 10) return styles.medium;
  if (size >= 2) return styles.small;
  return styles.tiny;
};

export const getDefaultPinStyle = (feature) => {
  if (!feature) return null;

  const size = feature.get("features").length;
  const text = size.toString() > 1 ? size.toString() : null;

  return new window.ol.style.Style({
    image: new window.ol.style.Circle({
      radius: getPinStyle(size).radius,
      fill: new window.ol.style.Fill({
        color: getPinStyle(size).color,
      }),
      stroke: new window.ol.style.Stroke({
        color: "#FFFFFF",
        width: getPinStyle(size).borderWidth,
      }),
    }),
    ...(text && {
      text: new window.ol.style.Text({
        font: "normal normal 500 20px/48px Golos",
        textAlign: "center",
        text,
        fill: new window.ol.style.Fill({
          color: "#ffffff",
        }),
      }),
    }),
  });
};

export const getActivePinStyle = () =>
  new window.ol.style.Style({
    image: new window.ol.style.Icon({
      src: require("../../../assets/map/active-pin.svg").default,
      anchor: [0.5, 1],
    }),
    zIndex: 1000,
  });
