import { FC, useContext, useEffect, useMemo, useState } from "react";
import "./DiscussionLandingPage.scss";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import classNames from "classnames";
import AuthForm from "../../containers/Forms/AuthForm/AuthForm";
import Welcome from "./components/Welcome/Welcome";
import StreamsAndOnlineMeetings from "./components/StreamsAndOnlineMeetings/StreamsAndOnlineMeetings";
import Gallery from "./components/Gallery/Gallery";
import {
  HeaderRoutesType,
  setAppColor,
  setHeaderOptions,
} from "../../../store/actions/LayoutActions";
import {
  routes as DiscussionRoutes,
  MediaPlatformSections,
} from "./routing/routes";
import ThematicProjects from "./components/ThematicProjects/ThematicProjects";
import MediaPlatform from "./components/MediaPlatform/MediaPlatform";
import Reviews from "./components/Reviews/Reviews";
import PhotoReport from "./components/PhotoReport/PhotoReport";
import Discussions from "./components/Discussions/Discussions";
import Rating from "./components/Rating/Rating";
import { routes as baseAppRoutes } from "../../App.helper";
import Experts from "./components/Experts/Experts";
import {
  mmrTexts,
  ogBaseTitle,
  ogVideoTitle,
} from "./DiscussionLanding.constants";
import { RootState } from "../../../types/State.interface";
import { AuthActionType, ComponentType } from "../../../services/sudirService";
import { AppContext } from "../../Root";
import useConnectToProject from "./hooks/useConnectToProject";
import { AppColorsEnum } from "../../App.interface";
import OnlineTranslations, {
  translationIdQueryKey,
} from "./components/OnlineTranslations/OnlineTranslations";
import MetaDecorator from "../../presentational/MetaDecorator/MetaDecorator";
import ogImage from "../../../assets/discussion-landing/repost.svg";
import ScreenLoader from "./components/ScreenLoader/ScreenLoader";
import useDiscussionLandingContext from "./hooks/useDiscussionLandingContext";
import { selectIsUserReady } from "../../../store/selectors/profile";
import ProposeIdea from "./components/ProposeIdea/ProposeIdea";
import Activities from "./components/Activities/Activities";
import { values } from "../../../utils/object";
import { upFirst } from "../../../utils/string";
import { TextSettingsDto } from "../../../types/MediaPlatform.interface";
import MediaPlatformService from "../../../services/mediaPlatform.service";
import { PROJECT_CULTURE_ID } from "../../../utils/mediaPlatform";

const DiscussionLandingPage: FC = () => {
  const discussionLandingContext = useDiscussionLandingContext();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory<{ lastVisitedPage?: string }>();
  const appContext = useContext(AppContext);
  const connectToProject = useConnectToProject();

  const isUserReady = useSelector(selectIsUserReady);
  const currentProjects = useSelector(
    (state: RootState) => state.projects.current
  );
  const headerConfig = useSelector((state: RootState) => state.layout.routes);
  const [sectionsReadyStateMap, setSectionsReadyStateMap] = useState({});
  const [progress, setProgress] = useState(0);
  const [texts, setTexts] = useState<TextSettingsDto>();
  const sectionName = useMemo(
    () => new URLSearchParams(location.search)?.get("section"),
    [location.search]
  );

  const handleSectionDataLoadEnd = (data, key) => {
    setSectionsReadyStateMap((state) => ({
      ...state,
      [key]: Boolean(data.length),
    }));
  };

  const shownSections = useMemo(() => {
    const res = [];
    values(MediaPlatformSections).forEach((section) => {
      if (discussionLandingContext[`with${upFirst(section)}`]) {
        res.push(section);
      }
    });

    return res;
  }, [discussionLandingContext]);

  const renderedSections = useMemo(() => {
    const renderMap = {
      [MediaPlatformSections.ONLINE_STREAM]: () => (
        <OnlineTranslations
          onLoadEnd={(data) =>
            handleSectionDataLoadEnd(data, MediaPlatformSections.ONLINE_STREAM)}
        />
      ),
      [MediaPlatformSections.PHOTO_REPORT]: () => (
        <PhotoReport
          onLoadEnd={(data) =>
            handleSectionDataLoadEnd(data, MediaPlatformSections.PHOTO_REPORT)}
        />
      ),
      [MediaPlatformSections.ABOUT_US]: () => (
        <Reviews
          onLoadEnd={(data) =>
            handleSectionDataLoadEnd(data, MediaPlatformSections.ABOUT_US)}
        />
      ),
      [MediaPlatformSections.EVENT]: () => (
        <Activities
          onLoadEnd={(data) =>
            handleSectionDataLoadEnd(data, MediaPlatformSections.EVENT)}
        />
      ),
      [MediaPlatformSections.THEMATIC_PROJECT]: () => (
        <ThematicProjects
          description={
            discussionLandingContext.projectCode === PROJECT_CULTURE_ID
              ? mmrTexts.thematicProjectsText
              : texts?.thematicProjectsText || ""
          }
          onLoadEnd={(data) =>
            handleSectionDataLoadEnd(
              data,
              MediaPlatformSections.THEMATIC_PROJECT
            )}
        />
      ),
      [MediaPlatformSections.EXPERT_TEAM]: () => (
        <Experts
          onLoadEnd={(data) =>
            handleSectionDataLoadEnd(data, MediaPlatformSections.EXPERT_TEAM)}
        />
      ),
      [MediaPlatformSections.NEWS]: () => (
        <MediaPlatform
          description={
            discussionLandingContext.projectCode === PROJECT_CULTURE_ID
              ? mmrTexts.mediaPlatformText
              : texts?.mediaPlatformText || ""
          }
          onLoadEnd={(data) =>
            handleSectionDataLoadEnd(data, MediaPlatformSections.NEWS)}
        />
      ),
      [MediaPlatformSections.BROADCAST]: () => (
        <StreamsAndOnlineMeetings
          onLoadEnd={(data) =>
            handleSectionDataLoadEnd(data, MediaPlatformSections.BROADCAST)}
        />
      ),
      [MediaPlatformSections.DISCUSSION]: () => (
        <Discussions
          onLoadEnd={(data) =>
            handleSectionDataLoadEnd(data, MediaPlatformSections.DISCUSSION)}
        />
      ),
      [MediaPlatformSections.SUGGEST_IDEA]: () => (
        <ProposeIdea
          onLoadEnd={(data) =>
            handleSectionDataLoadEnd(data, MediaPlatformSections.SUGGEST_IDEA)}
        />
      ),
      [MediaPlatformSections.CATEGORY]: () => (
        <Rating
          description={
            discussionLandingContext.projectCode === PROJECT_CULTURE_ID
              ? mmrTexts.ideaRatingText
              : texts?.ideaRatingText || ""
          }
          onLoadEnd={(data) =>
            handleSectionDataLoadEnd(data, MediaPlatformSections.CATEGORY)}
        />
      ),
      [MediaPlatformSections.IMPLEMENTED_IDEA]: () => (
        <Gallery
          description={
            discussionLandingContext.projectCode === PROJECT_CULTURE_ID
              ? mmrTexts.ideaPhotoText
              : texts?.ideaPhotoText || ""
          }
          onLoadEnd={(data) =>
            handleSectionDataLoadEnd(
              data,
              MediaPlatformSections.IMPLEMENTED_IDEA
            )}
        />
      ),
    };

    return shownSections
      .slice()
      .sort(
        (a, b) =>
          discussionLandingContext[`${a}Priority`] -
          discussionLandingContext[`${b}Priority`]
      )
      .map((section) => {
        const Component = renderMap[section];
        return <Component key={section} />;
      });
  }, [discussionLandingContext, shownSections, texts]);

  useEffect(() => {
    if (discussionLandingContext.projectCode !== PROJECT_CULTURE_ID) {
      MediaPlatformService.getTexts({
        mediaPlatformId: discussionLandingContext.id,
      })
        .then(({ data }) => setTexts(data))
        .catch(console.log);
    }
  }, [discussionLandingContext]);

  useEffect(() => {
    setProgress(
      Object.keys(sectionsReadyStateMap).length / shownSections.length
    );
  }, [sectionsReadyStateMap, shownSections.length]);

  useEffect(() => {
    if (Object.keys(sectionsReadyStateMap).length) {
      const allWatchedSectionsLoaded = shownSections.every(
        (key) => key in sectionsReadyStateMap
      );
      if (allWatchedSectionsLoaded) {
        const filteredRoutes = DiscussionRoutes.baseRoutes.filter((route) => {
          const sectionKey = route.route.replace("?section=", "");
          return sectionsReadyStateMap[sectionKey];
        });

        dispatch(
          setHeaderOptions({
            logo: {
              link: discussionLandingContext.url.slice(1),
            },
            routes: {
              baseRoutes: filteredRoutes,
              mobileRoutes: filteredRoutes,
            },
            type: HeaderRoutesType.DISCUSSION,
          })
        );
      }
    }
  }, [
    sectionsReadyStateMap,
    discussionLandingContext.url,
    shownSections,
    dispatch,
  ]);

  useEffect(
    () => () => {
      if (headerConfig?.type === HeaderRoutesType.DISCUSSION) {
        dispatch(setHeaderOptions({ logo: null, routes: null, type: null }));
        dispatch(
          setHeaderOptions({
            routes: baseAppRoutes,
            type: HeaderRoutesType.MAIN,
          })
        );
      }
    },
    [headerConfig?.type]
  );

  useEffect(() => {
    if (headerConfig?.type === HeaderRoutesType.DISCUSSION) {
      if (sectionName) {
        const element = document.querySelector<HTMLElement>(`#${sectionName}`);
        const headerElementHeight =
          document.querySelector<HTMLElement>(".cr-header")?.clientHeight ?? 0;

        if (element) {
          window.scrollTo({
            top: element.offsetTop - headerElementHeight,
            behavior: "smooth",
          });
        }
      }
    }
  }, [headerConfig?.type, sectionName]);

  useEffect(() => {
    dispatch(setHeaderOptions({ logo: null, routes: null, type: null }));
    dispatch(setAppColor(AppColorsEnum.WHITE));
    const wrapper: HTMLElement = document.querySelector(".wrapper");
    wrapper.classList.add("discussion-landing__wrapper");
    return () => {
      wrapper.classList.remove("discussion-landing__wrapper");
    };
  }, []);

  // ACTIONS AFTER AUTH
  useEffect(() => {
    if (!isUserReady || !currentProjects) return;

    const actions = appContext.sudirService.getActions(
      ComponentType.DISCUSSION_LANDING
    );
    const joinProject = actions.find(
      (act) => act.type === AuthActionType.JOIN_PROJECT
    );
    if (joinProject) {
      connectToProject();
    } else {
      const actions = appContext.sudirService.getActions(
        ComponentType.AFTER_REGISTRATION_FORM
      );
      const redirectToLastVisitedPage = actions.find(
        (act) => act.type === AuthActionType.VISIT_LAST_PAGE
      );
      const lastVisitedPage = redirectToLastVisitedPage
        ? redirectToLastVisitedPage.args.lastVisitedPage
        : history.location.state?.lastVisitedPage;
      if (lastVisitedPage?.toLowerCase() === discussionLandingContext.url) {
        connectToProject(true);
      }
    }
  }, [isUserReady, currentProjects, discussionLandingContext.url]);

  const isVideoOpen = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(translationIdQueryKey);
  }, [location.search]);

  return (
    <>
      <MetaDecorator
        title="Платформа Правительства Москвы «Город идей»"
        description="Ты предлагаешь, мы воплощаем!"
        opengraph={{
          title: isVideoOpen ? ogVideoTitle : ogBaseTitle,
          image: ogImage,
        }}
      />
      <AuthForm />
      {progress < 1 && <ScreenLoader progress={progress} />}
      <div className={classNames("discussion-landing")}>
        <Welcome />
        {renderedSections}
      </div>
    </>
  );
};

export default DiscussionLandingPage;
