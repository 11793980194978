export const discussionLandingRepostUrl = "/culture";

export enum RegistrationStatus {
  WAIT = "WAIT",
  NOW = "NOW",
  FINISHED = "FINISHED",
  REGISTRATION_FINISHED = "REGISTRATION_FINISHED",
  REGISTERED = "REGISTERED",
}

export const RegistrationStatusToTextMap = {
  [RegistrationStatus.WAIT]: "Регистрация",
  [RegistrationStatus.NOW]: "Регистрация",
  [RegistrationStatus.REGISTERED]: "Вы зарегистрированы",
  [RegistrationStatus.FINISHED]: "Завершено",
  [RegistrationStatus.REGISTRATION_FINISHED]: "Регистрация закрыта",
};

export enum DiscussionStatus {
  WAITING = "WAITING",
  OPEN = "OPEN",
  CLOSED = "CLOSED",
}

export const ogBaseTitle = "Платформа Правительства Москвы «Город идей»";
export const ogVideoTitle =
  "Посмотрите новое видео от «Города идей» на медиаплатформе «Город обсуждает стандарты культурного досуга»!";

export const mmrTexts = {
  thematicProjectsText: `Развивайте театры, музеи, галереи, культурные центры и библиотеки,
    участвуйте в международных и локальных фестивалях.
    Присоединяйтесь и становитесь частью культуры Москвы!`,
  ideaRatingText: `Каждую неделю эксперты проекта отбирают самые интересные идеи москвичей,
    посвященные новому стандарту культурного досуга в столице, а также отправленные в раздел «Предложить идею».
    Пользователи со стандартной и полной учетной записью mos.ru, чьи идеи будут отобраны экспертами для рейтинга,
    будут отмечены в номинациях проектов и после их завершения получат <a href="/pointsRules" target="blank"> 3000 баллов.</a>`,
  ideaPhotoText: `В этом разделе публикуются фотографии реализованных идей жителей по развитию культурного досуга в Москве.`,
  mediaPlatformText: `Тема культурного досуга настолько масштабная и интересная, потому мы
    впервые запускаем такой проект для пользователей, где можно не только
    предложить идеи по развитию сфер культурного досуга, но и принять
    участие в обсуждении важных тем с экспертами и единомышленниками. В
    этом разделе вы можете ознакомиться с новостями и посмотреть
    фотоотчеты с прошедших мероприятий в сфере культуры.`,
};
