import { Link } from "react-router-dom";
import { TRoute } from "../../../../types/navigation.interface";
import Heading from "../../../presentational/Typography/Heading";
import EventsTrackWrapperClick from "../../EventsTrack/wrappers/EventsTrackWrapperClick";
import css from "./FooterRoute.module.scss";

interface TProps {
  route: TRoute;
}

export function FooterRoute({ route }: TProps) {
  return (
    <EventsTrackWrapperClick id={route.trackClickId}>
      <div className={css.route}>
        <Heading size={6} className={css.title}>
          {route.route ? (
            <Link to={route.route} className={css.titleLink}>
              {route.text}
            </Link>
          ) : (
            route.text
          )}
        </Heading>
        {!!route.children?.length && (
          <div className={css.children}>
            {route.children?.map((child) => (
              <EventsTrackWrapperClick key={child.id} id={child.trackClickId}>
                <Link to={child.route} className={css.child}>
                  {child.text}
                </Link>
              </EventsTrackWrapperClick>
            ))}
          </div>
        )}
      </div>
    </EventsTrackWrapperClick>
  );
}
