import { useEffect, useState } from "react";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import { useHistory } from "react-router-dom";

import classNames from "classnames";
import NewsService from "../../../services/newsService";
import Share from "../../presentational/Share/Share";
import MetaDecorator from "../../presentational/MetaDecorator/MetaDecorator";

import styles from "./NewsDetailsPage.module.scss";
import { News } from "../../../types/News.interface";
import { ApiStatusCode } from "../../../types/Common.interface";
import { NewsDetailsVideo } from "./NewsDetailsVideo/NewsDetailsVideo";

const NewsDetails = () => {
  const history = useHistory();
  const [news, setNews] = useState<News | null>(null);

  useEffect(() => {
    const id = getNewsId();

    NewsService.getNewsById(id)
      .then((value) => {
        if (value.status === ApiStatusCode.OK) {
          setNews(value.data);
        } else {
          history.push("/news");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getNewsId = () => {
    const [blank, page, id] = history.location.pathname.split("/");

    return id;
  };

  const pushPage = (item, page) => {
    history.push({
      pathname: `/${page}`,
      state: { item },
    });
  };

  // RENDER
  const renderArrowBack = () => (
    <div
      className={styles["news-details__back"]}
      onClick={(e) => history.push("/news")}
    >
      <div className={classNames(styles.ic, "promo-icon-88")} />
      Все новости
    </div>
  );

  const renderTitle = () =>
    news && <h1 className={styles["news-details__title"]}>{news.title}</h1>;

  const renderInfo = () => (
    <div className={styles["news-details__info"]}>
      <div className={styles["news-details__info-date"]}>
        {dayjs(news.published).locale("ru").format("DD MMMM YYYY, HH:mm")}
      </div>
      <div
        className={styles["news-details__info-tag"]}
        onClick={() => pushPage(news, "news")}
      >
        {news.tag}
      </div>
    </div>
  );

  const renderImage = () => (
    <img
      className={styles["news-details__img"]}
      src={`/uploads/get/${news.imageId}`}
      alt={news.imageId}
    />
  );

  const renderText = () => (
    <div
      className={styles["news-details__text"]}
      dangerouslySetInnerHTML={{ __html: news.text }}
    />
  );

  const renderShare = () => (
    <div className={styles["news-details__share"]}>
      <span>Поделиться:</span>
      <Share
        type="unfolded"
        url={`/news/${news.number}`}
        imageUrl={`/uploads/get/${news.imageId}`}
        title={`${news.title} — Платформа Правительства Москвы «Город идей»`}
        colorIcon="#282828"
      />
    </div>
  );

  return (
    <section className={styles["news-details"]}>
      {news && (
        <MetaDecorator
          title="Платформа Правительства Москвы «Город идей»"
          description="Всё самое интересное и актуальное о проектах."
          opengraph={{
            title: news?.title,
            description: news?.text,
            url: window.location.href,
            image: `${window.location.origin}/uploads/get/${news?.imageId}`,
          }}
        />
      )}

      {renderArrowBack()}
      {news && renderTitle()}
      {news && renderInfo()}
      {news ? (
        news.videoId ? (
          <NewsDetailsVideo videoId={news.videoId} posterId={news.imageId} />
        ) : (
          renderImage()
        )
      ) : null}
      {news && renderText()}
      {news && renderShare()}
    </section>
  );
};

export default NewsDetails;
