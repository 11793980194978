import { FC } from "react";
import "./ReviewCard.scss";

import { Scrollbar } from "react-scrollbars-custom";
import Button from "../../Button/Button";
import { MediaPlatformReview } from "../../../../../../types/MediaPlatform.interface";

type ReviewCardProps = MediaPlatformReview;

const ReviewCard: FC<ReviewCardProps> = ({ resource, news, link }) => (
  <div className="review-card">
    <Scrollbar
      className="review-card__scroll discussion-landing-scroll"
      noDefaultStyles
    >
      <div
        className="review-card__source"
        dangerouslySetInnerHTML={{ __html: resource }}
      />
      {": "}
      <div
        className="review-card__message"
        dangerouslySetInnerHTML={{ __html: news }}
      />
    </Scrollbar>
    <Button
      linkUrl={link}
      hrefTarget="blank"
      classList="review-card__button"
      text="Читать"
      type="outlined"
    />
  </div>
);
export default ReviewCard;
