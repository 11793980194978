import React, {
  ComponentType,
  InputHTMLAttributes,
  RefAttributes,
} from "react";

import "./Search.scss";
import { getInputInfo, setListPosition } from "../../../../utils/selectHelper";
import SearchList from "./SearchList/SearchList";

export enum SearchStyleType {
  SIMPLE = "SIMPLE",
  GROUP = "GROUP",
}

interface SearchProps {
  items?: any[];
  type?: SearchStyleType;
  selectedItems?: any[];
  value?: string;
  placeholder?: string;
  bindTo?: string;
  isRounded?: Boolean;
  isWithList?: boolean;
  isMultiplePerRow?: boolean;
  isCloseOnSelect?: Boolean;
  isLoading?: boolean;
  restrictListBy?: number;
  onClick?: () => void;
  onItemSelect?: (item) => void;
  onChange?: (value) => void;
  isClearPlaceholderOnSelect?: boolean;
  autocomplete?: boolean;
  expandOnSearchMatch?: boolean;
  ComponentInput?: ComponentType<
    InputHTMLAttributes<HTMLInputElement> & RefAttributes<HTMLInputElement>
  >;
  replaceInputOnMouseEnter?: boolean;
}

const padding = 13;

class Search extends React.Component<SearchProps, any> {
  inputRef: any;

  listRef: any;

  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value || "",
      canShowList: false,
      inputInfo: null,
    };

    this.inputRef = React.createRef();
    this.listRef = React.createRef();
  }

  onClick = () => {
    if (this.props.onClick && typeof this.props.onClick === "function") {
      this.props.onClick();
    } else {
      console.error("Please provide a callback for Button component");
    }
  };

  renderLoader = () => {
    if (this.props.isLoading) {
      return (
        <div className="cr-button__icon">
          <i className="cr-button__icon-loader" />
        </div>
      );
    }
  };

  getClassesForInput = () => {
    let classes = "cr-search__input";

    if (this.props.isRounded) classes += " cr-search__input-rounded";

    // rest goes here

    return classes;
  };

  onChange = (e) => {
    this.props.onChange && this.props.onChange(e.target.value);
    this.setState(
      {
        inputInfo: getInputInfo(this.inputRef),
      },
      () => setListPosition(this.listRef, this.state.inputInfo, padding)
    );
  };

  onFocus = () => {
    this.setState(
      {
        canShowList: true,
        inputInfo: getInputInfo(this.inputRef),
      },
      () => setListPosition(this.listRef, this.state.inputInfo, padding)
    );
  };

  toGroupItems = (items) =>
    items.map((item) => ({
      ...item,
      isExpanded: false,
    }));

  toExpandItems = (items) =>
    items.map((item) => ({
      ...item,
      isExpanded: true,
    }));

  // RENDER

  renderList = () => {
    if (this.state.canShowList) {
      let { items } = this.props;
      if (this.props.type === SearchStyleType.GROUP) {
        items = this.props.expandOnSearchMatch
          ? this.toExpandItems(items)
          : this.toGroupItems(items);

        // Если пустой инпут сворачиваем все группы
        if (!this.props.value?.trim()) {
          items = items.map((item) => ({ ...item, isExpanded: false }));
        }
      }

      return (
        <SearchList
          items={items}
          type={this.props.type || SearchStyleType.SIMPLE}
          selectedItems={this.props.selectedItems}
          bindTo={this.props.bindTo}
          restrictBy={this.props.restrictListBy}
          onItemSelect={this.props.onItemSelect}
          isMultiplePerRow={this.props.isMultiplePerRow}
          replaceInputOnMouseEnter={this.props.replaceInputOnMouseEnter}
          inputRef={this.inputRef}
        />
      );
    }
  };

  render() {
    const inputProps = {
      ref: this.inputRef,
      className: this.getClassesForInput(),
      value: this.props.value,
      type: "text",
      id: "search-input",
      placeholder: this.props.placeholder,
      onFocus: this.onFocus,
      onChange: this.onChange,
      autoComplete: this.props.autocomplete === false ? "off" : "on",
    };
    const { ComponentInput } = this.props;
    return (
      <div
        className="cr-search"
        onBlur={(e) => this.setState({ canShowList: false })}
      >
        {ComponentInput ? (
          <ComponentInput {...inputProps} />
        ) : (
          <input {...inputProps} />
        )}
        {this.props.value?.trim() && (
          <div
            className="ic promo-icon-14 close"
            onClick={(e) => this.props.onChange && this.props.onChange("")}
          />
        )}
        {this.renderLoader()}
        <div className="ic promo-icon-1 search" />

        {this.renderList()}
      </div>
    );
  }
}

export default Search;
