import { FC } from "react";
import classNames from "classnames";
import { HeadingProps } from "./Heading.types";
import styles from "./Heading.module.scss";
import Base from "../Base";

const Heading: FC<HeadingProps> = ({ size = 1, className, ...otherProps }) => {
  const tag = `h${size}` as const;
  return (
    <Base
      component={tag}
      className={classNames(styles[tag], className)}
      {...otherProps}
    />
  );
};

export default Heading;
