import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../../../types/State.interface";
import EventsTrackWrapperScroll from "../../../containers/EventsTrack/wrappers/EventsTrackWrapperScroll";
import Text from "../../../presentational/Typography/Text";
import css from "./AboutSection.module.scss";
import { AboutStatistic } from "./AboutStatistic/AboutStatistic";
import { aboutStatistics } from "./helpers";
import Button from "../../../presentational/Controls/Button/Button";
import { useIsMobile } from "../../../../hooks/useMedia";

const AboutSection = () => {
  const history = useHistory();
  const isPhone = useIsMobile();

  const totalStatistics = useSelector(
    (state: RootState) => state.totalStatistics.stats
  );

  if (!totalStatistics) return null;

  return (
    <EventsTrackWrapperScroll id={4}>
      <section className={css.wrapper}>
        <div className={css.description}>
          <Text size={2} paragraph className={css.text}>
            Платформа Правительства Москвы «Город идей» дает возможность жителям
            предлагать идеи по развитию города.
          </Text>
          {!isPhone && (
            <Button
              onClick={() => history.push("/about")}
              type="filled"
              size="mid"
              text="ПОДРОБНЕЕ О НАС"
            />
          )}
        </div>

        <div className={css.stats}>
          {aboutStatistics(totalStatistics).map((stat) => (
            <AboutStatistic key={stat.icon} {...stat} />
          ))}
        </div>
      </section>
    </EventsTrackWrapperScroll>
  );
};

export default AboutSection;
