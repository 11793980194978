import React, {
  MouseEventHandler,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { useMediaQuery } from "react-responsive";
import {
  phoneWidth,
  tabletWidth,
} from "../../../../utils/constants/widthConstants";
import { ArrowOptions } from "../../../presentational/Sliders/SliderArrows/SliderArrows";
import NextModal, {
  HammerTypeEvent,
} from "../../../presentational/Modals/NextModal/NextModal";
import "./CommonViewer.scss";

export interface CommonViewerProps {
  openAt?: number;
  className: string;
  arrowOptions?: ArrowOptions;
  onChange: (index) => void;
  onClose: () => void;
  onSlidePrev?: MouseEventHandler;
  onSlideNext?: MouseEventHandler;
  canSlidePrev?: boolean;
  canSlideNext?: boolean;
}

const CommonViewer: React.FC<PropsWithChildren<CommonViewerProps>> = ({
  openAt,
  className,
  arrowOptions,
  onChange,
  onClose,
  onSlidePrev,
  onSlideNext,
  canSlidePrev = true,
  canSlideNext = true,
  children,
}: PropsWithChildren<CommonViewerProps>) => {
  const [currentIndex, setCurrentIndex] = useState(openAt || 0);

  const isMobile = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const isDesktop = useMediaQuery({ query: `(min-width: ${tabletWidth}px)` });
  const isTablet = useMediaQuery({
    query: `(max-width: ${tabletWidth}px) and (min-width: ${phoneWidth}px)`,
  });

  useEffect(() => {
    onChange(currentIndex);
  }, [currentIndex]);

  const _onSlidePrev = (event) => {
    if (event === HammerTypeEvent) return;
    setCurrentIndex(
      currentIndex === 0 ? (children as any).length - 1 : currentIndex - 1
    );

    onSlidePrev?.(event);
  };

  const _onSlideNext = (event) => {
    if (event === HammerTypeEvent) return;
    setCurrentIndex(
      currentIndex === (children as any).length - 1 ? 0 : currentIndex + 1
    );
    onSlideNext?.(event);
  };

  const getModalClassList = () => {
    let classList = "common-viewer ";
    className && (classList += `${className} `);
    (children as any).length === 1 && (classList += "common-viewer--single ");
    return classList;
  };

  return (
    <NextModal
      class={getModalClassList()}
      isOpened
      canSlidePrev={canSlidePrev}
      canSlideNext={canSlideNext}
      onSlidePrev={_onSlidePrev}
      onSlideNext={_onSlideNext}
      onClose={onClose}
      withHammer={false}
      arrowOptions={arrowOptions}
    >
      {children[currentIndex]}
    </NextModal>
  );
};

export default CommonViewer;
