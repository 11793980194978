import React from "react";
import Header from "./Header";
import QrCodeModal from "../Modals/QrCodeModal/QrCodeModal";
import AuthForm from "../Forms/AuthForm/AuthForm";

function HeaderContainer() {
  return (
    <>
      <Header />

      <AuthForm />
      <QrCodeModal />
    </>
  );
}

export default HeaderContainer;
