import "./PageTitle.scss";

interface TProps {
  text: string;
}

const PageTitle = ({ text }: TProps) => (
  <div className="page-title">{text}</div>
);

export default PageTitle;
