import React from "react";

const InputWithArrow = React.forwardRef(
  ({ isListVisible, label, children, ...props }, ref) => (
    <div {...props} ref={ref}>
      <div
        className={`promo-icon-chevron-down-rounded ${isListVisible ? "open" : ""}`}
      />
      {children}
    </div>
  )
);

export default InputWithArrow;
