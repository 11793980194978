import React, { FC, useState } from "react";

import classNames from "classnames";
import { isMobile } from "react-device-detect";
import { Idea } from "../../../../../types/Idea.interface";
import Share from "../../../../presentational/Share/Share";
import { User } from "../../../../../types/User.interface";
import EventsTrackWrapperClick from "../../../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";
import {
  canSeeHiddenNames,
  nameIfHidden,
  renderHatIfUserNameIsHidden,
} from "../../../../../utils/user.utils";
import styles from "./IdeaCard.module.scss";
import Like from "../../../../presentational/Controls/Like/Like";
import Text from "../../../../presentational/Typography/Text";

interface Props {
  idea?: Idea | any;
  user?: User;
  onCardClick?: (idea: Idea) => void;
  onAuthorClick?: (idea: Idea) => void;
  onTagClick?: (idea: Idea) => void | null;
  onLike?: (idea: Idea) => void;
}

const IdeaCard: FC<Props> = ({
  user,
  idea,
  onLike,
  onAuthorClick,
  onTagClick,
  onCardClick,
}) => {
  const [pointed, setPointed] = useState(false);

  const getName = () => {
    const author = idea?.author;
    if (!author) return "";
    if (author.deletedSudir || (!author.showName && !canSeeHiddenNames(user))) {
      return nameIfHidden;
    }

    return `${author.lastName} ${idea.author.firstName && idea.author.firstName[0]}.`;
  };

  const getTitle = () =>
    `Идея «${idea?.ideaName}» предложена на платформе «Город идей» и реализована городом.`;

  const renderInfo = () => {
    const authorName = getName();
    const hidden = !pointed && !isMobile;

    return (
      <div
        className={classNames(styles.info, {
          [styles.info_hidden]: hidden,
        })}
      >
        <EventsTrackWrapperClick needParent id={175} replace={[authorName]}>
          <div
            className={styles.author}
            onClick={(e) => {
              e.stopPropagation();
              onAuthorClick?.(idea);
            }}
          >
            <Text size={4} ellipsis className={styles.author__name}>
              {authorName}
            </Text>
            {renderHatIfUserNameIsHidden(idea.author, user)}
          </div>
        </EventsTrackWrapperClick>
        <Text className={styles["idea-name"]} size={3} ellipsis={3}>
          {idea?.ideaName}
        </Text>
        <div className={styles.bottom}>
          <div
            className={styles.tag}
            onClick={(e) => {
              e.stopPropagation();
              onTagClick?.(idea);
            }}
          >
            <Text size={4} ellipsis uppercase className={styles.tag__text}>
              {idea?.tag?.name}
            </Text>
          </div>
          <div className={styles.share}>
            <Share
              url={`/ideas/${idea?.id}`}
              imageUrl={`/uploads/get/${idea?.watermarkAttachmentId}`}
              title={getTitle()}
              backgroundColorIcon="#FFFFFF29"
              backgroundColorPanelIcon="white"
              colorIcon="white"
              colorPanelIcon="black"
              canOpen={!hidden}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={styles["idea-card"]}
      onClick={() => onCardClick?.(idea)}
      onPointerEnter={() => setPointed(true)}
      onPointerLeave={() => setPointed(false)}
    >
      {idea?.watermarkAttachmentId && (
        <img
          className={styles.bg}
          src={`/uploads/get/${idea.watermarkAttachmentId}`}
          alt={idea.ideaName}
        />
      )}

      <EventsTrackWrapperClick needParent id={176}>
        <div className={styles.like}>
          <Like
            onClick={(e) => {
              e.stopPropagation();
              onLike?.(idea);
            }}
            count={idea?.rating}
            liked={idea?.liked && user?.loggedIn}
          />
        </div>
      </EventsTrackWrapperClick>

      {renderInfo()}
    </div>
  );
};

export default IdeaCard;
