import { SittingKey } from "../../types/Sittings.interface";
import SittingsService from "../../services/sittingsService";
import { ApiStatusCode } from "../../types/Common.interface";

export const GET_SETTING_KEY_SUCCESS = "GET_SETTING_KEY_SUCCESS";

export const getSittingByKey = (key: SittingKey) => (dispatch) =>
  SittingsService.getSittingByKey(key)
    .then(({ data, status }) => {
      if (status === ApiStatusCode.OK) {
        dispatch({
          type: GET_SETTING_KEY_SUCCESS,
          payload: { key, value: data },
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
