import React from "react";
import Hammer from "react-hammerjs";
import { AppModal } from "../../../containers/Modal/AppModal";

import "./NextModal.scss";
import classNames from "classnames";

export const HammerTypeEvent = "HammerTypeEvent";

const NextModal = (props) => {
  const renderContent = () => (
    <div className="next-modal__content">{props.children}</div>
  );

  const renderArrows = () => (
    <div className="next-modal__arrows">
      <div
        className={`${"ic promo-icon-105"} next-modal__arrow next-modal__arrow-left ${
          props.canSlidePrev ? "" : "arrow__disabled"
        }`}
        onClick={props.onSlidePrev}
      />
      <div
        className={`${"ic promo-icon-88"} next-modal__arrow next-modal__arrow-right ${
          props.canSlideNext ? "" : "arrow__disabled"
        }`}
        onClick={props.onSlideNext}
      />
    </div>
  );

  const renderCustomArrows = () => {
    const { left, right } = props.arrowOptions;
    return (
      <div className="next-modal__carrows">
        <div
          className="next-modal__carrow next-modal__carrow-left"
          onClick={props.onSlidePrev}
        >
          <span className={`next-modal__cicon ${left.icon}`} />
          <span className="next-modal__ctitle">{left.title}</span>
        </div>

        <div
          className="next-modal__carrow next-modal__carrow-right"
          onClick={props.onSlideNext}
        >
          <span className={`next-modal__cicon ${right.icon}`} />
          <span className="next-modal__ctitle">{right.title}</span>
        </div>
      </div>
    );
  };

  return (
    <AppModal
      center
      classNames={{
        modal: `next-modal ${props.class}`,
        overlay: classNames("next-modal-overlay", props.overlayClass),
      }}
      closeIconId="close-modal-icon"
      blockScroll
      closeIconSize={25}
      open={props.isOpened}
      closeOnEsc
      closeOnOverlayClick={false}
      onClose={props.onClose}
    >
      <Hammer
        onSwipe={(e) => {
          if (!e || !e.direction) return;

          e.direction === 4
            ? props.canSlidePrev && props.onSlidePrev(HammerTypeEvent)
            : props.canSlideNext && props.onSlideNext(HammerTypeEvent);
        }}
      >
        <div className="next-modal__hammer-wrapper">
          {props.isOpened && renderContent()}
          {props.arrowOptions ? renderCustomArrows() : renderArrows()}
        </div>
      </Hammer>
    </AppModal>
  );
};

export default NextModal;
