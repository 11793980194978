import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  Fragment,
  useCallback,
} from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import SwiperCore, { EffectFlip } from "swiper";

import classNames from "classnames";
import { Idea } from "../../../../../types/Idea.interface";
import { RootState } from "../../../../../types/State.interface";
import { IdeasListProps } from "./Ideas.interface";
import {
  AuthAction,
  AuthActionParam,
  AuthActionType,
  ComponentType,
} from "../../../../../services/sudirService";
import { AppContext } from "../../../../Root";
import styles from "./IdeasList.module.scss";
import IdeaService from "../../../../../services/ideaService";
import { useForceUpdate } from "../../../../../hooks/useForceUpdate";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import { displayError, zeroFill } from "../../../../../utils";
import { useIsMobile, useIsWideTablet } from "../../../../../hooks/useMedia";
import { selectIsNeedAfterRegistrationForm } from "../../../../../store/selectors/profile";
import EventsTrackWrapperClick from "../../../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";
import UserDetailsModal from "../../../../containers/Modals/UserDetailsModal/UserDetailsModal";
import IdeasPhotoViewer from "../../../../containers/Modals/IdeasPhotoViewer/IdeasPhotoViewer";
import SendIdeaForm from "../../../../containers/Forms/SendIdeaForm/SendIdeaForm";
import IdeaCard from "../IdeaCard/IdeaCard";
import Slider from "../../../../presentational/Sliders/Slider/Slider";
import AppLink from "../../../../presentational/Controls/AppLink/AppLink";
import Text from "../../../../presentational/Typography/Text";
import Button from "../../../../presentational/Controls/Button/Button";
import { keys, values } from "../../../../../utils/object";

const instructionSteps = [
  "Сделайте фото",
  "Загрузите фото",
  "Получите награду",
];

SwiperCore.use([EffectFlip]);

const flipTimeout = 100;

const IdeasList = ({
  isAllPublished = false,
  isRandom = false,
  isShowAllInNewTab = false,
  loop = false,
  tags,
  maxPages,
  fetchSize = 12,
  viewerClassName,
  pagination,
}: IdeasListProps) => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const forceUpdate = useForceUpdate();

  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);
  const isNeedAfterRegistrationForm = useSelector(
    selectIsNeedAfterRegistrationForm
  );
  const [ideasList, setIdeasList] = useState([]);
  const [ideasPaging, setIdeasPaging] = useState(null);
  const [isIdeasLoading, setIsIdeasLoading] = useState(false);
  const [nextPageLoaded, setNextPageLoaded] = useState<number>();
  const [prevPageLoaded, setPrevPageLoaded] = useState<number>();

  const [swipers, setSwipers] = useState<Record<string, SwiperCore>>({});
  const user = useSelector((state: RootState) => state.user.userDetails);

  const [ideaUploadModalOpen, setIdeaUploadModalOpen] = useState(false);
  const [idea, setIdea] = useState<Idea | null>(null);
  const [photoViewerOpen, setPhotoViewerOpen] = useState(false);
  const [authorModalOpen, setAuthorModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const isTablet = useIsWideTablet();
  const isPhone = useIsMobile();

  const batches = isPhone ? 1 : isTablet ? 2 : 3;

  const ideasToShow = useMemo(
    () => ideasList.slice(0, ideasList.length - (ideasList.length % batches)),
    [batches, ideasList]
  );
  const ideaIndex = useMemo(
    () => (ideasToShow || []).findIndex((item) => item === idea),
    [idea, ideasToShow]
  );

  const ideasBatches = useMemo(() => {
    const res = [];

    ideasToShow.forEach((idea, i) => {
      if (!res[i % batches]) {
        res[i % batches] = [];
      }
      res[i % batches].push(idea);
    });
    return res;
  }, [batches, ideasToShow]);

  const getTotalPages = (paging) =>
    (maxPages
      ? Math.min(maxPages, paging?.totalPages || 0)
      : paging?.totalPages || 0);

  const scrollToList = () => {
    document.querySelector(`.${styles["ideas-list"]}`).scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const goToPage = useCallback(
    (page: number, isPrev: boolean) => {
      const tids = [];
      let timeout = flipTimeout;

      values(swipers).forEach((sw) => {
        const lastIdx = sw.slides.length - (sw.loopedSlides || 0) * 2 - 1;
        if (sw.realIndex !== page) {
          tids.push(
            setTimeout(() => {
              if (isPrev) {
                sw.realIndex - 1 === page ||
                (page === lastIdx && sw.realIndex === 0 && loop)
                  ? sw.slidePrev()
                  : sw.slideToLoop(page);
              } else {
                sw.realIndex + 1 === page ||
                (page === 0 && sw.realIndex === lastIdx && loop)
                  ? sw.slideNext()
                  : sw.slideToLoop(page);
              }
            }, timeout)
          );

          timeout += flipTimeout;
        }
      });

      return tids;
    },
    [loop, swipers]
  );

  // ACTIONS AFTER AUTH
  useEffect(() => {
    if (!ideasToShow.length || !loggedIn || isNeedAfterRegistrationForm) return;

    const actions = appContext.sudirService.getActions(ComponentType.IDEA_LIST);
    if (actions.length) {
      const openIdeaModal = actions.find(
        (act) => act.type === AuthActionType.OPEN_IDEAS_MODAL
      );
      if (openIdeaModal) {
        if (openIdeaModal.args?.navigate) {
          scrollToList();
        }
        setIdeaUploadModalOpen(true);
      }

      const likeIdea = actions.find((act) => act.type === AuthActionType.LIKE);
      if (likeIdea) {
        IdeaService.ideaById(likeIdea.args.ideaId)
          .then((response) => {
            if (response.status === ApiStatusCode.OK) {
              onLike(response.data);
              if (likeIdea.args?.navigate) {
                scrollToList();
              }
            }
          })
          .catch(console.log);
      }
    }
  }, [ideasToShow, loggedIn, isNeedAfterRegistrationForm]);

  useEffect(() => {
    dispatchNext(0).then((data) => {
      if (loop && data) {
        const totalPages = getTotalPages(data.paging);
        if (totalPages > 1) {
          dispatchNext(totalPages - 1, true);
        }
      }
    });
  }, []);

  useEffect(() => {
    values(swipers).map((sw) => sw.update());
  }, [swipers, ideasToShow?.length]);

  useEffect(() => {
    if (keys(swipers).length === batches) {
      const firstIndexOnPage = currentPage * batches;
      if (canFetchNext(firstIndexOnPage)) {
        dispatchNext(nextPageLoaded + 1);
      } else if (canFetchPrev(firstIndexOnPage)) {
        dispatchNext(prevPageLoaded - 1, true);
      }
    }
  }, [currentPage, batches]);

  useEffect(() => {
    if (+prevPageLoaded < getTotalPages(ideasPaging) - 1) {
      values(swipers).map((sw) => {
        sw.slideToLoop(sw.realIndex + fetchSize / batches, 0);
      });
    }
  }, [prevPageLoaded]);

  useEffect(() => {
    const tids = goToPage(currentPage, false);

    return () => {
      tids.forEach(clearTimeout);
    };
  }, [currentPage, goToPage]);

  useEffect(() => {
    let timeout = 500;
    const tids = [];
    values(swipers).forEach((sw) => {
      sw.autoplay.stop();
      if (!photoViewerOpen) {
        tids.push(setTimeout(() => sw.autoplay.start(), timeout));
        timeout += flipTimeout;
      }
    });

    return () => tids.forEach(clearTimeout);
  }, [photoViewerOpen, swipers, currentPage]);

  const dispatchNext = (page, isPrev = false) => {
    const statuses = ["PUBLISHED_MAIN"];
    if (isAllPublished) {
      statuses.push("PUBLISHED");
    }

    const query = {
      page,
      size: fetchSize,
      sort: "main_pos,desc",
    } as any;

    const params = {
      isRandom,
      statuses,
      hidden: false,
    } as any;

    if (tags) {
      params.tags = tags;
    }

    setIsIdeasLoading(true);
    return IdeaService.getByFilter(query, params)
      .then(({ data, paging }) => {
        setIdeasList((prev) => {
          const changedList = Array.isArray(prev) ? [...prev] : [];
          if (data) {
            if (isPrev) {
              const lastPageRemainder = changedList.length % fetchSize;
              changedList.splice(
                changedList.length -
                  (getTotalPages(paging) - 1 - page) * fetchSize +
                  (lastPageRemainder ? fetchSize - lastPageRemainder : 0),
                0,
                ...data
              );
            } else {
              changedList.splice(page * fetchSize, 0, ...data);
            }
          }

          return changedList;
        });
        setIdeasPaging(paging);
        setIsIdeasLoading(false);
        if (isPrev) {
          setPrevPageLoaded(page);
        } else {
          setNextPageLoaded(page);
        }
        forceUpdate();
        return { data, paging };
      })
      .catch(() => {
        displayError("IdeasList", "Ошибка получения идей");
        setIsIdeasLoading(false);
      });
  };

  const openIdeaUploadModal = () => {
    if (!loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.IDEA_LIST,
        type: AuthActionType.OPEN_IDEAS_MODAL,
        args: { navigate: true },
      };

      const action1 = new AuthAction(thisContainerData);

      return appContext.sudirService.authWithActions([action1]);
    }

    return setIdeaUploadModalOpen(true);
  };

  const pushPage = (item, page) => {
    history.push({
      pathname: `/${page}`,
      state: { item },
    });
  };

  // RENDER

  const setNextIdea = (idea) => {
    const idx = ideasToShow.indexOf(idea);
    let nextIdx = idx + 1;
    const next = ideasToShow[nextIdx];
    if (next) {
      setIdea(next);
    } else {
      nextIdx = 0;
      setIdea(ideasToShow[nextIdx]);
    }

    const nextPage = Math.floor(nextIdx / batches);
    goToPage(nextPage, false);
  };

  const setPrevIdea = (idea) => {
    const idx = ideasToShow.indexOf(idea);
    let prevIdx = idx - 1;
    const prev = ideasToShow[prevIdx];
    if (prev) {
      setIdea(prev);
    } else {
      prevIdx = ideasToShow.length - 1;
      setIdea(ideasToShow[prevIdx]);
    }

    const prevPage = Math.floor(prevIdx / batches);
    goToPage(prevPage, true);
  };

  const canSlidePrev = () =>
    !isIdeasLoading && ideasToShow.length > 1 && (loop || ideaIndex > 0);

  const canSlideNext = () =>
    !isIdeasLoading &&
    ideasToShow.length > 1 &&
    (loop || ideaIndex < ideasToShow.length - 1);

  const canFetchNext = (index) => {
    const loadedFromStart = (nextPageLoaded + 1) * fetchSize;
    return (
      index >= loadedFromStart - 4 &&
      index < loadedFromStart &&
      nextPageLoaded < getTotalPages(ideasPaging) - 1 &&
      (!loop || nextPageLoaded < prevPageLoaded - 1)
    );
  };

  const canFetchPrev = (index) => {
    const lastPageRemainder = ideasList.length % fetchSize;
    const loadedFromStart =
      ideasList.length -
      (getTotalPages(ideasPaging) - prevPageLoaded) * fetchSize +
      (lastPageRemainder ? fetchSize - lastPageRemainder : 0);
    return (
      loop &&
      index >= loadedFromStart &&
      index < loadedFromStart + 4 &&
      prevPageLoaded > 0 &&
      prevPageLoaded > nextPageLoaded + 1
    );
  };

  const onSlideChange = (sw: SwiperCore) => setCurrentPage(sw.realIndex);

  const onLike = (idea: Idea) => {
    if (!loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.IDEA_LIST,
        type: AuthActionType.LIKE,
        args: {
          ideaId: idea.id,
          navigate: true,
        },
      };

      const action1 = new AuthAction(thisContainerData);
      return appContext.sudirService.authWithActions([action1]);
    }

    !idea.liked &&
      IdeaService.like(idea.id)
        .then(() => {
          const newIdea = { ...idea, liked: true, rating: idea.rating + 1 };
          setIdeasList((prev) => {
            const i = prev.findIndex(({ id }) => id === idea.id);
            if (~i) {
              const changedList = [...prev];
              changedList.splice(i, 1, newIdea);
              return changedList;
            }
            return prev;
          });
          setIdea((prev) => (prev === idea ? newIdea : prev));
        })
        .catch(console.log);
  };

  const renderUploadButton = () => {
    const btnText = "Загрузить фото";
    return (
      <EventsTrackWrapperClick needParent id={182} replace={[btnText]}>
        <Button
          size="mid"
          type="filled"
          text={btnText}
          onClick={() => openIdeaUploadModal()}
        />
      </EventsTrackWrapperClick>
    );
  };

  const renderUpload = () => (
    <div className={styles.upload}>
      <div className={styles.info}>
        <Text size={2} className={styles["info-text"]}>
          Подтверждайте реализацию идей вместе с&nbsp;нами! Вы можете отправить
          фото реализованных идей для публикации.
        </Text>
        {!isPhone && renderUploadButton()}
      </div>

      <div className={styles.instruction}>
        <ul>
          {instructionSteps.map((step, i) => (
            <li key={step}>
              <Text bold className={styles["step-num"]}>
                {zeroFill(i + 1, 2)}
              </Text>
              <Text
                size={isTablet ? 3 : 2}
                uppercase
                className={styles["step-text"]}
              >
                {step}
              </Text>
            </li>
          ))}
        </ul>
      </div>

      {isPhone && renderUploadButton()}
    </div>
  );

  return (
    <>
      <div className={styles["ideas-list"]}>
        {renderUpload()}
        <div className={styles.sliders}>
          {ideasBatches.map((batch, i) => (
            <Fragment key={i}>
              {Boolean(batch.length) && (
                <Slider
                  sliderOptions={{
                    className: classNames(styles.slider, {
                      [styles.loading]: isIdeasLoading,
                    }),
                    slideClass: styles.slide,
                    autoplay: {
                      delay: 5000,
                      disableOnInteraction: false,
                    },
                    onAfterInit: (sw) => {
                      sw.update();
                    },
                    onSwiper: (sw) => {
                      setSwipers((prev) => ({ ...prev, [i]: sw }));
                    },
                    onDestroy: () => {
                      setSwipers((prev) => {
                        const copy = { ...prev };
                        delete copy[i];
                        return copy;
                      });
                    },
                    onSlideChange,
                    loop,
                    slidesPerView: 1,
                    autoHeight: true,
                    effect: "flip",
                    flipEffect: { slideShadows: false, limitRotation: true },
                    speed: 600,
                  }}
                  arrowsOrtions={{
                    disabled: isIdeasLoading,
                    pagination,
                    className: styles.arrows,
                  }}
                  showArrows={i === 0}
                >
                  {batch.map((idea: any) => (
                    <IdeaCard
                      key={idea.id}
                      user={user}
                      idea={idea}
                      onCardClick={(idea) => {
                        setIdea(idea);
                        setPhotoViewerOpen(true);
                      }}
                      onLike={onLike}
                      onTagClick={(idea) => pushPage(idea, "ideas")}
                      onAuthorClick={(idea) => {
                        setIdea(idea);
                        setAuthorModalOpen(true);
                      }}
                    />
                  ))}
                </Slider>
              )}
            </Fragment>
          ))}
        </div>
        {!!ideasToShow.length && (
          <EventsTrackWrapperClick
            needParent
            id={183}
            replace={["Посмотреть все"]}
          >
            <AppLink
              size={isTablet ? 3 : 2}
              className={styles["view-all"]}
              to={`/ideas${tags ? `?tags=${tags.join(",")}` : ""}`}
              target={isShowAllInNewTab ? "_blank" : "_self"}
              underline
            >
              Посмотреть все
            </AppLink>
          </EventsTrackWrapperClick>
        )}
      </div>

      {/* MODALS */}
      {idea && authorModalOpen && (
        <UserDetailsModal
          idea={idea}
          isOpen
          onClose={() => setAuthorModalOpen(false)}
        />
      )}
      {idea && (
        <IdeasPhotoViewer
          className={viewerClassName}
          idea={idea}
          isOpened={photoViewerOpen}
          onClose={(e) => setPhotoViewerOpen(false)}
          canSlideNext={canSlideNext()}
          canSlidePrev={canSlidePrev()}
          onSlideNext={() => {
            setNextIdea(idea);
          }}
          onSlidePrev={() => {
            setPrevIdea(idea);
          }}
          onAuthorClick={(idea) => {
            setIdea(idea);
            setAuthorModalOpen(true);
          }}
          onLike={onLike}
        />
      )}
      {ideaUploadModalOpen && (
        <SendIdeaForm
          isOpened={ideaUploadModalOpen}
          user={user}
          onClose={() => setIdeaUploadModalOpen(false)}
        />
      )}
    </>
  );
};

export default IdeasList;
