import EventsTrackParentIdContext from "../../../containers/EventsTrack/EventsTrackParentIdContext";
import EventsTrackWrapperScroll from "../../../containers/EventsTrack/wrappers/EventsTrackWrapperScroll";
import IdeasList from "./IdeasList/IdeasList";

const IdeasSection = () => (
  <EventsTrackParentIdContext.Provider value="BLOCK_RESULTS">
    <EventsTrackWrapperScroll id={5}>
      <div>
        <IdeasList fetchSize={15} maxPages={1} pagination="dots" />
      </div>
    </EventsTrackWrapperScroll>
  </EventsTrackParentIdContext.Provider>
);

export default IdeasSection;
