import { withRouter } from "react-router-dom";
import PublicationCard from "../../presentational/Cards/PublicationCard/PublicationCard";
import "./PublicationsPage.scss";
import CardGridPageGenerate from "../../presentational/CardGridPageGenerate/CardGridPageGenerate";

const PublicationsPage = (props) => {
  const title = "Проект Правительства Москвы «Сообщество»";
  const description = "Проект Правительства Москвы «Сообщество»";
  return (
    <CardGridPageGenerate
      section="publications"
      title={title}
      description={description}
      pageDescription="«Сообщество» — это коллекция историй людей, которые сегодня живут в Москве. Мы познакомим вас с самыми интересными горожанами, их опытом, идеями и компетенциями. Мы расскажем, о чем они думают, чем занимаются, как болеют и выздоравливают, на чем ездят, где отдыхают и от чего грустят, как они меняют Москву днем и почему не спят ночью. Вы увидите их лица, услышите голоса и сможете поделиться с нами собственными историями."
      opengraph={{ title, description }}
      pageTitle="Сообщество"
      endpoint="/api/publication/list?statuses=PUBLISHED,PUBLISHED_MAIN&sort=published,desc&"
      screenWidth={props.screenWidth}
      tags={props.tags}
    >
      <PublicationCard
        href={(publicationItem) => `/public/${publicationItem.number}`}
        linkRel="canonical"
      />
    </CardGridPageGenerate>
  );
};

export default withRouter(PublicationsPage);
