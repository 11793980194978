import { FC, useEffect } from "react";
import "./Gallery.scss";
import IdeasList from "../../../../containers/IdeasList/IdeasList";
import { MediaPlatformSections } from "../../routing/routes";
import useDiscussionLandingContext from "../../hooks/useDiscussionLandingContext";
import {
  cultureTags,
  gorodIdeaTitlesMoscowSport,
  moscowSportTags,
  MP_MOSCOWSPORT_CODE,
  PROJECT_CULTURE_ID,
} from "../../../../../utils/mediaPlatform";

export interface GalleryProps {
  onLoadEnd?: (data) => void;
  description: string;
}

const Gallery: FC<GalleryProps> = ({ onLoadEnd, description }) => {
  const discussionLandingContext = useDiscussionLandingContext();

  useEffect(() => {
    onLoadEnd?.([]);
  }, []);

  const isCulture = discussionLandingContext.projectCode === PROJECT_CULTURE_ID;

  return (
    <section
      className="discussion-landing-gallery"
      id={MediaPlatformSections.IMPLEMENTED_IDEA}
    >
      <div className="discussion-landing-title">ГАЛЕРЕЯ РЕАЛИЗОВАННЫХ ИДЕЙ</div>
      {!!description && (
        <div
          className="discussion-landing-text"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
      <IdeasList
        isShowInfoCard={false}
        isCulture={isCulture}
        isAllPublished
        isRandom
        loop
        isShowAllInNewTab
        tags={
          discussionLandingContext.code === MP_MOSCOWSPORT_CODE
            ? moscowSportTags
            : cultureTags
        }
        ideaItemTitles={
          discussionLandingContext.code === MP_MOSCOWSPORT_CODE
            ? gorodIdeaTitlesMoscowSport
            : undefined
        }
        spaceBetween={24}
        maxPages={20}
        viewerClassName="discussion-landing-modal"
      />
    </section>
  );
};

export default Gallery;
