import { FC, useEffect, useRef, useState } from "react";
import {
  MapInstance,
  MapProps,
  MapTranslate,
  TranslateFn,
  ZoomFn,
} from "./Map.interface";
import "./Map.scss";
import { animationDuration, moveStep, scaleStep } from "./Map.constants";
import {
  animate,
  debounce,
  getDistanceBetweenTouches,
  throttle,
} from "../../../../../utils";
import { getLimitedTranslate, getMaxScale, getMinScale } from "./Map.utils";
import classNames from "classnames";

const Map: FC<MapProps> = ({
  size: [mapWidth, mapHeight],
  layerSrc,
  objects,
  onMapInit,
}) => {
  const wrapperRef = useRef<HTMLDivElement>();
  const containerRef = useRef<HTMLDivElement>();
  const translateRef = useRef<MapTranslate>({ x: 0, y: 0, scale: 1 });
  const mapInstanceRef = useRef<MapInstance>();
  const [isMaxScaled, setIsMaxScaled] = useState(false);
  const [isMinScaled, setIsMinScaled] = useState(false);

  const translate: TranslateFn = (
    newX: number,
    newY: number,
    newScale: number,
    animation = 0
  ) => {
    const { x, y, scale } = getLimitedTranslate(
      { x: newX, y: newY, scale: newScale },
      mapInstanceRef.current
    );

    const prevTranslate = { ...translateRef.current };
    animate(animation, (step) => {
      const partX = prevTranslate.x + (x - prevTranslate.x) * step;
      const partY = prevTranslate.y + (y - prevTranslate.y) * step;
      const partScale =
        prevTranslate.scale + (scale - prevTranslate.scale) * step;
      containerRef.current.style.transform = `translate(${partX}px, ${partY}px) scale(${partScale})`;
    });

    setIsMaxScaled(scale >= getMaxScale(mapInstanceRef.current));
    setIsMinScaled(scale <= getMinScale(mapInstanceRef.current));

    translateRef.current = { x, y, scale };
  };
  const zoom: ZoomFn = (
    newX: number,
    newY: number,
    newScale: number,
    animation = 0,
    toCenter = false
  ) => {
    const { width, height } = wrapperRef.current.getBoundingClientRect();
    const { x, y, scale } = translateRef.current;
    const posX = newX + x;
    const posY = newY + y;
    const { scale: newLimitedScale } = getLimitedTranslate(
      {
        x,
        y,
        scale: newScale,
      },
      mapInstanceRef.current
    );
    const scaleCoef = newLimitedScale / scale - 1;

    let zoomX;
    let zoomY;
    let zoomPosX;
    let zoomPosY;
    if (toCenter) {
      zoomPosX = width / 2;
      zoomPosY = height / 2;
      zoomX = x + (zoomPosX - posX);
      zoomY = y + (zoomPosY - posY);
    } else {
      zoomPosX = posX;
      zoomPosY = posY;
      zoomX = x;
      zoomY = y;
    }

    translate(
      zoomX - (-zoomX + zoomPosX) * scaleCoef,
      zoomY - (-zoomY + zoomPosY) * scaleCoef,
      newScale,
      animation
    );
  };
  const zoomToCurrentCenter = (newScale: number, animation = 0) => {
    const { width, height } = wrapperRef.current.getBoundingClientRect();
    const { x, y } = translateRef.current;
    zoom(width / 2 - x, height / 2 - y, newScale, animation);
  };

  useEffect(() => {
    const container = containerRef.current;
    const wrapper = wrapperRef.current;
    const translateTo: ZoomFn = (
      mapPosX,
      mapPosY,
      newScale,
      animation = 0,
      toCenter = false
    ) => {
      const { scale } = translateRef.current;
      return zoom(
        mapPosX * scale,
        mapPosY * scale,
        newScale,
        animation,
        toCenter
      );
    };
    const getTranslate = () => translateRef.current;
    mapInstanceRef.current = {
      translateTo,
      translate,
      container,
      wrapper,
      getTranslate,
    };

    onMapInit?.(mapInstanceRef.current);

    let moveXStart = 0;
    let moveYStart = 0;
    let pinchDistanceStart = 0;
    let pinchScaleStart: number;
    const touchstartHandler = (e: TouchEvent | MouseEvent) => {
      if ("touches" in e && e.touches.length === 2) {
        e.preventDefault();
        pinchScaleStart = translateRef.current.scale;
        pinchDistanceStart = getDistanceBetweenTouches(e);
      } else if ("touches" in e) {
        moveXStart = e.touches[0]?.clientX || 0;
        moveYStart = e.touches[0]?.clientY || 0;
      } else {
        moveXStart = e.clientX || 0;
        moveYStart = e.clientY || 0;
      }

      container.addEventListener("mousemove", touchmoveHandler);
      container.addEventListener("touchmove", touchmoveHandler);
    };
    const touchmoveHandler = throttle((e: TouchEvent | MouseEvent) => {
      e.preventDefault();

      if ("touches" in e && e.touches.length === 2) {
        const { left, top } = wrapperRef.current.getBoundingClientRect();
        const { x, y } = translateRef.current;
        const currentPinchDistance = getDistanceBetweenTouches(e);
        const newScale =
          pinchScaleStart * (currentPinchDistance / pinchDistanceStart);
        const newX = (e.touches[0].clientX + e.touches[1].clientX) / 2;
        const newY = (e.touches[0].clientY + e.touches[1].clientY) / 2;
        zoom(newX - left - x, newY - top - y, newScale);
      } else {
        const { x, y, scale } = translateRef.current;
        let moveXCurrent = 0;
        let moveYCurrent = 0;
        if ("touches" in e) {
          moveXCurrent = e.touches[0]?.clientX || 0;
          moveYCurrent = e.touches[0]?.clientY || 0;
        } else {
          moveXCurrent = e.clientX || 0;
          moveYCurrent = e.clientY || 0;
        }
        translate(
          x + (moveXCurrent - moveXStart),
          y + (moveYCurrent - moveYStart),
          scale
        );
        moveXStart = moveXCurrent;
        moveYStart = moveYCurrent;
      }
    }, 10);
    const touchendHandler = (e: TouchEvent | MouseEvent) => {
      container.removeEventListener("mousemove", touchmoveHandler);
      container.removeEventListener("touchmove", touchmoveHandler);
    };
    const wheelHandler = throttle((e: WheelEvent) => {
      e.preventDefault();
      const { x, y, scale } = translateRef.current;
      const { left, top } = wrapperRef.current.getBoundingClientRect();
      zoom(
        e.clientX - left - x,
        e.clientY - top - y,
        e.deltaY < 0 ? scale + scaleStep : scale - scaleStep,
        animationDuration
      );
    }, 50);
    let cursorOverMap = false;
    const mouseenterHandler = (e: MouseEvent) => {
      cursorOverMap = true;
    };
    const mouseleaveHandler = (e: MouseEvent) => {
      cursorOverMap = false;
    };
    const keyHandler = (e: KeyboardEvent) => {
      if (!cursorOverMap) {
        return;
      }
      e.preventDefault();
      const { x, y, scale } = translateRef.current;
      switch (e.keyCode) {
        case 37:
          translate(x + moveStep, y, scale, animationDuration);
          break;
        case 38:
          translate(x, y + moveStep, scale, animationDuration);
          break;
        case 39:
          translate(x - moveStep, y, scale, animationDuration);
          break;
        case 40:
          translate(x, y - moveStep, scale, animationDuration);
          break;
      }
    };
    const resizeHandler = debounce(() => {
      translate(
        translateRef.current.x,
        translateRef.current.y,
        translateRef.current.scale
      );
    }, 100);

    container.addEventListener("mousedown", touchstartHandler);
    container.addEventListener("touchstart", touchstartHandler);
    container.addEventListener("mouseup", touchendHandler);
    container.addEventListener("mouseleave", touchendHandler);
    container.addEventListener("touchend", touchendHandler);
    container.addEventListener("wheel", wheelHandler);
    container.addEventListener("mouseenter", mouseenterHandler);
    container.addEventListener("mouseleave", mouseleaveHandler);
    document.addEventListener("keydown", keyHandler);

    const observer = new ResizeObserver(resizeHandler);
    observer.observe(wrapper);

    return () => {
      container.removeEventListener("mousedown", touchstartHandler);
      container.removeEventListener("touchstart", touchstartHandler);
      container.removeEventListener("mousemove", touchmoveHandler);
      container.removeEventListener("touchmove", touchmoveHandler);
      container.removeEventListener("mouseup", touchendHandler);
      container.removeEventListener("mouseleave", touchendHandler);
      container.removeEventListener("touchend", touchendHandler);
      container.removeEventListener("wheel", wheelHandler);
      container.removeEventListener("mouseenter", mouseenterHandler);
      container.removeEventListener("mouseleave", mouseleaveHandler);
      document.removeEventListener("keydown", keyHandler);
      observer.unobserve(wrapper);
    };
  }, []);

  return (
    <div className="crowd-birthday-10-map" ref={wrapperRef}>
      <div
        className="crowd-birthday-10-map__inner"
        ref={containerRef}
        tabIndex={0}
        style={{ width: mapWidth, height: mapHeight }}
      >
        <div className="crowd-birthday-10-map__bg">
          {layerSrc.map((src, i) => (
            <img key={i} src={src} alt="" />
          ))}
        </div>

        {objects?.map(({ pos: [left, top], children }, i) => (
          <div
            key={i}
            className="crowd-birthday-10-map__block"
            style={{ transform: `translate(${left}px, ${top}px)` }}
          >
            {children}
          </div>
        ))}
      </div>
      <div className="crowd-birthday-10-map__zoom">
        <div
          className={classNames(
            "crowd-birthday-10-map__zoom__plus",
            "promo-icon-plus",
            {
              disabled: isMaxScaled,
            }
          )}
          onClick={() =>
            zoomToCurrentCenter(
              translateRef.current.scale + scaleStep,
              animationDuration
            )}
        />
        <div
          className={classNames(
            "crowd-birthday-10-map__zoom__minus",
            "promo-icon-minus",
            {
              disabled: isMinScaled,
            }
          )}
          onClick={() =>
            zoomToCurrentCenter(
              translateRef.current.scale - scaleStep,
              animationDuration
            )}
        />
      </div>
    </div>
  );
};

export default Map;
