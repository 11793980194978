import { FC, MouseEventHandler } from "react";
import styles from "./Like.module.scss";
import Text from "../../Typography/Text";

export interface LikeProps {
  onClick?: MouseEventHandler<HTMLDivElement>;
  count?: number;
  liked?: boolean;
}

const Like: FC<LikeProps> = ({ onClick, count = 0, liked = false }) => (
  <div className={styles.like} onClick={onClick}>
    <i className={liked ? "promo-icon-91" : "promo-icon-92"} />
    {!!count && (
      <Text size={1} className={styles.text}>
        {count}
      </Text>
    )}
  </div>
);

export default Like;
