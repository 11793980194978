import { BaseResponse } from "../types/Common.interface";
import { API, httpParamsSerialize } from "./api";

export const defaultZoom = 10;

declare global {
  interface Window {
    egip: any;
    ol: any;
  }
}

export interface LocationInfo {
  address: string;
  simpleAddress: string;
  id: string;
  unom: number;
}

export interface Coordinates {
  longitude: number;
  latitude: number;
}

const MapService = {
  createMap: (target, zoom?, overlay?) =>
    window.egip?.layers.createMap({
      target,
      layers: [
        new window.ol.layer.Tile({
          source: new window.ol.source.XYZ({
            url: `${window.egip?.getConfig().getTiles2gisUrl().replace("layerType=nc&", "")}&s=eb089c46-fc8b-4052-befd-9f9e75279aa5&ts=vector_a&key=10153539-2026-4a0c-b7a3-52ddb3fed411&appId=empty`,
          }),
        }),
      ],
      view: window.egip.layers.createViewWGS({
        zoom: zoom || defaultZoom,
        minZoom: 8,
        maxZoom: 20,
        style: "e05ac437-fcc2-4845-ad74-b1de9ce07555",
        defaultBackgroundColor: "#3b3b3b",
      }),
      overlays: [overlay],
    }),

  clearMap: (map) => {
    const vectors = map
      .getLayers()
      .getArray()
      .filter((layer) => layer.type !== "TILE");
    vectors.forEach((vector) => map.removeLayer(vector));
  },

  transformCoords: (cords) =>
    window.ol.proj.transform(cords, "EPSG:3857", "EPSG:4326"),

  getPoints: () =>
    window.egip?.layers.createVectorLayer({
      id: "points",
      type: "random",
      source: window.egip.layers.createVectorSource({}),
    }),

  addMultiplePointLayer: (map, marker, addresses) => {
    const layer = window.egip.layers.createVectorLayer({
      id: "points",
      type: "random",
      source: window.egip.layers.createVectorSource({
        features: addresses.map((adress) => {
          const f = MapService.getFeatureFromCoords(adress.coords);
          f.setStyle(marker);
          f.meta = adress;
          return f;
        }),
      }),
    });

    map.addLayer(layer);
  },

  getEgipCoords: (coordinates: Coordinates) =>
    window.ol?.proj.fromLonLat([coordinates.longitude, coordinates.latitude]),

  pointFeature: (coords) => {
    const feat = new window.ol.Feature(new window.ol.geom.Point(coords));
    feat.setProperties({
      color: window.egip.layers.mock.getRandomColor(),
    });
    feat.setStyle((f, res) => [MapService._marker()]);
    return feat;
  },

  addFeature: (points, coords) => {
    points.getSource().addFeature(MapService.pointFeature(coords));
  },

  getFeatureFromCoords: (coords: Array<string>) =>
    new window.ol.Feature({
      geometry: new window.ol.geom.Point(window.ol.proj.fromLonLat(coords)),
    }),

  _marker: () =>
    new window.ol.style.Style({
      image: new window.ol.style.Icon({
        anchor: [0.5, 1],
        src: require(`../assets/propose-item/pin.png`).default,
        scale: 0.1,
      }),
    }),

  getZoom: (map) => map.getView().getZoom(),

  zoomAnimate: (map, coords, zoom = 15) => {
    const duration = 500;
    const currentZoom = MapService.getZoom(map);
    const _zoom = zoom || 15;
    map.getView().animate(
      {
        zoom: currentZoom - 1,
        duration,
      },
      {
        center: coords,
        duration,
      },
      {
        zoom: !zoom && currentZoom > _zoom ? currentZoom : _zoom,
        duration,
      }
    );

    return true;
  },

  getAddressesByCoords: (coords): Promise<LocationInfo[]> => {
    const [longitude, latitude] = coords;

    const query = {
      latitude,
      longitude,
    };

    const url = `/api/address/v2/getByPoint?${httpParamsSerialize(query)}`;
    return API.get(url).then(({ data }) => {
      const response = data;
      return response.data;
    });
  },

  getCoordinatesByAddress: (address) =>
    API.get<BaseResponse<Coordinates>>(
      `/api/address/v2/getCoordinatesByAddress?address=${address}`
    ).then(({ data }) => data?.data),
};

export default MapService;
