import React from "react";
import dayjs from "dayjs";
import { useMediaQuery } from "react-responsive";

import "./IdeaDetailsCard.scss";
import { useSelector } from "react-redux";
import { Scrollbar } from "react-scrollbars-custom";
import Share from "../../Share/Share";
import { IdeaDetails } from "../../../../types/Idea.interface";
import { GorodIdeaSphere } from "../../../../services/gorodService";

import { tabletWidth } from "../../../../utils/constants/widthConstants";
import {
  canSeeHiddenNames,
  nameIfHidden,
  renderHatIfUserNameIsHidden,
} from "../../../../utils/user.utils";
import { RootState } from "../../../../types/State.interface";
import FlippableCard from "../FlippableCard/FlippableCard";
import { getQuarter } from "../../../../utils/dates";

interface Props {
  sphere: GorodIdeaSphere;
  idea?: IdeaDetails | any;
  regions: any[];
  districts: any[];
  onClick?: (idea) => void;
  page?: number;
}

const IdeaDetailsCard = (props: Props) => {
  const isDesktop = useMediaQuery({ query: `(min-width: ${tabletWidth}px)` });

  const currentUser = useSelector((state: RootState) => state.user.userDetails);

  const handleClick = () => {
    if (props.onClick && typeof props.onClick === "function") {
      props.onClick(props.idea);
    }
  };

  const getRegionById = (id) =>
    props.regions?.find((reg) => reg.externalId === +id);

  const getDistrictById = (id) =>
    props.districts?.find((reg) => reg.externalId === +id);

  const getRegionNameById = (id) => {
    const region = getRegionById(id);
    return (region && `${region.name.replace(/ округ/, "")} АО`) || "";
  };

  const getDistrictNameById = (id) => {
    const district = getDistrictById(id);
    return (district && `, ${district.name} р-н`) || "";
  };

  const getIdeaPlace = (idea) => {
    switch (idea.place) {
      case "ADDRESS":
        return idea.address;
      case "REGION":
      case "DISTRICT":
        return `${getRegionNameById(idea.region)}${getDistrictNameById(idea.district)}`;
      case "CITY":
        return "город Москва";
      default:
        return "";
    }
  };

  const renderStatus = (idea) => (
    <div
      className={`ideadetails-card__status ${idea.completed ? "status-completed" : "status-uncompleted"}`}
    >
      {idea.completed ? (
        <span className="ic promo-icon-108" />
      ) : (
        <span className="ic promo-icon-1102" />
      )}

      {idea.completed ? "Реализовано" : "В реализации"}
    </div>
  );

  const renderDate = (idea) => (
    <div className="ideadetails-card__date">
      {dayjs(idea.created).format("DD.MM.YYYY")}
    </div>
  );

  const getName = () => {
    if (!props.idea.author) return "";
    const { deletedSudir, showName, firstName, lastName } = props.idea.author;
    if (deletedSudir || (!showName && !canSeeHiddenNames(currentUser))) return nameIfHidden;
    return `${props.idea.author.lastName} ${props.idea.author.firstName}`;
  };

  const getOrganizationAndDeadline = (idea) => {
    const determineVerb = (organizationName, completed) => {
      if (!completed) return "реализует";

      const name = organizationName.toLowerCase();
      const verbMap = {
        департамент: "реализовал",
        префектура: "реализовала",
        гку: "реализовало",
        комитет: "реализовал",
      };

      for (const key in verbMap) {
        if (name.includes(key)) {
          return verbMap[key];
        }
      }

      return "реализовал";
    };

    const date = idea.completed
      ? idea.completedDateTime
      : idea.realizedDeadline;
    if (idea.organizationName && date) {
      const verb = determineVerb(idea.organizationName, idea.completed);
      const quarter = getQuarter(date);
      const year = dayjs(date).year();
      return `${idea.organizationName} ${verb} идею в ${quarter} квартале ${year}`;
    }
  };

  const renderFrontContent = () => {
    const { idea } = props;
    return (
      <div className="ideadetails-card frontside">
        <div className="ideadetails-card__header">{renderStatus(idea)}</div>
        <div className="ideadetails-card__body">
          <div className="ideadetails-card__text-container">
            <div className="ideadetails-card__name">
              {getName()}
              {renderHatIfUserNameIsHidden(idea.author, currentUser)}
            </div>
            <div className="ideadetails-card__place">{getIdeaPlace(idea)}</div>
            <div className="ideadetails-card__title">{idea.title}</div>
          </div>
          <div
            className={
              idea.attachmentId
                ? "ideadetails-card__has-image"
                : "ideadetails-card__no-image"
            }
          >
            {idea.attachmentId ? (
              <img
                src={`/uploads/get/${idea.attachmentId}`}
                key={idea.attachmentId}
                alt={`${idea.title}`}
              />
            ) : (
              <img alt={`${idea.title}`} />
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderBackContent = () => {
    const { idea } = props;
    return (
      <div className="ideadetails-card backside">
        <div className="ideadetails-card__header">{renderStatus(idea)}</div>
        <div className="ideadetails-card__organization">
          {idea.organizationName &&
            idea.realizedDeadline &&
            getOrganizationAndDeadline(idea)}
        </div>
        <div className="ideadetails-card__title">{idea.title}</div>
        <Scrollbar className="ideadetails-card__description">
          {idea.description}
        </Scrollbar>
        {idea.completed && (
          <div className="ideadetails-card__footer">
            <Share
              url={`/propose/${props.sphere.code}?page=${props.page + 1}&id=${idea.id}`}
              imageUrl="/images/social_pic.jpg"
              title={`Идея «${idea.title}», предложенная на платформе «Город идей»`}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <FlippableCard
      front={renderFrontContent()}
      back={renderBackContent()}
      flipBackOnTap
    />
  );
};

export default IdeaDetailsCard;
