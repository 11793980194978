import { FC } from "react";
import "./PhotoReportCard.scss";
import Button from "../../Button/Button";
import { MediaPlatformPhotoReport } from "../../../../../../types/MediaPlatform.interface";

type PhotoReportCardProps = {
  photoReport: MediaPlatformPhotoReport;
  onSelectPhotoReport: (photoReport: MediaPlatformPhotoReport) => void;
};

const PhotoReportCard: FC<PhotoReportCardProps> = ({
  photoReport,
  onSelectPhotoReport = () => {},
}) => {
  const { coverId, name } = photoReport;

  return (
    <div className="photo-report-card">
      <div className="photo-report-card__header">
        <div className="photo-report-card__img">
          <img src={`/uploads/get/${coverId}`} alt="" />
        </div>
      </div>
      <div className="photo-report-card__footer">
        <div
          className="photo-report-card__text"
          dangerouslySetInnerHTML={{ __html: name }}
        />
        <Button
          onClick={() => onSelectPhotoReport(photoReport)}
          text="Подробнее"
          type="outlined"
        />
      </div>
    </div>
  );
};

export default PhotoReportCard;
