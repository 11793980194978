import React, { FC, useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { RootState } from "../../../../../types/State.interface";
import ProposeIdeaForm from "../../../../containers/Forms/ProposeIdeaForm/ProposeIdeaForm";
import { proposeIdeaData } from "../../mocks/proposeIdea";
import GorodService, {
  GorodIdeaCategory,
  GorodIdeaSphere,
} from "../../../../../services/gorodService";
import CommonSlider from "../../../../presentational/Sliders/CommonSlider/CommonSlider";
import ProposeIdeaSubmittedForm from "../../../../containers/Forms/ProposeIdeaForm/ProposeIdeaSubmittedForm";
import styles from "./ProposeIdea.module.scss";
import { AppContext } from "../../../../Root";
import {
  AuthActionParam,
  AuthActionType,
  ComponentType,
  AuthAction,
} from "../../../../../services/sudirService";
import { useIsMobile, useIsWideTablet } from "../../../../../hooks/useMedia";
import { selectSpheres } from "../../../../../store/selectors/gorod";
import { selectIsNeedAfterRegistrationForm } from "../../../../../store/selectors/profile";
import useDiscussionLandingContext from "../../hooks/useDiscussionLandingContext";
import MediaPlatformService from "../../../../../services/mediaPlatform.service";
import { LandingIdeaTopicDto } from "../../../../../types/MediaPlatform.interface";
import { MediaPlatformSections } from "../../routing/routes";

export interface ProposeIdeaProps {
  onLoadEnd?: (data) => void;
}

const ProposeIdea: FC<ProposeIdeaProps> = ({ onLoadEnd }) => {
  const [isProposeModalOpen, setIsProposeModalOpen] = useState(false);
  const [selectedSphere, setSelectedSphere] = useState<GorodIdeaSphere | null>(
    null
  );

  const [landingIdeaTopics, setLandingIdeaTopics] = useState<(LandingIdeaTopicDto & { category: any })[] | null
  >(null);

  const discussionLandingContext = useDiscussionLandingContext();

  const [selectedCategory, setSelectedCategory] =
    useState<GorodIdeaCategory | null>(null);
  const loggedIn: boolean = useSelector(
    (state: RootState) => state.user.loggedIn
  );
  const isNeedAfterRegistrationForm = useSelector(
    selectIsNeedAfterRegistrationForm
  );
  const spheres: GorodIdeaSphere[] = useSelector(selectSpheres);
  const isPhone = useIsMobile();
  const isTablet = useIsWideTablet();
  const [isSubmittedProposeModalOpen, setIsSubmittedProposeModalOpen] =
    useState(false);
  const appContext = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    if (spheres) {
      const categories = spheres.reduce(
        (acc, sphere) => acc.concat(sphere.categories),
        []
      );
      const comparisonArray = proposeIdeaData;

      const filteredCategories = categories.reduce((acc, category) => {
        if (comparisonArray.some((idea) => idea.code === category.code)) {
          acc.push(category);
        }
        return acc;
      }, []);

      MediaPlatformService.getAllByMediaPlatformId({
        mediaPlatformId: discussionLandingContext.id,
      })
        .then((res) => {
          const topics = res.data.filter((topic) => topic.published);

          const topicsWitchCategories = topics.map((topic) => ({
            ...topic,
            category: categories.find(
              (category) => category.id === topic.gorodIdeaCategoryId
            ),
          }));

          setLandingIdeaTopics(
            topicsWitchCategories.filter((topic) => topic.category)
          );
        })
        .catch(console.error);
      onLoadEnd?.(filteredCategories);
    }
  }, [spheres]);

  useEffect(() => {
    if (!spheres?.length || isNeedAfterRegistrationForm) return;

    const actions = appContext.sudirService.getActions(
      ComponentType.PROPOSE_IDEA_PAGE
    );
    const openProposeFormAction = actions.find(
      (act) => act.type === AuthActionType.OPEN_PROPOSE_MODAL
    );
    if (openProposeFormAction) {
      const { code, categoryCode } = openProposeFormAction.args;
      const sphere = GorodService.getSphereByCode(code, spheres);
      const category =
        sphere && GorodService.getCategoryByCode(sphere, categoryCode);
      setSelectedSphere(sphere);
      setSelectedCategory(category);
      setIsProposeModalOpen(true);
      history.push(`${window.location.pathname}?section=ProposeIdea`);
    }
  }, [isNeedAfterRegistrationForm, spheres]);

  const getSphereByCategoryCode = (category) => {
    if (spheres && category && category.groupId) {
      const { groupId } = category;
      return spheres.find((sphere) => sphere.id === groupId);
    }
    return null;
  };

  const handleButtonClick = (category) => {
    const sphere = getSphereByCategoryCode(category);
    if (!loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.PROPOSE_IDEA_PAGE,
        type: AuthActionType.OPEN_PROPOSE_MODAL,
        args: {
          code: sphere?.code || null,
          categoryCode: category?.code || null,
        },
      };
      const joinGameAction = new AuthAction(thisContainerData);
      return appContext.sudirService.authWithActions([joinGameAction]);
    }
    setSelectedSphere(sphere);
    setSelectedCategory(category);
    setIsProposeModalOpen(true);
  };

  const handleOpenPropose = (sphere) => {
    setSelectedSphere(sphere);
    setIsProposeModalOpen(true);
  };

  // SWIPER
  const handleOnInit = () => {};
  const handleOnSlideChange = () => {};

  const renderCategoryButton = ({
    category,
    key,
    backgroundColor = "blue",
  }: {
    category: any;
    key?: number | string;
    backgroundColor: string;
  }) => (
    <button
      type="button"
      style={{
        backgroundColor: `#${backgroundColor.replace("#", "")}`,
      }}
      className={styles["category-button"]}
      key={key}
      onClick={() => handleButtonClick(category)}
    >
      <span>{category.title}</span>
    </button>
  );

  const createSlide = ({
    topics,
    slideKey,
    className,
  }: {
    topics: (LandingIdeaTopicDto & { category: any })[];
    slideKey: number;
    className: string;
  }) => (
    <div className={classNames(styles.grid, className)} key={slideKey}>
      {topics.map((topic, index) =>
        renderCategoryButton({
          category: topic.category,
          key: index,
          backgroundColor: topic.color,
        })
      )}
    </div>
  );

  const groupArr = (arr: any[], chunkSize: number) => {
    const result: any[] = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }

    return result;
  };

  const renderTabletView = () => {
    const groupedTopicsList = groupArr(landingIdeaTopics, 4);

    const slides = groupedTopicsList.map((groupedTopics, i) =>
      createSlide({
        topics: groupedTopics,
        slideKey: i,
        className: classNames({
          [`${styles["grid-col-1"]} ${styles["grid-row-1"]}`]:
            landingIdeaTopics.length === 1,
          [`${styles["grid-col-2"]} ${styles["grid-row-1"]}`]:
            landingIdeaTopics.length === 2,
          [`${styles["grid-col-2"]} ${styles["grid-row-2"]}`]:
            landingIdeaTopics.length >= 3,
        }),
      })
    );

    return (
      <CommonSlider
        className="discussion__swiper"
        onInit={handleOnInit}
        onSlideChange={handleOnSlideChange}
        effect="fade"
        options={{
          spaceBetween: 24,
          slidesPerColumn: 1,
          slidesPerView: 1,
          slidesPerGroup: 1,
        }}
      >
        {slides}
      </CommonSlider>
    );
  };

  const renderPhoneView = () => {
    const groupedTopicsList = groupArr(landingIdeaTopics, 2);

    const slides = groupedTopicsList.map((groupedTopics, i) =>
      createSlide({
        topics: groupedTopics,
        slideKey: i,
        className: classNames(styles["grid-col-1"], {
          [styles["grid-row-2"]]: landingIdeaTopics.length >= 2,
          [styles["grid-row-1"]]: landingIdeaTopics.length < 2,
        }),
      })
    );

    return (
      <CommonSlider
        className="discussion__swiper"
        onInit={handleOnInit}
        onSlideChange={handleOnSlideChange}
        effect="fade"
        options={{
          spaceBetween: 24,
          slidesPerColumn: 1,
          slidesPerView: 1,
          slidesPerGroup: 1,
        }}
      >
        {slides}
      </CommonSlider>
    );
  };

  const renderDesktopView = () => (
    <div
      className={classNames(styles.grid, {
        [styles[`grid-col-${landingIdeaTopics.length}`]]:
          landingIdeaTopics.length < 4,
        [styles["grid-col-4"]]: landingIdeaTopics.length >= 4,
      })}
    >
      {landingIdeaTopics.map((topic, index) =>
        renderCategoryButton({
          category: topic.category,
          key: index,
          backgroundColor: topic.color,
        })
      )}
    </div>
  );

  if (!landingIdeaTopics?.length) {
    return null;
  }

  return (
    <section id={MediaPlatformSections.SUGGEST_IDEA}>
      <div className="discussion-landing-title">
        ПРЕДЛАГАЙТЕ ИДЕИ В ЛЮБОЕ ВРЕМЯ
      </div>
      <div className={classNames(styles["buttons-container"])}>
        {isPhone
          ? renderPhoneView()
          : isTablet
            ? renderTabletView()
            : renderDesktopView()}
      </div>

      {isProposeModalOpen && (
        <ProposeIdeaForm
          sphere={selectedSphere}
          category={selectedCategory}
          isOpen={isProposeModalOpen}
          onClose={() => setIsProposeModalOpen(false)}
          setIsSubmittedProposeModalOpen={setIsSubmittedProposeModalOpen}
          fromLanding
        />
      )}
      {isSubmittedProposeModalOpen && (
        <ProposeIdeaSubmittedForm
          isOpen={isSubmittedProposeModalOpen}
          onClose={() => setIsSubmittedProposeModalOpen(false)}
          handleOpenPropose={handleOpenPropose}
        />
      )}
    </section>
  );
};

export default ProposeIdea;
