import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import {
  BigPlayButton,
  ControlBar,
  Player,
  PlayerReference,
} from "video-react";
import styles from "./VideoPreview.module.scss";

interface VideoPreviewProps {
  videoPreviewId: string;
  containerClassName?: string;
}

export const VideoPreview = ({
  videoPreviewId,
  containerClassName,
}: VideoPreviewProps) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const playerRef = useRef<PlayerReference | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsPlaying(entry.isIntersecting);

        if (!entry.isIntersecting && playerRef.current) {
          playerRef.current.seek(0);
        }
      },
      { threshold: 1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (playerRef.current) {
      if (isPlaying) {
        playerRef.current.play();
      } else {
        playerRef.current.pause();
      }
    }
  }, [isPlaying]);

  return (
    <div ref={containerRef} className={classNames(containerClassName)}>
      <Player muted ref={playerRef} playsInline key={videoPreviewId}>
        <source src={`/uploads/get/${videoPreviewId}`} />
        <BigPlayButton
          className={styles["original-play-button"]}
          position="center"
        />
        <ControlBar disableDefaultControls className={styles["control-bar"]} />
      </Player>
      <div className={styles["play-icon"]} />
    </div>
  );
};
