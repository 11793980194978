import { MutableRefObject, useEffect } from "react";
import { throttle } from "../utils";

interface TProps {
  ref: MutableRefObject<HTMLElement>;
  cb: () => void;
  active: boolean;
}

export const usePointerLeave = ({ ref, cb, active }: TProps) => {
  useEffect(() => {
    const handlePointerOutside = throttle((event: PointerEvent) => {
      if (
        active &&
        ref.current &&
        !ref.current.contains(event.target as Node)
      ) {
        const rect = ref.current.getBoundingClientRect();
        const buffer = 20;

        if (
          event.clientX < rect.left - buffer ||
          event.clientX > rect.right + buffer ||
          event.clientY < rect.top - buffer ||
          event.clientY > rect.bottom + buffer
        ) {
          cb();
        }
      }
    }, 200);

    document.addEventListener("pointerdown", handlePointerOutside);
    document.addEventListener("pointermove", handlePointerOutside);

    return () => {
      document.removeEventListener("pointerdown", handlePointerOutside);
      document.removeEventListener("pointermove", handlePointerOutside);
    };
  }, [ref, cb, active]);
};
