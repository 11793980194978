import { API } from "./api";
import { BaseResponse, PaginationResponse } from "../types/Common.interface";
import { GroupDirection } from "../types/GroupDirection.interface";
import { queryBuilder } from "../utils/url";
import { LandingInfo } from "../components/pages/DiscussionLanding/DiscussionLanding.interface";
import { Activity } from "../components/pages/DiscussionLanding/components/Activities/Activity.interface";
import {
  Comment,
  Discussion,
} from "../components/pages/DiscussionLanding/components/Discussions/Discussion.interface";
import { Stream } from "../components/pages/DiscussionLanding/components/StreamsAndOnlineMeetings/StreamsAndOnlineMeetings.interface";
import {
  LandingIdeaTopicDto,
  MediaPlatformDto,
  MediaPlatformIdeaCategory,
  MediaPlatformNews,
  MediaPlatformPhotoReport,
  MediaPlatformProposedIdea,
  MediaPlatformReview,
  MediaPlatformThematicProject,
  TextSettingsDto,
} from "../types/MediaPlatform.interface";

export interface GetTopCommentsQuery {
  stageId: string;
}

export interface GetLastCommentsQuery {
  stageId: string;
  size: number;
}

export interface VoteCommentQuery {
  id: string;
}

export interface BaseRegisterOnEventQuery {
  firstName: string;
  lastName: string;
  email: string;
}

export type RegisterOnEventQuery<T extends string> =
  BaseRegisterOnEventQuery & {
    [key in T]: string;
  };

export interface GetBroadcastRegistartionListQuery {
  broadcastIds: string;
}

export interface GetEventRegistartionListQuery {
  eventIds: string;
}

export interface GetUsersLikedListQuery {
  commentId: string;
  page: number;
  size: number;
}

export interface GetOnlineStreamsQuery {
  mediaPlatformId: string | number;
  page?: number;
  size?: number;
}

export interface ExpertQuoteResponse {
  id: string;
  text: string;
  expertFullName: string;
  expertPosition1: string;
  expertPosition2: string;
  showQuote: boolean;
  desktopImageId: string;
  mobileImageId: string;
  tabletImageId: string;
}

interface MediaPlatformBaseParams {
  mediaPlatformId: string | number;
}

const MediaPlatformService = {
  async getAboutUs(params: MediaPlatformBaseParams) {
    const { data } = await API.get<BaseResponse<MediaPlatformReview[]>>(
      `/api/aboutUs/getAll`,
      { params }
    );
    return data;
  },

  async getBroadcasts(params: MediaPlatformBaseParams) {
    const { data } = await API.get<BaseResponse<Stream[]>>(
      `/api/broadcast/getAll`,
      { params }
    );
    return data;
  },

  async getEvents(params: MediaPlatformBaseParams) {
    const { data } = await API.get<BaseResponse<Activity[]>>(
      `/api/event/getAll`,
      { params }
    );
    return data;
  },

  async getThematicProjects(params: MediaPlatformBaseParams) {
    const { data } = await API.get<
      BaseResponse<MediaPlatformThematicProject[]>
    >("/api/thematicProject/getAll", { params });
    return data;
  },

  async getSimpleDiscussions() {
    const { data } = await API.get<BaseResponse<Discussion[]>>(
      `/api/project/getSimpleDiscussions`
    );
    return data;
  },

  async getTopComments(query: GetTopCommentsQuery) {
    const { data } = await API.get<BaseResponse<Comment[]>>(
      `/api/discussion/getTopComments${queryBuilder(query)}`
    );
    return data;
  },

  async getLastComments(query: GetLastCommentsQuery) {
    const { data } = await API.get<PaginationResponse<Comment[]>>(
      `/api/discussion/getLastComments${queryBuilder(query)}`
    );
    return data;
  },

  async getInfo(params: MediaPlatformBaseParams) {
    const { data } = await API.get<BaseResponse<LandingInfo>>(
      "/api/landingInfo/get",
      { params }
    );
    return data;
  },

  async getCounter(mediaPlatformId: string) {
    const { data } = await API.get<BaseResponse<number>>(
      `/api/mediaPlatform/counter/get?mediaPlatformId=${mediaPlatformId}`
    );
    return data;
  },

  async getPhotoReports(params: MediaPlatformBaseParams) {
    const { data } = await API.get<BaseResponse<MediaPlatformPhotoReport[]>>(
      "/api/photoReport/getAll",
      { params }
    );
    return data;
  },

  async getNews(
    params: MediaPlatformBaseParams & { page?: number; size?: number }
  ) {
    const { data } = await API.get<BaseResponse<MediaPlatformNews[]>>(
      "/api/landingNews/getAll",
      { params }
    );
    return data;
  },

  async voteComment(query: VoteCommentQuery, isLike: boolean) {
    const { data } = await API.post<BaseResponse>(
      `/api/discussion/comment/vote?like=${isLike}`,
      query
    );
    return data;
  },

  async getIdeasRating(
    params: MediaPlatformBaseParams & { page?: number; size?: number }
  ) {
    const { data } = await API.get<
      PaginationResponse<MediaPlatformIdeaCategory[]>
    >("/api/category/getAll", {
      params,
    });
    return data;
  },

  async getIdeaByByCategoryIds(ids: Array<string>) {
    const query = ids
      .reduce((prev, id) => `${prev}categoryIds=${id}&`, "?")
      .slice(0, -1);

    const { data } = await API.get<BaseResponse<MediaPlatformProposedIdea[]>>(
      `/api/category/idea/getByCategoryIds${query}`
    );
    return data;
  },

  async projectGroupDirectionGetAll(projectId) {
    const { data } = await API.get<BaseResponse<GroupDirection[]>>(
      `/api/projectGroupDirection/getAll?projectId=${projectId}`
    );
    return data;
  },

  async projectGroupDirectionGetById(groupDirectionId) {
    const { data } = await API.get<BaseResponse<GroupDirection>>(
      `/api/projectGroupDirection/getById?id=${groupDirectionId}`
    );
    return data;
  },

  async registerOnBroadcast(query: RegisterOnEventQuery<"broadcastId">) {
    const { data } = await API.post<BaseResponse>(
      "/api/broadcast/register",
      query
    );
    return data;
  },

  async registerOnEvent(query: RegisterOnEventQuery<"eventId">) {
    const { data } = await API.post<BaseResponse>("/api/event/register", query);
    return data;
  },

  async getBrodcastRegistrationList(query: GetBroadcastRegistartionListQuery) {
    const { data } = await API.get<
      BaseResponse<{ broadcastId: string; registered: boolean }[]>
    >(`/api/broadcast/registration/getCurrent${queryBuilder(query)}`);
    return data;
  },

  async getEventRegistrationList(query: GetEventRegistartionListQuery) {
    const { data } = await API.get<
      BaseResponse<{ eventId: string; registered: boolean }[]>
    >(`/api/event/registration/getCurrent${queryBuilder(query)}`);
    return data;
  },

  async getUsersLikedList(query: GetUsersLikedListQuery) {
    const { data } = await API.get<PaginationResponse<[]>>(
      `/api/comments/vote/users${queryBuilder(query)}`
    );
    return data;
  },

  async getOnlineStreams(query: GetOnlineStreamsQuery) {
    const { data } = await API.get<PaginationResponse<[]>>(
      `/api/onlineStream/getAll${queryBuilder(query)}`
    );
    return data;
  },

  async getExpertQuote(params: MediaPlatformBaseParams) {
    const { data } = await API.get<BaseResponse<ExpertQuoteResponse>>(
      "/api/expertQuote/get",
      { params }
    );
    return data;
  },

  async getAll() {
    const { data } = await API.get<BaseResponse<MediaPlatformDto[]>>(
      "/api/mediaPlatform/getAll"
    );
    return data;
  },

  async getTexts(params: MediaPlatformBaseParams) {
    const { data } = await API.get<BaseResponse<TextSettingsDto>>(
      "/api/mediaPlatform/textSettings/get",
      { params }
    );
    return data;
  },

  async getAllByMediaPlatformId(params: MediaPlatformBaseParams) {
    const { data } = await API.get<BaseResponse<LandingIdeaTopicDto[]>>(
      "/api/landingIdeaTopic/getAllByMediaPlatformId",
      { params }
    );
    return data;
  },

  async getByFilter(query) {
    const url = `/api/idea/mediaPlatform/getByFilter${queryBuilder(query)}`;
    const { data } = await API.post(url);
    return data;
  },

  async getMediaPlatformIdeasCount(params: MediaPlatformBaseParams) {
    const { data } = await API.get<BaseResponse<number>>(
      `/api/mediaPlatform/counter/ideas`,
      { params }
    );
    return data;
  },
};

export default MediaPlatformService;
