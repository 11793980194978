import React, { useState, Children, PropsWithChildren } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { useMediaQuery } from "react-responsive";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.css";

import "./CommonSlider.scss";
import { Link } from "react-router-dom";
import classNames from "classnames";
import {
  phoneWidth,
  tabletWidth,
} from "../../../../utils/constants/widthConstants";
import SliderArrows, {
  ArrowOptions,
  SliderArrowsProps,
} from "../SliderArrows/SliderArrows";
import { useForceUpdate } from "../../../../hooks/useForceUpdate";

export interface CommonSliderOptions {
  autoPlay?: any;
  autoHeight?: boolean;
  allowTouchMove?: boolean;
  touchStartPreventDefault?: boolean;
  loop?: boolean;
  spaceBetween?: number;
  slidesPerView?: number;
  slidesPerColumn?: number;
  slidesPerGroup?: number;
  width?: number;
  centeredSlides?: boolean;
  centerInsufficientSlides?: boolean;
}

export interface LinkOptions {
  text: string;
  linkUrl: string;
}

export interface CommonSliderProps {
  options?: CommonSliderOptions;
  linkOptions?: LinkOptions;
  arrowOptions?: ArrowOptions;
  className: string;
  arrowsContainerClassName?: string;
  effect?: "fade";
  containterClassName?: string;
  onSlideClick?: () => {};
  onSlideChange?: (sw: SwiperCore) => void;
  onInit?: Swiper["onInit"];
  onSwiper?: Swiper["onSwiper"];
  onResize?: Swiper["onResize"];
  pagination?: SliderArrowsProps["pagination"];
}

const CommonSlider = ({
  options,
  linkOptions,
  arrowOptions,
  children,
  className,
  arrowsContainerClassName,
  containterClassName,
  onSlideChange,
  onInit,
  onSwiper,
  onResize,
  pagination,
  effect,
}: PropsWithChildren<CommonSliderProps>) => {
  const isDesktop = useMediaQuery({ query: `(min-width: ${tabletWidth}px)` });
  const isTablet = useMediaQuery({
    query: `(max-width: ${tabletWidth}px) and (min-width: ${phoneWidth}px)`,
  });

  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>(null);
  const forceUpdate = useForceUpdate();

  const onSwiperCb = (sw: SwiperCore) => {
    setSwiperInstance(sw);
    onSwiper?.(sw);
  };

  const onSlideChangeCb = (swiper) => {
    if (onSlideChange) {
      onSlideChange(swiper);
    }
    forceUpdate();
  };

  const isSlideVisible = (index) => {
    if (swiperInstance) {
      if (effect !== "fade") return true;
      if (options.slidesPerView % 1 !== 0) return true;

      const slidesPerGroup = options.slidesPerGroup || 1;
      const slidesPerView = options.slidesPerView || 3;
      if (slidesPerGroup === 1) {
        return (
          index >= swiperInstance?.realIndex &&
          index < swiperInstance?.realIndex + slidesPerView
        );
      }
      const visibleCount = slidesPerView;
      const groupCount = slidesPerGroup || visibleCount;
      const currentIndex = swiperInstance?.realIndex || 0;
      const start = Math.floor(currentIndex / groupCount) * groupCount;
      const end = start + groupCount;
      return index >= start && index < end;
    }
  };

  const defaultSlidesPerView = () => (isDesktop ? 3 : isTablet ? 2 : 1);

  const showArrows = () => {
    const childrenCount = Children.count(children);
    const hasChildrenToSlide = childrenCount > 1;
    let limitedTo = defaultSlidesPerView();
    const slidesPerView = options?.slidesPerView || defaultSlidesPerView();
    const slidesPerColumn = options?.slidesPerColumn || null;
    if (slidesPerView && slidesPerColumn) {
      limitedTo = slidesPerView * slidesPerColumn;
    }
    return hasChildrenToSlide && limitedTo < childrenCount;
  };

  const renderSlider = () => (
    <Swiper
      autoplay={{
        delay: 200000,
        disableOnInteraction: false,
        ...options?.autoPlay,
      }}
      touchStartPreventDefault={options?.touchStartPreventDefault ?? true}
      allowTouchMove={
        typeof options?.allowTouchMove === "undefined"
          ? true
          : options.allowTouchMove
      }
      centeredSlides={options?.centeredSlides}
      centerInsufficientSlides={options?.centerInsufficientSlides}
      onInit={onInit}
      onSwiper={onSwiperCb}
      onSlideChange={onSlideChangeCb}
      loop={Boolean(options?.loop) && showArrows()}
      slidesPerView={options?.slidesPerView || defaultSlidesPerView()}
      slidesPerColumn={options?.slidesPerColumn || null}
      slidesPerGroup={options?.slidesPerGroup || defaultSlidesPerView()}
      spaceBetween={
        options?.spaceBetween === 0 ? 0 : options?.spaceBetween || 24
      }
      autoHeight={options?.autoHeight}
      slidesPerColumnFill="row"
      observer
      observeParents
      containerModifierClass={`${containterClassName} `}
      {...(onResize ? { onResize } : {})}
    >
      {Children.map(children, (element, index) => {
        const slideProps = {
          key: index,
        };
        return (
          <SwiperSlide
            key={index}
            className={classNames({
              "visible-slide": isSlideVisible(index),
              "invisible-slide": !isSlideVisible(index),
            })}
          >
            <div {...slideProps}>{element}</div>{" "}
          </SwiperSlide>
        );
      })}
    </Swiper>
  );

  return (
    <div
      className={classNames(
        "common-slider",
        className,
        { [`common-slider--no-controls`]: !showArrows() },
        { [`common-slider--${effect}`]: effect }
      )}
    >
      {renderSlider()}
      <div className="common-slider__footer">
        {showArrows() && (
          <SliderArrows
            className={arrowsContainerClassName}
            swiper={swiperInstance}
            arrowOptions={arrowOptions}
            pagination={pagination}
          />
        )}

        {linkOptions && (
          <Link to={linkOptions.linkUrl} className="common-slider__link">
            {linkOptions.text}
          </Link>
        )}
      </div>
    </div>
  );
};

export default CommonSlider;
