import { FC, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useRouteMatch } from "react-router-dom";
import "./PhotoReport.scss";

import SwiperCore from "swiper";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import { displayError } from "../../../../../utils";
import CommonSlider from "../../../../presentational/Sliders/CommonSlider/CommonSlider";
import {
  phoneWidth,
  tabletWidth,
} from "../../../../../utils/constants/widthConstants";
import PhotoReportCard from "./PhotoReportCard/PhotoReportCard";
import MediaPlatformService from "../../../../../services/mediaPlatform.service";
import PhotoReportModal from "../PhotoReportModal/PhotoReportModal";
import useDiscussionLandingContext from "../../hooks/useDiscussionLandingContext";
import { MediaPlatformPhotoReport } from "../../../../../types/MediaPlatform.interface";
import { MediaPlatformSections } from "../../routing/routes";

export interface PhotoReportsProps {
  onLoadEnd?: (data) => void;
}

const PhotoReports: FC<PhotoReportsProps> = ({ onLoadEnd }) => {
  const discussionLandingContext = useDiscussionLandingContext();
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const isTablet = useMediaQuery({
    query: `(max-width: ${tabletWidth}px) and (min-width: ${phoneWidth}px)`,
  });
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>(null);
  const [photoReports, setPhotoReports] = useState<
    Array<MediaPlatformPhotoReport>
  >([]);
  const [selectedPhotoReport, setSelectedPhotoReport] = useState(null);

  const routeMath = useRouteMatch<{ id: string }>(
    `${discussionLandingContext.url}/photo-report/:id`
  );

  const fetchData = async () => {
    try {
      const response = await MediaPlatformService.getPhotoReports({
        mediaPlatformId: discussionLandingContext.id,
      });
      if (response.status === ApiStatusCode.OK) {
        const photoReports = response.data.filter(
          (photoReport) => photoReport.published
        );
        setPhotoReports(photoReports);

        if (routeMath && !selectedPhotoReport) {
          const photoReport = photoReports.find(
            (report) => report.id === routeMath.params.id
          );
          if (photoReport) {
            handleSelectPhotoReport(photoReport);
          }
        }
      } else {
        displayError("Ошибка при получении фотоотчетов", response.message);
      }
    } catch (error) {
      displayError("PhotoReport", "Ошибка при получении фотоотчетов");
    } finally {
      onLoadEnd([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [discussionLandingContext.id]);

  const handleOnInit = () => {};
  const handleOnSlideChange = () => {};

  const handleSelectPhotoReport = (photoReport: MediaPlatformPhotoReport) => {
    window.history.pushState(
      {},
      "",
      `${discussionLandingContext.url}/photo-report/${photoReport.id}`
    );
    setSelectedPhotoReport(photoReport);
  };

  const handleModalClose = () => {
    window.history.pushState({}, "", discussionLandingContext.url);
    setSelectedPhotoReport(null);
  };

  return photoReports.length ? (
    <section
      className="discussion-landing-photo-report"
      id={MediaPlatformSections.PHOTO_REPORT}
    >
      <div className="discussion-landing-subtitle">Фотоотчет</div>

      <CommonSlider
        onInit={handleOnInit}
        onSwiper={setSwiperInstance}
        onSlideChange={handleOnSlideChange}
        className="notifications__swiper"
        containterClassName="box-shadow-padding"
        options={{
          loop: true,
          spaceBetween: 24,
          slidesPerView: isTablet ? 2 : isPhone ? 1 : 3,
          slidesPerColumn: 1,
          slidesPerGroup: 1,
        }}
      >
        {photoReports.map((photoReport) => (
          <PhotoReportCard
            key={photoReport.id}
            photoReport={photoReport}
            onSelectPhotoReport={handleSelectPhotoReport}
          />
        ))}
      </CommonSlider>

      {selectedPhotoReport && (
        <PhotoReportModal
          photoReport={selectedPhotoReport}
          onClose={handleModalClose}
        />
      )}
    </section>
  ) : null;
};

export default PhotoReports;
