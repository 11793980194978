import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import classNames from "classnames";
import Slide from "../../../presentational/Sliders/TrioSlider/Slide/Slide";
import TrioSlider from "../../../presentational/Sliders/TrioSlider/TrioSlider";
import {
  formatProjectDates,
  calculateRemainedTime,
  projectIsAnnouncement,
  projectIsCurrent,
  projectIsFinished,
  numberWithSpaces,
  userIsProjectParticipant,
  isTeamMember,
  trimSpaces,
  stripHTML,
} from "../../../../utils/index";
import { showInfoModal } from "../../../../store/actions/LayoutActions";
import { Banner } from "../../../../types/Banner.interface";
import { getUserProjectsState } from "../../../../store/actions/UserActions";
import { AppContext } from "../../../Root";
import { ProjectTypeEnum } from "../../../../types/Projects.interface";
import {
  AuthAction,
  AuthActionParam,
  AuthActionType,
  ComponentType,
} from "../../../../services/sudirService";
import PointsService from "../../../../services/pointsService";
import ProposeIdeaSubmittedForm from "../../../containers/Forms/ProposeIdeaForm/ProposeIdeaSubmittedForm";
import ProposeIdeaForm from "../../../containers/Forms/ProposeIdeaForm/ProposeIdeaForm";
import GorodService, {
  GorodIdeaSphere,
} from "../../../../services/gorodService";
import EventsTrackWrapperScroll from "../../../containers/EventsTrack/wrappers/EventsTrackWrapperScroll";
import EventsTrackWrapperClick from "../../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";
import Share from "../../../presentational/Share/Share";
import {
  selectIsNeedAfterRegistrationForm,
  selectUserDetails,
} from "../../../../store/selectors/profile";
import styles from "./BannersSection.module.scss";
import { useIsMobile, useIsTablet } from "../../../../hooks/useMedia";
import Text from "../../../presentational/Typography/Text";
import Button from "../../../presentational/Controls/Button/Button";
import { selectCurrentProjects } from "../../../../store/selectors/project";
import { selectBanners } from "../../../../store/selectors/banner";
import { selectSpheres } from "../../../../store/selectors/gorod";

export interface BannersSectionProps {
  onInitTrio?: (instance: any) => void;
}

SwiperCore.use([Autoplay]);

const BannersSection = (props: BannersSectionProps) => {
  const appContext = useContext(AppContext);

  const isPhone = useIsMobile();
  const isTablet = useIsTablet();

  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(selectUserDetails);
  const currentProjects = useSelector(selectCurrentProjects);
  const spheres: GorodIdeaSphere[] = useSelector(selectSpheres);
  const banners = useSelector(selectBanners);

  const [selectedSphere, setSelectedSphere] = useState<GorodIdeaSphere>(null);
  const [banner, setBanner] = useState<Banner | null>(null);
  const [isProposeModalOpen, setIsProposeModalOpen] = useState<boolean>(false);
  const [isSubmittedProposeModalOpen, setIsSubmittedProposeModalOpen] =
    useState<boolean>(false);
  const isNeedAfterRegistrationForm = useSelector(
    selectIsNeedAfterRegistrationForm
  );

  useEffect(() => {
    if (user.loggedIn) {
      dispatch(getUserProjectsState(currentProjects.map((p) => p.id)));
    }
  }, [user.loggedIn]);

  useEffect(() => {
    if (banners?.length) {
      setBanner((prev) => prev || banners[0]);
    }
  }, [banners]);

  // ACTIONS AFTER AUTH
  useEffect(() => {
    if (!spheres?.length) return;
    if (isNeedAfterRegistrationForm) return;

    const actions = appContext.sudirService.getActions(
      ComponentType.MAIN_BANNER_SECTION
    );
    if (actions.length) {
      const redirectAction = actions.find(
        (act) => act.type === AuthActionType.REDIRECT
      );
      if (redirectAction) {
        if (redirectAction.args.absolutePath) {
          const { projectId, projectName, redirectLink } = redirectAction.args;

          PointsService.getWelcomePointsMessage(
            user,
            projectName,
            ProjectTypeEnum.CURRENT,
            projectId
          ).then((message) => {
            const handler = () => {
              window.location.href = redirectLink;
            };

            return dispatch(
              showInfoModal(message, renderOkButton(handler), handler)
            );
          });
        } else {
          history.push(redirectAction.args.redirectLink);
        }
      }

      const _openProposeModal = actions.find(
        (act) => act.type === AuthActionType.OPEN_PROPOSE_MODAL
      );
      if (_openProposeModal) {
        const { code } = _openProposeModal.args;
        const sphere = GorodService.getSphereByCode(code, spheres);
        openProposeModal(sphere);
      }
    }
  }, [isNeedAfterRegistrationForm, spheres]);

  const participateInProject = (e, projectId, link) => {
    e && e.preventDefault();

    if (!user.loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.MAIN_BANNER_SECTION,
        type: AuthActionType.REDIRECT,
        args: {
          projectId,
          redirectLink: link,
        },
      };

      const projectContainerData: AuthActionParam = {
        component: ComponentType.GENERIC_PROJECT_CONTAINER,
        type: AuthActionType.JOIN_PROJECT,
        args: {
          projectId,
          redirectLink: link,
        },
      };

      const mainContainerData: AuthActionParam = {
        component: ComponentType.MAIN,
        type: AuthActionType.REDIRECT_AFTER_REG_FORM,
        args: {
          projectId,
          redirectLink: link,
        },
      };

      const action1 = new AuthAction(thisContainerData);
      const action2 = new AuthAction(projectContainerData);
      const action3 = new AuthAction(mainContainerData);

      return appContext.sudirService.authWithActions([
        action1,
        action2,
        action3,
      ]);
    }

    history.push({
      pathname: "announcement",
      search: `id=${projectId}`,
      state: {
        participate: true,
        projectId,
      },
    });
  };

  const toPlatform = (e, banner) => {
    e && e.preventDefault();

    const projectId = banner.projectInfo.id;
    const projectName =
      banner.projectInfo.nameOnPromo || banner.projectInfo.title;
    const link = banner.projectInfo.url;

    if (!user.loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.MAIN_BANNER_SECTION,
        type: AuthActionType.REDIRECT,
        args: {
          projectId,
          projectName,
          absolutePath: true,
          redirectLink: link,
        },
      };

      const mainContainerData: AuthActionParam = {
        component: ComponentType.MAIN,
        type: AuthActionType.REDIRECT_AFTER_REG_FORM,
        args: {
          projectId,
          absolutePath: true,
          redirectLink: link,
        },
      };

      const action1 = new AuthAction(thisContainerData);
      const action2 = new AuthAction(mainContainerData);

      return appContext.sudirService.authWithActions([action1, action2]);
    }

    window.location.href = link;
  };

  const getBannerTitle = (banner) => {
    if (banner.projectInfo) {
      return (
        banner.projectInfo.nameOnPromo ||
        banner.projectInfo.title ||
        banner.name
      );
    }

    return banner.name;
  };

  const getBannerTime = (banner) => {
    if (projectIsAnnouncement(banner.projectInfo)) {
      return <span>{formatProjectDates(banner.projectInfo)}</span>;
    }

    if (projectIsCurrent(banner.projectInfo)) {
      return <span>{getDaysToEnd(banner)} до завершения</span>;
    }

    if (projectIsFinished(banner.projectInfo)) {
      return <span>{formatProjectDates(banner.projectInfo)}</span>;
    }
  };

  const getDaysToEnd = (banner) =>
    `${calculateRemainedTime(banner.projectInfo)[0]} ${calculateRemainedTime(banner.projectInfo)[1]}`;

  const openProposeModal = (sphere?: GorodIdeaSphere) => {
    if (!user.loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.MAIN_BANNER_SECTION,
        type: AuthActionType.OPEN_PROPOSE_MODAL,
        args: {
          code: sphere?.code || null,
        },
      };

      const joinGameAction = new AuthAction(thisContainerData);
      return appContext.sudirService.authWithActions([joinGameAction]);
    }

    setSelectedSphere(sphere);
    setIsProposeModalOpen(true);
  };

  // RENDER

  const renderProjectBannerButton = (banner) => {
    if (projectIsAnnouncement(banner.projectInfo)) {
      // Авторизован и уже является УЧАСТНИКОМ проекта
      if (
        (user.loggedIn && userIsProjectParticipant(user, banner.projectId)) ||
        (!banner.projectInfo.start && !banner.projectInfo.finish)
      ) {
        return (
          <Button
            type="filled"
            text="Подробнее"
            linkUrl={`/announcement?id=${banner.projectId}`}
          />
        );
      }

      // НЕ АВТОРИЗОВАН (ГОСТЬ)
      if (!user.loggedIn) {
        return (
          <Button
            type="filled"
            text="Хочу участвовать"
            onClick={(event) =>
              participateInProject(
                event,
                banner.projectId,
                `/announcement?id=${banner.projectId}`
              )}
          />
        );
      }

      // Авторизован, но еще НЕ является Участником проекта
      if (user.loggedIn && !userIsProjectParticipant(user, banner.projectId)) {
        return (
          <Button
            type="filled"
            text="Хочу участвовать"
            onClick={(event) =>
              participateInProject(
                event,
                banner.projectId,
                `/announcement?id=${banner.projectId}`
              )}
          />
        );
      }
    }

    if (projectIsCurrent(banner.projectInfo)) {
      // Авторизован и уже является УЧАСТНИКОМ проекта
      if (
        (user.loggedIn && userIsProjectParticipant(user, banner.projectId)) ||
        (!banner.projectInfo.start && !banner.projectInfo.finish)
      ) {
        return (
          <Button
            type="filled"
            text="Перейти на проект"
            onClick={(event) => toPlatform(event, banner)}
          />
        );
      }

      // Авторизован, но еще НЕ является Участником проекта
      if (user.loggedIn && !userIsProjectParticipant(user, banner.projectId)) {
        const btnText = "Принять участие";
        return (
          <EventsTrackWrapperClick needParent id={19} replace={[btnText]}>
            <Button
              type="filled"
              text={btnText}
              onClick={(event) => toPlatform(event, banner)}
            />
          </EventsTrackWrapperClick>
        );
      }

      // НЕ АВТОРИЗОВАН (ГОСТЬ)
      if (!user.loggedIn) {
        const btnText = "Принять участие";
        return (
          <EventsTrackWrapperClick needParent id={19} replace={[btnText]}>
            <Button
              type="filled"
              text={btnText}
              onClick={(event) => toPlatform(event, banner)}
            />
          </EventsTrackWrapperClick>
        );
      }
    }

    if (projectIsFinished(banner.projectInfo)) {
      const _isTeamMember = isTeamMember(user, banner.projectId);
      return (
        <Button
          type="filled"
          text={_isTeamMember ? "Перейти на проект" : "Итоги проекта"}
          // hrefTarget="_blank"
          linkUrl={
            _isTeamMember
              ? banner.projectInfo.url
              : banner.buttonLink
                ? banner.buttonLink
                : `/plan?id=${banner.projectId}`
          }
        />
      );
    }
  };

  const renderBannerTitle = (banner) => {
    if (banner.type === "PROJECT") {
      if (projectIsAnnouncement(banner.projectInfo)) {
        return <div className={styles.title}>Следующий проект</div>;
      }

      if (projectIsCurrent(banner.projectInfo)) {
        return <div className={styles.title}>Текущий проект</div>;
      }

      if (projectIsFinished(banner.projectInfo)) {
        return <div className={styles.title}>Завершенный проект</div>;
      }

      return <div className={styles.title}>ПРОЕКТ</div>;
    }

    if (banner.type === "COMMON") {
      return <div className={styles.title}>{banner.firstTitle}</div>;
    }

    if (banner.type === "MAYOR") {
      return <div className={styles["mayor-title"]}>Город идей</div>;
    }
  };

  const renderBannerName = (banner) => {
    const bannerTitle = getBannerTitle(banner);
    if (banner.type === "PROJECT") {
      return projectIsCurrent(banner.projectInfo) &&
        !!banner.projectInfo.url ? (
        <EventsTrackWrapperClick needParent id={18} replace={[bannerTitle]}>
          <a
            className={styles.name}
            href={banner.projectInfo.url}
            style={{ cursor: "pointer" }}
            dangerouslySetInnerHTML={{
              __html: bannerTitle,
            }}
          />
        </EventsTrackWrapperClick>
      ) : (
        <EventsTrackWrapperClick needParent id={18} replace={[bannerTitle]}>
          <div
            className={styles.name}
            style={{ cursor: "default" }}
            dangerouslySetInnerHTML={{
              __html: bannerTitle,
            }}
          />
        </EventsTrackWrapperClick>
      );
    }
  };

  const renderBannerTime = (banner) => {
    if (banner.type === "PROJECT") {
      return (
        <Text size={isTablet ? 4 : 2} className={styles.time}>
          {getBannerTime(banner)}
        </Text>
      );
    }
  };

  const renderBannerDescription = (banner) => {
    if (banner.type === "PROJECT" || banner.type === "COMMON") {
      return (
        <Text size={isTablet ? 3 : 1} className={styles.description}>
          <span
            dangerouslySetInnerHTML={{
              __html: banner.comment,
            }}
          />
        </Text>
      );
    }
  };

  const renderBannerMayorDescription = (banner) => {
    if (banner.type === "MAYOR") {
      return (
        <>
          <Text size={isTablet ? 3 : 1} className={styles["mayor-description"]}>
            <EventsTrackWrapperClick
              needParent
              id={20}
              replace={[trimSpaces(stripHTML(banner.comment))]}
            >
              <a
                dangerouslySetInnerHTML={{
                  __html: banner.comment,
                }}
                href="/speech"
              />
            </EventsTrackWrapperClick>
          </Text>
          <Text size={isTablet ? 3 : 1} className={styles["mayor-signature"]}>
            <img
              src={require("../../../../assets/mayor_sign.svg").default}
              alt="С.Собянин"
            />{" "}
            Мэр Москвы
          </Text>
        </>
      );
    }
  };

  const renderBannerButton = (banner) => {
    if (banner.type === "PROJECT") {
      return renderProjectBannerButton(banner);
    }

    if (banner.type === "COMMON") {
      return renderCommonBannerButton(banner);
    }

    if (banner.type === "MAYOR") {
      return renderMayorBannerButton(banner);
    }
  };

  const renderBannerStats = (banner) => {
    if (banner.type === "PROJECT" && banner.projectInfo) {
      return (
        <div className={styles.stats}>
          {banner.projectInfo.participantCount > 0 && (
            <div className={styles["stats-item"]}>
              <i className="promo-icon-user-multi" />
              <span>
                {numberWithSpaces(banner.projectInfo.participantCount)}
              </span>
            </div>
          )}

          {banner.projectInfo.proposalCount > 0 && (
            <div className={styles["stats-item"]}>
              <i className="promo-icon-idea-multi" />
              <span>{numberWithSpaces(banner.projectInfo.proposalCount)}</span>
            </div>
          )}
        </div>
      );
    }
  };

  const renderBannerShare = (banner) =>
    (projectIsAnnouncement(banner.projectInfo) ||
      projectIsCurrent(banner.projectInfo)) && (
      <div className={styles.share}>
        <Text size={isTablet ? 4 : 3} className={styles.subscribe}>
          Поделитесь приглашением на проект
        </Text>
        <div className={styles["share-buttons"]}>
          <Share
            type="unfolded"
            url={`/projects/${banner.projectInfo.id}`}
            imageUrl={`/uploads/get/${banner.projectInfo.logo.horizontal || banner.projectInfo.logo.square}`}
            title={`Приглашаю принять участие в проекте «${(
              banner.projectInfo.nameOnPromo ||
              banner.projectInfo.title ||
              ""
            ).replace(/&nbsp;?/g, " ")}» на платформе «Город идей»!`}
            colorIcon="#666666"
          />
        </div>
      </div>
    );

  const renderOkButton = (handler) => (
    <Button type="outlined" text="Хорошо" onClick={handler} />
  );

  const hasButtonLink = (buttonLink) => buttonLink && buttonLink !== "false";

  const renderCommonBannerButton = (banner) => (
    <EventsTrackWrapperClick needParent id={40} replace={[banner.buttonName]}>
      <Button
        type="filled"
        text={banner.buttonName}
        linkUrl={hasButtonLink(banner.buttonLink) ? banner.buttonLink : ""}
        onClick={!hasButtonLink(banner.buttonLink) && openProposeModal}
        externalLink={hasButtonLink(banner.buttonLink)}
      />
    </EventsTrackWrapperClick>
  );

  const renderMayorBannerButton = (banner) => (
    <EventsTrackWrapperClick needParent id={21}>
      <Button type="filled" text="Предложить идею" onClick={openProposeModal} />
    </EventsTrackWrapperClick>
  );

  const renderMobileBannersSwiper = () => {
    if (!isPhone) return;

    const classes = () =>
      classNames(styles["mobile-slide"], {
        "item-colorized": process.env.NODE_ENV === "development",
      });

    return (
      <div className={styles["mobile-swiper"]}>
        <Swiper
          loop
          slidesPerView={1}
          spaceBetween={20}
          onSlideChange={(swiper) => {
            setBanner(banners[swiper.realIndex]);
          }}
        >
          {banners.map((banner) => (
            <SwiperSlide key={banner.id} className={classes()}>
              {banner.phoneAttachmentId && (
                <img
                  src={`/uploads/get/${banner.phoneAttachmentId}`}
                  alt={banner.phoneAttachmentId}
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  };

  const renderBannerContent = () => {
    if (!banner) return;

    return (
      <div className={styles.content}>
        {renderBannerTitle(banner)}
        {renderBannerName(banner)}
        {renderBannerTime(banner)}
        {renderMobileBannersSwiper()}
        {renderBannerMayorDescription(banner)}
        {renderBannerDescription(banner)}

        <div className={styles.footer}>
          <div className={styles.row}>
            {renderBannerButton(banner)}
            {renderBannerStats(banner)}
          </div>
          {renderBannerShare(banner)}
        </div>
      </div>
    );
  };

  const renderdContent = () => {
    const bannersList = () => {
      if (!banners) return [];

      // make smth better
      return banners.length < 6 ? [...banners, ...banners] : banners;
    };
    return (
      <EventsTrackWrapperScroll id={1} needParent>
        <div
          className={classNames(
            styles.banner,
            banner?.type ? styles[banner.type.toLowerCase()] : null
          )}
        >
          {renderBannerContent()}

          <div className={styles.trio}>
            {banners && !isPhone && (
              <TrioSlider
                setWidthAsHeight
                onInit={(instance) => {
                  props.onInitTrio?.(instance);
                }}
                autoPlay
                interval={7000}
                onChange={(banner) => {
                  setBanner(banner);
                }}
              >
                {bannersList().map((banner, id) => (
                  <Slide data={banner} key={id}>
                    <div
                      style={{
                        backgroundImage: `url('/uploads/get/${
                          isTablet
                            ? banner.tabAttachmentId
                            : banner.attachmentId
                        }')`,
                      }}
                    />
                  </Slide>
                ))}
              </TrioSlider>
            )}
          </div>
        </div>
      </EventsTrackWrapperScroll>
    );
  };

  return (
    <>
      {renderdContent()}
      {isProposeModalOpen && (
        <ProposeIdeaForm
          sphere={selectedSphere}
          isOpen
          onClose={() => setIsProposeModalOpen(false)}
          setIsSubmittedProposeModalOpen={setIsSubmittedProposeModalOpen}
        />
      )}
      {isSubmittedProposeModalOpen && (
        <ProposeIdeaSubmittedForm
          isOpen
          onClose={() => setIsSubmittedProposeModalOpen(false)}
          handleOpenPropose={openProposeModal}
        />
      )}
    </>
  );
};

export default BannersSection;
