import { Swiper, SwiperSlide } from "swiper/react";
import React, {
  HTMLAttributes,
  ReactElement,
  useEffect,
  useState,
} from "react";

import { useMediaQuery } from "react-responsive";

import SwiperCore from "swiper";
import styles from "./BannerLine.module.scss";
import { INewsLine } from "../../../../types/NewsLine.interface";
import {
  phoneWidth,
  tabletWidth,
} from "../../../../utils/constants/widthConstants";
import EventsTrackWrapperClick from "../../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";

export interface NewsLineProps extends HTMLAttributes<HTMLDivElement> {
  lines: INewsLine[];
  onInitSwiper?: (swiper: SwiperCore) => void;
}

const defaultTime = 7;

const BannerLine = ({
  lines = [],
  onInitSwiper,
  ...otherProps
}: NewsLineProps): ReactElement => {
  const isTablet = useMediaQuery({ query: `(max-width: ${tabletWidth}px)` });
  const isMobile = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>(null);
  const [currentLine, setCurrentLine] = useState<INewsLine>(lines[0]);

  useEffect(() => {
    if (swiperInstance) {
      if (lines.length > 1) {
        swiperInstance.autoplay.start();
        return;
      }

      swiperInstance.autoplay.stop();
    }
  }, [lines, swiperInstance]);

  useEffect(() => {
    if (!swiperInstance || lines.length <= 1) return;

    const delay = (currentLine?.duration || defaultTime) * 1000;

    if (typeof swiperInstance.params.autoplay === "object") {
      swiperInstance.params.autoplay.delay = delay;
      swiperInstance.autoplay.start();
    }
  }, [currentLine, swiperInstance]);

  const getLineImgUrl = (line: INewsLine) => {
    if (isMobile) {
      return line.mobileAttachmentId;
    }

    if (isTablet) {
      return line.tabletAttachmentId;
    }

    return line.desktopAttachmentId;
  };

  const initSwiper = (swiper) => {
    // для отмены отключения автоплея при уходе с текущей вкладки браузера, чтобы свайпер синхронно переключался с баннерами
    document.removeEventListener(
      "visibilitychange",
      swiper.autoplay.onVisibilityChange
    );
    setSwiperInstance(swiper);
    onInitSwiper?.(swiper);
  };

  return (
    lines.length > 0 && (
      <div className={styles["banner-line"]} {...otherProps}>
        <Swiper
          autoplay={
            lines.length > 1
              ? {
                  delay: (currentLine?.duration || defaultTime) * 1000,
                  waitForTransition: false,
                }
              : false
          }
          onSlideChange={(swiper) => {
            setCurrentLine(lines[swiper.realIndex]);
          }}
          slidesPerView={1}
          loop={lines.length > 1}
          allowTouchMove={false}
          onSwiper={(instance) => initSwiper(instance)}
          speed={300}
        >
          {lines?.map((line) => (
            <SwiperSlide key={line.id}>
              <EventsTrackWrapperClick id={17} replace={[line.id]}>
                <div className={styles.slide}>
                  <a
                    style={{ cursor: line.link ? "pointer" : "default" }}
                    href={line.link || ""}
                    target="_blank"
                    onClick={(evt) => {
                      if (!line.link) {
                        evt.preventDefault();
                      }
                    }}
                    rel="noreferrer"
                    className={styles.link}
                  >
                    <img
                      src={`/uploads/get/${getLineImgUrl(line)}`}
                      alt="Изображение баннера"
                    />
                  </a>
                </div>
              </EventsTrackWrapperClick>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    )
  );
};

export default BannerLine;
