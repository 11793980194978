import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { RootState } from "../../../../types/State.interface";

import "./GlobalControl.scss";
import InfoModal from "../../Modals/InfoModal/InfoModal";
import MaintenanceModal from "../../Modals/MaintenanceModal/MaintenanceModal";
import EventsTrackWrapperClick from "../../EventsTrack/wrappers/EventsTrackWrapperClick";
import ScrollTopButton from "../../../presentational/Controls/ScrollTopButton/ScrollTopButton";
import Chat from "../../../presentational/Chat/Chat";
import {
  getScreenWidth,
  getScroll,
  setIsScrolling,
} from "../../../../store/actions/GlobalEventsActions";
import { debounce, throttle } from "../../../../utils";
import { scrollTop } from "../../../../utils/helperFunctions";
import { birthday10LandingUrl } from "../../../pages/CrowdBirthday10/CrowdBirthday10.constants";
import { newYear2025Url } from "../../../pages/NewYear2025/constants";
import { selectMediaPlatformList } from "../../../../store/selectors/mediaPlatform";

let scrollTimeout = null;

const GlobalControl: FC = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const inWebViewMode = useSelector(
    (state: RootState) => state.layout.inWebViewMode
  );
  const scroll = useSelector((state: RootState) => state.globalEvents.scroll);
  const mediaPlatformList = useSelector(selectMediaPlatformList);

  const mpUrls = useMemo(
    () => mediaPlatformList?.map(({ url }) => url) || [],
    [mediaPlatformList]
  );
  const noScrollPages = useMemo(() => ["/discussion", ...mpUrls], [mpUrls]);

  const isChatVisible = useCallback(() => {
    const hiddenPath = [
      "/birthday",
      "/projects",
      "/city",
      "/newyear2024",
      newYear2025Url,
      "/public",
      ...mpUrls,
      birthday10LandingUrl,
    ];
    if (
      inWebViewMode ||
      hiddenPath.some((p) => window.location.pathname.toLowerCase().includes(p))
    ) return false;
    return true;
  }, [inWebViewMode, mpUrls]);

  const [showChat, setShowChat] = useState(() => isChatVisible());

  useEffect(() => {
    dispatch(getScreenWidth());

    window.addEventListener(
      "resize",
      debounce((e) => dispatch(getScreenWidth()), 500)
    );
    window.addEventListener(
      "scroll",
      throttle((e) => {
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(() => {
          dispatch(setIsScrolling(false));
        }, 100);

        dispatch(setIsScrolling(true));
        dispatch(getScroll());
      }, 10)
    );
  }, []);

  useEffect(() => {
    const handler = () => {
      if (!noScrollPages.includes(location.pathname)) {
        scrollTop(false);
      }
      setShowChat(isChatVisible());
    };

    setShowChat(isChatVisible());
    const unlisten = history.listen(handler);

    return () => unlisten();
  }, [history, isChatVisible, location.pathname, noScrollPages]);

  return (
    <>
      {children}
      <InfoModal />
      <MaintenanceModal />
      <EventsTrackWrapperClick id={250}>
        <ScrollTopButton scrolled={scroll} />
      </EventsTrackWrapperClick>

      <Chat isVisible={showChat} />
    </>
  );
};

export default GlobalControl;
