import { Route } from "react-router-dom";
import { useSelector } from "react-redux";
import DiscussionLandingPage from "../DiscussionLandingPage";
import DiscussionLandingContext from "../DiscussionLanding.context";
import { selectMediaPlatformList } from "../../../../store/selectors/mediaPlatform";

const useMpRoutes = () => {
  const mediaPlatformList = useSelector(selectMediaPlatformList);

  return mediaPlatformList?.map((config) => (
    <Route
      path={config.url}
      render={() => (
        <DiscussionLandingContext.Provider value={config}>
          <DiscussionLandingPage />
        </DiscussionLandingContext.Provider>
      )}
      key={config.id}
    />
  ));
};

export default useMpRoutes;
