import { Category } from "../../../../types/Propose.interface";

export const proposeIdeaData: Category[] = [
  {code: "idea_43", title: "Культурно-досуговые и библиотечные услуги"},
  {code: "idea_28", title: "Программы летнего городского досуга"},
  {code: "idea_7", title: "Культурно-досуговые мероприятия"},
  {code: "idea_9", title: "Зоны отдыха для взрослых и детей"},
  {code: "idea_27", title: "Развивающие программы для школьников"},
  {code: "idea_8", title: "Мероприятия от горожан"},
  {code: "idea_3", title: "Благоустройство парков"},
  {code: "idea_10", title: "Мероприятия и услуги"}
];