import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import CommonSlider from "../../../../presentational/Sliders/CommonSlider/CommonSlider";
import { RootState } from "../../../../../types/State.interface";
import {
  phoneWidth,
  tabletWidth,
} from "../../../../../utils/constants/widthConstants";
import "./Discussions.scss";
import DiscussionCard from "./DiscussionCard/DiscussionCard";
import dayjs from "dayjs";
import TopComments from "./TopComments/TopComments";
import MediaPlatformService from "../../../../../services/mediaPlatform.service";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import { displayError, isParticipant } from "../../../../../utils";
import { Discussion, DiscussionStatus, Comment } from "./Discussion.interface";
import Button from "../../../../presentational/Controls/Button/Button";
import AllComments from "./AllComments/AllComments";
import {
  AuthAction,
  AuthActionParam,
  AuthActionType,
  ComponentType,
} from "../../../../../services/sudirService";
import { AppContext } from "../../../../Root";
import { CommentProps } from "./Comment/Comment";
import useDiscussionLandingContext from "../../hooks/useDiscussionLandingContext";
import { MediaPlatformSections } from "../../routing/routes";

dayjs.locale("ru");

export interface DiscussionsProps {
  onLoadEnd: (data) => void;
}

enum DiscussionDataKeys {
  Discussions = "discussions",
  TopComments = "topComments",
  AllComments = "allComments",
}

const Discussions: FC<DiscussionsProps> = ({ onLoadEnd }) => {
  const discussionLandingContext = useDiscussionLandingContext();
  const appContext = useContext(AppContext);
  const currentUser = useSelector((state: RootState) => state.user.userDetails);
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const isTablet = useMediaQuery({
    query: `(max-width: ${tabletWidth}px) and (min-width: ${phoneWidth}px)`,
  });
  const env = useSelector((state: RootState) => state.environment);
  const currentProjects = useSelector(
    (state: RootState) => state.projects.current
  );
  const currentProjectId = currentProjects?.[0]?.id;
  const [loadedComponentData, setLoadedComponentData] = useState({});
  const [discussions, setDiscussions] = useState<Discussion[]>(null);
  const [selectedDiscussion, setSelectedDiscussion] =
    useState<Discussion>(null);
  const [topComments, setTopComments] = useState(null);
  const [allComments, setAllComments] = useState(null);

  const isCurrentUserParticipant = useMemo(
    () => currentProjectId && isParticipant(currentUser, currentProjectId),
    [currentProjectId, currentUser]
  );

  useEffect(() => {
    const componentDataKeys = [
      DiscussionDataKeys.AllComments,
      DiscussionDataKeys.TopComments,
      DiscussionDataKeys.Discussions,
    ];
    const componentDataHasBeenLoaded = componentDataKeys.every(
      (key) => key in loadedComponentData
    );

    if (componentDataHasBeenLoaded) {
      onLoadEnd(discussions || []);
    }
  }, [loadedComponentData]);

  // ACTIONS AFTER AUTH
  useEffect(() => {
    if (!currentUser?.loggedIn || !allComments?.length) return;

    const actions = appContext.sudirService.getActions(
      ComponentType.DISCUSSION_LANDING_ALL_COMMENTS
    );
    if (actions.length) {
      const likeCommentAction = actions.find(
        (act) => act.type === AuthActionType.LIKE
      );
      if (likeCommentAction) {
        const comment = allComments.find(
          (comment) => comment.id === likeCommentAction.args.commentId
        );
        handleVote(comment);
      }
    }
  }, [currentUser?.loggedIn, allComments]);

  useEffect(() => {
    if (currentProjects) {
      (async () => {
        const project = discussionLandingContext.projectCode
          ? currentProjects.find(
              ({ id }) => id === discussionLandingContext.projectCode
            )
          : undefined;
        if (project) {
          try {
            const response = await MediaPlatformService.getSimpleDiscussions();
            if (response.status === ApiStatusCode.OK) {
              setDiscussions(response.data);
              const currentSelectedDiscussion =
                response.data.find(
                  (discussion) => discussion.status === DiscussionStatus.STARTED
                ) || null;
              setSelectedDiscussion(currentSelectedDiscussion);

              if (!currentSelectedDiscussion) {
                onLoadEnd([]);
              }
            } else {
              onLoadEnd([]);
              displayError(
                "Ошибка при получении карточек обсуждений",
                response.message
              );
            }
          } catch (error) {
            onLoadEnd([]);
            displayError(
              "Discussions",
              "Ошибка при получении карточек обсуждений"
            );
          } finally {
            setLoadedComponentData((state) => ({
              ...state,
              [DiscussionDataKeys.Discussions]: true,
            }));
          }
        } else {
          onLoadEnd([]);
        }
      })();
    }
  }, [currentProjects, discussionLandingContext.projectCode]);

  useEffect(() => {
    if (selectedDiscussion) {
      fetchTopComments();
      fetchAllComments();
    }
  }, [selectedDiscussion]);

  const fetchTopComments = async () => {
    try {
      const response = await MediaPlatformService.getTopComments({
        stageId: selectedDiscussion.id,
      });
      if (response.status === ApiStatusCode.OK) {
        setTopComments(response.data);
      } else {
        displayError("Ошибка при получении топ комментариев", response.message);
      }
    } catch (error) {
      displayError("Discussions", "Ошибка при получении топ комментариев");
    } finally {
      setLoadedComponentData((state) => ({
        ...state,
        [DiscussionDataKeys.TopComments]: true,
      }));
    }
  };

  const fetchAllComments = async () => {
    try {
      const response = await MediaPlatformService.getLastComments({
        stageId: selectedDiscussion.id,
        size: 10,
      });
      if (response.status === ApiStatusCode.OK) {
        setAllComments(response.data);
      } else {
        displayError(
          "Ошибка при получении последних комментариев",
          response.message
        );
      }
    } catch (error) {
      displayError(
        "Discussions",
        "Ошибка при получении последних комментариев"
      );
    } finally {
      setLoadedComponentData((state) => ({
        ...state,
        [DiscussionDataKeys.AllComments]: true,
      }));
    }
  };

  const handleCardClick = (data: Discussion) => {
    setSelectedDiscussion(data);
  };

  const handleVote = async (comment: Comment) => {
    if (!currentUser.loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.DISCUSSION_LANDING_ALL_COMMENTS,
        type: AuthActionType.LIKE,
        args: {
          commentId: comment.id,
        },
      };
      const action1 = new AuthAction(thisContainerData);
      return appContext.sudirService.authWithActions([action1]);
    }

    try {
      const response = await MediaPlatformService.voteComment(
        { id: comment.id },
        !comment.voted
      );
      if (response.status === ApiStatusCode.OK) {
        fetchTopComments();
        fetchAllComments();
      } else {
        displayError("Ошибка при лайке коммента", response.message);
      }
    } catch (error) {
      displayError("CommentCard", "Ошибка при лайке коммента");
    }
  };

  const isLikeVisible: CommentProps["isLikeVisible"] = (comment) =>
    currentUser.loggedIn &&
    isCurrentUserParticipant &&
    currentUser.id !== comment.author.id &&
    comment.author.participant &&
    (!!comment.upVotes || selectedDiscussion.status === "STARTED");

  if (!discussions?.length) return <></>;

  return (
    <section
      className="discussion-landing-discussion"
      id={MediaPlatformSections.DISCUSSION}
    >
      <div className="discussion-landing-title">Тематические обсуждения</div>

      <CommonSlider
        className="discussion-landing-discussion__swiper"
        options={{
          loop: true,
          spaceBetween: 24,
          slidesPerView: isTablet ? 2 : isPhone ? 1 : 3,
          slidesPerColumn: 1,
          slidesPerGroup: 1,
        }}
      >
        {discussions?.map((item) => (
          <DiscussionCard key={item.id} data={item} onClick={handleCardClick} />
        ))}
      </CommonSlider>

      {selectedDiscussion && (
        <div className="discussion-landing-discussion__selected">
          <div className="discussion-landing-subtitle discussion-landing-discussion__subtitle">
            ОБСУЖДЕНИЕ: {selectedDiscussion.title}
          </div>
          <div className="discussion-landing-discussion__date">
            {`${dayjs(selectedDiscussion.start).format(
              "DD.MM.YYYY"
            )} - ${dayjs(selectedDiscussion.finish).format("DD.MM.YYYY")}`}
          </div>
          <div
            className="discussion-landing-discussion__text"
            dangerouslySetInnerHTML={{
              __html: selectedDiscussion.taskSpecification?.posts[0]?.content,
            }}
          />

          {Boolean(topComments?.length) && (
            <div className="discussion-landing-discussion__top">
              <TopComments
                comments={topComments}
                isLikeVisible={isLikeVisible}
                discussion={selectedDiscussion}
                onVote={handleVote}
                isLikeDisabled={selectedDiscussion.status !== "STARTED"}
              />
            </div>
          )}

          {selectedDiscussion.status !== DiscussionStatus.FINISHED && (
            <Button
              type="filled"
              text="Написать комментарий"
              externalLink
              hrefTarget="_blank"
              classList="discussion-landing-discussion__write-btn"
              linkUrl={`${env.platformUrl}/discussion/${selectedDiscussion.id}`}
            />
          )}

          {Boolean(allComments?.length) && (
            <div className="discussion-landing-discussion__comments">
              <AllComments
                comments={allComments}
                isLikeVisible={isLikeVisible}
                discussion={selectedDiscussion}
                onVote={handleVote}
                isLikeDisabled={selectedDiscussion.status !== "STARTED"}
              />
            </div>
          )}
        </div>
      )}
    </section>
  );
};
export default Discussions;
