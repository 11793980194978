import MediaPlatformService from "../../services/mediaPlatform.service";

export const MEDIAPLATFORM_SET_LIST = "MEDIAPLATFORM_SET_LIST";

export const getAllMediaPlatforms = () => (dispatch) =>
  MediaPlatformService.getAll()
    .then(({ data }) => {
      dispatch({
        type: MEDIAPLATFORM_SET_LIST,
        payload: data || [],
      });
    })
    .catch((err) => {
      console.log(err);
    });
