import CommonSlider from "../../presentational/Sliders/CommonSlider/CommonSlider";
import { SpecialProjectsCard } from "./components/SpecialProjectsCard/SpecialProjectsCard";
import "./SpecialProjects.scss";
import { useMemo, useState } from "react";
import { specialProjects } from "./constants/constants";
import { TSpecialProject } from "./types/types";
import { useMediaQueries } from "../../../hooks/useMedia";
import { SpecialProjectsImages } from "./components/SpecialProjectsImages/SpecialProjectsImages";

export const SpecialProjects = () => {
  const [hoveredLandingId, setHoveredLandingId] = useState<
    TSpecialProject["id"] | null
  >(null);
  const { isMobile, isDesktop, isTablet } = useMediaQueries();
  const imageKey = isDesktop ? "desktop" : isTablet ? "tablet" : "mobile";

  const listOfCards = useMemo(
    () =>
      specialProjects.map((specialProject) => (
        <SpecialProjectsCard
          specialProject={specialProject}
          key={specialProject.id}
          imageKey={imageKey}
          setHoveredLandingId={setHoveredLandingId}
        />
      )),
    [imageKey]
  );

  return (
    <section className="special-projects">
      <div className="special-projects__main">
        <SpecialProjectsImages
          specialProjects={specialProjects}
          imageKey={imageKey}
          activeSpecialProjectId={hoveredLandingId}
        />
        <div className="special-projects__main__backdrop" />
        <h1 className="special-projects__main__title">
          Специальные
          <br />
          проекты
        </h1>
      </div>
      {!isMobile && (
        <CommonSlider
          className="special-projects__slider"
          arrowsContainerClassName="special-projects__arrows-container"
          options={{
            loop: false,
            spaceBetween: isDesktop ? 24 : 22,
            slidesPerView: isDesktop ? 4 : 3,
            slidesPerColumn: 1,
            slidesPerGroup: 1,
          }}
        >
          {listOfCards}
        </CommonSlider>
      )}
      {isMobile && (
        <div className="special-projects__list-of-cards">{listOfCards}</div>
      )}
    </section>
  );
};
