import React, { useEffect } from "react";

import "./CaptchaMosRu.scss";

interface Props {
  captchaValue: string;
  captchaImageBase64: string;
  setCaptchaValue: (val: string) => void;
  refreshCaptcha: () => void;
}

const CaptchaMosRu = ({
  captchaValue,
  captchaImageBase64,
  setCaptchaValue,
  refreshCaptcha,
}: Props) => {
  useEffect(() => {
    refreshCaptcha();
  }, []);

  // RENDER

  return (
    <div className="captcha-mosru">
      <input
        value={captchaValue}
        onChange={(e) => {
          setCaptchaValue(e.target.value);
        }}
        type="text"
        placeholder="Проверочный код"
        className="cr-input__input"
      />
      <img src={captchaImageBase64} alt="Проверочный код" />
      <div className="refresh" onClick={refreshCaptcha}>
        <span className="ic promo-icon-110" />
        <span className="refresh__text">Обновить код</span>
      </div>
    </div>
  );
};

export default CaptchaMosRu;
