import { FC, useContext, useEffect, useMemo, useState } from "react";
import "./ThematicProjects.scss";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";

import { useSelector } from "react-redux";
import {
  phoneWidth,
  wideTabletWidth,
} from "../../../../../utils/constants/widthConstants";
import CommonSlider from "../../../../presentational/Sliders/CommonSlider/CommonSlider";
import ThematicProjectCard from "./ThematicProjectCard/ThematicProjectCard";
import MediaPlatformService from "../../../../../services/mediaPlatform.service";
import {
  ApiStatusCode,
  ArrayItem,
} from "../../../../../types/Common.interface";
import { displayError, shuffle } from "../../../../../utils";
import { StageTypes } from "../../../../../types/Stage.interface";
import {
  GroupDirection,
  GroupDirectionNode,
  GroupDirectionStage,
} from "../../../../../types/GroupDirection.interface";
import NodesModal from "../NodeSelectorModal/NodeSelectorModal";
import { ProjectStage } from "./ThematicProjectCard/ThematicProjectCard.interface";
import { RootState } from "../../../../../types/State.interface";
import { AppContext } from "../../../../Root";
import {
  AuthAction,
  AuthActionType,
  ComponentType,
} from "../../../../../services/sudirService";
import GenerationModal from "../GenerationModal/GenerationModal";
import NodeWithStageSelectorModal from "../NodeWithStageSelectorModal/NodeWithStageSelectorModal";
import useDiscussionLandingContext from "../../hooks/useDiscussionLandingContext";
import { selectIsUserReady } from "../../../../../store/selectors/profile";
import { MediaPlatformThematicProject } from "../../../../../types/MediaPlatform.interface";
import { MediaPlatformSections } from "../../routing/routes";

const nodesModalTitles = {
  [ProjectStage.PROPOSE]: "Выберите направление для подачи идеи",
  [ProjectStage.DISCUSS]: "Выберите направление",
  [ProjectStage.ESTIMATE]: "Выберите направление для оценки идей",
};

const StageStatusSortWeight: Record<GroupDirectionStage["status"], number> = {
  STARTED: 0,
  NEW: 10,
  FINISHED: 20,
};
const StageTypeSortWeight: Partial<Record<StageTypes, number>> = {
  [StageTypes.GENERATION]: 0,
  [StageTypes.VOTING]: 1,
  [StageTypes.SIMPLE_DISCUSSION]: 2,
};

export interface ThematicProjectsProps {
  onLoadEnd?: (data) => void;
  description: string;
}

const ThematicProjects: FC<ThematicProjectsProps> = ({
  onLoadEnd,
  description,
}) => {
  const discussionLandingContext = useDiscussionLandingContext();
  const appContext = useContext(AppContext);
  const env = useSelector((state: RootState) => state.environment);
  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);
  const isUserReady = useSelector(selectIsUserReady);
  const currentProjects = useSelector(
    (state: RootState) => state.projects.current
  );
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const isTablet = useMediaQuery({
    query: `(max-width: ${wideTabletWidth}px)`,
  });
  const [thematicProjects, setThematicProjects] = useState<
    Array<MediaPlatformThematicProject>
  >([]);
  const [groupDirections, setGroupDirections] = useState<Array<GroupDirection>>(
    []
  );
  const [selected, setSelected] =
    useState<ArrayItem<typeof thematicProjectsWithGroups>>();
  const [generationNode, setGenerationNode] = useState<
    GroupDirectionNode & { activeStage: GroupDirectionStage }
  >(null);
  const [lastNodeProposalSubmitted, setLastNodeProposalSubmitted] =
    useState<GroupDirectionNode>();
  const [stageSelectorModalOpen, setStageSelectorModalOpen] = useState(false);
  const history = useHistory();

  const successProposalMessage = `Спасибо за ваше предложение, оно будет рассмотрено экспертами проекта. За изменением статуса своих идей можно следить в <a href="${env.platformUrl}/profile/ideas" target="_blank">личном кабинете.</a>`;

  const selectedNodesExceptLast = useMemo(
    () =>
      (lastNodeProposalSubmitted
        ? selected?.filteredNodes.filter(
            (node) => node !== lastNodeProposalSubmitted
          )
        : selected?.filteredNodes),
    [lastNodeProposalSubmitted, selected?.filteredNodes]
  );

  const thematicProjectsWithGroups = useMemo(() => {
    let rootGroup: GroupDirection = null;
    const groupDirectionsSet = new Map();
    for (const group of groupDirections || []) {
      if (group.hasRootNode) {
        rootGroup = group;
      } else {
        groupDirectionsSet.set(group.id, group);
      }
    }
    const stageTypes = [
      StageTypes.GENERATION,
      StageTypes.SIMPLE_DISCUSSION,
      StageTypes.VOTING,
    ];

    const getSortedStagesFromNodes = (nodes: GroupDirectionNode[]) =>
      nodes
        ?.map(({ stages }) => stages)
        .flat()
        .filter(({ type }) => stageTypes.includes(type))
        .sort(
          (a, b) =>
            StageStatusSortWeight[a.status] +
            StageTypeSortWeight[a.type] -
            (StageStatusSortWeight[b.status] + StageTypeSortWeight[b.type])
        );

    const rootActiveStage = getSortedStagesFromNodes(rootGroup?.nodes)?.[0];

    return thematicProjects
      .map((p) => {
        const groupDirection = groupDirectionsSet.get(p.categoryGroup);

        const sortedStages = getSortedStagesFromNodes(groupDirection?.nodes);
        let activeStage = sortedStages?.[0];
        if (
          (activeStage?.status !== "STARTED" &&
            rootActiveStage?.status === "STARTED") ||
          (activeStage?.status === "FINISHED" &&
            rootActiveStage?.status !== "FINISHED")
        ) {
          activeStage = rootActiveStage;
        }

        const filteredNodes: (GroupDirectionNode & {
          activeStage: GroupDirectionStage;
        })[] = [];
        if (activeStage?.status === "STARTED") {
          if (activeStage === rootActiveStage) {
            for (const node of rootGroup?.nodes || []) {
              // для корневой группы, вместо направлений показываем стейджи
              filteredNodes.push(
                ...node.stages
                  .filter(
                    (stage) =>
                      stage.type === activeStage.type &&
                      stage.status === "STARTED"
                  )
                  .map((stage) => ({
                    ...node,
                    title: stage.title,
                    activeStage: stage,
                  }))
              );
            }
          } else {
            for (const node of groupDirection?.nodes || []) {
              const currentActiveStage = node.stages.find(
                (stage) =>
                  stage.type === activeStage.type && stage.status === "STARTED"
              );
              if (currentActiveStage) {
                filteredNodes.push({
                  ...node,
                  activeStage: currentActiveStage,
                });
              }
            }
          }
        }

        let activeProjectStage = ProjectStage.FINISHED;
        if (activeStage) {
          if (activeStage.status === "STARTED") {
            switch (activeStage.type) {
              case StageTypes.GENERATION:
                activeProjectStage = ProjectStage.PROPOSE;
                break;
              case StageTypes.VOTING:
                activeProjectStage = ProjectStage.ESTIMATE;
                break;
              case StageTypes.SIMPLE_DISCUSSION:
                activeProjectStage = ProjectStage.DISCUSS;
                break;
            }
          } else if (activeStage.status === "NEW") {
            activeProjectStage = ProjectStage.SOON_START;
          }
        }

        return {
          groupDirection,
          thematicProject: p,
          filteredNodes,
          activeProjectStage,
        };
      })
      .filter(({ groupDirection }) => !!groupDirection);
  }, [groupDirections, thematicProjects]);

  const chooseNode = (
    node: ArrayItem<
      ArrayItem<typeof thematicProjectsWithGroups>["filteredNodes"]
    >
  ) => {
    switch (node.activeStage.type) {
      case StageTypes.GENERATION:
        setGenerationNode(node);
        break;
      case StageTypes.SIMPLE_DISCUSSION:
        window.open(
          `${env.platformUrl}/discussion/${node.activeStage.id}`,
          "_blank"
        );
        break;
      case StageTypes.VOTING:
        window.open(
          `${env.platformUrl}/voting/${node.activeStage.id}`,
          "_blank"
        );
        break;
    }
  };

  const onSelect = (data: ArrayItem<typeof thematicProjectsWithGroups>) => {
    if (!loggedIn && data.activeProjectStage !== ProjectStage.DISCUSS) {
      const action = new AuthAction({
        component: ComponentType.DISCUSSION_LANDING_THEMATIC_PROJECTS,
        type: AuthActionType.THEMATIC_PROJECT_SELECT,
        args: {
          id: data.thematicProject.id,
        },
      });
      return appContext.sudirService.authWithActions([action]);
    }

    if (data.filteredNodes.length) {
      if (
        data.activeProjectStage === ProjectStage.DISCUSS &&
        data.filteredNodes.length === 1
      ) {
        chooseNode(data.filteredNodes[0]);
      } else {
        setSelected(data);
      }
    }
  };

  const onCloseProposeModal = () => {
    setSelected(undefined);
    setGenerationNode(undefined);
    setStageSelectorModalOpen(false);
    setLastNodeProposalSubmitted(undefined);
  };

  // ACTIONS AFTER AUTH
  useEffect(() => {
    if (!isUserReady || !thematicProjectsWithGroups.length || !env.platformUrl) return;

    const actions = appContext.sudirService.getActions(
      ComponentType.DISCUSSION_LANDING_THEMATIC_PROJECTS
    );
    const selectProject = actions.find(
      (act) => act.type === AuthActionType.THEMATIC_PROJECT_SELECT
    );
    if (selectProject) {
      const selectedItem = thematicProjectsWithGroups.find(
        ({ thematicProject }) => selectProject.args.id === thematicProject.id
      );
      if (selectedItem) {
        onSelect(selectedItem);
      }
      history.push(`${window.location.pathname}?section=ThematicProjects`);
    }
  }, [isUserReady, thematicProjectsWithGroups, env.platformUrl]);

  useEffect(() => {
    fetchData();
  }, [currentProjects, discussionLandingContext]);

  const fetchData = async () => {
    if (currentProjects) {
      const project = discussionLandingContext.projectCode
        ? currentProjects.find(
            ({ id }) => id === discussionLandingContext.projectCode
          )
        : undefined;
      let onLoadEndData = [];
      if (project) {
        try {
          const [thematicProjectsResponse, groupDirectionsResponse] =
            await Promise.all([
              MediaPlatformService.getThematicProjects({
                mediaPlatformId: discussionLandingContext.id,
              }),
              MediaPlatformService.projectGroupDirectionGetAll(project.id),
            ]);
          if (thematicProjectsResponse.status === ApiStatusCode.OK) {
            const result = shuffle([...thematicProjectsResponse.data]);
            setThematicProjects(result);
            onLoadEndData = result;
          } else {
            displayError(
              "Ошибка при получении тематических проектов",
              thematicProjectsResponse.message
            );
          }

          if (groupDirectionsResponse.status === ApiStatusCode.OK) {
            setGroupDirections(groupDirectionsResponse.data);
          } else {
            displayError(
              "Ошибка при получении групп направлений",
              groupDirectionsResponse.message
            );
          }
        } catch (error) {
          displayError(
            "ThematicProjects",
            "Ошибка при получении тематических проектов"
          );
        } finally {
          onLoadEnd(onLoadEndData);
        }
      } else {
        onLoadEnd(onLoadEndData);
      }
    }
  };

  if (!thematicProjectsWithGroups?.length) return <></>;

  return (
    <>
      <section
        className="discussion-landing-thematic-projects"
        id={MediaPlatformSections.THEMATIC_PROJECT}
      >
        <div className="discussion-landing-title">Тематические проекты</div>
        {!!description && (
          <div
            className="discussion-landing-text"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}

        <CommonSlider
          className="notifications__swiper"
          options={{
            loop: true,
            spaceBetween: 24,
            slidesPerView: isPhone ? 1 : isTablet ? 2 : 3,
            slidesPerColumn: 1,
            slidesPerGroup: 1,
          }}
        >
          {thematicProjectsWithGroups.map((item) => (
            <ThematicProjectCard
              key={item.thematicProject.id}
              {...item}
              onSelect={() => onSelect(item)}
            />
          ))}
        </CommonSlider>
      </section>
      {!!selected &&
        !generationNode?.activeStage &&
        !stageSelectorModalOpen && (
          <NodesModal
            open
            onClose={onCloseProposeModal}
            title={nodesModalTitles[selected.activeProjectStage]}
            nodes={selected.filteredNodes}
            onChoose={chooseNode}
          />
        )}
      {!!selected && !generationNode?.activeStage && stageSelectorModalOpen && (
        <NodeWithStageSelectorModal
          isOpen
          close={onCloseProposeModal}
          header="Ваша идея отправлена"
          message={successProposalMessage}
          listHeader="Предложить идею по теме:"
          nodes={selectedNodesExceptLast}
          onChoose={chooseNode}
        />
      )}
      {!!generationNode?.activeStage && (
        <GenerationModal
          open
          onClose={onCloseProposeModal}
          onFormClose={onCloseProposeModal}
          stage={generationNode.activeStage}
          onSubmit={() => {
            setLastNodeProposalSubmitted(generationNode);
            setGenerationNode(undefined);
            setStageSelectorModalOpen(true);
          }}
          onSubmitDraft={onCloseProposeModal}
        />
      )}
    </>
  );
};
export default ThematicProjects;
