import { Link, NavLink, useLocation } from "react-router-dom";
import EventsTrackWrapperClick from "../EventsTrack/wrappers/EventsTrackWrapperClick";
import { isActiveLink } from "./header.helpers";
import { TRoute } from "../../../types/navigation.interface";

interface TProps {
  item: TRoute;
  closeDropdown: (id: string) => void;
}

export function HeaderMobileRoute({ item, closeDropdown }: TProps) {
  const { pathname, search } = useLocation();

  if (!item.children) {
    return (
      <li className="cr-header__inner-mobile-dropdown-link" key={item.route}>
        <EventsTrackWrapperClick id={item.trackClickId}>
          <Link
            to={item.route}
            onClick={() => closeDropdown("mobile")}
            className={
              isActiveLink(pathname, search, item.route) ? "active-link" : ""
            }
          >
            {item.text}
          </Link>
        </EventsTrackWrapperClick>
        {item.badge && (
          <span className="cr-header__inner-mobile-dropdown-link-badge">
            {item.badge}
          </span>
        )}
      </li>
    );
  }

  return (
    <div className="cr-header__inner-mobile-dropdown-link" key={item.text}>
      {item.text}
      <div>
        {item.children.map((child) => (
          <EventsTrackWrapperClick key={child.text} id={child.trackClickId}>
            <NavLink
              to={child.route}
              onClick={() => closeDropdown("mobile")}
              activeClassName="active-link"
            >
              {child.text}
            </NavLink>
          </EventsTrackWrapperClick>
        ))}
      </div>
    </div>
  );
}
