import { Stage } from "./Stage.interface";

export interface ActiveIDs {
  announcementIds: string[];
  currentIds: string[];
}

export interface ProjectStats {
  archivedProjectCount: number;
  participantCount: number;
  proposalCount: number;
  registrationCount: number;
}

export interface ProjectLogo {
  horizontal: string;
  link: string;
  square: string;
  vertical: string;
  realizedStub: string;
}

export interface ProjectStructure {
  description: string;
  id: string;
  nodes: ProjectStructure[];
  stages: Stage[];
  title: string;
  projectCategoryDescription: string;
}

export interface Project {
  book: string;
  title: string;
  deployed: boolean;
  discussionCommentCount: number;
  discussionUserCount: number;
  finish: string;
  hasSubProjects: boolean;
  subProjectIds: string[];
  id: string;
  image: string;
  imageExt: string;
  logo: ProjectLogo;
  nameOnPromo: string;
  participantCount: number;
  proposalCount: number;
  published: boolean;
  questionnaire: ProjectQuestionnaire;
  registrationFinish: string;
  registrationStart: string;
  shortDescription: string;
  start: string;
  structure: ProjectStructure;
  isMain: boolean;
  totalProposalCount: number;
  parentProjectId: string;
  url: string;
  useQuestionnaire: boolean;
  realizedIdeaCount: number;
  waitingRealizedIdeaCount: number;
  hasLanding: boolean;
}

export interface ProjectQuestionnaire {
  fields: any[];
  hint: any;
  submitMessage: string;
  title: string;
}

export interface ProjectState {
  activeIDs: ActiveIDs | null;
  archive: Project[] | null;
  completed: Project[] | null;
  announcement: Project[] | null;
  current: Project[] | null;
  stats: ProjectStats | null;
}

export enum ProjectTypeEnum {
  CURRENT = "CURRENT",
  ANNOUNCEMENT = "ANNOUNCEMENT", // add more
}

export enum MosProjects {
  GI = "GI",
  AG = "AG",
  ED = "ED",
  GZ = "GZ",
  NG = "NG",
  MP = "MP",
}
