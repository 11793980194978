import { FC } from "react";
import "./NewsCard.scss";
import { Scrollbar } from "react-scrollbars-custom";

import Button from "../../../Button/Button";
import { MediaPlatformNews } from "../../../../../../../types/MediaPlatform.interface";

type NewsCardProps = {
  news: MediaPlatformNews;
  onSelectNews: (news: MediaPlatformNews) => void;
};

const NewsCard: FC<NewsCardProps> = ({ news, onSelectNews }) => {
  const { mainPhotoId, header } = news;

  return (
    <div className="news-card">
      <div className="news-card__header">
        <div className="news-card__img">
          <img src={`/uploads/get/${mainPhotoId}`} alt="" />
        </div>
      </div>
      <div className="news-card__footer">
        <Scrollbar
          className="news-card__scroll discussion-landing-scroll"
          noDefaultStyles
        >
          <div
            className="news-card__text"
            dangerouslySetInnerHTML={{ __html: header }}
          />
        </Scrollbar>
        <Button
          onClick={() => onSelectNews(news)}
          text="Читать"
          type="outlined"
        />
      </div>
    </div>
  );
};
export default NewsCard;
