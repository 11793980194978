import EventsTrackWrapperClick from "../EventsTrack/wrappers/EventsTrackWrapperClick";
import { TRoute } from "../../../types/navigation.interface";
import { HeaderMobileRoute } from "./HeaderMobileRoute";
import { HeaderUserLink } from "./HeaderUserLink";

interface TProps {
  routes: TRoute[] | null;
  openDropdown: (id: string) => void;
  closeDropdown: (id: string) => void;
  dropdown: Record<string, boolean>;
}

export function HeaderMobileLinks({
  routes,
  openDropdown,
  closeDropdown,
  dropdown,
}: TProps) {
  return (
    <div className="cr-header__inner-mobile cr-header-link">
      <EventsTrackWrapperClick id={["HEADER", 22]}>
        <div
          role="button"
          tabIndex={0}
          className="promo-icon-39 ic"
          onClick={() => openDropdown("mobile")}
          onBlur={() => closeDropdown("mobile")}
        />
      </EventsTrackWrapperClick>

      {dropdown.mobile && (
        <div
          role="button"
          className="cr-header__inner-mobile-dropdown"
          tabIndex={0}
          onMouseDown={(e) => e.preventDefault()}
        >
          <div className="cr-header__inner-mobile-dropdown-head">
            <HeaderUserLink withNotifications={false} />

            <div
              role="button"
              tabIndex={0}
              className="ic promo-icon-14"
              onClick={() => closeDropdown("mobile")}
            />
          </div>

          {routes?.map((item) => (
            <HeaderMobileRoute item={item} closeDropdown={closeDropdown} />
          ))}
        </div>
      )}
    </div>
  );
}
