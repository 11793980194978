import React from "react";
import { withRouter } from "react-router-dom";
import NewsCard from "../../presentational/Cards/NewsCard/NewsCard";

import "./NewsPage.scss";
import CardGridPageGenerate from "../../presentational/CardGridPageGenerate/CardGridPageGenerate";

const NewsPage = (props) => {
  const title = "«Город идей» — Новости платформы";
  const description =
    "Будьте в курсе всех новостей и событий платформы «Город идей». Узнавайте о проектах, мероприятиях, и о том, как город воплощает идеи жителей в реальность.";
  return (
    <CardGridPageGenerate
      section="news"
      title={title}
      description={description}
      opengraph={{ title, description }}
      pageTitle="Новости"
      endpoint="/api/news/list?statuses=PUBLISHED,PUBLISHED_MAIN&sort=published,desc&"
      screenWidth={props.screenWidth}
      tags={props.tags}
    >
      <NewsCard
        href={(news) => `${props.location.pathname}/${news.number}`}
        linkRel="canonical"
      />
    </CardGridPageGenerate>
  );
};

export default withRouter(NewsPage);
