import getNewsServiceLine from "../../services/newsServiceLine";
import { INewsLine } from "../../types/NewsLine.interface";

export const GET_NEWS_LINES_REQUEST = "GET_NEWS_LINES_REQUEST";
export const GET_NEWS_LINES_SUCCESS = "GET_NEWS_LINES_SUCCESS";
export const GET_NEWS_LINES_FAIL = "GET_NEWS_LINES_FAIL";

export function getNewsLines() {
  return (dispatch) => {
    dispatch(request());

    getNewsServiceLine.getNewsById().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: GET_NEWS_LINES_REQUEST };
  }

  function success(response) {
    const lines = response?.data as INewsLine[];

    const linesWithPosition = [];
    const linesWithoutPosition = [];

    for (const line of lines) {
      if (line.position === null) {
        linesWithoutPosition.push(line);
      } else {
        linesWithPosition.push(line);
      }
    }

    const sortedLines = linesWithPosition.sort(
      (a, b) => a.position - b.position
    );

    if (linesWithoutPosition.length > 0) {
      const lastPosition =
        sortedLines.length > 1
          ? sortedLines[sortedLines.length - 1].position
          : 0;

      linesWithoutPosition.forEach((line, i) => {
        sortedLines.push({
          ...line,
          position: lastPosition + i + 1,
        });
      });
    }

    return {
      type: GET_NEWS_LINES_SUCCESS,
      payload: sortedLines,
    };
  }
  function failure(error) {
    return { type: GET_NEWS_LINES_FAIL, error };
  }
}
