import React, { useState } from "react";
import { AppModal } from "../../Modal/AppModal";
import Button from "../../../presentational/Controls/Button/Button";
import styles from "./JobReportsModal.module.scss";
import EventsTrackWrapperClick from "../../EventsTrack/wrappers/EventsTrackWrapperClick";
import EventsTrackClickModalCloseBtn from "../../EventsTrack/wrappers/helpers/EventsTrackClickModalCloseBtn";
import Select from "../../../presentational/Controls/Select/Select";
import { Report } from "../../../../types/Report.interface";
import Loader from "../../../presentational/Loaders/Loader/Loader";

type Props = {
  reports: Report[];
  isOpened: boolean | null;
  onClose: () => void;
  isFetching: boolean;
};

const JobReportsModal = ({
  reports = [],
  isOpened,
  onClose,
  isFetching,
}: Props) => {
  const [activeReport, setActiveReport] = useState<Report | null>(null);

  const handleClose = () => {
    onClose();
    setActiveReport(null);
  };

  const isDontHaveReports = reports.length === 0;

  return (
    <AppModal
      center
      classNames={{ modal: styles["job-reports-modal"] }}
      overlayId="modal-overlay"
      closeIconId="close-modal-icon"
      blockScroll
      closeIconSize={25}
      open={!!isOpened}
      closeOnEsc
      closeOnOverlayClick={false}
      onClose={handleClose}
      CloseBtnComponent={EventsTrackClickModalCloseBtn}
    >
      <div className={styles["job-reports"]}>
        <div className={styles["job-reports__title"]}>Выбор отчета</div>
        {isFetching ? (
          <div className={styles["job-reports__loader-container"]}>
            <Loader />
          </div>
        ) : (
          <>
            <div className={styles["job-reports__description"]}>
              С гордостью делимся с вами результатами, которых удалось достичь
              городу благодаря инициативам жителей, полученным на платформе
              "Город идей".
            </div>

            <Select
              isDisabled={isDontHaveReports}
              items={reports}
              bindTo="year"
              value={activeReport?.year}
              closeOnSelect
              placeholder="Выберите год"
              label="Выберите отчетный год"
              onItemSelect={(report) => {
                setActiveReport(report);
              }}
            />

            {isDontHaveReports && (
              <div className={styles["empty-reports-message"]}>
                <span>
                  В данный момент в этом разделе нет загруженных отчетов.
                  Пожалуйста, проверьте позже.
                </span>
              </div>
            )}

            <ul className={styles["job-reports__links"]}>
              {activeReport &&
                activeReport.reports?.map((report, key) => (
                  <li className={styles["job-reports__link"]} key={key}>
                    <EventsTrackWrapperClick
                      id={196}
                      replace={[report.periodTitle]}
                    >
                      <a
                        href={`/uploads/get/${report.attachmentId}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button
                          classList={styles.button}
                          text={report.periodTitle}
                          type="filled"
                        />
                      </a>
                    </EventsTrackWrapperClick>
                  </li>
                ))}
            </ul>
          </>
        )}
      </div>
    </AppModal>
  );
};

export default JobReportsModal;
