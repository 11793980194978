import { useCallback, useEffect, useRef } from "react";

interface TProps {
  count: number;
  elementRef: React.RefObject<HTMLElement>;
}

export const useNumberAnimation = ({ count, elementRef }: TProps) => {
  const isAnimatedRef = useRef(false);

  const setAnimatedCount = useCallback(
    (animatedCount: number) => {
      //   console.log(elementRef.current);
      if (elementRef.current) {
        // eslint-disable-next-line no-param-reassign
        elementRef.current.textContent = animatedCount.toLocaleString("ru-RU");
      }
    },
    [elementRef]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isAnimatedRef.current) {
          if (!(count || 0)) {
            setAnimatedCount(0);
            return;
          }

          const startValue = Math.ceil((count || 0) * 0.1);
          setAnimatedCount(startValue);

          let fastTimer;
          let slowTimer;
          const totalDuration = 3000;
          if ((count || 0) <= 10) {
            const slowInterval = totalDuration / ((count || 0) - startValue);
            let current = startValue;
            fastTimer = setInterval(() => {
              current++;
              setAnimatedCount(current);
              if (current >= (count || 0)) {
                clearInterval(fastTimer);
              }
            }, slowInterval);
          } else {
            const finalSteps = 10;
            const fastTarget = (count || 0) - finalSteps;
            const fastDuration = totalDuration * 0.6;
            const fastSteps = 60;
            const fastIncrement = (fastTarget - startValue) / fastSteps;
            let current = startValue;
            fastTimer = setInterval(() => {
              current += fastIncrement;
              if (current >= fastTarget) {
                setAnimatedCount(Math.floor(fastTarget));
                clearInterval(fastTimer);
                let slowCurrent = Math.floor(fastTarget);
                const slowDuration = totalDuration * 0.4;
                const slowIntervalTime = slowDuration / finalSteps;
                slowTimer = setInterval(() => {
                  slowCurrent++;
                  setAnimatedCount(slowCurrent);
                  if (slowCurrent >= (count || 0)) {
                    clearInterval(slowTimer);
                  }
                }, slowIntervalTime);
              } else {
                setAnimatedCount(Math.floor(current));
              }
            }, fastDuration / fastSteps);
          }
          isAnimatedRef.current = true;
        }
      },
      { threshold: 0.1 }
    );
    if (elementRef.current) {
      observer.observe(elementRef.current);
    }
    return () => {
      observer.disconnect();
      isAnimatedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, setAnimatedCount, elementRef.current]);
};
